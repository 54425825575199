import axios from "axios";
import  Constant from "./Constant";
import crypto from 'crypto';
const LocalStorageService = (function(){
 function _serviceToken(isTokened) {
  
  var tokenVal = "notoken";
   var tokenExpiry = new Date().getTime();  
   if(localStorage.getItem("expiry") <= tokenExpiry || !localStorage.getItem("access_token")){
    localStorage.removeItem("isToken");
     
    axios({
        method: 'POST',
        url: Constant.url+"auth/login",
        data: {email: "rashid@convexinteractive.com",password:"webapi123789"}
    }).then(function (response) {
      localStorage.removeItem("access_token");
      
      localStorage.removeItem("expiry");
      var expiry = new Date().getTime()+(60*59*1000)
      
      // var expiry = new Date().getTime()+60000;
      localStorage.setItem("expiry", expiry)
      localStorage.setItem("isToken",'enable');
      localStorage.setItem("access_token", response.data.token)
      tokenVal = "bearer " + response.data.token;
      isTokened();
    }).then(function (response) {
        
    });
    }
    else{
      isTokened();
    }
    var config = {
      headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
    };
    
    
    return true;
 }

 function decryptAes(text) {
  let key = 'yNNH04mZplp7xDWTnw3r8AVvAiiAPtA7';
  let iv = 'spfjtrbhgijlenpy';
  let encryptedText = Buffer.from(text, 'hex');
  let decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
 }

 function encryptAes(text) {   
  if(text == null){
    return "";
  }
  let key = 'yNNH04mZplp7xDWTnw3r8AVvAiiAPtA7';
  let iv = 'spfjtrbhgijlenpy';
  // Creating Cipheriv with its parameter
  let cipher = 
     crypto.createCipher('aes-256-cbc', Buffer.from(key), iv);
   
  // Updating text
  let encrypted = cipher.update(text);
   
  // Using concatenation
  encrypted = Buffer.concat([encrypted, cipher.final()]);
   
  // Returning iv and encrypted data
  return encrypted.toString('hex');
 }
  
  

return {
   _serviceToken : _serviceToken,
   decryptAes : decryptAes,
   encryptAes : encryptAes

 }
})();
export default LocalStorageService;