import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";

class PersonalizeProgram extends React.Component{
    constructor(){
        super();      
        this.state = {
          programs : []
        }
    }
    handleProgramList = () => {
      let programs = "";
      programs = this.props.data.map((program,index) => (
        <div class="prog_list_cont_inner">
        <a class={(program.program_fav) ? "dramas_box active" : "dramas_box"} id={"dramas_box"+index} onClick={(e) => this.programActive("dramas_box"+index,program.program_id,e)}>
        <div class="shows_slider_img"><ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name}/></div>
        <div class="shows_slider_txt">
          <h4>{program.program_name}</h4>
        </div>
        </a>
      </div>
              
      ));        
      return programs;
    };  

    programActive = (e,programId) => {      
      
      this.setState({
        programs: this.state.programs.concat(programId)
      })
      
      $("#"+e).toggleClass('active');
    }
    addProgram = () =>{
      $("#submitbtn").text('Processing...');
      LocalStorageService._serviceToken(this.initializeToken);
    }

    initializeToken = () => {      
      let classRef = this;
      let user_id = localStorage.getItem('user_id');
      let params = JSON.stringify({ programs: this.state.programs, user_id: user_id });
      axios({
          method: 'POST',
          url: Constant.url+"add-favourite-programs-2",
          data: { dataparams: LocalStorageService.encryptAes(params)},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
              $("#skipVal").click(); 
              
              // return <Redirect  to='/basicdetail'/>;                                               
          }else{
            // $("#submitbtn").text('NEXT');
            $("#skipVal").click(); 
          }
      }).then(function (result) {
          // console.log(response);
      });
    }

    render(){
        return(
           <div class="splash">
                <div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{display : 'block'}}>
                <header>
                  <div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV"/></Link>
                    <div class="right_hdr"> 
                      <div class="skip">
                      <p id="skipVal" onClick={this.props.nextVal}>SKIP</p>
                      </div> 
                  	</div>
                  </div>
                </header>	
                <div class="uk-modal-dialog popup_content personlize ">
                	<div class="">
                		<div class="">                         
                    <section class="pad_left pad_right pad_tb prog_box_sec margin_personlize">
                    	<h1>Choose your favorite Shows</h1>
                				<p>Your experience will improve the more you watch</p>
                      <div class="prog_list_cont">
                        {this.handleProgramList()}                          
                      </div>
                    </section>    

                		</div>
                	</div>
                </div>
                {/* <!-- Footer Start --> */}
                <footer>
                	<div class="gf_txt  ">
                		<a id="submitbtn" onClick = {this.addProgram} class="gf_watch_btn">NEXT</a>
                	</div>
                </footer>
                {/* <!-- Footer End -->      */}
                </div>               
           </div>
        )
    }
}
export default withRouter(PersonalizeProgram);