import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { withRouter, Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Side_Bar_User from '../section/Side_Bar_User';
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import LocalStorageService from "../../utils/LocalStorageService";
import { isMobile } from "react-device-detect";
var mobileView = false;
class Packages extends React.Component {
    constructor() {
        super();

        this.handleMobileChange = this.handleMobileChange.bind(this);
        // this.handleOTPChange = this.handleOTPChange.bind(this);
        this.state = {
            // mobileView:false,
            packages: {
                isLoading: false,
                package_data: []
            },
            mobile_num: localStorage.getItem('mobile').substr(3, localStorage.getItem('mobile').length),
            package_id: '',
            package_renew_title: '',
            package_renew_price: '',
            package_renew_desc: '',
            telco: '',
            image: '',
            otp: '',
            paymentMethod: '',
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '',
        }
    }

    componentDidMount() {                
        MetaCreation.createMeta('static', 'unsigned', (e) => this.callMetaData(e));
        // if(window.matchMedia('(max-width: 787px)').matches && localStorage.getItem('package_id')){
        
        //     mobileView = true;
        //     this.setState({package_id: localStorage.getItem('package_id')})
        //     this.payWithBalance();
        // }else{
        //     window.location.href = '/signed';
        // }
        // if (localStorage.getItem('mobileSelectdPackage') == "yes" && isMobile) {            
        //     this.selectPackageMobile();
        // } else if (isMobile) {
        
        //     window.location.href = '/profile';
        // }else{
            this.callApi();
        // }
    }
    handleMobileChange(event) {
        this.setState({ mobile_num: event.target.mobile });
    }
    handleChange = otp => this.setState({ otp });

    callApi = () => {
        let classRef = this;
        axios({
            url: Constant.url + 'packages-v2',
            method: 'POST',
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: { 'type': 'prepaid', user_id: localStorage.getItem("user_id") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                
                classRef.setState({
                    packages: {
                        isLoading: true,
                        package_data: response.data.data.packages
                    }
                })

            }
        })
    }

    unSubscription = () => {              
        if (localStorage.getItem('package_status') == 'true') {
            $("#unsubSuccess").html('Processing...');
            $("#unsubSuccessdiv").show();  
            let classRef = this;
            axios({
                url: Constant.url + 'unsubscribe-with-vendor-web',
                method: 'POST',
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
                data: { user_id: localStorage.getItem("user_id"), package_id: localStorage.getItem("package_id")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                
                if (response.data.code === 200) {
                    $("#unsubSuccess").html('Successfully Unsubscribed !');   
                    localStorage.setItem('subscriptionStatus','')
                    window.location.href = '/packages';
                }else{
                    $("#unsubSuccessdiv").hide();   
                    $("#unsubError").show();    
                    setTimeout(function(){
                        $("#unsubSuccessdiv").hide();  
                        $("#unsubError").hide();    
                    },1000)                
                }
            })
        }
    }

    callMetaData = (metaResponse) => {
        
        this.setState({
            metaKeywords: metaResponse.keywords,
            metaTitle: metaResponse.title,
            metaDescription: metaResponse.description,
            metaImage: metaResponse.thumbnail,
            schema: metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    selectPackage = () => {
        let classRef = this;
        
        if ($("input[name='pkg_checkbox_inner']:checked").val() != undefined) {
            $(".subscribe_packages").hide();
            $(".signin_coverSec").hide();
            $(".mobilebalance_easypesa_packages").show();
            let package_detail = $("input[name='pkg_checkbox_inner']:checked").val();
            let package_detail_split = package_detail.split("_");
            classRef.setState({ package_id: package_detail_split[0] });
            classRef.setState({ package_renew_title: package_detail_split[1] });
            classRef.setState({ package_renew_price: package_detail_split[2] });
            classRef.setState({ package_renew_desc: package_detail_split[3] });
        }
    }

    selectPackageNew = () => {
        let classRef = this;
        $("#sub").html("Processing...");
        let package_detail = $("input[name='pkg_checkbox_inner']:checked").val();
        let package_detail_split = package_detail.split("_");
        this.setState({ package_id: package_detail_split[0] });
        this.setState({ package_renew_title: package_detail_split[1] });
        this.setState({ package_renew_price: package_detail_split[2] });
        this.setState({ package_renew_desc: package_detail_split[3] });
        
            axios({
                url: Constant.url + 'subscription-new',
                method: 'POST',
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
                data: {
                     user_id: localStorage.getItem("user_id"),
                     package_id: package_detail_split[0],
                     type: "prepaid", 
                     mobile: "92"+localStorage.getItem("mobile"),                      
                     from: "web", 
                    }
            }).then(function (result) {
                console.log("sucssss")
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                console.log(response.data,"asdasd")
                if (response.data.code === 200) {
                    classRef.getUserDetail()
                    $("#errorPaymentMessage").html(response.data.message)
                    $(".paymentUnsuccessful").show();                                               
                      setTimeout(function () {
                        window.location.href = '/packages';
                    }, 500)
                }else{
                    $("#sub").html("Subscribe");                    
                    $("#unsubError").html(response.data.message);    
                    $("#unsubErrordiv").show();    
                              
                }
            })
        

    }


    selectPackageMobile = () => {
        $(".subscribe_packages").hide();
        $(".signin_coverSec").hide();
        $(".mobilebalance_easypesa_packages").show();
        this.setState({ package_id: localStorage.getItem('mobile_package_id') });
        this.setState({ package_renew_title: localStorage.getItem('mobile_package_renew_title') });
        this.setState({ package_renew_price: localStorage.getItem('mobile_package_renew_price') });
        this.setState({ package_renew_desc: localStorage.getItem('mobile_package_renew_desc') });
        localStorage.setItem('mobileSelectdPackage', 'no')
    }

    selectTelco = () => {
        // images/zong.svg
        let classRef = this;
        
        $(".mobilebalance_easypesa_packages").hide();
        $(".subscribe_packages").hide();
        $(".payWithMobile").show();
        var telco = '';
        var image = '';
        if ($("#telco_check1").is(":checked")) {
            telco = $("#telco_check1").val();
            image = 'images/' + $("#telco_check1").val() + '.png';
        }
        if ($("#telco_check2").is(":checked")) {
            telco = $("#telco_check2").val();
            image = 'images/' + $("#telco_check2").val() + '.svg';
        }
        if ($("#telco_check3").is(":checked")) {
            telco = $("#telco_check3").val();
            image = 'images/' + $("#telco_check3").val() + '.png';
        }
        if ($("#telco_check4").is(":checked")) {
            telco = $("#telco_check4").val();
            image = 'images/' + $("#telco_check4").val() + '.svg';
        }
        classRef.setState({ telco: telco });
        classRef.setState({ image: image });
        $(".payWithMobile").hide();
        $(".reviewPayment").show();
        
        
    }
    confirmPayment = () => {
        let classRef = this;
        if($("#mobile").val() == ""){
            $("#unsubError").html('Please Enter Mobile Number');
            $("#unsubErrordiv").show();  
            setTimeout(function(){
                $("#unsubSuccessdiv").hide();  
                $("#unsubError").hide(); 
                $("#unsubError").hide();                
            },2000)
            return;
        }        
        
        $("#unsubError").hide();  
        $(".reviewPayment").hide();
        $(".approvalpending").show();
        axios({
            url: Constant.url + 'easypay-transaction',
            method: 'POST',
            data: {
                'package_id': classRef.state.package_id,
                'user_id': localStorage.getItem('user_id'),
                'mobile': localStorage.getItem('mobile'),
                'telco': classRef.state.telco,
                'email': localStorage.getItem('email'),
                'mobileAccountNo': $("#mobile").val(),
                'from': 'web'
            }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                if (response.data.data.first_time == 'yes') {
                    $(".approvalpending").hide();
                    $(".buyWithEasypesa").show();
                    $(".successPaymentMessage").html(response.data.data.message);
                    classRef.getUserDetail()
                    // setTimeout(function () {
                    //     window.location.href = '/packages';
                    // }, 500)
                } else if (response.data.status == 'FAILURE') {
                    $(".approvalpending").hide();
                    $("#errorPaymentMessage").html(response.data.data.message)
                    $(".paymentUnsuccessful").show();
                } else {
                    $(".approvalpending").hide();
                    $(".paymentSuccessful").show();
                    classRef.getUserDetail()
                    setTimeout(function () {
                        window.location.href = '/packages';
                    }, 500)
                }

            } else {
                $(".approvalpending").hide();
                $("#errorPaymentMessage").html(response.data.data.message)
                $(".paymentUnsuccessful").show();
            }
        })
    }
    getUserDetail = () => {
        let classRef = this;
        let params = JSON.stringify({ user_id: localStorage.getItem('user_id') });
        axios({
            url: Constant.url + 'user-package-detail-v2',
            method: 'POST',
            // data: { dataparams: LocalStorageService.encryptAes(params)},
            data: { user_id: localStorage.getItem('user_id')},
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {                
                if (response.data.data[0].user.package.active_package == 1) {
                    localStorage.setItem('userPackageTitle', response.data.data[0].user.package.title);
                    localStorage.setItem('package_id', response.data.data[0].user.package.id);
                    localStorage.setItem('userPackageDesc', response.data.data[0].user.package.description);
                    localStorage.setItem('userPackageExpiryDate', response.data.data[0].user.package.expiry_date);
                    localStorage.setItem('validTillDate', response.data.data[0].user.package.valid_till_date);
                    localStorage.setItem('userCreatedAt', response.data.data[0].user.created_at);
                    localStorage.setItem('subscriptionStatus', response.data.data[0].user.package.subscription_status);
                    localStorage.setItem('userPackageAutoRenewal', response.data.data[0].user.package.auto_renewal);
                    localStorage.setItem('package_status', true);
                } else {
                    localStorage.setItem('userPackageTitle', '');
                    localStorage.setItem('package_id', '');
                    localStorage.setItem('userPackageDesc', '');
                    localStorage.setItem('userPackageExpiryDate', '');
                    localStorage.setItem('validTillDate', '');
                    localStorage.setItem('userCreatedAt', '');
                    localStorage.setItem('subscriptionStatus', '');
                    localStorage.setItem('userPackageAutoRenewal', '');
                    localStorage.setItem('package_status', false);
                }
            }
        })
    }
    handlePackage() {
        let result = "";
        let index = 0;
        let classRef = this;
        result = this.state.packages.package_data.map((response, index) => (
            <div className="package_checkbox" key={index}>
                <input
                    id={"pkg_checkbox_inner" + response.id}
                    type="radio"
                    name="pkg_checkbox_inner"
                    value={response.id + '_' + response.title + '_' + response.price + '_' + response.description}
                    defaultChecked={(response.active_package == 1) ? true : false}
                />
                <label htmlFor={"pkg_checkbox_inner" + response.id}>
                    <i className="pkg_icon"></i>{response.title}
                </label>
            </div>
        )
        ,
            $.each(classRef.state.packages.package_data, function (i) {
                if (classRef.state.packages.package_data[i].active_package == 1) {
                    $("#sub").hide()
                    $("#unsub").show()
                }
            })
        );
        $('.subscribe_packages').on('change', '.package_checkbox', function (e) {
            console.log(localStorage.getItem('package_id'),"zxczxc");
            let package_det = $("input[name='pkg_checkbox_inner']:checked").val()
            if (package_det[0] == localStorage.getItem('package_id')) {
                $("#sub").hide()
                $("#unsub").show()
            } else {
                $("#unsub").hide()
                $("#sub").show()
            }
        });
        return result;
    }


    // clickBack = () =>{        
    //     let classRef = this;
    //     var $radios = $('input:radio[name=pkg_checkbox_inner]');
    //     if($radios.is(':checked') === false) {
    //         $radios.filter('[value='+pkg_checkbox_inner+']').prop('checked', true);
    //     }
    // }

    otpTransaction = () => {
        let classRef = this;
        $(".buyWithEasypesa").hide();
        $(".approvalpending").show();
        axios({
            url: Constant.url + 'otp-transaction',
            method: 'POST',
            data: {
                'package_id': classRef.state.package_id,
                'user_id': localStorage.getItem('user_id'),
                'mobile': localStorage.getItem('mobile'),
                'telco': classRef.state.telco,
                'email': localStorage.getItem('email'),
                'mobileAccountNo': $("#mobile").val(),
                'from': 'web',
                'otp': classRef.state.otp
            }
        }).then(function (result) {
            let response = {};
            response['data'] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code == 200) {
                $(".approvalpending").hide();
                $(".paymentSuccessful").show();
                classRef.getUserDetail()
                  setTimeout(function () {
                        window.location.href = '/packages';
                    }, 500)
            } else {
                $(".approvalpending").hide();
                $(".paymentUnsuccessful").show();
            }
        })
    }

    selectMethod = () => {
        $(".mobilebalance_easypesa_packages").hide();
        $(".payWithMobile").show();
        this.setState({ paymentMethod: 'easypesa' })
    }

    unSubModal = () =>{
        $("#modal_dialog").addClass('uk-flex uk-open');
    }

    render() {
        return (
            <div>
                <Meta schema={null} metaKeywords={this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription} metaImage={this.state.metaImage} />
                <Header />
                <div className="content_sec">
                <div id="modal_dialog" class="uk-flex-top modal_sharing" uk-modal="">
                  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close=""></button>
                    <div class="sharing_content">
                    <h3>Are you sure, you want to unsubscribe ?</h3>
                        <button className = "uk-modal-close">NO</button>
                        <button onClick={this.unSubscription} >CONFIRM</button>
                      </div>
                  </div>    
                </div>                    
                    <div uk-height-match="target: .h_match">
                        <Side_Bar_User />
                        <div className="h_match asad_bg telco_bg">
                            {/* package 1st */}
                            <section className="pad_left pad_right pad_tb prog_box_sec signin_coverSec">
                                <div className="signin_cover"><img src="/images/signin_img.jpg" /></div>
                                <div className="subscribe_packages">
                                    {(this.state.packages.isLoading) ? this.handlePackage() : ''}
                                    <button className="buywith" id="sub" onClick={this.selectPackageNew}>Subscribe</button>
                                    <div><button className={localStorage.getItem('package_status') == "true" ? 'buywith' : 'buywith unsbpurchase'} id="unsub" onClick={this.unSubModal} style={{ display: 'none' }}>{localStorage.getItem('package_status') == "true" ? 'Unsubscribe' : 'Unsubscribed'}</button></div>
                                </div>

                            </section>
                            {/* Mobile Balance And Easypesa Package */}
                            <section className="pad_left pad_right pad_tb mobilebalance_easypesa_packages epheight" style={{ display: 'none' }}>
                                <div className="backbar">
                                    {/* {(!isMobile) ? */}
                                        <a className="back_btn" onClick={() => { $("#unsub").hide(); $("#sub").show(); $(".mobilebalance_easypesa_packages").hide(); $(".signin_coverSec").show(); $(".subscribe_packages").show(); }}><img src="/images/ic_left.svg" uk-svg="" />Back</a> 
                                        {/* : ''} */}
                                    <h1 className="page_title">Buy with Easypaisa</h1>
                                </div>
                                <div className="paymentmethodsec mrgn_minus" style={{ display: 'none' }}>
                                    <h3><img src="/images/mobile_check.svg" uk-svg="" /> Buy with Easypaisa</h3>
                                    <div className="MainThreeCenterBox">
                                        <div className="ThreeCenterBox">
                                            <div className="paymentmethodbtns">
                                                <p>Directly with your networks</p>
                                                <button><img src="/images/telenor.png" /></button>
                                                <button><img src="/images/zong.svg" /></button>
                                            </div>
                                        </div>
                                        <div className="ThreeCenterBox uk-text-center dividerwdthbox">
                                            <p className="uk-divider-icon dividerwdth">OR</p>
                                        </div>
                                        <div className="ThreeCenterBox">
                                            <div className="paymentmethodbtns">
                                                <p>3 Days FREE when you pay with Google Play</p>
                                                <button className="buywith">Buy with <img src="/images/gmail_icon.svg" /> Pay</button>
                                                <p><span>Supports all networks</span> <img src="/images/telenor.png" /> <img src="/images/zong.svg" /> <img src="/images/jazz.png" /> <img src="/images/ufone.svg" /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="paymentmethodsec mrgn_minus">
                                    <h3><img src="/images/account_balance.svg" uk-svg="" /> Buy with Easypaisa</h3>
                                    <div className="MainThreeCenterBox">
                                        <div className="ThreeCenterBox">
                                            <div className="paymentmethodbtns">
                                                <p>Through your easypaisa account number</p>
                                                <button className="btnLarge" onClick={this.selectTelco}><img src="/images/easypesa.png" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="paymentmethodsec">
                        <h3><img src="/images/creditcard_icon.svg" uk-svg="" /> Buy with Credit/Debit card</h3>
                        <div className="MainThreeCenterBox">
                            <div className="ThreeCenterBox">
                            <div className="paymentmethodbtns">
                                <p>Through your easypaisa account</p>
                                <button className="btnLarge" type="button" uk-toggle="target: #CreditDebitModal"><img src="/images/easypesa.png" /></button>
                            </div>
                            </div>
                        </div>
                    </div> */}
                            </section>
                            {/* Payment Mothd Easypesa */}
                            <section className="pad_left pad_right pad_tb buyWithEasypesa epheight" style={{ display: 'none' }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".buyWithEasypesa").hide(); $(".reviewPayment").show(); }}><img src="/images/ic_left.svg" uk-svg="" />Back</a>
                                    <h1 className="page_title">Buy with Easypaisa</h1>
                                </div>
                                <div className="otp_verification_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div class="easypesalogo">
                                                <img src="/images/easypesa.png" />
                                            </div>
                                            <h3>Enter your verification code</h3>
                                            <div className="pin_code_box">
                                                <OtpInput
                                                    value={this.state.otp}
                                                    onChange={this.handleChange}
                                                    numInputs={4}
                                                    separator={<span>&nbsp;</span>}
                                                />
                                            </div>
                                            <div class="otp_verification_txt">
                                                <div>
                                                    <p>Enter the code sent to <span>{$("#mobile").val()}</span></p>
                                                </div>
                                                <p>You will receive an SMS to confirm your payment.</p>
                                            </div>
                                            <div><a class="next_telco_btn" onClick={this.otpTransaction}>Verify 4-digit code</a></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* Approval Pending Easypesa */}
                            <section className="pad_left pad_right pad_tb approvalpending epheight" style={{ display: 'none' }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".approvalpending").hide(); $(".buyWithEasypesa").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Easypaisa</h1>
                                </div>
                                <div className="otp_verification_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="easypesalogo">
                                                <img src="/images/easypesa.png" />
                                            </div>
                                            <p className="aprovalptext">Approval pending...</p>
                                            <div className="easypesaapprovalscreen">
                                                <img src="/images/easypesaapprovalscreen.png" />
                                            </div>
                                            <div className="otp_verification_txt">
                                                <div>
                                                    <p>Open your account in easypaisa app and approve the request to proceed.</p>
                                                </div>
                                                <div className="spiner_aproval">
                                                    <div uk-spinner="" />
                                                </div>
                                                <p className="sixtysec">approve within 60 sec</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* Select your network */}
                            {/* <section className="pad_left pad_right pad_tb payWithMobile epheight" style={{ display: 'none' }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".payWithMobile").hide(); $(".mobilebalance_easypesa_packages").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Mobile Balance</h1>
                                </div>
                                <div className="select_telco_section">
                                    <h3>Select your network</h3>

                                    <div className="package_checkbox">
                                        <input id="telco_check1" type="radio" name="telco_check" defaultValue="telenor" defaultChecked="checked" /><label htmlFor="telco_check1"><i className="pkg_icon"></i>Telenor <img src="/images/telenor.png" /></label>
                                    </div>
                                    <div className="package_checkbox">
                                        <input id="telco_check2" type="radio" name="telco_check" defaultValue="zong" /><label htmlFor="telco_check2"><i className="pkg_icon"></i>Zong 4g  <img src="/images/zong.svg" /></label>
                                    </div>
                                    <div className="package_checkbox">
                                        <input id="telco_check3" type="radio" name="telco_check" defaultValue="jazz" /><label htmlFor="telco_check3"><i className="pkg_icon"></i>Jazz  <img src="/images/jazz.png" /></label>
                                    </div>
                                    <div className="package_checkbox">
                                        <input id="telco_check4" type="radio" name="telco_check" defaultValue="ufone" /><label htmlFor="telco_check4"><i className="pkg_icon"></i>Ufone  <img src="/images/ufone.svg" /></label>
                                    </div>
                                    <div className="help_support_box">
                                        <h4>Help & Support</h4>
                                        <p>Service provider: mjunoon.tv</p>
                                        <p>You agree that you will automatically be charged the subscription fee every period until you cancel.</p>
                                        <a className="next_telco_btn" onClick={this.selectTelco}>Next</a>
                                    </div>
                                </div>
                            </section> */}
                            <section className="pad_left pad_right pad_tb reviewPayment epheight" style={{ display: "none" }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".reviewPayment").hide(); $(".mobilebalance_easypesa_packages").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Mobile Balance</h1>
                                </div>
                                <div className="select_telco_section">
                                    <h3>{(this.state.packages.package_data.length > 0 && this.state.package_id != 0) ? this.state.package_renew_title : ''}</h3>
                                    <div className="review_txt">
                                        <h4>{(this.state.packages.package_data.length > 0 && this.state.package_id != 0) ? this.state.package_renew_price : ''}</h4>
                                        <p>{(this.state.packages.package_data.length > 0 && this.state.package_id != 0) ? this.state.package_renew_desc : ''}</p>
                                    </div>
                                    <div className="select_number_box">
                                        <h5>Enter Your Easypaisa Account Number</h5>
                                        <p>You will receive one time access code via SMS</p>
                                        <div className="select_number_telco">
                                            <img src='/images/easypesa.png' />
                                        </div>
                                        {/* <input type="text" className="telco_code" defaultValue="PK +92" /> */}
                                        <input type="number" className="telco_number" name="mobile" id="mobile"  minLength="10" maxLength="11" placeholder="03xxxxxxxxx" onChange={this.handleMobileChange} />
                                    </div>
                                    <div className="help_support_box">
                                        <h4>Help & Support</h4>
                                        <p>Service provider: mjunoon.tv</p>
                                        <p>You agree that you will automatically be charged the subscription fee every period until you cancel.</p>
                                        <a className="next_telco_btn" onClick={this.confirmPayment}>Pay with Easypaisa</a>
                                    </div>
                                </div>
                            </section>
                            <section className="pad_left pad_right pad_tb otpVerification epheight" style={{ display: "none" }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".otpVerification").hide(); $(".payWithMobile").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Mobile Balance</h1>
                                </div>
                                <div className="otp_verification_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <h3>Enter your verification code</h3>
                                            <div className="pin_code_box">
                                                <OtpInput
                                                    value={this.state.otp}
                                                    onChange={this.handleChange}
                                                    numInputs={4}
                                                    separator={<span>&nbsp;</span>}
                                                />
                                            </div>
                                            <div className="otp_verification_txt">
                                                <div>
                                                    <p>Enter the code sent to <span>+92 {this.state.mobile_num}</span></p>
                                                </div>
                                                <p>You will receive an SMS to confirm your payment.</p>
                                            </div>
                                            <a className="next_telco_btn" onClick={this.checkPaymentStatus}>Next</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="pad_left pad_right pad_tb paymentSuccessful epheight" style={{ display: "none" }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".paymentSuccessful").hide(); $(".buyWithEasypesa").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Mobile Balance</h1>
                                </div>
                                <div className="otp_verification_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="payment_successful">
                                                <img src="/images/check_outline.svg" />
                                                <h3>Payment Completed Successfully</h3>

                                                <p id="successPaymentMessage"></p>
                                                {/* <p>Check your email for your receipt.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="pad_left pad_right pad_tb paymentUnsuccessful epheight" style={{ display: "none" }}>
                                <div className="backbar">
                                    <a className="back_btn" onClick={() => { $(".paymentUnsuccessful").hide(); $(".buyWithEasypesa").show(); }}><img src="/images/ic_left.svg" uk-svg="" /> Back</a>
                                    <h1 className="page_title">Buy with Mobile Balance</h1>
                                </div>
                                <div className="otp_verification_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="payment_unsuccessfully">
                                                <Link to='/'><img src="/images/close_outline.svg" /></Link>
                                                <h3>Payment Completed Unsuccessfully</h3>
                                                <p id="errorPaymentMessage"></p>
                                                {/* <p>Check your email for your receipt.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <Footer />
                        </div>
                    </div>

                </div>
                {/* Buy with Credit/Debit card modal Start */}
                <div id="CreditDebitModal" className="uk-flex-top CreditDebitModal" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <h3>Buy with credit card</h3>
                        <p><img src="/images/easypesadark.png" /><img src="/images/creditcard_icon.svg" uk-svg="" /></p>
                        <div className="CreditDebitModalbtns">
                            <button className=" uk-modal-close" type="button">Cancel</button>
                            <button className type="button">OK</button>
                        </div>
                    </div>
                </div>
                {/* Buy with Credit/Debit card modal End */}
                <div style={{display : 'none'}} id="unsubErrordiv" className="msg_box">
                    <div className="ai_recom" id="unsubError">Unable to unsubscribe !</div>
                </div>
                <div style={{display : 'none'}} id="unsubSuccessdiv" className="msg_box">
                    <div className="ai_recom_green" id="unsubSuccess">Successfully Unsubscribed !</div>
                </div>
                <div style={{display : 'none'}} id="subSuccessdiv" className="msg_box">
                    <div className="ai_recom_green" id="subSuccess">Successfully Subscribed !</div>
                </div>
            </div>
        )
    }
}
export default withRouter(Packages);
