import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from "jquery";
import Header from "../common/Header";
class TopTrending extends React.Component {
  constructor() {
    super();
    //banner
    this.state = {
      fbUrl : "",
      whatsappUrl : "",
      twitterUrl : "",
      googleUrl : "",
      linkedinUrl : ""
    }
  }


  handleProgramList = () => {
    let programs = "";
    let classRef = this;
    // console.log("props trending progs", this.props.data);
    programs = this.props.data.map((program, index) => (
      <div className="TopTenTrendingBox ProgramsCard" key={index} id={"glider_slider_pro" + classRef.props.sliderKey + '_' + index}>
        <Link to={"/program/" + program.program_slug}>
          <div className="shows_slider_img">
            <ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} />
            <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
          </div>
          {/* <span className="TrendingNumber">{parseInt(index + 1)}</span> */}
        </Link>
      </div>
    ));
    return programs;
  };
  render() {
    return (
      <div>
        <section className="pad_left pad_bh shows_slider_sec ProgramSectionHome">
          <div className="headng_box">
            <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name + ' ' + this.props.type : this.props.name}</strong></h2>
          {this.props.name !== 'Top Trending Programs' ? 
            <Link className="view_all" to={"/list?type=program&topic=" + this.props.name}>{(window.location.pathname === '/') ? 'View All' : ''}</Link> :''} </div>
          <div className="slider_new_container drama_sldr_container">
            <div className="drama_sldr_new" uk-slider='finite: true; sets: true;'>
              <div className="uk-slider-items">

                {this.handleProgramList()}
              </div>
              <a className="CarouselPrevBtn" href="#" uk-slider-item="previous"><img src="/images/left_arrow.png" alt="right arrow" /></a>
              <a className="CarouselNextBtn" href="#" uk-slider-item="next"><img src="/images/right_arrow.png" alt="right arrow" /></a>
            </div>
          </div>
        </section>
        <hr />
      </div>
    );
  }
}

export default TopTrending;