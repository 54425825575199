import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
// import {AdSlot} from "react-dfp";

class Points extends React.Component{
    constructor(){
        super();
        this.callApi = this.callApi.bind(this);
        this.handlePointResponse = this.handlePointResponse.bind(this);
        this.state = {
              isLoaded: false,
              msg: 'Loading...',
              list: [],
              metaKeywords: '',
              metaTitle: '',
              metaDescription: '',
              metaImage: '',
              schema: '' 
          }
    }
  
    componentDidMount(){
        this.callApi();
        MetaCreation.createMeta('static','psl-points',(e) => this.callMetaData(e));
    }

    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
      }

    callApi = () =>{
        let classRef = this;
        axios({
            method: 'POST',
            url: "https://cdn2.mjunoon.tv:9191/v2/points",
        }).then(function (response) {
            
            if(response.data.code === 200){  
                
                classRef.setState({
                        isLoaded : true,
                        list: response.data.data
                })
    
            }
        })
    }

    handlePointResponse = () =>{
        let points = "";
        let index = 0;
        points = this.state.list.map((point,index) => (
            <tr>
                <td>
                    <div className="tbl_img"> <ImageWebp webp={point.image} src={point.image} alt={point.team} />
                    </div>
                    <p className="tbl_txt"><span>{point.team}</span> {point.slogan}</p>
                </td>
                <td>{point.M}</td>
                <td>{point.W}</td>
                <td>{point.L}</td>
                <td>{point.N_R}</td>
                <td>{point.PT}</td>
                <td>{point.NRR}</td>
            </tr>
            ));        
        return points;        
    }


    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                <Header/>
                <div class="content_sec live_sec">
                        <section class="tending_player_sec uk-padding-remove-top">
                            <div class="psl_sec">
                                <div class="box_psl">
                                    <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                        <ul class="uk-slider-items">
                                        <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                        </ul>
                                    </div>
                            {/* <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>      */}
                            <div class="psl_table" uk-scrollspy="cls: uk-animation-fade; target: tr; delay: 100;">
                            <table>
                            <thead>
                                <tr>
                                <th />
                                <th>M</th>
                                <th>W</th>
                                <th>L</th>
                                <th>N/R</th>
                                <th>PT</th>
                                <th>NRR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isLoaded ? this.handlePointResponse() : ''}
                            </tbody>
                            </table>
                            </div>
                            </div>
                            </div>
                            <div class="sidebar">
                            </div>
                        </section>
                    </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(Points);