import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";

export class Mgame extends Component {
  render() {
    return (
      <div>
        <Header />
          <div className="uk-container">
            <div className="m-games-wrap pad_top_mobile">

            <div className="comingbanner" >
              <img src="/img/comin.png" />
            </div>

            <div>
              <img src="/img/berrytalkadimage.jpg" />
              </div>
            </div>
          </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Mgame);
