import React from 'react';

class LiveRelatedFilterShimmer extends React.Component {

    render() {
        return (
            <div className="RelatedFilter">                
                <div className="RelatedFilterBtns">
                    <ul className="overflowscroll">
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                        <li><a></a></li>
                    </ul>
                </div>
                <div className="RelatedFilterBox">
                    <ul className="overflowscroll">
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                        <li>
                            <div className="fav_sld_img"></div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default LiveRelatedFilterShimmer;