import React from 'react';

class PlayerShimmer extends React.Component {    

    render() {
        return (
            <div>
                <div className="PlayerShimmerContainer">
                    {/* <div className="LiveTVShimmerimg"> </div> */}
                    <div className="info_tab_box">
                        <div className="abt_content">
                            <div className="seprateheading"></div>
                            <div className="InfoTextspan"></div>
                        </div>
                        <div className="info_txt">
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                            </div>
                            <h1 className="h1fordramadetail"></h1>
                            <p className="info_category_txt"></p>
                            <div className="favourite"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerShimmer;