import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WC_Episode from "../section/WC_Episode";
import WC_ProgramMobile from "../section/WC_ProgramMobile";
import WC_EpisodeMobile from "../section/WC_EpisodeMobile";
import WC_Channel from "../section/WC_Channel";
import WC_Program from "../section/WC_Program";
import Meta from "../section/Meta";
import MetaCreation from "../../utils/MetaCreation";
import DataNotFound from '../section/DataNotFound';
import {isMobile} from "react-device-detect";


class Search extends React.Component{
    constructor(props) {
        super(props);
        //search                
        this.loadAssets = this.loadAssets.bind(this);
        this.handlePopTopicsList = this.handlePopTopicsList.bind(this);
        this.handleText = this.handleText.bind(this);   
        this.handleChange = this.handleChange.bind(this);


        this.state = {
            topics: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            pop_topics: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            datachannels: {
                isLoaded: false,
                msg: 'Loading...',
                list: [],
                dataFound: ''
            },
            dataprograms: {
                isLoaded: false,
                msg: 'Loading...',
                list: [],
                dataFound: ''
            },
            dataepisodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: [],
                dataFound: ''
            },
            searchText: "",
            showaftersearch: true,
            assetLoaded: false,
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''  
        }

    }
    componentDidMount(){
        MetaCreation.createMeta('static','search',(e) => this.callMetaData(e));        
        let search = new URLSearchParams(this.props.location.search);
        let searchVal = search.get("search");        
        let searchtype=search.get("searchtype");
        let from=search.get("from");
        let to=search.get("to");
        let type=search.get("type");
        let channel=search.get("channel");
        let program=search.get("program");    
        if(searchtype == 'advance'){
            this.fetchAdvanceDataSearchApi(from,to,type,channel,program);
        }else{    
        if(searchVal !== null){                                    
            //console.log(searchVal,'searchquery');
            this.fetchDataSearchApi(searchVal);
            window.tagManagerEvent(searchVal + " keyword", "Search", searchVal, {placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id:  window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') :'', name : localStorage.getItem('name') ? localStorage.getItem('name') :'',  number : localStorage.getItem('mobile') ? localStorage.getItem('mobile') :'', search_keyword: searchVal, user_custom_id : localStorage.getItem('user_id') ? localStorage.getItem('user_id') :'', user_device_id :''});
        }else{
            this.setState({
                dataepisodes : {dataFound : 'nodatafound'}  ,
                datachannels : {dataFound : 'nodatafound'}  ,
                dataprograms : {dataFound : 'nodatafound'}  ,
            })
            
            window.tagManagerEvent("Search Screen", "Search", "Search", {placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id:  window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') :'', name : localStorage.getItem('name') ? localStorage.getItem('name') :'',  number : localStorage.getItem('mobile') ? localStorage.getItem('mobile') :'', search_keyword: searchVal, user_custom_id : localStorage.getItem('user_id') ? localStorage.getItem('user_id') :'', user_device_id :''});
        }
        }
        this.setState({
            searchText: searchVal
        })

    }

    componentDidUpdate(){
        // console.log("inside will update")
        // if(!this.state.assetLoaded){            
        //     this.loadAssets();
        //     this.setState({
        //         assetLoaded: true
        //     })
        // }

    }
    
    handleChange(event) {
        this.setState({searchText: event.target.searchText});  
	}	    

	handleText(event) {        
        event.preventDefault();
    }

    callMetaData = (metaResponse) =>{
        //console.log(metaResponse,'inside Meta data')
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
        


    fetchDataSearchApi = (searchVal) => {
        // const searchValue=queryString.parse(this.props.location.search);
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let text =searchVal;
        axios({
            method: 'POST',
            url: Constant.url+"search",
            data: {user_id: user_id,search:text},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                //console.log();
                classRef.setState({
                    datachannels:{
                        isLoaded : true,
                        list: response.data.data.channels,
                        dataFound : (response.data.data.channels) ? '' :'nodatafound'
                    },
                    dataprograms:{
                        isLoaded : true,
                        list: response.data.data.programs,
                        dataFound : (response.data.data.programs) ? '' :'nodatafound'
                    },
                    dataepisodes:{
                        isLoaded : true,
                        list: response.data.data.episodes,
                        dataFound : (response.data.data.episodes) ? '' :'nodatafound'
                    },
                    showaftersearch : true
                })
            }else{
                console.log("Ssssss")
                classRef.setState({dataepisodes:{dataFound: 'nodatafound'},dataprograms:{dataFound: 'nodatafound'},datachannels:{dataFound: 'nodatafound'}})
            }
            window.imgNotFound();
        }).then(function (response) {
            // console.log(response);
        });
    };

    fetchAdvanceDataSearchApi = (from,to,type,channel,program) => {
        // const searchValue=queryString.parse(this.props.location.search);
        let classRef = this;
        let user_id = localStorage.getItem('user_id');        
        axios({
            method: 'POST',
            url: Constant.url+"advance-search",
            data: {user_id: user_id,from : from,to : to,type : type,channel : channel,program : program},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                //console.log();
                classRef.setState({
                    dataepisodes:{
                        isLoaded : true,
                        list: response.data.data.episodes,
                        dataFound : (response.data.data.episodes) ? '' :'nodatafound'
                    },
                    showaftersearch : true
                })
            }else{
                classRef.setState({dataepisodes:{dataFound: 'nodatafound'},dataprograms:{dataFound: 'nodatafound'},datachannels:{dataFound: 'nodatafound'}})
            }
            window.imgNotFound();
        }).then(function (response) {
            // console.log(response);
        });
    };
    loadAssets = () =>{
        //console.log("INITIALIZED LOAD ASSETS FUNCTION");
        $('.tag_sldr .tag_search').click(function(){
            if ($(this).hasClass('active')){
                //console.log("ACTIVATE CLASS");
                $(this).removeClass('active');
                $(this).parent('li').parent('ul').removeClass('ul_active');
            } else {//console.log("UN ACTIVATE CLASS");
                $('.tag_sldr .tag_search').removeClass('active');
                $(this).parent('li').parent('ul').addClass('ul_active');
                $(this).addClass('active');
                //
            }
        });
        $('.tag_clear').click(function(){
            $(this).parent('li').parent('ul').removeClass('ul_active');
            $('.tag_sldr .tag_search').removeClass('active');
        });
    };

    deleteTopics = (topic) => {
        //console.log(topic,"inside delete");
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"remove-single-topic",
            data: {user_id: user_id,tag: topic},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                // document.location.reload(true);
            }
        }).then(function (response) {
        });
    }

    addTopic = (topic) => {
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"add-favourite-topics",
            data: {
                user_id: user_id,
                topic : topic
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                //console.log('successfully inserted topics');
                // return <Redirect  to='/basicdetail'/>;                                               
            }
        }).then(function (response) {
            // console.log(response);
        });
    }

    topicActive = (e,topic) => {
        if(!localStorage.getItem('user_id')){
            new Header().openLoginModal();
            return;
        }
        if($("#"+e).hasClass('active')){
            this.deleteTopics(topic)
        }else{
            this.addTopic(topic)
        }
        $("#"+e).toggleClass('active');
    }

    handlePopTopicsList = () => {
        
        let pop_topics = "";
        pop_topics = this.state.pop_topics.list.map((pop_topic,index) => (
            <div key={index} className="topics_tag_box"><span className="plus_tick"  id={"plus_tick"+index} onClick={(e) => this.topicActive("plus_tick"+index,pop_topic,e)}></span> <a href={"?search="+pop_topic}>{pop_topic}</a></div>
            // <a className="tag_box" href={"?search="+pop_topic}><span className="plus_tick"></span><span>{pop_topic}</span></a>
        ));        
        return pop_topics;
    };     
    
    
    render(){
        
        return(

           <div>
           {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>           */}
           <Header/>                          
           <div className="content_sec">
          
           <hr></hr>
     
            {/* after search */}
            <div id = "after-search">
            {(this.state.dataepisodes.dataFound === 'nodatafound' && this.state.datachannels.dataFound === 'nodatafound' && this.state.dataprograms.dataFound === 'nodatafound') ? <DataNotFound/> : ""}    
            {this.state.datachannels.isLoaded === true && this.state.datachannels.list && this.state.showaftersearch  === true ?<WC_Channel name="Channels" data={this.state.datachannels.list}/> : ''}
            <hr></hr>
            {(isMobile)?
            ''
            // this.state.dataprograms.isLoaded === true && this.state.dataprograms.list && this.state.showaftersearch  === true ?<WC_ProgramMobile name="Programs" data={this.state.dataprograms.list}/> : ''                               
            :this.state.dataprograms.isLoaded === true && this.state.dataprograms.list && this.state.showaftersearch  === true ?<WC_Program name="Programs" data={this.state.dataprograms.list}/> : ''                               }
            <hr></hr>
            {(isMobile)?
            this.state.dataepisodes.isLoaded === true && this.state.dataepisodes.list && this.state.showaftersearch  === true ?<WC_EpisodeMobile name="Episodes" data={this.state.dataepisodes.list}/>: (this.state.dataepisodes.isLoaded === false) ? <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div> : (this.state.dataepisodes.isLoaded === 'nodatafound') ? "" : ""
            :this.state.dataepisodes.isLoaded === true && this.state.dataepisodes.list && this.state.showaftersearch  === true ?<WC_Episode name="Episodes" data={this.state.dataepisodes.list}/>: (this.state.dataepisodes.isLoaded === false) ? <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div> : (this.state.dataepisodes.isLoaded === 'nodatafound') ? "" : ""
            }

            </div>
            </div>
                   
           <Footer/>     
           </div>
        );
     }    
}

export default withRouter(Search);