import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
class Empty extends React.Component{
    render(){
        return (
            <div>
                  <div class="headng_box">
                    <h2 class="h2forhome"><strong>{this.props.name}</strong></h2>                    
                  </div>
                  <div class="empty_box">
                    <div class="mdl">
                      <div class="mdl_inner">                        
                      <img src={this.props.image} alt={this.props.name}/>
                      <p>You haven't added<br/> anything to this list.
                      </p>
                      </div>
                    </div>
                  </div>
            </div>
        );
    }
}

export default Empty;