import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
// import {AdSlot} from "react-dfp";

class Matches extends React.Component{

    constructor(){
        super();
        this.callApi = this.callApi.bind(this);
        this.handleMatchResponse = this.handleMatchResponse.bind(this);
        this.state = {
              isLoaded: false,
              msg: 'Loading...',
              list: [],
              metaKeywords: '',
              metaTitle: '',
              metaDescription: '',
              metaImage: '',
              schema: '' 
          }
    }
  
    componentDidMount(){
        this.callApi();
        MetaCreation.createMeta('static','psl-matches',(e) => this.callMetaData(e));
    }      
      callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
      }
  
    callApi = () =>{
      let classRef = this;
      axios({
          method: 'POST',
          url: "https://cdn2.mjunoon.tv:9191/v2/matches",
      }).then(function (response) {
          
          if(response.data.code === 200){  
                          
              classRef.setState({
                      isLoaded : true,
                      list: response.data.data
              })
  
          }
      })
    }

    playAudio = () => {
        var audio = new Audio('/images/psl/flip.mp3');
        audio.play()
    }

    handleMatchResponse = () =>{
        let matches = "";
        let index = 0;
        matches = this.state.list.map((match,index) => (
            <div className="psl_box">
                <div className="teams team_l">
                <span className="match_count">{match.id}</span>
                <div className={"psl_team_img "+match.team1}><ImageWebp webp={match.team1_images} src={match.team1_images} alt={match.team1} />
                </div>
                </div>
                    {(match.live === 'completed') ? 
                        <div className="match_time">
                        <h5 className="match_name">{match.title}</h5>
                        <p className={(match.team1_win === 'yes') ? "match_date_time win_match" : "match_date_time"}>{ReactHtmlParser(match.team1_score)}</p>
                        <p className={(match.team2_win === 'yes') ? "match_place win_match" : "match_place"}>{ReactHtmlParser(match.team2_score)}</p>
                        </div>
                    :""}
                    {(match.live === 'yes') ?
                        <div className="match_time">
                        <h5 className="match_name">{match.matches}</h5>
                        <p className="match_date_time"><span className="live_span">LIVE</span></p>
                        <p className="match_place">in {match.location}</p>
                        </div>
                    :""}
                    {(match.live === 'no') ?
                        <div className="match_time">
                        <h5 className="match_name">{match.matches}</h5>
                        <p className="match_date_time">{match.date}</p>
                        <p className="match_place">in {match.location}</p>
                        </div>
                    :""}
                <div className="teams team_r">
                <div className={"psl_team_img "+match.team2}><ImageWebp webp={match.team2_image} src={match.team2_image} alt={match.team2} />
                </div>
                </div>
            </div>            
        ));        
        return matches;
    } 

    render(){
        return(
            
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                <Header/>
                <div class="content_sec live_sec">
                        <section class="tending_player_sec uk-padding-remove-top">
                            <div class="psl_sec">
                                <div class="box_psl">
                                    <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                        <ul class="uk-slider-items">
                                        <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                        </ul>
                                    </div>
                            <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>     
                            <div class="matches_sec" uk-scrollspy="cls: uk-animation-fade; target: .psl_box; delay: 100;">
                            {(this.state.isLoaded) ? this.handleMatchResponse() : 'Loading ...'}
                            </div>
                            </div>
                            </div>
                            <div class="sidebar">
                            </div>
                        </section>
                    </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(Matches);