import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import $ from "jquery";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
class Episode extends React.Component {

  componentDidMount() {
    // let classRef = this;
    // setTimeout(function () {
    //   classRef.renderHover();
    // }, 2000)
  }

  // renderHover = () => {
  //   let classRef = this;
  //   $.each(classRef.props.data, function (i) { //Loop the array
  //     var timer;
  //     $("#glider_slider_epi" + classRef.props.sliderKey + '_' + i).mouseenter(
  //       function () {
  //         var that = this;
  //         timer = setTimeout(function(){
  //         $("#glider_slider_epi" + classRef.props.sliderKey + '_' + i).addClass('HoverClass');
  //         $('#glider_slider_epi' + classRef.props.sliderKey).css("overflow", "inherit");

  //         // {"video_url_"+ classRef.props.sliderKey + '_' + index}
  //         if (classRef.props.data[i].episode_video_url != "") {
  //           $("#video_url_" + classRef.props.sliderKey + '_' + i + " source").attr('src', classRef.props.data[i].episode_video_url);
  //           $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].load();
  //           $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].play();
  //           $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].muted = true;
  //           if(!$('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).hasClass('addvolume')){
  //             $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).addClass('addvolume');  
  //             $('.HoverNModelImgVid').on('click', '#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i,
  //             function (e) {
  //               if ($('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).hasClass('vidVolumemute')) {
  //                 $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).removeClass('vidVolumemute');
  //                 $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].muted = true;
  //               } else {
  //                 $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).addClass('vidVolumemute');
  //                 $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].muted = false;
  //               }
  //             }
  //           );
  //           }            
  //           $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].onloadeddata = function () {
  //             $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).show()
  //             $('#img_op_' + classRef.props.sliderKey + '_' + i).css('opacity', 0);
  //           }
  //         }
  //         // $("#video_url_"+ classRef.props.sliderKey + '_' + i)[0].onended = function() { 
  //         // $('#img_op_' + i).css('opacity', 0);                               
  //         // };     
  //       }, 1000);     
  //       }).mouseleave(function () {
  //         clearTimeout(timer);
  //         // $("#video_url_"+ classRef.props.sliderKey + '_' + i)[0].pause();
  //         if ($(".HoverClass").length > 0) {
  //           $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).hide()
  //           $("#glider_slider_epi" + classRef.props.sliderKey + '_' + i).removeClass('HoverClass');
  //           $('#glider_slider_epi' + classRef.props.sliderKey).css("overflow", "");
  //           $('#img_op_' + classRef.props.sliderKey + '_' + i).css('opacity', 1);
  //           if (classRef.props.data[i].episode_video_url != "") {
  //             $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].pause();
  //             $("#video_url_" + classRef.props.sliderKey + '_' + i)[0].muted = true;
  //           }
  //           $('#HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + i).removeClass('vidVolumemute');
  //         }
  //       }
  //     )
  //   })
  // }

  handleEpisodeList = () => {
    let episodes = "";
    let classRef = this;
    // console.log("props data name: ", this.props.name);
    episodes = this.props.data.map((episode, index) => (
      <div key={index} id={"glider_slider_epi" + classRef.props.sliderKey + '_' + index}>
        <div className="prog_sldr_box">
          <div className="prog_sldr_img">
            {/* <Link to={'/watch/' + episode.episode_slug}>
                <ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
                <div className="duration_time"><span>{episode.episode_duration}</span></div>
                <div className="hvr_box"><span uk-icon="play"></span></div>
            </Link>  */}
            <Link to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug}>
                <ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
                <div className="duration_time"><span>{episode.episode_duration}</span></div>
                <div className="hvr_box"><span uk-icon="play"></span></div>
            </Link> 
          </div>
          <div className="prog_sldr_text">
            <h4 className="homesectionone">
              <b>
                {/* <Link className="anchor" to={'/watch/' + episode.episode_slug} title={episode.episode_name}>
                  {episode.episode_name}
                </Link> */}
                <Link className="anchor" to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug} title={episode.episode_name}>
                  {episode.episode_name}
                </Link>
              </b>
            </h4>
            <p>
              <span className="episode_channel">{(episode.program_name === undefined) ? '' : episode.program_name } </span> 
              <span>{episode.episode_views + "views. " + episode.episode_date}   </span>
              {/* <span>{}</span>  */}
            </p>
          </div>
        </div>
        {/* Overlay Box Start */}
        {/* commented hover temporarily */}
        {/* <div className="HoverNModelOverlay HoverNModelEpisode">
          <div className="HoverNModel">
            <div className="HoverNModelImgVid">
              <Link className="redirectLink" to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug}></Link>
              {(episode.episode_video_url != "") ?
                <video id={"video_url_" + classRef.props.sliderKey + '_' + index} width={1366} webkit-playsinline="true"
                playsInline={true} muted>
                  <source src={""} type="video/mp4" />

                </video>
                : ''}
              <ImageWebp id={'img_op_' + classRef.props.sliderKey + '_' + index} webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
              <span className="viewerstvg">{episode.episode_views + "Views"}</span>
              <button className="HoverNModel_vidVolume" style={{ display: 'none' }} id={'HoverNModel_vidVolume' + classRef.props.sliderKey + '_' + index}>
                <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
              </button>
            </div>
            <div className="HoverNModelTxt">
              <div className="HoverNModelTxtHead">
                <Link to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug} className="HoverNModelHeading"><h4>{episode.channel_name}</h4>
                  <span>{episode.channel_name + " . " + episode.episode_views + " " + "views" + " . " + episode.episode_date}</span>
                </Link>
                <ul>
                  <li><Link to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug}><img src="/images/player_share.svg" uk-svg="" /></Link></li>
                  <li><Link to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug}><img src="/images/player_heart.svg" uk-svg="" /></Link></li>
                  <li>
                    <Link to={'/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/' + episode.episode_slug}><img src="/images/player_info.svg" uk-svg="" /></Link>
                    <button>
                      <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                      <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="ptag">
                <ShowMoreText
                  lines={1}
                  more="Show more"
                  less="Show less"
                  anchorClass=""                  
                  expanded={false}
                >
                  {ReactHtmlParser(episode.episode_description)}
                </ShowMoreText>
                </div>
            </div>
          </div>
        </div> */}
        {/* Overlay Box End */}
      </div>
    ));
    return episodes;
  };
  render() {
    return (
      <div>
        <section className="pad_left pad_bh prog_box_sec episodeSection">
          <div className="headng_box">
            <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name + ' ' + this.props.type : this.props.name}</strong></h2>
            {/* <Link className="view_all" to={(this.props.livePro) ? '/program/' + this.props.channelSlug : (this.props.isBranded) ? '/' + this.props.channelSlug : '/list?type=episode&topic=' + this.props.name}>{(window.location.pathname === '/' || window.location.pathname === '/karachi-eat') ? 'View All' : ''}</Link> </div> */}
            <Link className="view_all" to={(this.props.livePro) ? '/program/' + this.props.channelSlug : (this.props.isBranded) ? '/' + this.props.channelSlug : '/' + this.props.name.toLowerCase().replace(/\s+/g, '-') + '/'}>{(window.location.pathname === '/' || window.location.pathname === '/karachi-eat') ? 'View All' : ''}</Link> </div>
          <div className="slider_new_container arrow_top">
            <div className="prog_sldr_new" uk-slider='finite: true; sets: true;easing: ease;draggable: true;'>
              <div className="uk-slider-items">
                {this.handleEpisodeList()}
              </div>
              <a class="CarouselPrevBtn" href="#" uk-slider-item="previous"><img src="/images/left_arrow.png" alt="right arrow"/></a>
              <a class="CarouselNextBtn" href="#" uk-slider-item="next"><img src="/images/right_arrow.png" alt="right arrow"/></a>
            </div>

          </div>
        </section>
        <hr />
      </div>
    );
  }
}

export default Episode;