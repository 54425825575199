import React from 'react';
import RelatedFilterShimemr from './RelatedFilterShimemr';

class LiveTVShimmer extends React.Component {    

    render() {
        return (
            <div>
                <section className="LivetvHeaderPlayer pad_left pad_tb pad_right LiveTVShimmerCon">
                        <div className="LivetvHeaderPlayerSection">
                        <div className="PlayerForLivetvBox">
                            <div className="LiveTVShimmerimg">                                
                            </div>
                        </div>  
                            <RelatedFilterShimemr/>                      
                        </div>
                    </section>
                   
            </div>
        );
    }
}

export default LiveTVShimmer;