import React from 'react';

class TrendingShimmer extends React.Component {    

    render() {
        return (
            <div>
                <section className="TrendingShimmerCont">
                        <div className="trending_boxshmr">
                            <div className="treanding_head">
                                <div className="imgTrnd"></div>
                                <h4></h4>
                                <p></p>
                                <div className="ad_reminder"></div>
                            </div>
                            <div className="trending_vid_box"></div>
                            <div className="treanding_foot tags_box">
                                <div className="tag_box"></div>
                                <div className="tag_box"></div>
                                <div className="tag_box"></div>
                                <div className="FeedShareBtn"></div>
                            </div>
                        </div>
                        <div className="trending_boxshmr">
                            <div className="treanding_head">
                                <div className="imgTrnd"></div>
                                <h4></h4>
                                <p></p>
                                <div className="ad_reminder"></div>
                            </div>
                            <div className="trending_vid_box"></div>
                            <div className="treanding_foot tags_box">
                                <div className="tag_box"></div>
                                <div className="tag_box"></div>
                                <div className="tag_box"></div>
                                <div className="FeedShareBtn"></div>
                            </div>
                        </div>
                    </section>
            </div>
        );
    }
}

export default TrendingShimmer;