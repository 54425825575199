import React from 'react';

class EpisodeShimmer extends React.Component {    

    render() {
        return (
            <div>
                {/* <section className="pad_left pad_tb prog_box_sec CrouselShimmerCont">
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong></strong></h2>
                        <div className="view_all"></div>
                    </div>
                    <div className="CrouselShimmerBox overflowscroll">
                    <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div><div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner">
                            <div className="prog_sldr_img">
                                <a></a>
                            </div>
                            <div className="prog_sldr_text">
                               <h4 className="homesectionone"></h4>
                               <p></p>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}

export default EpisodeShimmer;