import React from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import LocalStorageService from "../../utils/LocalStorageService";
import MetaCreation from "../../utils/MetaCreation";
import io from "socket.io-client";
import $ from "jquery";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import { DFPSlotsProvider, AdSlot, DFPManager } from "react-dfp";
// import copy from "copy-to-clipboard";
import Meta from "../section/Meta";

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import MobileEpisode from "../section/MobileEpisode";
import MobileProgram from "../section/MobileProgram";
import Channel from "../section/Channel";
import ChannelRelated from "../section/ChannelRelated";
import FavChannelLivetv from "../section/FavChannelLivetv";
import RelatedFilter from "../section/RelatedFilter";
import { func } from "prop-types";
import NowUpnextLive from "./NowUpnextLive";
import PlayerShimmer from "../section/PlayerShimmer";
import ChannelShimmer from "../section/ChannelShimmer";
import EpisodeShimmer from "../section/EpisodeShimmer";
import Loader from '../section/loader';

// const socket = io('http://srv3.mjunoon.tv:8001/');
var socket;
var socketTimer;
var adTimerInterval;
var initializeAdTimer;

var mobileScreenTimer;
var qualities;
var activeQuality;

var timer1;
var timer2;
var timer3;
class Live extends React.Component {
  constructor() {
    super();
    // $('body').addClass('loadkaro');
    this.callGetChannelUrlApi = this.callGetChannelUrlApi.bind(this);
    this.callChannelDetailApi = this.callChannelDetailApi.bind(this);
    this.handleLatestEpisodes = this.handleLatestEpisodes.bind(this);
    this.handleNextEpisodePromos = this.handleNextEpisodePromos.bind(this);
    this.handleDramas = this.handleDramas.bind(this);
    this.handleRelatedChannels = this.handleRelatedChannels.bind(this);
    this.handleChannelInfo = this.handleChannelInfo.bind(this);
    this.handleRelated = this.handleRelated.bind(this);
    this.initializePlayer = this.initializePlayer.bind(this);
    this.pslMobile = this.pslMobile.bind(this);
    this.adLShape = this.adLShape.bind(this);
    // this.checkclick = this.checkclick.bind(this);
    this.state = {
      channelUrl: {
        isLoaded: false,
        url: "",
        is_playback: false,
        backup_live_stream_url: "",
        addTagUrl: "",
        mastHeadUrl: "",
        mastHeadPicture: "",
        ad_start_time: 30000,
        ad_repeat_time: 500000,
        ad_horizontal: "",
        ad_vertical: "",
        ad_half_screen: "",
        ad_redirect: "",
        channelLiveImage: ""

      },
      channelDetail: {
        isLoaded: false,
        data: {},
      },
      isFav: false,
      flagetag: 0,
      share: true,
      liveViews: 0,
      isRewind: false,
      rewindInitialize: false,
      metaKeywords: "",
      metaTitle: "",
      metaDescription: "",
      metaImage: "",
      schema: "",
      livenode: true,
      screenOrientation: "portrait",

      frame: false,
      initClose: true,
      isAds: false,
      adLshape: true,
      frameUrl: "https://srv3.mjunoon.tv:7777/",
      channelType: {
        isLoaded: false,
        list: {},
      },
      nowAndUpNext: {
        isLoaded: false,
        list: {},
        heading: ''
      },
      episodeDetail: {
        isLoaded: false,
      },
      playerRender: true,
      restartWatchLive: true,
      restartProgram: false,
      qualitClicked: true,
      showQty: false,
      isFree: 1
    };
    DFPManager.load();

    // ReactGA.initialize('UA-93668328-1');
  }

  componentDidMount() {

    window.addEventListener("orientationchange", this.setScreenOrientation);
    //Check if player already available in PIP
    if (document.pictureInPictureElement) {

      document.exitPictureInPicture();
    }

    let slug = this.props.match.params.slug;

    ReactGA.initialize("G-42RPWCYELN");
    ReactGA.ga("send", "screen_view", slug + Constant.version);
    var classRef = this;

    this.getLiveViews();

    MetaCreation.createMeta("channel", slug, (e) => this.callMetaData(e));
    LocalStorageService._serviceToken((e) => this.initializeToken(slug, e));
    // this.initializePlayer();
    if (slug === "psl-live") {
      this.setState({ frame: true });
    } else {
      classRef.setState({ frame: false });
    }
    if (localStorage.getItem('package_status') != "true") {
      this.adLShape(slug);
    }

    this.channelTypeApi();

  }
  
  channelTypeApi = () => {
    let classRef = this;
    axios({
      method: 'GET',
      url: Constant.url + "/channel-types",
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
      if (response.data.code === 200) {
        classRef.setState({
          channelType: {
            isLoaded: true,
            list: response.data.data.types
          }
        })
      }
    }).then(function (response) {

    });
  };

  nowAndUpNext = (slug) => {
    let classRef = this;
    axios({
      method: 'POST',
      url: Constant.url + "/guide-channel",
      data: {
        channel_slug: slug,
        user_id: localStorage.getItem("user_id")
      },
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
      if (response.data.code === 200) {
        classRef.setState({
          nowAndUpNext: {
            isLoaded: true,
            heading: response.data.data.body[0].heading,
            list: response.data.data.body[0].guide_live_upcoming
          }
        })
      }
    }).then(function (response) {

    });
  };

  clearAd = () => {
    let classRef = this;
    $("#related_channel").hide();
    if ($(".dyn-add4").length != 0) {
      clearInterval(timer2);
      timer2 = setInterval(function () {
        classRef.adRenderInterval()
      }, 120000);
    } else {
      if (classRef.state.frame === false) {
        $(".dyn-add").remove();
        $(".dyn-add2").remove();
        $(".rmp-ad-container iframe").css({
          width: "100%",
          height: "100%",
          "-webkit-transition": "all .50s ease",
          "-moz-transition": "all .50s ease",
          "-o-transition": "all .50s ease",
          "-ms-transition": "all .50s ease",
          transition: " all .50s ease",
        });
        $(".rmp-content").css({
          width: "100%",
          height: "100%",
          "-webkit-transition": "all .50s ease",
          "-moz-transition": "all .50s ease",
          "-o-transition": "all .50s ease",
          "-ms-transition": "all .50s ease",
          transition: " all .50s ease",
        });
        $(".player_hd_bar").show();
        $(".rmp-control-bar").show();
      }
    }
  }

  adRenderInterval = () => {
    let classRef = this;
    if ($(".dyn-add4").length > 0) {
      clearInterval(timer1);
      timer1 = setInterval(function () {
        classRef.clearAd();
      }, 5000);
    } else {
      if (classRef.state.frame === false) {
        classRef.XYaxisAd();
        clearInterval(timer1);
        timer1 = setInterval(function () {
          classRef.clearAd();
        }, 5000);
      }
    }
  }
  adLShape = (slug) => {
    let classRef = this;
    if (
      window.location.pathname !== "/coronavirus-updates-live" &&
      this.state.adLshape === true
    ) {
      this.setState({ frame: false });
      //add for first time
      timer3 = setTimeout(function () {
        $("#related_channel").hide();
        if (classRef.state.frame === false) {
          classRef.setState({ flagetag: 1 });
          classRef.XYaxisAd();
          clearInterval(timer1);
          timer1 = setInterval(function () {
            classRef.clearAd();
          }, 5000);
          clearInterval(timer2);
          timer2 = setInterval(function () {
            classRef.adRenderInterval()
          }, 120000);
        }
      }, 30000);
      //add for first time end

      // ad with interval start
      timer2 = setInterval(function () {
        classRef.adRenderInterval()
      }, 120000);
      // ad with interval end

    } else {

      this.setState({ frame: true });
    }
  };
  getLiveViews = () => {
    var self = this;


    socketTimer = setInterval(function () {

      $(".watching_now").html(
        self.state.liveViews !== 0
          ? "<img src='/images/player_eye.svg' uk-svg='' /> " + self.viewsFormatter(self.state.liveViews)
          : ""
      );
    }, 5000);
  };

  viewsFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
      : Math.sign(num) * Math.abs(num);
  }
  componentWillReceiveProps(nextProps) {
    var classRef = this;

    if (document.pictureInPictureElement) {

      document.exitPictureInPicture();
    }
    let slug = this.props.match.params.slug;
    if ('/' + slug == window.location.pathname) {
      return;
    }
    // window.socket.emit('disconnect-watching', {socket_id: window.socket.id, channel_slug: slug});

    window.RMP.destroy();
    // $('.player_sec').prepend(classRef.playerControls());
    this.setState({ playerRender: false }, function () {
      classRef.setState({ playerRender: true })
    })
    clearInterval(adTimerInterval);
    clearInterval(initializeAdTimer);
    clearInterval(timer1);
    clearInterval(timer2);
    clearTimeout(timer3);
    if (typeof window.ADRMP != "undefined") {
      window.ADRMP.destroy();
    }

    // $(".player_hd_bar ").after('<div id="rmpPlayer" />');
    $(".mastHead").after('<div id="adPlayer" />');
    slug = nextProps.match.params.slug;
    classRef.setState({
      liveViews: 0,
    });
    // window.socket.emit('watching-now', {socket_id: window.socket.id, channel_slug: slug});
    if (socket) {
      socket.disconnect();
    }


    this.setState({ channelDetail: { isLoaded: false, data: {} } });
    this.setState({ channelUrl: { isLoaded: false } });
    // this.setState({ channelType: { isLoaded: false } });
    if (slug === "coronavirus-updates-live") {
      this.setState({ frame: true });
    } else {
      classRef.setState({ frame: false });
    }
    if (localStorage.getItem('package_status') != "true") {
      this.adLShape(slug);
    }
    LocalStorageService._serviceToken((e) => this.initializeToken(slug, e));
    MetaCreation.createMeta("channel", slug, (e) => this.callMetaData(e));
    DFPManager.reload();
    this.channelTypeApi();
    // if (!$.trim( $('#adPlayer').text() ).length == 0 ){
    //do something
    // $(".sidebar").addClass('ad_act');
    // }
    // adTimerInterval = setTimeout(function () {
    //     $(".sidebar").addClass('ad_act');
    //     $(".ad_cont").addClass('ad_act_ii');
    //     classRef.initializeAd();
    // }, 3000);
  }
  componentWillUnmount() {
    if (document.pictureInPictureElement) {

      document.exitPictureInPicture();
    }
    // clearInterval(adTimerInterval);
    let slug = this.props.match.params.slug;
    if (socket) {
      socket.disconnect();
    }

    clearInterval(initializeAdTimer);
    clearInterval(socketTimer);
    clearInterval(adTimerInterval);
    clearInterval(mobileScreenTimer);


    // window.socket.emit('disconnect-watching', {socket_id: window.socket.id, channel_slug: slug});
  }
  callMetaData = (metaResponse) => {

    this.setState({
      metaKeywords: metaResponse.keywords,
      metaTitle: metaResponse.title,
      metaDescription: metaResponse.description,
      metaImage: metaResponse.thumbnail,
      schema: metaResponse.schema,
    });
    let classRef = this;
    setTimeout(function () {
      window.metaUpdated();
    }, 1000);
  };
  callRewindGetChannelUrlApi = () => {
    var timeshift = false;

    if (this.state.rewindInitialize === true) {
      let classRef = this;
      var rewindVar = '';
      this.setState({ livenode: false });
      if (this.state.restartProgram) {
        rewindVar = localStorage.getItem("rewindtime")
        timeshift = true;
      } else if (localStorage.getItem("vodrewind") == 'yes') {
        rewindVar = localStorage.getItem("rewindtime")
      } else {
        rewindVar =
          $("#rewind").val() != ""
            ? $("#rewind").val() + ":00"
            : $("#rewindmobile").val() + ":00";
      }

      axios({
        method: "POST",
        url: Constant.url + "streaming-url",
        data: {
          date: rewindVar,
          slug: this.props.match.params.slug,
          is_rewind: "yes",
          type: "channel",
          user_id: "",
          timeshift: timeshift
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200 && response.data.status === true) {
          classRef.setState({
            channelUrl: {
              url: response.data.data.live_stream_url,
              is_playback: true,
              ad_start_time: response.data.data.ad_start_time,
              ad_repeat_time: response.data.data.ad_repeat_time,
              ad_horizontal: response.data.data.ad_horizontal,
              ad_vertical: response.data.data.ad_vertical,
              ad_half_screen: response.data.data.ad_half_screen,
              ad_redirect: response.data.data.ad_redirect,
              channelLiveImage: response.data.data.channel_live_image,
            },
            episodeDetail: {
              isLoaded: true,
            },
            isFree: response.data.data.is_free
          });
          window.RMP.destroy();
          classRef.setState({ playerRender: false }, function () {
            classRef.setState({ playerRender: true })
          })
          if (response.data.data.is_mast === 1) {
            window.ADRMP.destroy();
          }
          // $(".player_hd_bar").after('<div id="rmpPlayer" />');         
          classRef.initializePlayer();
          $('.player_sec').addClass('VodSec');
        }
      })
    } else {
      {
        this.setState({ rewindInitialize: true });
        var rewindVar = "";
        let classRef = this;
        this.setState({ livenode: false });
        if (this.state.restartProgram) {
          rewindVar = localStorage.getItem("rewindtime")
          timeshift = true;
        } else if (localStorage.getItem("vodrewind") == 'yes') {
          rewindVar = localStorage.getItem("rewindtime")
        } else {
          rewindVar =
            $("#rewind").val() != ""
              ? $("#rewind").val() + ":00"
              : $("#rewindmobile").val() + ":00";
        }
        axios({
          method: "POST",
          url: Constant.url + "streaming-url",
          data: {
            date: rewindVar,
            slug: this.props.match.params.slug,
            is_rewind: "yes",
            type: "channel",
            user_id: "",
            timeshift: timeshift
          },
          headers: {
            Authorization: "bearer " + localStorage.getItem("access_token"),
          },
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(
            LocalStorageService.decryptAes(result.data.eData)
          );
          if (response.data.code === 200 && response.data.status === true) {
            classRef.setState({
              channelUrl: {
                url: response.data.data.live_stream_url,
                is_playback: true,
                ad_start_time: response.data.data.ad_start_time,
                ad_repeat_time: response.data.data.ad_repeat_time,
                ad_horizontal: response.data.data.ad_horizontal,
                ad_vertical: response.data.data.ad_vertical,
                ad_half_screen: response.data.data.ad_half_screen,
                ad_redirect: response.data.data.ad_redirect
              },
              episodeDetail: {
                isLoaded: true,
              },
            });
            
            if (localStorage.getItem('restart') == 'yes') {
              localStorage.setItem('restart', 'no');
            } else if (localStorage.getItem('vodrewind') == 'yes') {
              localStorage.setItem('vodrewind', 'no');
            } else {
              window.RMP.destroy();
            }

            classRef.setState({ playerRender: false }, function () {
              classRef.setState({ playerRender: true })
            })
            if (response.data.data.is_mast === 1) {
              window.ADRMP.destroy();
            }

            // alert("rewind player else");
            // $(".player_hd_bar").after('<div id="rmpPlayer" />');
            // $('.mastHead').after('<div id="adPlayer" />');
            classRef.initializePlayer();
            $('.player_sec').addClass('VodSec');

            if (localStorage.getItem('package_status') != "true" && response.data.data.is_mast === 1) {
              classRef.initializeAd();
            }
          }
          // classRef.initializePlayer();
        });
      }
    }
  };

  initializeToken = (slug) => {
    if (localStorage.getItem('restart') == 'yes') {
      this.setState({ restartProgram: true }, function () {
        this.callRewindGetChannelUrlApi();

      })
    } else if (localStorage.getItem('vodrewind') == 'yes') {
      this.callRewindGetChannelUrlApi();
    } else {
      this.callGetChannelUrlApi(slug, 1);
    }

    this.callChannelDetailApi(slug);
    // this.nowAndUpNext(slug);
  };
  callAfterRewind = () => {

    window.location.reload();
  };
  setScreenOrientation = () => {
    let classRef = this;
    if (window.matchMedia("(orientation: portrait)").matches) {

      // this.setState({
      //   screenOrientation: "landscape",
      // });
      //   this.pslMobile(1);
      this.closeBtn();
      $(".mob_overlaybox").slideUp();
      // if($(".land_qty_btn").length == 0){                   
      //   $("#rmpPlayer").append('<button class="qty_btn land_qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/player_quality.svg" uk-svg="" /><span>Video Quality</span></button><button class="pip_btn land_pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button>')
      //   $('#rmpPlayer').on('click', '.land_pip_btn', function (e) {  $(".rmp-pip").click() });
      //   $('#rmpPlayer').on('click', '.land_qty_btn', function (e) { classRef.invokeQuality() });
      // }      
      $('.rmp-pip').hide()
      $('.rmp-volume').hide()
      $('.rmp-quality').hide()

    }

    if (window.matchMedia("(orientation: landscape)").matches) {

      // this.setState({
      //   screenOrientation: "portrait",
      // });
      // $(".land_qty_btn").remove();
      // $(".land_pip_btn").remove();


      //   this.pslMobile(1);
      this.closeBtn();
      $(".mob_overlaybox").slideUp();
    }
  };
  callGetChannelUrlApi = (slug, flag) => {
    this.setState({ livenode: true, restartProgram: false });
    if (window.location.pathname === "/psl-live") {
      window.location.href = "/ten-sports-live";
    }
    // if (window.location.pathname === "/psl-live") {
    // window.location.href = "/";
    // }
    if (window.location.pathname === "/coronavirus-live-updates") {
      window.location.href = "/coronavirus-updates-live";
    }
    let classRef = this;

    axios({
      method: "POST",
      url: Constant.url + "streaming-url",
      data: { slug: slug, is_rewind: "no", type: "channel", user_id: "" },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {

      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );

      if (response.data.code === 200 && response.data.status === true) {
       
        let customAdTagUrl = Constant.customAdTagUrl(
          response.data.data.add_tag_url,
          {
            placement_channel: response.data.data.channel_name,
            placement_program: "",
            placement_genre: response.data.data.genres,
            user_gender: localStorage.getItem("gender")
              ? localStorage.getItem("gender")
              : "Unknown",
            user_age: localStorage.getItem("dob")
              ? Constant.getDateDiff(
                localStorage.getItem("dob"),
                Constant.currentDate
              )
              : "",
            user_interest:
              window.getUserTopics() !== "" ? window.getUserTopics() : "",
            user_type: localStorage.getItem("mobile") ? "real" : "temp",
            user_behavior: Constant.currentEpocTime,
          }
        );


        classRef.setState({
          channelUrl: {
            isLoaded: true,
            url: response.data.data.live_stream_url,
            is_playback: response.data.data.is_playback,
            backup_live_stream_url: response.data.data.backup_live_stream_url,
            addTagUrl: customAdTagUrl,
            mastHeadUrl: response.data.data.mast_head_url,
            mastHeadPicture: response.data.data.mast_head_image,
            ad_start_time: response.data.data.ad_start_time,
            ad_repeat_time: response.data.data.ad_repeat_time,
            ad_horizontal: response.data.data.ad_horizontal,
            ad_vertical: response.data.data.ad_vertical,
            ad_half_screen: response.data.data.ad_half_screen,
            ad_redirect: response.data.data.ad_redirect,
            channelLiveImage: response.data.data.channel_live_image
          },
          episodeDetail: {
            isLoaded: true,
          },
          metaTitle: response.data.data.meta_title,
          metaDescription: response.data.data.meta_desc,
          metaImage: response.data.data.thumbnail,
          metaKeywords: response.data.data.meta_keywords,
          frameUrl: response.data.data.psl_url,
          isAds: response.data.data.isAds,
        }, function () {
          // if($('body').hasClass('loadkaro')){
          //     setTimeout(function(){
          //         $('body').removeClass('loadkaro');
          //     },500)
          // }
        });

        if (response.data.data.is_mast == 1 && localStorage.getItem('package_status') != "true") {
          adTimerInterval = setTimeout(function () {
            $(".sidebar").addClass("ad_act");
            $(".ad_cont").addClass("ad_act_ii");
            classRef.initializeAd();
          }, 3000);
        }

        // ReactGA.initialize('UA-93668328-1');
      } else if (window.location.pathname === "/psl-live") {
        window.location.href = "/ten-sports-live";
      } else if (
        (response.data.code === 500 || response.data.code === 400) &&
        window.location.pathname !== "/psl-live"
      ) {
        window.location.href = "/watch-live-tv-channels";
        // window.location.href = "/404";
      }
      if (flag == 1) {
        classRef.initializePlayer();
      }
      if (localStorage.getItem('package_status') != "true" && response.data.data.is_mast == 1) {
        classRef.initializeAd();
      }

    });
    $('.player_sec').removeClass('VodSec');
    socket = io("https://srv3.mjunoon.tv:8002/" + slug);
    socket.on("connect", function () {

      socket.on("counter", function (data) {

        classRef.setState({
          liveViews: data.count,
        });
      });
    });
  };
  watchNow = () => {

    localStorage.setItem('restart', 'no');
    $(".restart_watch_live").attr("style", "display: none !important");
    $("#rmpPlayer").click();
  }

  restartPrgram = () => {
    //  var vid = document.getElementsByClassName("rmp-video")[0];
    //  vid.currentTime = 0
    window.RMP.dvrSeekTo(10)
    localStorage.setItem('restart', 'no');
    $(".restart_watch_live").attr("style", "display: none !important");
    $("#rmpPlayer").click();
  }
  changeTabRefreshAds = (adId) => {
    $("#fullscreenbutton").show();

    DFPManager.reload(adId);
  };
  pslbutton = (type) => {
    this.closeBtn();
    this.openPSL();
    // this.openPSLFrame(1,type);
  };
  pslMobile = (type) => {
    if (this.state.screenOrientation === "landscape") {
      this.closeBtn();
      this.openPSL();
    } else if (this.state.screenOrientation === "portrait") {
      this.closeBtn();

      var wh = $(window).height();
      var dh = $(".player_sec").height();
      var hh = $(".main_header").height();
      var final_height = parseInt(parseInt(wh) - parseInt(dh + hh));

      $(".mob_overlaybox").css({ height: final_height + "px" });
      $(".mob_overlaybox").slideToggle();
    }

    // $('#framepsl').load(function(){
    //     var iframe = $('#framepsl').contents();
    //     iframe.find("#close_btn_psl ").click(function(){
    //         $('.mob_overlaybox').slideUp();
    //     });
    // });
  };
  openPSL = () => {
    let classRef = this;
    $("#fullscreenbutton").show();
    $(".rmp-outline").attr("style", "display: none !important");
    $(".rmp-module").attr("style", "display: none !important");
    // $(".rmp-pip").attr("style", "display: none !important");
    $(".rmp-content").css({
      width: "50%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $(".rmp-ad-container iframe").css({
      width: "50%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $("#rmpPlayer").append(
      '<div class="dyn-add4" id="dyn-add4" style="bottom: 0px; height: 100%; position: absolute; width: 50%; color: rgb(255, 255, 255); right: 0px; display: block; top: 0px;"></div>'
    );
    $(".dyn-add4").append(
      '<a id="afterclickhalf" class="afterclick"><img src="https://www.mjunoon.tv/assets/images/close.png" style="width: 100%;" alt="close"></img></a><iframe  style="width: 100%; height: 100%;" src="' +
      classRef.state.frameUrl +
      '" frameborder="0" allowfullscreen></iframe>'
    );
    $("#afterclickhalf").on("click", function () {
      classRef.closeBtn();
    });
  };

  XYaxisAd = () => {
    let classRef = this;
    // $('.player_hd_bar').hide()
    // $('#vol_btn').hide()    
    $(".rmp-outline").attr("style", "display: none !important");
    $(".rmp-module").attr("style", "display: none !important");
    // $(".rmp-pip").attr("style", "display: none !important");

    $(".rmp-content").css({
      width: "85%",
      height: "85%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $(".rmp-ad-container iframe").css({
      width: "85%",
      height: "85%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $("#rmpPlayer").append(
      '<div class="dyn-add2" style="bottom: 15%; height: 85%; position: absolute; width: 15%; color: rgb(255, 255, 255); right: 0px; display: block;"><div class="ad_clck" id="afterclickver"><img alt="Vertical Ad" src="' +
      classRef.state.channelUrl.ad_vertical +
      '" style="width: 100%;height: 100%;"></div></div>'
    );
    $("#rmpPlayer").append(
      '<div class="dyn-add " style="bottom: 0px; height: 15%; position: absolute; width: 100%; color: rgb(255, 255, 255); left: 0px; display: block;"><div class="ad_clck" id="afterclickhor"><img alt="Horizontal Ad" src="' +
      classRef.state.channelUrl.ad_horizontal +
      '" style="width: 100%;height: 100%;"></div></div>'
    );
    $("#afterclickhor").on("click", function () {
      classRef.openRedirectionalAd();
    });
    $("#afterclickver").on("click", function () {
      classRef.openRedirectionalAd();
    });
    $(".player_hd_bar").hide();
    $(".rmp-control-bar").hide();
  };
  openRedirectionalAd = () => {
    // $('.player_hd_bar').hide()
    $("#rmpPlayer").click();
    $(".dyn-add").remove("");
    $(".dyn-add2").remove("");
    let classRef = this;
    $("#fullscreenbutton").show();
    $(".rmp-outline").attr("style", "display: none !important");
    $(".rmp-module").attr("style", "display: none !important");
    // $(".rmp-pip").attr("style", "display: none !important");
    $(".rmp-content").css({
      width: "50%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $(".rmp-ad-container iframe").css({
      width: "50%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $("#rmpPlayer").append(
      '<div class="dyn-add4" id="dyn-add4" style="bottom: 0px; height: 100%; position: absolute; width: 50%; color: rgb(255, 255, 255); right: 0px; display: block; top: 0px;"></div>'
    );
    $(".dyn-add4").append(
      '<a id="afterclickhalf" class="afterclick"><img alt="close" src="https://www.mjunoon.tv/assets/images/close.png" style="width: 100%;"></img></a><img alt="Half Screen" src="' +
      classRef.state.channelUrl.ad_half_screen +
      '" style="width: 100%;"></img><a class="ad_and_btn" href="' + classRef.state.channelUrl.ad_redirect + '" target="_blank"></a><a class="ad_ios_btn" href="' + classRef.state.channelUrl.ad_redirect + '" target="_blank"></a>'
    );
    $("#afterclickhalf").on("click", function () {
      classRef.closeBtn();
    });
  };

  closeBtn = () => {

    $(".rmp-control-bar").show();
    $(".rmp-overlay-wrapper").css("display", "block");
    // $('.player_hd_bar').show()
    $('#vol_btn').show()
    $("#rmpPlayer").click();
    $("#fullscreenbutton").hide();
    $(".rmp-outline").attr("style", "display: block !important");
    $(".rmp-module").attr("style", "display: block !important");
    // $(".rmp-pip").attr("style", "display: block !important");
    $(".dyn-add4").remove("");
    $(".rmp-ad-container iframe").css({
      width: "100%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
    $(".rmp-content").css({
      width: "100%",
      height: "100%",
      "-webkit-transition": "all .50s ease",
      "-moz-transition": "all .50s ease",
      "-o-transition": "all .50s ease",
      "-ms-transition": "all .50s ease",
      transition: " all .50s ease",
    });
  };

  clicktomin = () => {
    $("#fullscreenbutton").click();
    $("#fullscreenbutton").removeClass("rmp-i-resize-small");
    $("#fullscreenbutton").addClass("rmp-i-resize-full");
    $("#fullsrc").hide();
  };

  callChannelDetailApi = (slug) => {
    // if(window.location.pathname === "/psl-live"){
    //     window.location.href = "/";
    // }
    if (window.location.pathname === "/coronavirus-live-updates") {
      // window.location.href = '/coronavirus-updates-live';
      window.location.href = "/";
    }
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "channel-details",
      data: { slug: slug, user_id: localStorage.getItem("user_id") },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};

      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );

      if (response.data.code === 200 && response.data.status === true) {
        classRef.setState({
          channelDetail: {
            isLoaded: true,
            data: response.data.data,
          },
          isFav: response.data.data.channel_detail.channel_fav ? true : false,
        });

        window.tagManagerEvent(
          response.data.data.channel_detail.channel_name + " - TV Channel",
          "Live TV",
          response.data.data.channel_detail.channel_name,
          {
            placement_channel: response.data.data.channel_detail.channel_name,
            placement_program: "",
            placement_genre: response.data.data.channel_detail.channel_type,
            user_gender: localStorage.getItem("gender")
              ? localStorage.getItem("gender")
              : "Unknown",
            user_age: localStorage.getItem("dob")
              ? Constant.getDateDiff(
                localStorage.getItem("dob"),
                Constant.currentDate
              )
              : "",
            user_interest:
              window.getUserTopics() !== "" ? window.getUserTopics() : "",
            user_type: localStorage.getItem("mobile") ? "real" : "temp",
            user_behavior: Constant.currentEpocTime,
            custom_ga_client_id:
              window.ga_client_id !== "" ? window.ga_client_id : "",
            email: localStorage.getItem("email")
              ? localStorage.getItem("email")
              : "",
            name: localStorage.getItem("name")
              ? localStorage.getItem("name")
              : "",
            number: localStorage.getItem("mobile")
              ? localStorage.getItem("mobile")
              : "",
            search_keyword: "",
            user_custom_id: localStorage.getItem("user_id")
              ? localStorage.getItem("user_id")
              : "",
            user_device_id: "",
          }
        );
        window.firebaseEvent({
          custom_ga_client_id: "",
          email: localStorage.getItem("email")
            ? localStorage.getItem("email")
            : "",
          name: localStorage.getItem("name")
            ? localStorage.getItem("name")
            : "",
          number: localStorage.getItem("mobile")
            ? localStorage.getItem("mobile")
            : "",
          search_keyword: "",
          user_custom_id: localStorage.getItem("user_id")
            ? localStorage.getItem("user_id")
            : "",
          user_device_id: "",
          placement_channel: response.data.data.channel_detail.channel_name,
          placement_program: "",
          placement_genre: response.data.data.channel_detail.channel_type,
          user_gender: localStorage.getItem("gender")
            ? localStorage.getItem("gender")
            : "Unknown",
          user_age: localStorage.getItem("dob")
            ? Constant.getDateDiff(
              localStorage.getItem("dob"),
              Constant.currentDate
            )
            : "",
          user_interest: "",
          user_type: localStorage.getItem("mobile") ? "real" : "temp",
          user_behavior: Constant.currentEpocTime,
        });
        window.imgNotFound();
      }

    });
  };

  loadGlider = () => {
    new window.Glider(document.querySelector("#player_glider_slider_channel"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 92,
      itemWidth: 92,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#player_glider_slider_channel_prev",
        next: "#player_glider_slider_channel_next",
      },
      responsive: [
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 102,
            itemWidth: 102,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 108,
            itemWidth: 108,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 92,
            itemWidth: 92,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 100,
            itemWidth: 100,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 92,
            itemWidth: 92,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 122,
            itemWidth: 122,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 131,
            itemWidth: 131,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 128,
            itemWidth: 128,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 124,
            itemWidth: 124,
            draggable: false,
          },
        },
      ],
    });
  };

  addFavourite = (channelId) => {
    if (!localStorage.getItem("user_id")) {
      new Header().openLoginModal();
    }
    if (this.state.isFav) {
      let user_id = localStorage.getItem("user_id");
      let classRef = this;
      axios({
        method: "POST",
        url: Constant.url + "remove-favourite",
        data: {
          id: channelId,
          user_id: localStorage.getItem("user_id"),
          type: "channel",
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200 && response.data.status === true) {
          $(".favourite").removeClass("active");
          classRef.setState({
            isFav: false,
          });
        }

      });
    } else {
      let id = channelId;
      let user_id = localStorage.getItem("user_id");
      let classRef = this;
      let params = JSON.stringify({ channel_id: channelId, user_id: user_id });
      axios({
        method: "POST",
        url: Constant.url + "add-user-favourite-channels", //yaseen   add-user-favourite-channels-2
        // data: { dataparams: LocalStorageService.encryptAes(params) },
        data: {
          channel_id: channelId,
          user_id: user_id
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200 && response.data.status === true) {
          classRef.setState({
            isFav: true,
          });
          $(".favourite").addClass("active");
        }

      });
    }
  };

  handleLatestEpisodes = () => {
    return (
      <MobileEpisode
        name="Latest Episodes"
        sliderKey={1}
        data={this.state.channelDetail.data.latestEpisode}
      />
    );
  };

  handleNextEpisodePromos = () => {
    return (
      <MobileEpisode
        name="Next Episode Promos"
        sliderKey={2}
        data={this.state.channelDetail.data.promo}
      />
    );
  };
  handleDramas = () => {
    return (
      <MobileProgram
        name={
          this.state.channelDetail.data.channel_detail.channel_name + " Dramas"
        }
        sliderKey={3}
        data={this.state.channelDetail.data.programs}
      />
    );
  };
  handleRelatedChannels = () => {
    return (
      <Channel
        ad_class="a"
        name="Related Channels"
        sliderKey={4}
        data={this.state.channelDetail.data.channel}
      />
    );
  };
  handleChannelInfo = () => {
    return (
      <div className="info_tab_box">
        {/* <Link className="hashtag" to={"/search?search="+this.state.channelDetail.data.channel_detail.channel_name}>{'#'+this.state.channelDetail.data.channel_detail.channel_name}</Link> */}
        <div className="abt_content">
          <div className="InfoTextDesk">
            <div className="hashtagbox">
              {/* <a className="hashtag" href={void(0)}>#CNN</a> <a className="hashtag" href={void(0)}>#News</a> */}
            </div>
            <div className="seprateheading">
              <h1>{this.state.channelDetail.data.channel_detail.channel_name}</h1>
              {/* <p><span>1,463 views . Jun 1, 2021</span></p>               */}
            </div>
          </div>
          <div className="InfoTextMob">

            <div className="seprateheading">
              {/* <p><span>1,463 views . Jun 1, 2021</span></p> */}
            </div>
            <div className="hashtagbox">
              {/* <a className="hashtag" href={void(0)}>#CNN</a> <a className="hashtag" href={void(0)}>#News</a> */}
            </div>
          </div>

          <ShowMoreText
            lines={2}
            more={<span click uk-icon='icon: chevron-down'></span>}
            less={<span uk-icon='icon: chevron-up'></span>}
            anchorClass=""
            onClick={this.executeOnClick}
            expanded={false}
          >
            {ReactHtmlParser(
              this.state.channelDetail.data.channel_detail.channel_long_desc
            )}
          </ShowMoreText>
        </div>
        <div className="info_txt">
          <a className="channel_sldr_box" href="#">
            <div className="channel_sldr_img">
              <img
                src={this.state.channelDetail.data.channel_detail.channel_logo}
                alt={this.state.channelDetail.data.channel_detail.channel_name}
              />
            </div>
          </a>
          <h2 className="h1fordramadetail">
            <b>{this.state.channelDetail.data.channel_detail.channel_name} </b>
          </h2>
          <p className="info_category_txt">
            {this.state.channelDetail.data.channel_detail.channel_type}
          </p>
          {/* <a
            className={
              this.state.channelDetail.data.channel_detail.channel_fav ||
                this.state.isFav
                ? "favourite active"
                : "favourite"
            }
            onClick={(e) =>
              this.addFavourite(
                this.state.channelDetail.data.channel_detail.channel_id,
                e
              )
            }
          >
            FAVOURITE <span uk-icon="heart" />
          </a> */}
        </div>
      </div>
    );
  };
  toggleDetailButton = () => {
    $(".toggle_txt_btn").hide();
    $(".hide_txt").addClass("inline_txt");
  };
  handleRelated = () => {
    return <ChannelRelated data={this.state.channelDetail.data.channel} />;
  };
  fireEvent = () => {

    window.dataLayer.push({
      event: "trackAdClick",
      video_ad_click: Constant.getAdTagUrlParams(
        this.state.channelUrl.addTagUrl
      ),
    });
    window.firebaseEvent({
      video_ad_click: Constant.getAdTagUrlParams(
        this.state.channelUrl.addTagUrl
      ),
    });
  };
  executeOnClick(isExpanded) {
    if (isExpanded) {

      $("#addExpandClass").addClass("height_auto");
    } else {

      $("#addExpandClass").removeClass("height_auto");
    }
  }
  volumeHandle = () => {
    $(".rmp-volume").click();
    if ($('#volume_image').hasClass('active')) {
      $('#volume_image').removeClass('active')
      $("#volume_image").attr("src", "/images/volume_off.svg");
    } else {
      $('#volume_image').addClass('active')
      $("#volume_image").attr("src", "/images/volume_up.svg");
    }
  }

  initializePlayer = () => {
    // window.setVolume()

    $(".rmp-quality").remove();
    // $(".rmp-time-elapsed").remove();
    // $(".rmp-cast").remove();
    $(".rmp-pip").remove();
    $("#rewind").change(function () { classRef.setState({ rewindInitialize: false }) });

    var classRef = this;
    var src = {
      hls: this.state.channelUrl.url
    };
    var backupSrc = {
      hls: this.state.backup_live_stream_url
    };
    // Then we set our player settings
    if (this.state.restartProgram) {
      var settings = {
        licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
        src: src,
        backupSrc: backupSrc,
        autoHeightMode: true,
        quality: false,
        pip: true,
        googleCast: true,
        googleCastReceiverAppId: 'C899A03E',
        delayToFade: 3000,
        gaLabel: 'Live TV',
        // logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
        logo: '',
        logoWatermark: false,
        logoPosition: 'topleft',
        sharing: true,
        sharingNetworks: ['facebook', 'twitter', 'linkedin', 'Whatsapp', 'email'],
        speed: 'Speed',
        isLiveDvr: true,
        airplay: true,
        hlsJSMaxBufferLength: 80,
        hlsJSLiveSyncDuration: 12,
        detectAutoplayCapabilities: true,
        autoplay: true,
        srcChangeAutoplay: true,
        playsInline: true,
        skin: 's3',
        skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
        skinButtonColor: 'FFFFFF',
        skinAccentColor: 'FFA52D',
        ads: (isMobile == false && (localStorage.getItem('package_status') != "true")) ? true : false,
        nav: true,
        quickRewind: (this.state.livenode === true) ? false : 10,
        quickForward: (this.state.livenode === true) ? false : 10,
        // adBlockerDetection: false,
        // adEnablePreloading: false,
        // adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
        adTagUrl: (localStorage.getItem('package_status') != "true") ? this.state.channelUrl.addTagUrl : '',
        adAutoAlign: false
      };

    } else {
      var settings = {
        licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
        src: src,
        backupSrc: backupSrc,
        autoHeightMode: true,
        quality: false,
        pip: true,
        googleCast: true,
        googleCastReceiverAppId: 'C899A03E',
        delayToFade: 3000,
        gaLabel: 'Live TV',
        // logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
        logo: '',
        logoWatermark: false,
        logoPosition: 'topleft',
        sharing: true,
        sharingNetworks: ['facebook', 'twitter', 'linkedin', 'Whatsapp', 'email'],
        speed: 'Speed',
        isLive: (this.state.livenode === true) ? true : false,
        airplay: true,
        hlsJSMaxBufferLength: 80,
        hlsJSLiveSyncDuration: 12,
        detectAutoplayCapabilities: true,
        autoplay: true,
        srcChangeAutoplay: true,
        playsInline: true,
        skin: 's3',
        skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
        skinButtonColor: 'FFFFFF',
        skinAccentColor: 'FFA52D',
        ads: (isMobile == false && (localStorage.getItem('package_status') != "true")) ? true : false,
        nav: true,
        quickRewind: (this.state.livenode === true) ? false : 10,
        quickForward: (this.state.livenode === true) ? false : 10,
        // adBlockerDetection: false,
        // adEnablePreloading: false,
        // adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
        adTagUrl: (localStorage.getItem('package_status') != "true") ? this.state.channelUrl.addTagUrl : '',
        adAutoAlign: false
      };
    }
    // Reference to player container
    var elementID = 'rmpPlayer';
    // Object creation
    var rmp = window.initializeRMP(elementID);
    // rmp.setModuleOverlayVisible('quality',false);

    // Player container which will receive API events
    var rmpContainer = document.getElementById(elementID);




    $(".rmp-quality").css("cssText", 'display:none !important;');
    if (rmpContainer) {
      rmpContainer.addEventListener('ready', function () {
        $(".rmp-loading-spin").html('<div class="loader_box"><div class="loader"><div class="loader_inner"></div></div></div>')
        $(".rmp-quality").hide();
        // $(".rmp-quality").remove();
        // $(".rmp-time-elapsed").remove();
        $(".rmp-pip").remove();
        // $(".rmp-cast").remove();   
        if (classRef.state.qualitClicked) {
          setInterval(function () {
            $(".rmp-q0").html('');
            $('.rmp-overlay-wrapper').css('z-index', 1);
            $(".rmp-q0").html('Auto (' + $(".rmp-abr-active").text() + ')')
            if ($(".rmp-abr-active").text() == "720p HD" || $(".rmp-abr-active").text() == "1080p HD") {
              $(".qty_btn").addClass('active')
            } else {
              $(".qty_btn").removeClass('active')
            }
          }, 500)
          classRef.setState({ qualitClicked: false })
        }
        if (isMobile == false) {
          $(".rmp-volume").remove();
        }

        if (isMobile == false) {
          $(".rmp-volume").next().remove();
        } else {
          // $('.player_ul').on('click', '.pip_btn', function(e) {$(".rmp-pip").click()}); 
          // $('.PlayerVolumeStyle').on('click', '.pip_btn', function(e) {$(".rmp-volume").click()});           
          // rmp-i-close
        }

        activeQuality = rmp.getCurrentBitrateIndex();

        if (classRef.state.restartWatchLive) {
          $(".rmp-time-elapsed").remove();
          $(".rmp-duration").remove();
          $('#restart_button').on('click', '#livenow', function (e) { classRef.watchNow() });
          $('#restart_button').on('click', '#restartpro', function (e) { classRef.restartPrgram() });
          classRef.setState({ restartWatchLive: false })
        }

        if ($("#qty_btn").length == 0) {
          if (classRef.state.restartProgram) {
            $(".restart_watch_live").attr("style", "display: block !important");
            classRef.setState({ restartProgram: false })
          }
          if (isMobile == false) {
            $('.player_hd_bar').fadeIn(100);
            if(classRef.state.livenode === true){
              $(".rmp-control-bar").append('<span class="live_span">Live</span><button class="qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/player_quality.svg" uk-svg="" /><span>Video Quality</span></button><button class="pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button><p id="channel_name"></p>')
            }else{
              $(".rmp-control-bar").append('<button class="qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/player_quality.svg" uk-svg="" /><span>Video Quality</span></button><button class="pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button><p id="channel_name"></p>')
            }
            {/* {this.state.livenode === true ? (
              <span className="live_span">Live</span>
            ) : (
              <span
                onClick={this.callAfterRewind}
                className="live_span live_btn"
              >
                Live
              </span>
            )} */}
           
            // $(".rmp-control-bar").append('<button id="qty_btn"><img src="/images/hd_icon.svg" uk-svg="" /><span>Video Quality</span></button><button id="pip_btn"><img src="/images/pip.svg" uk-svg="" /><span>Mini Player</span></button><p id="channel_name">'+(classRef.state.channelDetail.isLoaded) ? classRef.state.channelDetail.data.channel_detail.channel_name : ""+'</p><button id="cast_btn"><i></i><span>Cast</span></button>')
            $('.rmp-control-bar').on('click', '.qty_btn', function (e) { classRef.invokeQuality() });
            $('.rmp-control-bar').on('click', '.pip_btn', function (e) { $(".rmp-pip").click() });
            $('#rmpPlayer').on('click', '.player_backscreen', function (e) { window.history.back(); });
            $('#rmpPlayer').on('click', '.live_btn', function (e) { window.location.reload(); });
            $('.stream_convert').on('click', '#audio_video', function (e) { classRef.handleAudioSwitch(); });
            $(document).mouseup(function (e) {
              var container = $(".quality_overlay");
              if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".rmp-overlay-wrapper").parent().removeClass('quality_overlay')
                $(".rmp-overlay-wrapper").parent().addClass('overlay_dec_zindex')
                $('.rmp-overlay-wrapper').css('display', 'none');
                if ($(".rmp-abr-active").text() == "720p HD") {
                  $(".qty_btn").addClass('active')
                } else {
                  $(".qty_btn").removeClass('active')
                }
              }
            });

            if ($('#casttext').length == 0) {
              $('.rmp-cast').wrapInner('<span id="casttext">Cast</span>')
            }
            setTimeout(function () {
              if (classRef.state.channelDetail.isLoaded === true) {
                $("#channel_name").html(classRef.state.channelDetail.data.channel_detail.channel_name)
              }
              $("#rmpPlayer").hover(
                function () {
                  if ($('.dyn-add').length > 0 || $('.dyn-add2').length > 0 || $('.dyn-add4').length > 0) {

                  } else {
                    $(".player_hd_bar").fadeIn(500);
                  }
                }, function () {
                  if ($(".player_hd_bar").length > 0) {
                    // setTimeout(function(){ 
                    //   
                    // },15000)
                  }
                }
              );
            }, 4000)

          }

        }

        // if($(".PlayerVolumeStyle").length == 0){
        if (isMobile == true) {
          $("#rmpPlayer").append('<div id="vol_btn" class="rmp-control-bar PlayerVolumeStyle"><img id="volume_image" src="/images/volume_off.svg" alt="volume_off" uk-svg=""/></div>')
          $('#rmpPlayer').on('click', '#vol_btn', function (e) { classRef.volumeHandle() });
          $('.player_ul').on('click', '.qty_btn', function (e) { classRef.invokeQuality() });
          $('.rmp-control-bar').on('click', '.land_qty_btn', function (e) { classRef.invokeQuality() });
          $('.rmp-module-overlay').on('click', '.rmp-module-overlay-close', function (e) { $(".player_hd_bar").fadeIn(500); });
          $('.stream_convert').on('click', '#audio_video', function (e) { classRef.handleAudioSwitch(); });
          if (classRef.state.restartProgram) {
            $(".restart_watch_live").attr("style", "display: block !important");
            classRef.setState({ restartProgram: false })
          }
        }
        // }
        // var tempChannels = '';
        // var temp = classRef.state.channelDetail.data.channel;
        // if(temp){
        //     for(var i = 0; i < temp.length;i++)
        //     {
        //         tempChannels += '<p class="channel_sldr_box" onClick="'+classRef.updateChannel(this,temp[i].channel_slug)+'" ><div class="channel_sldr_img"><img  src='+temp[i].channel_logo_jpg+' alt='+temp[i].channel_name+'/></div></p>';
        //     }
        // }

        if (isMobile == false) {
          // $(".rmp-control-bar").append('<div id="related_channel" style="display:none;"><div class="slider_new_container circle_center"><div id="player_glider_slider_channel" class="">'+tempChannels+'</div><button role="button" aria-label="Previous" id="player_glider_slider_channel_prev" class="glider-prev"><img src="/images/left_arrow.png" alt="left arrow"/></button><button role="button" aria-label="Next" id="player_glider_slider_channel_next" class="glider-next"><img src="/images/right_arrow.png" alt="right arrow"/></button></div></div>')
          // $(".rmp-control-bar").append('<button id="qty_btn"><i></i><span>Video Quality</span></button>')

          $(".rmp-i-live").hide();
          $(".rmp-sharing").hide();
          $(".rmp-quality").hide();
          $(".rmp-pip").hide();
          // $(".rmp-cast").hide();
          // $(".rmp-time-elapsed").hide();

        }
        window.initializeRewindJquery();
      });

      rmpContainer.addEventListener('seeked', function () {
        $(".rmp-quality").hide();

        if ($(".uk-icon-button").length == 0) {
          $(".rmp-sharing-social").append('<a target="_blank" href="https://api.whatsapp.com/send?text=' + encodeURIComponent(window.location.href) + '"><span className="rmp-i uk-icon-button" uk-icon="whatsapp" tabindex="0" role="button" aria-label="Email"></span></a>')
        }

        if (window.location.pathname === "/psl-live") {
          classRef.setState({ frame: true })
          if (isMobile == false) {
            $("#rmpPlayer").append('<ul class="icons_custm"><li id="pslmatch" class="pslmatch"><a><img alt="Icon Stats" src="https://images.mjunoon.tv:8181/images/psl_button.png?v=1.0.2"/></a></li></ul>');
          } else {
            $("#rmpPlayer").append('<ul class="icons_custm"><li id="mobpslmatch" class="mobpslmatch"><a><img alt="Icon Stats" src="https://images.mjunoon.tv:8181/images/psl_button.png?v=1.0.2"/></a></li></ul>')
          }
          $('#pslmatch').on('click', function () { classRef.pslbutton(1) });
          $('#mobpslmatch').on('click', function () { classRef.pslMobile(1) });
          $('#frameclose').on('click', function () { classRef.closeFrame() });
        } else {
          classRef.setState({ frame: false })
        }
      });
      rmpContainer.addEventListener('timeupdate', function () {
        if (isMobile == false) {

          $(".rmp-quick-unmute").remove();

          if ($('#casttext').length == 0) {
            $('.rmp-cast').wrapInner('<span id="casttext">Cast</span>')
          }
        } else {
          // if(classRef.state.screenOrientation == "portrait"){
          if ($('.rmp-cast').hasClass('portraitviewmob')) {
          } else {
            $('.rmp-cast').addClass('portraitviewmob')
          }

          // }else{
          //   if($('.rmp-cast').hasClass('portraitviewmob')){

          //     $('.rmp-cast').removeClass('portraitviewmob')
          //   }
          // }
        }
        $(".rmp-volume").hide();
        $(".rmp-sharing").remove();

        $(".rmp-quality").hide();
        $(".rmp-pip").hide();
        // $(".rmp-cast").hide();
        // $(".rmp-time-elapsed").hide();
      });
      rmpContainer.addEventListener('adimpression', function () {

        window.dataLayer.push({ event: 'trackAdView', video_ad_view: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl) });
        window.firebaseEvent({ video_ad_view: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl) });
        // rmp.setBitrate(0);
        // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
      });
      rmpContainer.addEventListener('adclick', function () {

        window.dataLayer.push({ event: 'trackAdClick', video_ad_click: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl) });
        window.firebaseEvent({ video_ad_click: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl) });
        // rmp.setBitrate(0);
        // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
      });
      rmpContainer.addEventListener("waiting", function (e) {

        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
          if ($('#casttext').length == 0) {
            $('.rmp-cast').wrapInner('<span id="casttext">Cast</span>')
          }

        }

        $(".rmp-sharing").remove();

        $(".rmp-quality").hide();
        $(".rmp-pip").hide()
        // $(".rmp-cast").hide();
        // $(".rmp-time-elapsed").hide();




        // $(".rmp-quality").remove();
      });
      rmpContainer.addEventListener('error', function () {
        var errorData = rmp.getErrorData();

        if (errorData) {
          var data = errorData.event;
          if (data) {

            $(".rmp-error-text").html('Stream is unable to connect to our servers. Check your internet connection <br/>(Error Code : 4033)')
            if (data.response.code == 404) {
              $(".rmp-error-text").html('The stream is currently unavailable. Please comeback later <br/>(Error Code : 4032)')
            } else if (data.response.code == 0) {
              $(".rmp-error-text").html('Stream is unable to connect to our servers. Check your internet connection <br/>(Error Code : 4033)')
            } else {
              $(".rmp-error-text").html('This stream may not be available in your country.<br/>(Error Code : 4031)')
            }
          }

        }
      });
      rmpContainer.addEventListener('warning', function () {

        // ReactGA.event({ category: 'Samaa TV', action: 'warning'+Constant.version });
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }

        $(".rmp-sharing").remove();

        $(".rmp-quality").hide();
        $(".rmp-pip").hide();

        // $(".rmp-cast").hide();
        // $(".rmp-time-elapsed").hide();
      });

      rmpContainer.addEventListener('destroycompleted', function () {

        // remove player container from DOM
        var parentContainer = rmpContainer.parentNode;
        if (parentContainer) {
          try {
            parentContainer.removeChild(rmpContainer);
          } catch (e) {

          }
        }
      });

      rmpContainer.addEventListener('play', function () {


        $(".rmp-pip").addClass('custom_pip');

        qualities = rmp.getBitrates();
        activeQuality = rmp.getCurrentBitrateIndex();

        if (isMobile == false) {
          $(".rmp-volume").hide();
          $('.rmp-overlay-wrapper').css('display', 'none');
        }

        if (isMobile == false) {
          $(".rmp-volume").remove();
        }

        if (isMobile == false) {
          $(".rmp-volume").next().remove();
        }


      })

      rmpContainer.addEventListener('pause', function () {

        if (isMobile == false) {
          $(".rmp-volume").hide();
          $('.rmp-overlay-wrapper').css('display', 'none');
          $(".rmp-quick-unmute").remove();
        }

        $(".rmp-sharing").hide();

        $(".rmp-quality").hide();
        $(".rmp-pip").hide();
        // $(".rmp-cast").hide();
        // $(".rmp-time-elapsed").hide();
      })

      rmpContainer.addEventListener('enterfullscreen', function () {
        $(".rmp-control-bar").addClass("controls_txt");
        $(".rewind").hide()
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $('.rmp-overlay-wrapper').css('display', 'none');
        }
        if (isMobile) {
          classRef.setState({
            screenOrientation: "landscape",
          })
          if ($(".land_qty_btn").length == 0) {
            $("#rmpPlayer").append('<button class="land_qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/player_quality.svg" uk-svg="" /><span>Video Quality</span></button>')
            // $("#rmpPlayer").append('<button class="qty_btn land_qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/player_quality.svg" uk-svg="" /><span>Video Quality</span></button><button class="pip_btn land_pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button>')
            // $('#rmpPlayer').on('click', '.land_pip_btn', function (e) {  $(".rmp-pip").click() });
            $('#rmpPlayer').on('click', '.land_qty_btn', function (e) { classRef.invokeQuality() });
          }
          if ($("#audio_video").is(":checked") == true) {
            if ($(".land_qty_btn").length > 0) {
              $(".land_qty_btn").hide()
              // $(".land_pip_btn").hide()                        
            }
          }
        }


        window.fullScreenPlayer();
        $(".rmp-i-live").hide();

        classRef.setState({ share: false })
      })

      rmpContainer.addEventListener('exitfullscreen', function () {

        $(".rewind").show()
        $(".rmp-control-bar").removeClass("controls_txt");
        $("#custom_pip").remove();
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $('.rmp-overlay-wrapper').css('display', 'none');
        }
        if (isMobile) {
          classRef.setState({
            screenOrientation: "portrait",
          }, function () {

            $(".land_qty_btn").remove();
            // $(".land_pip_btn").remove();
          });
          if ($("#audio_video").is(":checked") == true) {
            if ($(".qty_btn").length > 0) {
              $(".qty_btn").hide()
              // $(".land_pip_btn").hide()                        
            }
          }
        }

        // $(".rmp-pip").show();

        $("#related_channel").hide();
        window.exitFullScreenPlayer();
        $("#channel_btn").remove();
        $(".rmp-i-live").hide();
        $(".rmp-sharing").hide();
        $(".rmp-quality").hide();
        // $(".rmp-cast").hide();
        // $(".rmp-time-elapsed").hide();
        classRef.setState({ share: true })

      })
      rmpContainer.addEventListener('touchstart', function () {
        $("#volume_btn").show();
        $(".player_ul_mob").show();
        $(".volume_range_control").show();
      })
      rmpContainer.addEventListener('loadstart', function () {
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }

        $(".rmp-sharing").remove();

        $(".rmp-quality").hide();
        // $(".rmp-cast").hide();
        $(".rmp-pip").hide();
        // $(".rmp-time-elapsed").hide();
      })
      rmpContainer.addEventListener('stalled', function () {
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }


        $(".rmp-quality").hide();
        // $(".rmp-cast").hide();
        $(".rmp-pip").hide();
        // $(".rmp-time-elapsed").hide();
      })
      rmpContainer.addEventListener('playing', function () {

        // if($("#qty_btn").length == 0){
        //   alert("LLLLLLLLL")
        //   $(".rmp-control-bar").append('<button id="qty_btn"><i></i><span>Video Quality</span></button>')

        // }

        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }


        $(".rmp-quality").hide();
        // $(".rmp-cast").hide();
        $(".rmp-pip").hide();
        // $(".rmp-time-elapsed").hide();
        activeQuality = rmp.getCurrentBitrateIndex();
      })
      rmpContainer.addEventListener('loadeddata', function () {
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }


      })
      rmpContainer.addEventListener('bufferstalled', function () {
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }


      })
      rmpContainer.addEventListener('buffernotstalledanymore', function () {
        if (isMobile == false) {
          $(".rmp-volume").hide();
          $(".rmp-quick-unmute").remove();
        }


      })
    }
    // Player initialisation - only after API events are registered
    rmp.init(settings);

    window.RMP = rmp;
    // if(this.state.rewindInitialize === false){
    // this.setState({livenode:false})
    window.initializeRewind(this.callRewindGetChannelUrlApi);
    // }
    // $("#sharebutton").on("click", function () {
    $(".playershare").on("click", '#sharebutton', function () {
      if ($("#customshare").length == 0) {
        $("#rmpPlayer").append(
          '<div id="customshare" class="customshare"><h3>Share</h3><span class="rmp-i rmp-i-close rmp-module-overlay-icons rmp-module-overlay-close" id="share_close" tabindex="0" role="button" aria-label="Close"></span><ul><li id="fb_share"><span class="rmp-i uk-icon-button" uk-icon="facebook" tabindex="0" role="button" aria-label="Email"></span></li><li><a target="_blank" href="https://twitter.com/intent/tweet?url=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="twitter" tabindex="0" role="button" aria-label="Email"></span></a></li><li id="email_share"><span class="rmp-i uk-icon-button" uk-icon="mail" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="linkedin" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://api.whatsapp.com/send?text=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="whatsapp" tabindex="0" role="button" aria-label="Email"></span></a></li></ul><div class="rmp-sharing-link"><span id="copy_share_link" class="rmp-i rmp-i-link"></span><input class="rmp-sharing-input" aria-label="Link to video" id="rmp-sharing-link-rmpPlayer" type="text" value="' +
          window.location.href +
          '"></div></div>'
        );
      }

      classRef.shareFunctions();
    });

    $(".player_ul").on("click", '#sharebuttonmob', function () {
      if ($("#customshare").length == 0) {
        $("#rmpPlayer").append(
          '<div id="customshare" class="customshare"><h3>Share</h3><span class="rmp-i rmp-i-close rmp-module-overlay-icons rmp-module-overlay-close" id="share_close" tabindex="0" role="button" aria-label="Close"></span><ul><li id="fb_share"><span class="rmp-i uk-icon-button" uk-icon="facebook" tabindex="0" role="button" aria-label="Email"></span></li><li><a target="_blank" href="https://twitter.com/intent/tweet?url=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="twitter" tabindex="0" role="button" aria-label="Email"></span></a></li><li id="email_share"><span class="rmp-i uk-icon-button" uk-icon="mail" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="linkedin" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://api.whatsapp.com/send?text=' +
          encodeURIComponent(window.location.href) +
          '"><span class="rmp-i uk-icon-button" uk-icon="whatsapp" tabindex="0" role="button" aria-label="Email"></span></a></li></ul><div class="rmp-sharing-link"><span id="copy_share_link" class="rmp-i rmp-i-link"></span><input class="rmp-sharing-input" aria-label="Link to video" id="rmp-sharing-link-rmpPlayer" type="text" value="' +
          window.location.href +
          '"></div></div>'
        );
      }

      classRef.shareFunctions();
    });

  }
  shareFunctions = () => {
    $("#fb_share").on("click", function () {
      $(".rmp-i-facebook").click();
    });
    $(".rmp-i-close").on("click", function () {
      $(".player_hd_bar").fadeIn(500);
      $("#customshare").remove();
    });
    $("#email_share").on("click", function () {
      $(".rmp-i-email").click();
    });
    $("#copy_share_link").on("click", function () {
      var share_link = window.location.href;
      navigator.clipboard.writeText(share_link)
    });
  };
  updateChannel = (e, slug) => {

  };
  invokeQuality = () => {
    // alert("invokeQuality")
    let classRef = this;

    if (isMobile == false) {
      $(".rmp-volume").hide();
      $('.rmp-i-close').hide()
      if (classRef.state.showQty) {
        classRef.setState({ showQty: false }, function () {
          $(".rmp-overlay-wrapper").parent().removeClass('quality_overlay')
          $(".rmp-overlay-wrapper").parent().addClass('overlay_dec_zindex')
          $('.rmp-overlay-wrapper').css('display', 'none');
          if ($(".rmp-abr-active").text() == "720p HD") {
            $(".qty_btn").addClass('active')
          } else {
            $(".qty_btn").removeClass('active')
          }
          // $('#rmpPlayer').removeClass('rmp-module-open-ui')
        })
      } else {
        classRef.setState({ showQty: true }, function () {
          $(".rmp-q0").html('');
          $('.rmp-overlay-wrapper').css('z-index', 1);
          $(".rmp-q0").html('Auto (' + $(".rmp-abr-active").text() + ')')
          if ($(".rmp-abr-active").text() == "720p HD" || $(".rmp-abr-active").text() == "1080p HD") {
            $(".qty_btn").addClass('active')
          } else {
            $(".qty_btn").removeClass('active')
          }
          $(".rmp-overlay-wrapper").parent().removeClass('overlay_dec_zindex')
          $(".rmp-overlay-wrapper").parent().addClass('quality_overlay')
          $('.rmp-overlay-wrapper').css('display', 'block');
          $('.rmp-quality').click();
          // $(".rmp-cast").hide();
          $(".rmp-pip").hide();
          $('.rmp-control-bar').show();
          $('.rmp-control-bar').removeClass('rmp-no-display');
          $('.rmp-overlay-title').hide();
          $('#rmpPlayer').removeClass('rmp-module-open-ui')
          $('.rmp-module-overlay-play-pause').hide();
          $('.rmp-module-overlay-close').attr('onclick')
        })

        $('.rmp-overlay-level').on('click', function() {
          $('.rmp-overlay-wrapper').fadeOut(500);
      });

      // quality tick 
      if ($('.rmp-overlay-level svg').length === 0) {
          var tickSVG = $('<svg width="16"  stroke="#b066fe" stroke-width="2" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.29627 10.7281L3.56574 7.99756L2.84858 8.71471L6.29627 12.1624L13.1514 5.30734L12.4342 4.59019L6.29627 10.7281Z" fill="#b066fe"/></svg>');
          $('.rmp-overlay-level').append(tickSVG);
      }
      }

    } else {
      // $('.player_hd_bar').hide();
      $('.rmp-quality').click();
      // $('#vol_btn').hide();
    }

  };
  switchChannel = () => {
    if ($("#related_channel").is(":visible")) {
      $("#related_channel").hide();
    } else {
      $("#related_channel").show();
    }
  };
  initializeAd = () => {
    var classRef = this;
    initializeAdTimer = setTimeout(function () {
      var adSettings = {
        licenseKey: "Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=",
        width: 640,
        height: 360,
        skin: "s2",
        autoplay: true,
        showControlsForVastPlayer: false,
        ads: true,
        detectAutoplayCapabilities: true,
        adTagReloadOnEnded: false,
        adOutStream: true,
        adTagUrl: classRef.state.channelUrl.mastHeadUrl,
      };

      // Reference to player container
      var elementID = "adPlayer";
      // Object creation
      var rmp = window.initializeRMP(elementID);
      var container = document.getElementById(elementID);

      // return;

      var _trace = function (input) {
        if (window.console.trace && input) {
          window.console.trace(input);
        }
      };

      var _removeElement = function (element) {
        var parent = element.parentNode;
        if (parent) {
          try {
            parent.removeChild(element);
          } catch (e) {
            _trace(e);
          }
        }
      };

      // when destroy method finishes we clear listeners and remove player from DOM
      var _onDestroyCompleted = function () {
        container.removeEventListener("destroycompleted", _onDestroyCompleted);
        _removeElement(container);
      };

      // player needs be removed from page
      // first we need to destroy it
      var _removePlayer = function () {
        $(".ad_cont").removeClass("ad_act_ii");
        $(".sidebar").removeClass("ad_act");
        container.addEventListener("destroycompleted", _onDestroyCompleted);
        rmp.destroy();
      };

      // function to fade out player
      var _endPlayer = function () {
        container.removeEventListener("autoplayfailure", _endPlayer);
        container.removeEventListener("adcontentresumerequested", _endPlayer);
        container.removeEventListener("addestroyed", _endPlayer);
        // nicely fade out player and remove it from DOM
        container.style.opacity = 0;
        container.style.visibility = "hidden";
        setTimeout(function () {
          _removePlayer();
        }, 400);
      };
      if (container) {
        container.addEventListener("ready", () => {
          // if Google IMA has been blocked by an ad-blocker or failed to load
          // we need to remove the player from DOM
          // $('.ad_space ').slideToggle('slow').addClass( "active" );
          // $('.sidebar').css("top","420px");
          // $('.sidebar').addClass('ad_act');
          // $('.ad_cont').addClass('ad_act_ii');
          // document.documentElement.scrollTop = 0;
          $("html, body").animate(
            {
              scrollTop: $("body").offset().top,
            },
            1000
          );

          $("#adPlayer .rmp-fullscreen").hide();
          $("#adPlayer .rmp-desktop-volume-icon").hide();
          $("#adPlayer .rmp-play-pause").hide();
          if (rmp.getAdParserBlocked()) {
            _removePlayer();
            return;
          }
        });
        // when ad ends - adcontentresumerequested event for Google IMA or addestroyed event for rmp-vast
        // we fade out player and remove it from DOM
        // in case of autoplayfailure event we also need to remove it - note that autoplayfailure should
        // be infrequent if you are using muted autoplay as recommended
        // whichever comes first
        container.addEventListener("autoplayfailure", _endPlayer);
        container.addEventListener("adcontentresumerequested", _endPlayer);
        container.addEventListener("addestroyed", _endPlayer);
      }
      // Player initialisation - only after API events are registered
      rmp.init(adSettings);
      // rmp.setVolumeAds(1);
      window.ADRMP = rmp;
    }, 1000);
  };
  closeFrame = () => {
    $(".mob_overlaybox").slideUp();
  };
  fullscrn = () => {
    // if($('#fullsrc').length == 0){
    //     $('.dyn-add4').append(' <div onclick="clicktomin()" id="fullsrc" className="rmp-fullscreen rmp-i rmp-i-resize-small after_max" tabindex="0" role="button" aria-label="Enter fullscreen"></div>');
    // }else{
    //     $('#fullsrc').show();
    // }
  };
  goBack = () => {
    window.history.back();
  }
  handleAudioSwitch = () => {
    let classRef = this;
    if (isMobile == false) {
      if ($("#audio_video").is(":checked") == true) {
        $("#audio_image").append('<img src="' + this.state.channelUrl.channelLiveImage + '"/><div><img src="/images/player_audioicon.svg" uk-svg="" /><P><strong>Audio Stream</strong><span>playing in background<span></P></div>');
        $("#audio_image").addClass('playeraudio');
      } else {
        $("#audio_image").html('');
        $("#audio_image").removeClass('playeraudio');
      }
    } else
      if ($("#audio_video").is(":checked") == true) {

        if ($(".land_qty_btn").length > 0) {
          if (this.state.screenOrientation == "landscape") {
            $(".land_qty_btn").hide()
            // $(".land_pip_btn").hide()          
          } else {
            $(".land_qty_btn").show()
            // $(".land_pip_btn").show()
          }
        }
        if ($(".qty_btn").length > 0) {
          if (this.state.screenOrientation == "portrait") {
            $(".qty_btn").hide()
            // $(".pip_btn").hide()          
          } else {
            $(".qty_btn").show()
            // $(".pip_btn").show()
          }
        }
        $("#audio_image").append('<img src="' + this.state.channelUrl.channelLiveImage + '"/><div><img src="/images/player_audioicon.svg" uk-svg="" /><P><strong>Audio Stream</strong><span>playing in background<span></P></div>');
        $("#audio_image").addClass('playeraudio');
      } else {
        if ($(".land_qty_btn").length > 0) {
          $(".land_qty_btn").show()
          // $(".land_pip_btn").show()                        
        }
        if ($(".qty_btn").length > 0) {
          $(".qty_btn").show()
          // $(".pip_btn").show()                        
        }
        $("#audio_image").html('');
        $("#audio_image").removeClass('playeraudio');
      }
    // if (isMobile == false) {
    //   if ($("#audio_video").is(":checked") == true) {        
    //       $("#rmpPlayer").addClass('playeraudio');
    //       $(".playeraudio:after").css("background-color", "red");
    //   } else {
    //       $("#rmpPlayer").removeClass('playeraudio');  

    //   }
    // }else
    //   if ($("#audio_video_mob").is(":checked") == true) {
    //     $("#rmpPlayer").addClass('playeraudio');
    //     $(".playeraudio:after").css("background-color", "red");
    //   } else {
    //     $("#rmpPlayer").removeClass('playeraudio');       
    //   }

  };

  playerControls = () => {
    return (
      <div id="rmpPlayer">
        {(isMobile == false) ?
          //desktop view player_hd_bar
          <div className="player_hd_bar" style={{ display: 'none' }}>
            <button className="player_backscreen" onClick={this.goBack}><img alt="Back" src="/images/player_back.svg" uk-svg="" /></button>
            {/* {this.state.livenode === true ? (
              <span className="live_span">Live</span>
            ) : (
              <span
                onClick={this.callAfterRewind}
                className="live_span live_btn"
              >
                Live
              </span>
            )} */}
            <img className="logo-playerScreen" src="/images/logo-old.png" alt="mJunoon.tv" />
            {this.state.livenode === true ? (
              <p className="watching_now" id="liveViews"></p>
            ) : (
              ""
            )}
            <ul className="player_ul">
              {/* <li>
                    <a id="castbutton">
                      <img alt="Share" src="/images/player_cast.svg" uk-svg="" />{" "}
                      <span>Cast</span>
                    </a>
                  </li> */}
              <li>
                {this.state.channelUrl.is_playback === true ? (
                  <button
                    className="rewind"
                    style={{ cursor: "pointer" }}
                    id="rewind"
                  >
                    <img
                      alt="Rewind"
                      src="/images/player_rewind.svg"
                      uk-svg=""
                    />{" "}
                    <span>Rewind</span>
                  </button>
                ) : (
                  ""
                )}
              </li>
              <li className="playershare">
                <a id="sharebutton">
                  <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                  <span>Share</span>
                </a>
              </li>
              <li>
                <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade; pos: top-right" for="audio_video">
                  <input
                    type="checkbox"
                    id="audio_video"
                  // onChange={this.handleAudioSwitch}
                  />

                  <div class="stream_convert_slider"></div>
                </label>
              </li>
            </ul>
          </div>
          :
          //mobile view player_hd_bar
          <div className="rmp-control-bar player_hd_bar">
            <button className="player_backscreen" onClick={this.goBack}><img alt="Back" src="/images/player_back.svg" uk-svg="" /></button>
            {this.state.livenode === true ? (
              <span className="live_span">Live</span>
            ) : (
              <span
                onClick={this.callAfterRewind}
                className="live_span live_btn"
              >
                Live
              </span>
            )}
            {this.state.livenode === true ? (
              <p className="watching_now" id="liveViews"></p>
            ) : (
              ""
            )}
            <ul className="player_ul">
              {/* <li>
                    <a id="castbutton">
                      <img alt="Share" src="/images/player_cast.svg" uk-svg="" />{" "}
                      
                    </a>
                  </li>                   */}
              {/* {(this.state.screenOrientation == "portrait")?
                <li>
                  <button class="pip_btn" id="pip_btn">
                    <img src="/images/player_pip.svg" uk-svg="" />
                  </button>
                </li>
                :""} */}



              {(this.state.screenOrientation == "portrait") ?
                <li>
                  <button class="qty_btn" id="qty_btn">
                    <img src="/images/player_quality.svg" uk-svg="" />
                  </button>
                </li>
                : ""}
              <li>
                {this.state.channelUrl.is_playback === true ? (
                  <button
                    className="rewind"
                    style={{ cursor: "pointer" }}
                    id="rewind"
                  >
                    <img
                      alt="Rewind"
                      src="/images/player_rewind.svg"
                      uk-svg=""
                    />{" "}

                  </button>
                ) : (
                  ""
                )}
              </li>
              {(this.state.screenOrientation == "landscape") ?
                <li>
                  <a id="sharebuttonmob">
                    <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                    <span>Share</span>
                  </a>
                </li>
                : ""}
              <li>

                <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade; pos: top-right" for="audio_video">
                  <input
                    type="checkbox"
                    id="audio_video"
                  // onChange={this.handleAudioSwitch}
                  />

                  <div class="stream_convert_slider"></div>
                </label>
              </li>
            </ul>
          </div>
        }

        {/* <div id="fullscreenbutton" onClick={this.fullscrn} style={{display:'none'}} className="rmp-fullscreen rmp-i rmp-i-resize-full after_max" tabindex="0" role="button" aria-label="Enter fullscreen"></div> */}
        <div id="audio_image"></div>
        <div id="restart_watch_live" className="restart_watch_live">
          <div>
            <p id="restart_button">
              <button id='livenow'><img src="/images/play.svg" uk-svg="" /><span>Watch Now</span></button>
              <button id='restartpro'><img src="/images/restartprog.svg" uk-svg="" /><span>Restart Program</span></button></p>
          </div>
        </div>
      </div>
    );
  }

  checkiSignin = () => {
    // if (!localStorage.getItem("user_id")) {

    //   $("#modal_login").addClass('uk-flex uk-open');

    // } else {

      window.location = '/packages/';

    // }
    {/* Disable By yaseen on request of Aamir bhai */}
  }

  render() {
    return (
      <div>
       
            {this.state.metaTitle !== "" ? (
              <Meta
                schema={null}
                metaKeywords={this.state.metaKeywords}
                metaTitle={this.state.metaTitle}
                metaDescription={this.state.metaDescription}
                metaImage={this.state.metaImage}
                metaUpdated={this.metaUpdated}
              />
            ) : (
              ""
            )}
 {this.state.episodeDetail.isLoaded? <>
               
            <Header />

            {(localStorage.getItem('package_status') == "true" || this.state.isFree == 1) ?
              <div>

                <div className="ad_cont ">
                  <div
                    className="img_before"
                    style={{
                      backgroundImage:
                        "url(" + this.state.channelUrl.mastHeadPicture + ")",
                    }}
                  />
                  <div className="ad_space ">
                    <div className="mastHead" />
                    <div id="adPlayer" />
                  </div>
                </div>

                <div uk-height-match="target: > .h_match" id="contentDiv">
                  <div className="content_sec live_sec  rmv_pt_60 h_match">
                    <div className="mob_overlaybox">
                      <a id="frameclose" className="frameclose">
                        <img
                          alt="Close"
                          src="https://www.mjunoon.tv/assets/images/close.png"
                          style={{ width: "100%" }}
                        ></img>
                      </a>
                      <iframe id="framepsl" src={this.state.frameUrl}></iframe>
                    </div>
                    {/* Player Section Start  */}

                    <section className="player_sec">
                      <div className="ShimmerPlayerLiveBox">
                        <div className="ShimmerPlayerLiveInner"></div>
                      </div>
                      {/* {this.state.channelDetail.isLoaded === false ? <PlayerShimmer/>:''} */}
                      {(this.state.playerRender) ? this.playerControls() : ''}

                      <div className="player_btm_bar">



                        <p className="watching_now_live">{(this.state.channelDetail.isLoaded === true) ? this.state.channelDetail.data.channel_detail.channel_views : ''} Views</p>
                        <ul className="player_ul">
                          <li><a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} target="_blank"><img src="/images/share.svg" alte="share" uk-svg="" /> Share</a></li>
                        </ul>


                      </div>
                      {this.state.channelDetail.isLoaded === true ? (
                        this.handleChannelInfo()
                      ) : this.state.channelDetail.isLoaded === false ? (
                        <PlayerShimmer />
                      ) : (
                        ""
                      )}
                      {(this.state.isAds === true && localStorage.getItem('package_status') != "true" && isMobile) ? (
                        <div className="ad_container">
                          <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot
                              slotId="adsidebar-mob-info"
                              dfpNetworkId="205019744"
                              sizes={[[320, 50]]}
                              adUnit="MJUNOON.TV-320X50"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </section>

                    {/* Mobile sidebar STart */}
                    <div className="sidebar mobile_info_sec">


                      {(localStorage.getItem('package_status') != "true" && !isMobile) ?
                        <div className="adbox_sidebar">
                          <div className="desktop-ads11">
                            <AdSlot
                              slotId="adsidebar-desktop-info"
                              dfpNetworkId="205019744"
                              sizes={[[300, 250]]}
                              adUnit="MJUNOON.TV-300x250"
                            />
                          </div>
                        </div>
                        : ''}
                      {(isMobile && (localStorage.getItem('user_id'))) ?
                        <FavChannelLivetv /> : ""}
                      {isMobile && this.state.channelType.isLoaded === true ? <RelatedFilter slug={this.props.match.params.slug} data={this.state.channelType.list} /> : ''}

                    </div>
                    {/* Mobile sidebar End */}
                    {/* {this.state.nowAndUpNext.isLoaded ? <NowUpnextLive heading = {this.state.nowAndUpNext.heading} data={this.state.nowAndUpNext.list}/>:''} */}
                    {/* Latest Episodes Start */}
                    {this.state.channelDetail.isLoaded === true &&
                      this.state.channelDetail.data.latestEpisode.length > 0
                      ? this.handleLatestEpisodes()
                      : ''}
                    {/* Latest Episodes End */}
                    <hr />
                    {/* HUM TV Dramas Start */}


                    {this.state.channelDetail.isLoaded === true &&
                      this.state.channelDetail.data.promo.length > 0
                      ? this.handleDramas()
                      : ""}
                    {/* HUM TV Dramas End */}
                    <hr />
                    {/* Next Episode Promos Start */}
                    {this.state.channelDetail.isLoaded === true &&
                      this.state.channelDetail.data.promo.length > 0
                      ? this.handleNextEpisodePromos()
                      : ""}
                    {/* Next Episode Promos End */}
                    <hr />
                    {/* Entertainment Channels Start */}
                    {this.state.channelDetail.isLoaded === true &&
                      this.state.channelDetail.data.channel.length > 0 ? (
                      this.handleRelatedChannels()
                    ) : this.state.channelDetail.isLoaded === false ? (
                      <div><EpisodeShimmer /><ChannelShimmer /></div>
                    ) : (
                      ""
                    )}
                    {/* Entertainment Channels End */}
                  </div>
                  {/* Content Section End */}
                  {/* sidebar STart */}
                  <div className="sidebar h_match" id="addExpandClass">
                    {(localStorage.getItem('package_status') != "true" && !isMobile) ?
                      <div className="adbox_sidebar">
                        <div className="desktop-ads11">
                          <AdSlot
                            slotId="adsidebar-desktop-info"
                            dfpNetworkId="205019744"
                            sizes={[[300, 250]]}
                            adUnit="MJUNOON.TV-300x250"
                          />
                        </div>
                      </div>
                      : ''}
                    {(localStorage.getItem('user_id')) ?
                      <FavChannelLivetv /> : ""}
                    {this.state.channelType.isLoaded === true ? <RelatedFilter slug={this.props.match.params.slug} data={this.state.channelType.list} /> : ''}
                  </div>

                </div>
                <Footer />
              </div>
              :
              ""
              // <div className="content_sec">
              //   <div uk-height-match="target:  .h_match">
              //     {/* <Side_Bar_User/> */}
              //     <div class="h_match">
              //       <section className="pad_tb pad_right pad_left bg_nosign trendingSubscribe">
              //         <div className="no_signin_inner">
              //           <div className="mdl">
              //             <div className="mdl_inner">
              //               <img src="/images/logo.png" alt="Mjunoon TV" />
              //               <h2>Unlimited Entertainment</h2>
              //               <h3>Awaits You</h3>
              //               {/* <Link to="/packages" class="continuewith comp"> Subscribe Now</Link>   */}
              //               <button onClick={(e) => this.checkiSignin()} className="continuewith butn phone_btn">Subscribe Now</button>
              //             </div>
              //           </div>
              //         </div>
              //       </section>
              //       {/* <Footer/> */}
              //     </div>
              //   </div>
              // </div>
            }
             {/* {this.state.channelDetail.isLoaded ?       */}
        </> : <Loader/> }
          
      </div>
    );
  }
}

export default Live;
