import React from "react";

class TvGuideNowShimmer extends React.Component {
  render() {
    return (
      <div>
        <section className="pad_left pad_b tvguideLiveSec CrouselShimmerCont tvguideLiveShimmerCont">
          <div className="headng_box">
            <h2 className="h2forhome">
              <strong></strong>
            </h2>
            <div className="view_all"></div>
          </div>

          <div className="tvguideLiveShimmer overflowscroll">
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
            <div className="tvguideLiveShimmerinner tvguideLiveSliderInner">
              <div className="tvguideLiveSliderBox">
                <a className="tvguideLiveSliderimg"></a>
              </div>
              <div className="tvguideLiveSliderTxt">
                <div className="tvguideLiveSliderTxtimg"></div>
                <h4></h4>
                <span className="views_txt"></span>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TvGuideNowShimmer;
