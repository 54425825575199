import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Constant from "../../utils/Constant";
import Watch_History from '../pages/Watch_History';
import LocalStorageService from "../../utils/LocalStorageService";
import ImageWebp from 'react-image-webp';
import $ from 'jquery';

class List_Episode extends React.Component{

    constructor(){
        super();     
        
        
    }

    componentDidMount(){
    }
    deleteEpisodes = () =>{
        LocalStorageService._serviceToken(this.deleteEpisodesApi);
    }
    deleteEpisodesApi = () =>{
        
        let user_id = localStorage.getItem('user_id');
        if(this.props.name === 'Watch History'){
            
            axios({
                method: 'POST',
                url: Constant.url+"remove-watch-history",
                data: {user_id: user_id},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    window.location.reload();
                }
            }).then(function (result) {

            });
        }else{
            
            axios({
                method: 'POST',
                url: Constant.url+"remove-watch-list",
                data: {user_id: user_id},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    window.location.reload();
                }
            }).then(function (result) {

            });
        }
    }

    deleteSingleEpisode = (id,diVal) =>{
        LocalStorageService._serviceToken((e) => this.deleteSingleEpisodeApi(id,diVal,e));
    }

    deleteSingleEpisodeApi = (id,diVal) =>{
        
        let user_id = localStorage.getItem('user_id');
        if(this.props.name === 'Watch History'){
            
            axios({
                method: 'POST',
                url: Constant.url+"remove-single-watch-history",
                data: {user_id: user_id,episode_id : id},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    $("#"+diVal).hide();
                }
            }).then(function (result) {

            });
        }else{
            
            axios({
                method: 'POST',
                url: Constant.url+"remove-single-watch-list",
                data: {user_id: user_id,episode_id : id},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    $("#"+diVal).hide();
                }
            }).then(function (result) {

            });
        }
    }    
    
    handleepisodeList = () => {
        let episodes = "";
        episodes = this.props.data.map((episode,index) => (
          <li id={"prog_sldr_box"+index}>
            <div class="prog_sldr_box">
              <a onClick={(e) => this.deleteSingleEpisode(episode.episode_id,"prog_sldr_box"+index,e)} class="del_btn"><img src="/images/del.svg" alt="delete" uk-svg="" /> </a>
              <div class="prog_sldr_img">
              <Link to={"/watch/"+episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name}/></Link> 
              </div>
              <div class="prog_sldr_text">
                <h4 class="homesectionone"><b><Link class="anchor" to={"/watch/"+episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link></b></h4>
                <p><span className="episode_channel">{episode.channel_name}. </span> <span>{episode.episode_views}57K views</span> </p>
              </div>
            </div>
          </li>
        ));        
        return episodes;
    };
    render(){
        return (
            <div>
                <div class="headng_box">
                    <h2 class="h2forhome"><strong>{this.props.name}</strong></h2><a onClick={this.deleteEpisodes} class="view_all">Clear all</a>
                    </div> 
                    <ul class="continue_watching_m">
                    {this.handleepisodeList()}
                    </ul>
            </div>
        );
    }
}

export default List_Episode;