import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import Loader from '../section/loader';

const Footer = () => {
    return (
        <div>
           
            <footer className="pad_right pad_left pad_tb">
                <div className="uk-height-match" uk-grid = ''>
                    <div className="uk-width-1-2@l uk-width-1-1@m uk-width-1-1@s">
                        <ul>
                            <li><Link to="/about-us">ABOUT US</Link></li>
                            <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
                            <li><Link to="/terms-and-conditions">TERMS & CONDITIONS</Link></li>
                            <li><Link to="/contact-us">CONTACT US</Link></li>
                            <li><Link to="/faqs">FAQS</Link></li>
                        </ul>
                        <p>All Rights Reserved. © Copyright 2017 - {(new Date().getFullYear())} mjunoon.tv | Powered by <a href="https://www.convexinteractive.com/" target="_blank">Convex Interactive PVT LTD.</a></p>
                    </div>
                    {/* hide_dsk_ftr class add below and make col-1-1 also */}
                    <div className="uk-width-1-2@l uk-width-1-1@m uk-width-1-1@s">
                        <div className="ftr_btns"> 
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.convex.mjunoon.TV&amp;hl=en"><img src="../../images/googleplay.png" alt="Mjunoon Androiod App"/></a> 
                        <a target="_blank" href="https://itunes.apple.com/us/app/mjunoon-tv/id1107354784?mt=8"><img src="../../images/apstore.png" alt="Mjunoon IOS App"/></a> 
                        <a target="_blank" href="https://appgallery.huawei.com/#/app/C100874529"><img src={"/images/Badge-Black@3x.png"} alt="Mjunoon Huawei App"/></a>                     
                        </div>
                    </div>
                </div>
                
            </footer>
            <div class="loadkaro_bar">
            <Loader/>
            </div>
        </div>
        
    );
};

export default Footer;