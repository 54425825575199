import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import ReactGA from 'react-ga';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import LocalStorageService from "../../utils/LocalStorageService";
import { relative } from 'path';
class HeaderNew extends React.Component {
    constructor() {
        super();
        
    }




    // Searh Bar Show
    searchBarShow = () => {
        $('.searchSec').addClass('showSearchBar');
    }
    searchBarHide = () => {
        
        $('.searchSec').removeClass('showSearchBar');
        $('.searchSec').removeClass('noBtmRadius');
        $('.searchSec').removeClass('ShowFilterBox');
        $('.searchSec').trigger("reset");
    }




    render() {
        return (

            <div>


                {/* //sidebar */}
                {/* <aside className="side_menu">
                    <div className="mdl">
                        <div className="mdl_inner">
                            <ul>


                                {/* {/* <li className = {(window.location.pathname === '/search') ? 'active' : ''}> <Link to="/search"><img src={"/images/search.svg"} alt="search" uk-svg=""/> <span>SEARCH</span></Link> </li> */}
                                {/* <li className={(window.location.pathname === '/') ? 'active' : ''}> <Link to="/"><img src={"/images/home.svg"} alt="home" uk-svg="" /> <span>HOME</span></Link> </li>
                                <li className={(window.location.pathname === '/latest-news-headlines' || window.location.pathname === '/latest-news-headlines' || window.location.pathname === '/family' || window.location.pathname === '/talk-shows' || window.location.pathname === '/comedy' || window.location.pathname === '/entertainment' || window.location.pathname === '/music' || window.location.pathname === '/cooking' || window.location.pathname === '/events' || window.location.pathname === '/trending' || window.location.pathname === '/strepsils-stereo' || window.location.pathname === '/featured' || window.location.pathname === '/cricket-worldcup-2019' || window.location.pathname === '/pepsi-battle-of-the-bands-s4' || window.location.pathname === '/global' || window.location.pathname === '/technology') ? 'active' : ''}> <Link to="/latest-news-headlines"><img src={"/images/trending.svg"} alt="trending" uk-svg="" /> <span>FEED</span></Link> </li>
                                <li className={(window.location.pathname === '/watch-live-tv-channels') ? 'active' : ''}> <Link className="live_tv_icon" to="/watch-live-tv-channels"><div className="menu_icon"><img src={"/images/livetv.svg"} alt="live tv" uk-svg="" /></div> <span>LIVE TV</span></Link> </li> */}
                                {/* <li className={(window.location.pathname === '/tv-guide') ? 'active' : ''}> <Link to="/tv-guide"><img src={"/images/tvguideicon.svg"} alt="live tv" uk-svg="" /> <span>TV GUIDE</span></Link> </li> */}
                                {/* <li className={(window.location.pathname === '/movies') ? 'active' : ''}> <Link to="/movies"><img src={"/images/category-icon.svg"} alt="Movies" uk-svg="" /> <span>MOVIES</span></Link> </li>
                                <li className={(window.location.pathname === '/m-games') ? 'active' : ''}> <Link to="/mgames" ><img src={"/images/mgames-icon.svg"} alt="MGames" uk-svg="" /> <span>MGAMES</span></Link> </li> */}
                                {/* {localStorage.getItem('user_id') ?
                                <li className = {(window.location.pathname === '/watch-list') ? 'active' : ''}  > <Link to="/watch-list"><img src="/images/watchlist.svg" uk-svg=""/> <span>WATCH LIST</span></Link><div></div></li>
                                :<li> <a onClick={this.openLoginModal}><img src="/images/watchlist.svg" uk-svg=""/> <span>WATCH LIST</span></a><div></div></li>} */}
                                {/* <li className = {(window.location.pathname === '/psl/live-updates' || window.location.pathname === '/psl/points' || window.location.pathname === '/psl/matches' || window.location.pathname === '/psl/teams') ? 'active' : ''}  > <Link to="/psl/live-updates"><img width={40} src={"/images/psl/psl.webp"} alt="psl"/> <span>HBL PSL</span></Link> </li> */}
                                {/* <li style={{marginTop: '5px'}} className = {(window.location.pathname === '/psl/live-updates' || window.location.pathname === '/psl/points' || window.location.pathname === '/psl/matches' || window.location.pathname === '/psl/teams') ? 'active' : ''}  > <Link to="/psl/live-updates"><ImageWebp webp={"/images/psl/psl.webp"} src={"/images/psl/psl.png"} alt="psl" width={40}/> </Link> </li> */}
                                {/* <li className={(window.location.pathname === '/profile') || (window.location.pathname === '/favourite-topics') || (window.location.pathname === '/favourite-channel') || (window.location.pathname === '/favourite-program') || (window.location.pathname === '/watch-list') || (window.location.pathname === '/watch-history') ? 'more_desk active' : 'more_desk'} > <Link to={localStorage.getItem('user_id') ? "/profile" : ""} onClick={localStorage.getItem('user_id') ? "" : this.openLoginModal()}><img src={"/images/more.svg"} alt="more" uk-svg="" /> <span>MORE</span></Link> </li>
                                <li className={(window.location.pathname === '/profile') || (window.location.pathname === '/favourite-topics') || (window.location.pathname === '/favourite-channel') || (window.location.pathname === '/favourite-program') || (window.location.pathname === '/watch-list') || (window.location.pathname === '/watch-history') || (window.location.pathname === '/unsigned') ? 'more_mob active' : 'more_mob'}> {localStorage.getItem('user_id') ? <a href="#sidebar_profile_mob" uk-toggle=''><img src="/images/more.svg" alt="more" uk-svg="" /> <span>MORE</span></a> : <Link to={'/unsigned'}><img src="/images/more.svg" alt="more" uk-svg="" /> <span>MORE</span></Link>} </li>
                            </ul>
                        </div>
                    </div>
                </aside> */} 

            </div>
        );
    }
}

export default HeaderNew;