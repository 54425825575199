import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import $ from 'jquery';

export class WC_Program_series extends React.Component {
    componentDidMount() {
        let classRef = this;
        setTimeout(function () {
          classRef.renderHover();
        }, 2000)
      }
    
      renderHover = () => {
        let classRef = this;
        $.each(classRef.props.data, function (i) { //Loop the array
          $('.HoverNModelImgVid').on('click', '#HoverNModel_vidVolume' + '_' + i, function (e) {
            if ($('#HoverNModel_vidVolume' + '_' + i).hasClass('vidVolumemute')) {
              $('#HoverNModel_vidVolume' + '_' + i).removeClass('vidVolumemute');
              $("#video_url_" + i)[0].muted = true;
            } else {
              $('#HoverNModel_vidVolume' + '_' + i).addClass('vidVolumemute');
              $("#video_url_" + i)[0].muted = false;
            }
          });
          $("#glider_slider_pro" + '_' + i).hover(
            function () {
              
              $("#glider_slider_pro" + '_' + i).addClass('HoverClass');
              $('#glider_slider_pro' + classRef.props.sliderKey).css("overflow", "inherit");
            
            }, function () {
              
              // $("#video_url_"+ '_' + i)[0].pause();
              if ($(".HoverClass").length > 0) {
                $("#glider_slider_pro" + '_' + i).removeClass('HoverClass');
                $('#glider_slider_pro' + classRef.props.sliderKey).css("overflow", "");
                $('#img_op_' + i).css('opacity', 1);
                if (classRef.props.data[i].episode_video_url != "") {
                  $("#video_url_" + i)[0].pause();
                  $("#video_url_" + i)[0].muted = true;
                }
              }
            }
          )
        })
      }
    
      handleProgramList = () => {
        let programs = "";
        programs = this.props.data.map((program, index) => (
          <div key={index} id={"glider_slider_pro" + '_' + index} className="prog_list_cont_inner uk-position-relative">
            <Link class="dramas_box channel_sldr_box" to={"/movies/" + program.episode_slug}>
              <div class="shows_slider_img channel_sldr_img"><ImageWebp webp={program.episode_thumbnail} src={program.episode_thumbnail_jpg} alt={program.program_name} /></div>
              <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
             
            </Link>
          </div>
    
        ));
        return programs;
      };
      render() {
        return (
          <div>
            <section class={(this.props.isClass) ? "pad_left pad_right pad_t prog_box_sec dark_bg m_h" : "pad_left pad_right pad_t prog_box_sec dark_bg"}>
              <div class="headng_box">
                <h2 class="h2forhome"><strong>{this.props.name}</strong></h2>
              </div>
              <div class="prog_list_cont">
                {this.handleProgramList()}
              </div>
            </section>
          </div>
        );
      }
    }

export default WC_Program_series