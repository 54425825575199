import React from 'react';
import $ from 'jquery';

class Slider extends React.Component{
    componentDidMount(){
        var slideshow = window.UIkit.slider('#slideshow');
        var $slideItem = $('#slideshow ul > li');
        // $slideItem.on('beforeitemshow', function(){
        //     $slideItem.removeClass('uk-active')
            
        // });

    }
    render(){
        return (
            <div>
                <section className="home_slider">
                  <div id="slideshow2" className="uk-position-relative uk-visible-toggle uk-light"  tabIndex={-1} uk-slider="velocity:1;easing: ease; autoplay: true; autoplay-interval: 2500; center: true; pause-on-hover: true;">
                  {/* uk-slider="velocity:1; center: true; autoplay: true; autoplay-interval: 3000; draggable: true; easing: ease; pause-on-hover: false; " */}
                    
                    <ul className="uk-slider-items">
                      {this.props.data}

                    </ul>
                    {/* <a class="uk-position-center-left uk-position-small " href uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small " href uk-slidenav-next uk-slider-item="next"></a> */}
                   
                        <a className="banner-glider-next " href="#" uk-slider-item="next">
                            <img src="/images/next.svg" alt="next" uk-svg=""/>
                        </a>


                        <a className="banner-glider-prev " href="#" uk-slider-item="previous">
                        <img src="/images/prev.svg" alt="previous" uk-svg=""/>
                        </a>

                        <ul class="uk-slider-nav uk-dotnav"></ul>
                    </div>
                    
                </section>
                
            </div>
        );
    }
}

export default Slider;