import React from 'react';
import $ from 'jquery';
import { withRouter, Link } from 'react-router-dom';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import Header from "../common/Header";
class SliderNew extends React.Component {
    constructor() {
        super();
        //banner
        this.handleBannerList = this.handleBannerList.bind(this);
        this.state = {
            sliderLoaded : false,
            sliderIndex : true
        }
    }
    componentDidMount() {
        
    }
    showDetailsInfo = (selectorID) =>{ 
        $('body').css("overflow", "hidden");
        $('body').addClass("overlaybefore");
        $('#'+selectorID).css("bottom", 0);
    }
    hideDetailsInfo = (selectorID) =>{ 
        $('body').css("overflow", "");
        $('body').removeClass("overlaybefore");
        $('#'+selectorID).css("bottom", '-100%');
    }
    renderSlider = () =>{ 
        let classRef = this;
        if(classRef.state.sliderIndex){
            // Commented to test without video


            // setTimeout(function(){  
            //     if(window.UIkit.slider('#slideshow')){
            //         if(classRef.state.sliderIndex && window.UIkit.slider('#slideshow').index == 0){
            //             classRef.setState({sliderIndex:false})
            //             var vid = document.getElementById("slider_video_0");                
            //             $('#slideshow ul > li.uk-active').addClass('SlideActive')            
            //             vid.currentTime = 0;
            //             vid.play();
            //             vid.muted = true;
            //             $('#NewHomeSlider_vidVolume_0').show()            
            //             vid.onended = function() {                        
            //                     window.UIkit.slider('#slideshow').show(1);                                            
            //             };                          
            //         }
            //     }
            // },1000)
        }
        var slideshow = window.UIkit.slider('#slideshow');
        var $slideItem = $('#slideshow ul > li');
        
        if(!this.state.sliderLoaded){
        $.each(classRef.props.data, function (i) {
            var vid = document.getElementById("slider_video_"+ i);   
            $('#slider_video_'+i).show();            
            $('.NewHomeSlider_vid').on('click', '#NewHomeSlider_vidVolume_'+i, function(e) {
                if($('#NewHomeSlider_vidVolume_'+i).hasClass('vidVolumemute')){
                    $('#NewHomeSlider_vidVolume_'+i).removeClass('vidVolumemute');
                    vid.muted = true;
                    console.log("muted true: " + '#NewHomeSlider_vidVolume_'+i);
                }else{
                    $('#NewHomeSlider_vidVolume_'+i).addClass('vidVolumemute');
                    console.log("muted false: " + '#NewHomeSlider_vidVolume_'+i);
                    vid.muted = false;
                }
            }
        );})
        this.setState({sliderLoaded:true})
        }
        $slideItem.on('beforeitemshow', function () {                 
            $slideItem.removeClass('SlideActive') 
            $slideItem.removeClass('uk-active')                        
            // Commented to test without video


            // if(window.UIkit.slider('#slideshow').index == 1){
            //     $('#NewHomeSlider_vidVolume_0').hide();
            //     $('#NewHomeSlider_vidVolume_0').removeClass('vidVolumemute');
            //     document.getElementById("slider_video_0").muted = true;
            //     document.getElementById("slider_video_0").pause();
            //     if((classRef.props.data.length-1) > 1){
            //         $('#NewHomeSlider_vidVolume_2').hide();
            //         $('#NewHomeSlider_vidVolume_2').removeClass('vidVolumemute');
            //         document.getElementById("slider_video_2").muted = true;
            //         document.getElementById("slider_video_2").pause();
            //     }                                                                
            // }
            // if(window.UIkit.slider('#slideshow').index == 2){
            //     $('#NewHomeSlider_vidVolume_1').hide();
            //     $('#NewHomeSlider_vidVolume_1').removeClass('vidVolumemute');
            //     document.getElementById("slider_video_1").muted = true;
            //     document.getElementById("slider_video_1").pause();
            //     if((classRef.props.data.length-1) > 2){
            //         $('#NewHomeSlider_vidVolume_3').hide();
            //         $('#NewHomeSlider_vidVolume_3').removeClass('vidVolumemute');
            //         document.getElementById("slider_video_3").muted = true;
            //         document.getElementById("slider_video_3").pause();
            //     }                                                                
            // }
            // if(window.UIkit.slider('#slideshow').index == 3){
            //     $('#NewHomeSlider_vidVolume_2').hide();
            //     $('#NewHomeSlider_vidVolume_2').removeClass('vidVolumemute');
            //     document.getElementById("slider_video_2").muted = true;
            //     document.getElementById("slider_video_2").pause();
            //     if((classRef.props.data.length-1) > 3){
            //         $('#NewHomeSlider_vidVolume_4').hide();
            //         $('#NewHomeSlider_vidVolume_4').removeClass('vidVolumemute');
            //         document.getElementById("slider_video_4").muted = true;
            //         document.getElementById("slider_video_4").pause();
            //     }                                                                
            // } 
            // if(window.UIkit.slider('#slideshow').index == 4){
            //     $('#NewHomeSlider_vidVolume_3').hide();
            //     $('#NewHomeSlider_vidVolume_3').removeClass('vidVolumemute');
            //     document.getElementById("slider_video_3").muted = true;
            //     document.getElementById("slider_video_3").pause();
            //     if((classRef.props.data.length-1) > 4){
            //         $('#NewHomeSlider_vidVolume_5').hide();
            //         $('#NewHomeSlider_vidVolume_5').removeClass('vidVolumemute');
            //         document.getElementById("slider_video_5").muted = true;
            //         document.getElementById("slider_video_5").pause();
            //     }                                                                
            // }                        
            // if(window.UIkit.slider('#slideshow').index == 0){
            //     $('#NewHomeSlider_vidVolume_1').hide();
            //     $('#NewHomeSlider_vidVolume_1').removeClass('vidVolumemute');
            //     document.getElementById("slider_video_1").muted = true;
            //     document.getElementById("slider_video_1").pause();                                       
            // }    
        });

        $slideItem.on('itemshown', function(){            
            // Commented to test without video
            
            // setTimeout(function(){ 
                
            //     if(!"slider_video_"+window.UIkit.slider('#slideshow') && "slider_video_"+window.UIkit.slider('#slideshow').index !== 'undefined' && "slider_video_"+window.UIkit.slider('#slideshow') !== 'undefined'){                         
            //     var vid = document.getElementById("slider_video_"+window.UIkit.slider('#slideshow').index);                
            //     $('#slideshow ul > li.uk-active').addClass('SlideActive')            
            //     vid.currentTime = 0;
            //     vid.play();
            //     vid.muted = true;
            //     $('#NewHomeSlider_vidVolume_'+window.UIkit.slider('#slideshow').index).show()
                
            //     vid.onended = function() {        
            //         if(window.UIkit.slider('#slideshow').index == (classRef.props.data.length-1)) {
            //             window.UIkit.slider('#slideshow').show(0);
            //         }else{
            //             window.UIkit.slider('#slideshow').show(window.UIkit.slider('#slideshow').index+1);
            //         }                            
                    
            //     };  
            //  }
            // },2000) 
        });
    }

    addToFavourite = (heart_id, channelId) => {
        
        if (!localStorage.getItem('user_id')) {
          new Header().openLoginModal();
          return;
        }
        if ($("#" + heart_id).hasClass('active')) {
    
          $("#heart_" + channelId).removeClass('active')
          $("#heart_inside_" + channelId).removeClass('active')
          $("#" + heart_id).removeClass('active');
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "remove-favourite",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: { id: channelId, user_id: localStorage.getItem('user_id'),type: "channel" },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
    
            }
          })
        } else {
          $("#" + heart_id).addClass('active');
          $("#heart_" + channelId).addClass('active')
          $("#heart_inside_" + channelId).addClass('active')
          let params = JSON.stringify({ channel_id: channelId, user_id: localStorage.getItem('user_id') });
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "add-user-favourite-channels-2",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            // data: { dataparams: LocalStorageService.encryptAes(params)},
            data: { channel_id: channelId, user_id: localStorage.getItem("user_id") },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
            }
          })
        }
    }    

    addToFavouriteProgram = (heart_id, programId) => {
        
        if (!localStorage.getItem('user_id')) {
          new Header().openLoginModal();
          return;
        }
        if ($("#" + heart_id).hasClass('active')) {
    
          $("#heart_" + programId).removeClass('active')
          $("#heart_inside_" + programId).removeClass('active')
          
          $("#" + heart_id).removeClass('active');
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "remove-favourite",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: { program_id: programId, user_id: localStorage.getItem('user_id') },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
    
            }
          })
        } else {
          $("#" + heart_id).addClass('active');
          $("#heart_" + programId).addClass('active')
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "add-user-favourite-programs",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: { program_id: programId, user_id: localStorage.getItem('user_id') },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
            }
          })
        }
      }
    handleBannerList = () => {
        let banners = "";
        banners = this.props.data.map((banner, index) => (
            <li key={index} id={"slider_"+index}>
                <div className="NewHomeSlider_inner desktopSlide"> 
                <div className="NewHomeSlider_vid">                
                {/* <video style={{display : 'none'}} id={"slider_video_"+index} width={1366}>
                    <source src={banner.video} type="video/mp4" />
                    
                </video> */}
                <button className="NewHomeSlider_vidVolume" style={{display:'none'}} id={"NewHomeSlider_vidVolume_"+index}>
                    <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                    <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                    </button>  
                </div>               
                    {/* {isWebpSupported() ? <Image src={banner.thumbnail} alt={banner.name} width={1200} height={560} color='#ffffff'  /> : <img src={banner.thumbnail_jpg} alt={banner.name} width={1200} height={560} color={'#ffffff'}  />}  */}
                    <img src={banner.thumbnail} alt={banner.name} width={1200} height={560} color={'#ffffff'}  />
                    <div className="NewHomeSliderBg"></div>
                    <div className="NewHomeSlider_txt">
                    <div className="mdl">
                        <div className="mdl_inner">
                        <div className="NewHomeSlider_btns uk-margin-small-bottom">
                            {(banner.type == 'live')?<span className="liveicon">LIVE</span>:""}
                            {(banner.type == 'live')?<span className="viewicon">
                                <img src="images/eye.svg" uk-svg="" /> {banner.views}
                      </span>:""}
                        </div>
                        <img src={banner.m_web_logo} />
                            <h3>{banner.name} <br />{banner.duration}
                        </h3>
                        <div className="NewHomeSlider_btns gf_txt">
                            <Link to={banner.link} className="gf_watch_btn">
                                <img src="images/play.svg" uk-svg="" /> Watch
                            </Link>
                            {/* {(banner.type == "program") ? 
                            <a href="#" className="gf_watchlist_btn">
                                <img src="images/watchlist_btn.svg" uk-svg="" /> Watch List
                            </a>
                                :""    
                            } */}
                        </div>
                        <p>
                           {banner.description}
                        </p>
                        <p>
                            <Link to={"/"+banner.link}>SHOW MORE</Link>
                        </p>
                        </div></div>
                    </div>
                </div>                
            </li>
        ));   
        let classRef = this;
        setTimeout(function(){
            classRef.renderSlider();
        },3000)                
        return banners;
    };

    render() {
        return (
            <div>
                <section className="NewHomeSlider">
                    <div
                        id="slideshow"
                        className="uk-position-relative uk-visible-toggle uk-light"
                        tabIndex={-1}
                        uk-slider="velocity:1; center: true; autoplay: false; autoplay-interval: 4000"
                    >
                        <ul className="uk-slider-items">
                            {this.handleBannerList()}
                        </ul>
                        <ul className="uk-slider-nav"></ul>
                    </div>
                </section>
                
            </div>
        );
    }
}

export default SliderNew;