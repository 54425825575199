import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import FavChannelLivetv from '../section/FavChannelLivetv';
import TrendingChannels from '../section/TrendingChannels';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import TrendingShimmer from "../section/TrendingShimmer";
import ImageWebp from 'react-image-webp';
import {isMobile} from "react-device-detect";
import RelatedFilterShimemr from '../section/RelatedFilterShimemr';
import LiveRelatedFilterShimmer from '../section/LiveRelatedFilterShimmer';
import Loader from '../section/loader';
// import {AdSlot} from "react-dfp";



export class Reel extends React.Component {
    constructor(){
        super();        
        this.callGenreMenuApi = this.callGenreMenuApi.bind(this);
        this.handleGenreMenu = this.handleGenreMenu.bind(this);
        this.handleIconsGenreMenu = this.handleIconsGenreMenu.bind(this);
        this.callTrendingApi = this.callTrendingApi.bind(this);
        this.handEpisodes = this.handEpisodes.bind(this);
        // this.handleChannels = this.handleChannels.bind(this);
        this.handleShortCutIcons = this.handleShortCutIcons.bind(this);
        this.callMetaData = this.callMetaData.bind(this);
        this.Modalref = React.createRef();

        this.state = {
            allGenres : {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            episodes : {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            channels : {
                isLoaded: false,
                msg: 'Loading...',
                list: [],
                channel_status: 'live'
            },
            VodUrl: {
                isLoaded: false,
                url: ''
            },
            activeLoadedPlayer: {
                index: 0
            },
            pageNumber: 1,
            hasMore: true,
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '',
            isAds: false,
            isMuted: true,
            channelType : {
                isLoaded: false,
                list: {},
            isTrendingLoaded : false,
            }                                                    

        }

    }
    componentDidMount() {
        document.addEventListener("keydown", this.handleOutsideClick);
        document.addEventListener("click", this.handleOutsideClick);
      }
    
      componentWillUnmount() {
        document.addEventListener("keydown" ,this.handleOutsideClick )
        document.body.removeEventListener("click", this.handleOutsideClick);
      }
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    componentDidMount(){
        var slug = window.location.pathname.replace(/^\/|\/$/g, '');
        MetaCreation.createMeta('static',slug,(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callGenreMenuApi);
        LocalStorageService._serviceToken(this.callTrendingApi);         
        this.handEpisodes();
        // window.addEventListener('scroll', function() {
        //     var element = document.querySelectorAll('.trending_box');
        //     var position = element[0].getBoundingClientRect();
        //
        //     // checking whether fully visible
        //     if(position.top >= 0 && position.bottom <= window.innerHeight) {
        
        //     }
        //
        //     // checking for partial visibility
        //     if(position.top < window.innerHeight && position.bottom >= 0) {
        
        //     }
        // });
        var classRef = this;
        $(window).on('resize scroll', function() {
            classRef.checkActiveElementScroll();
        });
        DFPManager.load();

        this.channelTypeApi();
    }

    componentWillReceiveProps(nextProps) {
        var slug = window.location.pathname.replace(/^\/|\/$/g, '');   
        // console.log("slug in trending:", slug);       
        LocalStorageService._serviceToken(this.callTrendingApi);         
        this.handEpisodes();        
        var classRef = this;
        $(window).on('resize scroll', function() {
            classRef.checkActiveElementScroll();
        });
        DFPManager.load();        
        this.setState({
            pageNumber: 1,
        hasMore: true,
        VodUrl: {
            isLoaded: false,
            url: ''
        },
        episodes : {
            isLoaded: false,
            msg: 'Loading...',
            list: []
        }
        })
        this.channelTypeApi();
    }

    channelTypeApi = () => {
        let classRef = this;    
        axios({
            method: 'GET',
            url: Constant.url+"/channel-types",        
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){                
                classRef.setState({
                  channelType:{
                        isLoaded : true,
                        list: response.data.data.types
                    }
                },function(){
                    // setTimeout(function(){
                    //     $('body').removeClass('loadkaro');
                    // },800)
                })
            }
        }).then(function (response) {
            
        });
      };
    componentWillUnmount(){
        
        // $('#player'+this.state.activeLoadedPlayer.index).children('video')[0].destroy();
    }
    
    checkActiveElementScroll = () => {
        var classRef = this;
        $('.trending_box').each(function() {
            var activeColor = $(this).attr('id');
            let yValue = 0.55;
            if(window.mobilecheck()){

                yValue = 1.0;
            }
            
            if (window.$(this).isOnScreen(0.1, yValue)) {
                let index = $(this).attr('data');

                
                classRef.setState({
                    activeLoadedPlayer: {
                        index: index
                    }
                });
                // $('.loader_box').hide();
                // $('#episodeLink'+index).append('<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>');
                $('#episodeImg'+index).hide();
                $('#player'+index).show();
                $('#player'+index).children('video')[0].play();
            } else {

                let index = $(this).attr('data');
                
                $('#episodeImg'+index).show();
                $('#player'+index).hide();
                $('#player'+index).children('video')[0].pause();

                // var playPromise = video.play();
                //
                // if (playPromise !== undefined) {
                //     playPromise.then(_ => {
                //         // Automatic playback started!
                //         // Show playing UI.
                //     })
                //         .catch(error => {
                //             // Auto-play was prevented
                //             // Show paused UI.
                //         });
                // }
                $('#player'+index).children('video')[0].currentTime = 0;
                // $('#fixed-' + activeColor).removeClass(activeColor + '-active');
            }
        });
    };


    callGenreMenuApi = () => {
        let classRef = this;
        let genre = window.location.pathname.replace(/^\/|\/$/g, '');
        axios({
            method: 'POST',
            url: Constant.url+"get-genre-for-menu",
            data: {genre: genre},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                console.log("genre menu feed: ", response.data);
                classRef.setState({
                    allGenres:{
                        isLoaded : true,
                        list: response.data.data.genre
                    }
                })
            }
        }).then(function (response) {
             
        });

    };

    callTrendingApi = () => {
        
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let genre = window.location.pathname.replace(/^\/|\/$/g, '');
        axios({
            method: 'POST',
            url: Constant.url+"genre-base-episodes",
            data: {genre: genre,user_id:user_id,page: this.state.pageNumber},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                classRef.setState({isTrendingLoaded : true}) 
                if(classRef.state.pageNumber === 1){
                    classRef.setState({                    
                        channels:{
                            isLoaded : true,
                            list: response.data.data.channels,
                            channel_status:(response.data.data.channels.length > 0) ? "live" : 'program'
                        }                    
                    });
                  
                }
                classRef.setState({
                    episodes:{
                        isLoaded : true,
                        list: [...classRef.state.episodes.list, ...response.data.data.Episodes],
                    },
                    pageNumber: classRef.state.pageNumber + 1,
                    hasMore: response.data.data.hasMore 
                });

                
                classRef.checkActiveElementScroll();

                window.tagManagerEvent(response.data.data.Episodes[0].channel_name + " Feed", "Feed", response.data.data.Episodes[0].channel_name, {placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id:  window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') :'', name : localStorage.getItem('name') ? localStorage.getItem('name') :'',  number : localStorage.getItem('mobile') ? localStorage.getItem('mobile') :'', search_keyword: '', user_custom_id : localStorage.getItem('user_id') ? localStorage.getItem('user_id') :'', user_device_id :''});
                window.imgNotFound();

            }
        }).then(function (response) {
            
        });

    };

    // callGetVodUrlApi = (slug) => {
    //     let classRef = this;
    //     axios({
    //         method: 'POST',
    //         url: Constant.url+"streaming-url",
    //         headers: {},
    //         data: {slug: slug, is_rewind: "no", type: "episode", user_id: localStorage.getItem('user_id')}
    //     }).then(function (response) {
    //
    //         if(response.data.code === 200 && response.data.status === true){
    //             classRef.setState({
    //                 VodUrl: {
    //                     isLoaded: true,
    //                     url: response.data.data.live_stream_url,
    //
    //                 }
    //             });
    //         }
    
    //         classRef.initializePlayer();
    //     })
    // };
    addWatchList = (episodeId,fav_id,status) =>{
        LocalStorageService._serviceToken((e) => this.addWatchListToken(episodeId,fav_id,status,e));   
    }
    
    addWatchListToken = (episodeId,fav_id,status) =>{
        if(!localStorage.getItem('user_id')){
            new Header().openLoginModal();
            return;
        }
        if(status === true){
            $('#'+fav_id).html('');
            $('#'+fav_id).append('<img src="/images/watchlist_btn.svg" alt="watchlist_btn" uk-svg=""/>');
            let user_id = localStorage.getItem('user_id');
            axios({
                method: 'POST',
                url: Constant.url+"remove-single-watch-list",
                data: {user_id: user_id,episode_id: episodeId},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if(response.data.code === 200){
                    // document.location.reload(true);
                }
            }).then(function (result) {
            });
        }else if(status === false){
            $('#'+fav_id).html('');
            $('#'+fav_id).append('<img src="/images/watchlist_btn_active.svg" alt="watchlist_btn_active" uk-svg=""/>');
            let user_id = localStorage.getItem('user_id');
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url+"add-watch-list",
                data: {episodeId: episodeId, user_id: user_id,type:'vod'},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

                if(response.data.code === 200 && response.data.status === true){
                    classRef.setState({
                        // isWatchList: true
                    });
                }
                
            })
        }
    }
    handleGenreMenu = () =>{
        let allGenres = "";
        allGenres = this.state.allGenres.list.map((allGenre,index) => (
            <li key={index} className={(window.location.pathname.replace(/^\/|\/$/g, '') === allGenre.genreSlug) ? "trand_"+index+" "+'active': "trand_"+index}> <Link to={allGenre.genreSlug}><span>{allGenre.genreName}</span></Link> </li>
        ));
        return allGenres;
    }

    handleIconsGenreMenu = () =>{
        let allicons = "";

        allicons = this.state.allGenres.list.map((allGenre,index) => (
            
            <li key={index} className={(window.location.pathname.replace(/^\/|\/$/g, '') === allGenre.genreSlug) ? "trand_"+index+" "+'active': "trand_"+index}> <Link to={allGenre.genreSlug}><span><img alt={allGenre.genreName} src={(window.location.pathname.replace(/^\/|\/$/g, '') === allGenre.genreSlug) ? allGenre.activeIcon : allGenre.genreIcon}/></span><p>{allGenre.genreName}</p></Link></li>
        ));
        return allicons;
    }

    handleShortCutIcons = () => {
        $(this).toggleClass('toggle_btn_open');
        $('.trending_icon_box').slideToggle(500);
    }

    topicActive = (e,topic) => {
        if(!localStorage.getItem('user_id')){
            new Header().openLoginModal(); return
        }
        if($("#"+e).hasClass('active')){
            LocalStorageService._serviceToken((e) => this.deleteTopics(topic,e));                
        }else{
            LocalStorageService._serviceToken((e) => this.addTopic(topic,e));             
        }
        $("#"+e).toggleClass('active');

    }

    addTopic = (topic) => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"add-favourite-topics",
            data: {
                user_id: user_id,
                topic : topic
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                
                // return <Redirect  to='/basicdetail'/>;                                               
            }
        }).then(function (result) {
            
        });
    }

    deleteTopics = (topic) => {
        
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"remove-single-topic",
            data: {user_id: user_id,tag: topic},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                // document.location.reload(true);
            }
        });
    }

    openModalInner = (url) =>{ 
         
      this.setState({
        fbUrl: 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mjunoon.tv%2F'+url,
        whatsappUrl : "https://api.whatsapp.com/send?text=Watch%20mjunoon.tv%20to%20stream%20more%20than%2060%20live%20tv%20channels%20absolutely%20free",
        twitterUrl : "https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F"+url,
        linkedinUrl : "https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.mjunoon.tv%2F"+url+"&source=LinkedIn"
      }, function () {
          $("#modal_sharing_inner").addClass('uk-flex uk-open');
      });      
      }

    mutePlayer = () => {        
        this.setState({
            isMuted :true
        },function(){
            $('.muter').show();
            $('.unmuter').hide();
        });        
    }
    
    unmutePlayer = () => {
        
        this.setState({
            isMuted :false
        },function(){
            $('.muter').hide();
            $('.unmuter').show();
        }); 
    }

    handEpisodes = () => {
        let episodes = "";
        let currentPlayingIndex = null;
        var slug = window.location.pathname.replace(/^\/|\/$/g, '');   
        // console.log("trending boxes: ", this.state.episodes.list);
        episodes = this.state.episodes.list.map((episode,index) => (
            <div className='snapscrollalign' key={index}>
                
                <div className="  trending_box" id={"trbx"+index} data={index}>
                    <div className="treanding_head">
                        <img src={episode.channel_banner} alt={episode.channel_name}/>
                        {/*<ReactPlayer url={this.state.VodUrl.isLoaded === true ? this.state.VodUrl.url : ''}  controls={true} pip={true} poster={episode.channel_banner} className='react-player'  width='972px' height='546px'/>*/}
                        <h4>{episode.episode_name}</h4>
                        <p>{episode.channel_name} . {episode.episode_views} views . {episode.episode_date}</p>
                        {/* <a className="ad_reminder" id={"ad_reminder"+index} onClick={(e) => this.addWatchList(episode.episode_id,"ad_reminder"+index,(episode.isFav) ? true : false,e)}>{(episode.isFav) ? <img src="/images/watchlist_btn_active.svg" alt="reminder" uk-svg=""/> : <img src="/images/watchlist_btn.svg" alt="watchlist_btn" uk-svg=""/> }</a> */}
                    </div>

                    
                    <div className="trending_vid_box">
                        
                        <Link to={"/"+ slug +"/"+episode.episode_slug} id={"episodeLink"+index}>
                            <img src={episode.episode_thumbnail} alt={episode.episode_name} id={"episodeImg" + index}/>
                            <ReactPlayer id={"player" + index} style={{ background:'black', display: "none" }} controls={false} url={episode.episode_video_url} className='react-player' width="100%" height="100%" stopOnUnmount={true} muted={(this.state.isMuted) ? true : false} playing={index === currentPlayingIndex} // Auto-play only if this video is the currently playing one onPause={() => currentPlayingIndex = null} // Update currentPlayingIndex when video is paused
/>                     
                       </Link>
                        {/* <Link to={"/watch/"+episode.episode_slug} id={"episodeLink"+index}>
                            <img src={episode.episode_thumbnail} alt={episode.episode_name} id={"episodeImg" + index}/>
                            <ReactPlayer id={"player"+index} style={{display: "none"}} controls={false} url={episode.episode_video_url}   className='react-player'  width="100%" height="100%" muted={(this.state.isMuted) ? true : false}/>
                        </Link> */}
                        <div className="unmuter volume_style" onClick = {this.mutePlayer} style={{"display" : "none"}}><img  src="/images/volume_up.svg" alt="volume_up" uk-svg=""/></div>
                        <div className="muter volume_style" onClick = {this.unmutePlayer}><img src="/images/volume_off.svg" alt="volume_off" uk-svg=""/></div>
                        {/* <div className="duration_time"><span>09:59</span></div> */}
                    </div>
                    <div className="treanding_foot tags_box">                        
                        <a onClick={(e)=>this.openmodal()} className="FeedShareBtn"><img src="/images/share_outline.svg" uk-svg="" /><span>Share</span></a>
                        {/* <a onClick={(e)=>this.openModalInner("watch%2F" +episode.episode_slug)} className="FeedShareBtn"><img src="/images/share_outline.svg" uk-svg="" /><span>Share</span></a> */}
                        <div className="hide_overflow">
                        {episode.hasOwnProperty('topics') === true ?  episode.topics.map((topic,key) => {
                            // console.log("Topics :", topic)
                            return (
                                 (topic.topic_status === false) ? <a key={key} className='tag_box' id={"tag_box"+key+index} onClick={(e) => this.topicActive("tag_box"+key+index,topic.topic_name,e)}><span className="plus_tick"></span> <span>{topic.topic_name}</span></a> : ''
                            );
                        }) : ''}
                        </div>
                    </div>

                </div>
                {/* && localStorage.getItem('package_status') != "true" */}
                {/* Disable By yaseen on request of Aamir bhai */}

                {/* && localStorage.getItem('package_status') != "true" */}
                {this.state.isAds === true  && window.mobilecheck() === false && index % 3 === 0 && index !== 0 ? 
                    <div className="ad_container uk-margin-bottom uk-padding-remove-left">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"+index} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90-1" />
                        </div>
                    </div> : 
                    
                    (this.state.isAds === true ) ? 
                                <div className="ad_container ">
                                    <div className="desktop-ads11 mob_ad">
                                        {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                        {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                        <AdSlot slotId={"content-ad-mob"+index} dfpNetworkId="205019744" sizes={[ [320, 50]]} adUnit="MJUNOON.TV-320X50-1" />
                                    </div>
                                </div>
                :''}
                
            </div>
        ));
        return episodes;
    }

    // handleChannels = () => {
    //     let channels = "";
    //     channels = this.state.channels.list.map((channel,index) => (
    //         <li key={index}> <div className="live_style live_style_full"><Link className="fav_sld_img" to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)}><ImageWebp className="img_log" webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name} /><ImageWebp webp={channel.channel_live_image} src={channel.channel_live_image_jpg} alt={channel.channel_name}/><div className="hvr_box"><span uk-icon="play"></span></div> </Link>
    //                 <Link to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)} className="txt_box">
    //                 <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name} />
    //                 <h4>{channel.channel_name}</h4>
    //                 <span className="views_txt">{channel.channel_views} viewers</span>
    //                 <span className="live_span">{channel.channel_status === 'live' ? 'Live' : ''}</span> </Link>
    //                  </div></li>  
    //     ));
    //     return channels;
    // }


    checkiSignin = () => {
        // if(!localStorage.getItem("user_id")){
    
        // $("#modal_login").addClass('uk-flex uk-open');
    
        // }else{
    
          window.location = '/packages/';   
          
        // }
        {/* Disable By yaseen on request of Aamir bhai */}
        }


        openmodal = () => {
            $("#modal_sharing").addClass("uk-flex uk-open");
          };
          closeModal = () => {
            $("#modal_sharing").removeClass("uk-flex uk-open");
          };

        

          handleOutsideClick = (event) => {
            // Check if the click target is outside the search bar
            if (
              this.Modalref.current &&
              !this.Modalref.current.contains(event.target)
            ) {
              this.closeModal();
            }
            if(event.key === 'Escape ' || event.key === 'Esq' ){
              this.closeModal();
            }
        
          };


    render(){
        return(
            <div>   
                 {this.state.isTrendingLoaded ? <>    
                {/* {localStorage.getItem("package_status") == "true"?:false} */}
                
                
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                
                
                
                
                <Header/>
                {/* {(localStorage.getItem("package_status") == "false")?
                <div className="content_sec">
                <div uk-height-match="target:  .h_match"> 
                  
                    <div class="h_match"> 
                        <section className="pad_tb pad_right pad_left bg_nosign trendingSubscribe">
                            <div className="no_signin_inner">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <img src="images/logo.png" alt="Mjunoon TV" />
                                        <h2>Unlimited Entertainment</h2>
                                        <h3>Awaits You</h3>
                                        <button onClick={(e) => this.checkiSignin()} className="continuewith butn phone_btn">Subscribe Now</button>  
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                </div>
                : */}
                {/* {this.state.isTrendingLoaded === false ? < } */}


                {this.state.isTrendingLoaded === true ?  <>
                    
                <div className="content_sec live_sec h_match">                    
                    <section className="treanding_list pad_top_mobile"  uk-sticky="offset: 0;">
                        <div id="trend_sldr" className="treanding_list_slider" uk-slider="finite: true">
                            <ul className="uk-slider-items">
                                {this.state.allGenres.isLoaded === true ? this.handleGenreMenu():
                                <section className="TrendingShimmerCont"><div className="treanding_list_slider"><ul className="overflowscroll"><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li><li><a></a></li></ul></div></section>
                                }
                            </ul>
                            <a className="treanding_listPrev" href="#" uk-slider-item="previous"><span uk-icon="icon: chevron-left"></span></a>
                            <a className="treanding_listNext" href="#" uk-slider-item="next"><span uk-icon="icon: chevron-right"></span></a>
                        </div>
                    </section>
                    {/* <div className="trending_icon_box"  uk-sticky="offset: 113;">
                        <ul>
                            {this.state.allGenres.isLoaded === true ? this.handleIconsGenreMenu(): ""}
                        </ul>
                    </div> */}
                    
                    <section className="tending_player_sec">
                        {this.state.isAds === true && window.mobilecheck() === false && localStorage.getItem('package_status') != "true" ? <div className="ad_container ad_container_trending uk-margin-bottom">
                            <div className="desktop-ads desktop_ad">
                                <AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90-1" />
                            </div>
                        </div> : (this.state.isAds === true && localStorage.getItem('package_status') != "true") ?  <div className="ad_container ad_container_trending uk-margin-bottom ">
                            <div className="desktop-ads11 mob_ad">
                                
                                {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                <AdSlot slotId={"content-ad-mob"} dfpNetworkId="205019744" sizes={[ [320, 50]]} adUnit="MJUNOON.TV-320X50-1" />
                            </div>
                        </div> : ''}
                        <InfiniteScroll className = " " style={{overflow: 'none'}}
                        dataLength={this.state.episodes.list.length} //This is important field to render the next data
                        next={this.callTrendingApi}
                        hasMore={this.state.hasMore}
                        loader={<TrendingShimmer/>}
                        endMessage={<p style={{textAlign: 'center'}}><b>Yay! You have seen it all</b></p>}
                        >
                        {this.state.episodes.isLoaded === true ? this.handEpisodes(): <TrendingShimmer/>}                            
                        </InfiniteScroll>
                        
                        
                    </section>
                    {(!isMobile)?
                    <div className="sidebar h_match">
                        {this.state.isAds === true && this.state.channels.isLoaded && localStorage.getItem('package_status') != "true"? <div className="black_box">
                            <div className="desktop-ads11">                             
                                <AdSlot slotId="adsidebar-desktop-info" dfpNetworkId="205019744" sizes={this.state.channels.list.length >0? [ [300, 250]]  :[ [300, 250]]} adUnit={this.state.channels.list.length > 0 ? "MJUNOON.TV-300X250-1" : "MJUNOON.TV-300X250-1"} />
                            </div>
                        </div>: ''}                         
                        {/* <FavChannelLivetv />              */}
                        {this.state.channelType.isLoaded === true ?
                        <TrendingChannels  data={this.state.channelType.list} /> 
                        :
                        <section className="LiveTVShimmerCon">
                            <div className="LivetvHeaderPlayerSection">
                                <LiveRelatedFilterShimmer/>                      
                            </div>
                        </section>
                        }
                        {/* {this.state.episodes.isLoaded === true ? this.handleChannels(): ""} */}
                    </div>
                    :""}
                </div>
                <div
          id="modal_sharing"
          className="uk-flex-top modal_sharing"
          uk-modal=""
          sel-close
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default "
              type="button"
              uk-close=""
            ></button>
            <div className="sharing_content"ref={this.Modalref}
>
              <h3>Share with your Friends & Family !</h3>
              <ul>
                <li>
                  <a
                    className="icon_facebook"
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mjunoon.tv%2F"
                    target="_blank"
                  >
                    <span uk-icon="icon: facebook"></span>
                  </a>
                </li>
                <li>
                  <a
                    className="icon_whatsapp"
                    href="https://api.whatsapp.com/send?text=Watch%20mjunoon.tv%20to%20stream%20more%20than%2060%20live%20tv%20channels%20absolutely%20free"
                    target="_blank"
                  >
                    <span uk-icon="icon: whatsapp"></span>
                  </a>
                </li>
                {/* <li><a className="icon_twitter" href="https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F" target="_blank"><span uk-icon="icon: twitter"></span></a></li> */}
                <li>
                  <a
                    className="icon_twitterX"
                    href="https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      viewBox="0 0 512 462.799"
                    >
                      <path
                        fillRule="nonzero"
                        d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="icon_linkedin"
                    href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.mjunoon.tv%2F&source=LinkedIn"
                    target="_blank"
                  >
                    <span uk-icon="icon: linkedin"></span>
                  </a>
                </li>
                {/* <li><a className="icon_instagram" href="javascript:;"><span uk-icon="icon: instagram"></span></a></li> */}
              </ul>
            </div>
          </div>
        </div>
                <Footer/>
                
                </> : '' 
                // removed loader
                }
                </> : <Loader/> }
            </div>
        );
    }
}

export default Reel