import React from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import ImageWebp from 'react-image-webp';


class RelatedMoviesSidebar extends React.Component{

    componentDidMount(){
        new window.Glider(document.querySelector('#glider_slider_pro'+this.props.sliderKey), {
            slidesToShow: 2,
            slidesToScroll: 2,
            duration: 0.25,
            draggable: true,
            arrows: {
                prev: '#glider_slider_pro'+this.props.sliderKey+'_prev',
                next: '#glider_slider_pro'+this.props.sliderKey+'_next'
            },
            // responsive: [
            //     {
            //     breakpoint: 360,
            //         settings: {
            //             slidesToShow: 'auto',
            //             slidesToScroll: 'auto',
            //             exactWidth: 120,
            //             itemWidth: 120,
            //             duration: 0.25,
            //         }
            //     },{
            //     breakpoint: 375,
            //         settings: {
            //             exactWidth: 120,
            //             itemWidth: 120,
            //         }
            //     },{
            //     breakpoint: 411,
            //         settings: {
            //             exactWidth: 120,
            //             itemWidth: 120,
            //         }
            //     },{
            //     breakpoint: 768,
            //         settings: {
            //             exactWidth: 120,
            //             itemWidth: 120,
            //         }
            //     },{
            //     breakpoint: 1024,
            //         settings: {
            //             exactWidth: 220,
            //             itemWidth: 220,
            //         }
            //     },{
            //     breakpoint: 1201,
            //         settings: {
            //             exactWidth: 220,
            //             itemWidth: 220,
            //             draggable: false,
            //         }
            //     },{
            //     breakpoint: 1281,
            //         settings: {
            //             exactWidth: 220,
            //             itemWidth: 220,
            //             draggable: false,
            //         }
            //     },{
            //     breakpoint: 1401,
            //         settings: {
            //             exactWidth: 220,
            //             itemWidth: 220,
            //             draggable: false,
            //         }
            //     },{
            //     breakpoint: 1601,
            //         settings: {
            //             exactWidth: 220,
            //             itemWidth: 220,
            //             draggable: false,
            //         }
            //     }
            //     ]
          })
          
    }
    
   

    handleProgramList = () => {
        let programs = "";
        let classRef = this; 
        console.log("sidebar movies: ", this.props.data);
        programs = this.props.data.map((program,index) => (
            <div key={index} id={"glider_slider_pro"+classRef.props.sliderKey+'_'+index}>
                {
                    (this.props.customSlug !== "") ?
                        <Link to={"/" + this.props.customSlug + "/" + program.program_slug}>
                            <div className="shows_slider_img"><ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name}/></div>
                            
                        </Link> : <Link to={"/program/"+program.program_slug}>
                                <div className="shows_slider_img"><ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name}/></div>
                            </Link>
                }             
            </div>
        ));
        return programs;
    };
    render(){
        return (
            <div>
                <section className="shows_slider_sec">
                        <div className="headng_box">
                            <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name+' '+this.props.type : this.props.name }</strong></h2>
                            {/* <Link className="view_all" to={"/list?type=program&topic="+this.props.name}>{(window.location.pathname === '/') ? 'View All' : ''}</Link>  */}
                        </div>
                        <div className="slider_new_container drama_sldr_container">
                       <div id={"glider_slider_pro"+this.props.sliderKey} className="drama_sldr_new">
                        
                            {this.handleProgramList()}
                        </div>
                        <button role="button" aria-label="Previous" id={"glider_slider_pro"+this.props.sliderKey+"_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow"/></button>
                        <button role="button" aria-label="Next" id={"glider_slider_pro"+this.props.sliderKey+"_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrow" /></button>
                        
                        </div>
                </section>
                <hr/>
            </div>
        );
    }
}

export default RelatedMoviesSidebar;