import React from "react";
import { Link } from "react-router-dom";
import ImageWebp from "react-image-webp";
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";
import axios from "axios";
import $ from "jquery";
import ReactPlayer from "react-player";
import Header from "../common/Header";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
class AllChannels extends React.Component {
  constructor() {
    super();
    this.handleTypes = this.handleTypes.bind(this);
    this.handlechannelList = this.handlechannelList.bind(this);
    this.callApi = this.callApi.bind(this);
    this.state = {
      listDatas: {
        isLoaded: false,
        list: {},
      },
      channelUrl: {
        url: "",
        channelName: "",
        slug: "",
        views: 0,
      },
      loadFirst: true,
      isMuted: true,
      playerBuffer: false,
      isFree: 0
    };
  }

  componentDidMount() {

      const searchparam =  window.location.href
      const type = searchparam.split('search=')[1];
      var data = {type : type}
      var searchclicked = ''
    //search parameter of live tv added by musfar 13/6/24


    if(searchparam.includes('search=')){

      // map to get the value of active tab of channel types 
      this.props.data.map((ty,index) => {
        if(ty.id == type){
           searchclicked = index
        }
      })
      $("#" + "type_" + searchclicked ).addClass("active");
      this.callApi(data)
    }

    else{
    $("#relatedtab").addClass("active");
    var data = { search: "all", user_id: localStorage.getItem("user_id") };
    this.callApi(data);
    }
  }

  componentWillReceiveProps() {
    // var data= {slug: this.props.slug};
    // this.callApi(data)
  }

  clickTypes = (type, selectorVal) => {
    $("#search_channels").val("");
    $("#filterrelated>ul>li>a.active").removeClass("active");
    $("#" + selectorVal).addClass("active");
    this.setState({
      listDatas: {
        isLoaded: false,
      },
      loadFirst: false,
    });
    var data = { type: type, user_id: localStorage.getItem("user_id") };
    this.callApi(data);
  };

  clickRelated = () => {
    $("#filterrelated>ul>li>a.active").removeClass("active");
    $("#relatedtab").addClass("active");
    var data = { search: "all", user_id: localStorage.getItem("user_id") };
    this.callApi(data);
  };

  handleTypes = () => {
    let types = "";
    let classRef = this;
    types = this.props.data.map((type, index) => (
      <li>
        <a
          id={"type_" + index}
          onClick={() => this.clickTypes(type.id, "type_" + index)}
        >
          {type.type }
        </a>
      </li>
    ));
    return types;
  };

  callGetChannelUrlApi = (slug) => {
    let oldSlug = this.state.channelUrl.slug;
    if (oldSlug == slug) {
      return;
    }
    $(".channelNameNBtn").fadeOut();

    $("#watching_" + oldSlug).html("");
    $("#watching_" + slug).html(
      '<img src="/images/watch_tvguide.svg" uk-svg="" /> Watching'
    );
    $("#name_channel_" + oldSlug).removeClass("removetext");
    $("#name_channel_" + slug).addClass("removetext");

    let classRef = this;
    // classRef.setState({channelUrl: {isLoaded: false}})
    axios({
      method: "POST",
      url: Constant.url + "streaming-url",
      data: { slug: slug, is_rewind: "no", type: "channel", user_id: "" },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      if (response.data.code === 200 && response.data.status === true) {
        classRef.setState(
          {
            channelUrl: {
              isLoaded: true,
              url: response.data.data.live_stream_url,
              slug: slug,
              channelName: response.data.data.channel_name,
              views: response.data.data.views,
            },
            isFree: response.data.data.is_free
          },
          function () {
            $(".channelNameNBtn").fadeIn(1500);
          }
        );
        classRef.setState({ playerBuffer: true }, function () {
          setTimeout(function () {
            classRef.setState({ playerBuffer: false });
          }, 6000);
        });
      }
    });
  };
  callApi = (data) => {
    let classRef = this;
    let user_id = localStorage.getItem("user_id");
    // let data = (type == "") ?{slug: slug}:{slug: slug});
    axios({
      method: "POST",
      url: Constant.url + "get-releated-channels",
      data: data,
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200) {
          classRef.setState({
            listDatas: {
              isLoaded: true,
              list: response.data.data.related_channels,
            },
          });
          if (classRef.state.loadFirst) {
            classRef.callGetChannelUrlApi(
              response.data.data.related_channels[0].channel_slug
            );
          }
          classRef.setState({
            loadFirst: false,
          });
        }
      })
      .then(function (response) { });
  };

  addToFavourite = (heart_id, channelId) => {
    
    if (!localStorage.getItem("user_id")) {
      new Header().openLoginModal();
      return;
    }
    if ($("#" + heart_id).hasClass("active")) {
      $("#heart_2344" + channelId).removeClass("active");
      $("#" + heart_id).removeClass("active");
      let classRef = this;
      axios({
        method: "POST",
        url: Constant.url + "remove-favourite",
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
        data: {
          id: channelId,
          user_id: localStorage.getItem("user_id"),
          type: "channel",
        },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        
        if (response.data.code === 200 && response.data.status === true) {
        }
      });
    } else {
      $("#" + heart_id).addClass("active");
      $("#heart_2344" + channelId).addClass("active");
      let classRef = this;
      let user_id = localStorage.getItem("user_id");
      let params = JSON.stringify({ channel_id: channelId, user_id: localStorage.getItem("user_id") });
      axios({
        method: "POST",
        url: Constant.url + "add-user-favourite-channels",  //yaseen   add-user-favourite-channels-2
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
        // data: { dataparams: LocalStorageService.encryptAes(params)},
        data: { channel_id: channelId, user_id: localStorage.getItem("user_id") },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        
        if (response.data.code === 200 && response.data.status === true) {
        }
      });
    }
  };

  handlechannelList = () => {
    let channels = "";
    let classRef = this;
    channels = this.state.listDatas.list.map((channel, index) => (
      <li>
        <div className="live_style live_style_full">
          <a
            onClick={() => classRef.callGetChannelUrlApi(channel.channel_slug)}
            className="fav_sld_img"
            to={channel.channel_slug}
          >
            <ImageWebp
              className="img_log"
              webp={channel.channel_logo}
              src={channel.channel_logo_jpg}
              alt={channel.channel_name}
            />
            <ImageWebp
              webp={channel.channel_live_image}
              src={channel.channel_live_image_jpg}
              alt={channel.channel_name}
            />
            <h4 id={"name_channel_" + channel.channel_slug}>
              {channel.channel_name}
            </h4>
            <p
              className="watchingActive"
              id={"watching_" + channel.channel_slug}
            ></p>
            <span className="views_txt">{channel.channel_views}</span>
          </a>
          {/* {channel.is_fav ? (
            <span
              className="relatedHeart active"
              id={"heart_2344" + channel.channel_id}
              uk-icon="icon: heart"
              onClick={(e) =>
                this.addToFavourite(
                  "heart_2344" + channel.channel_id,
                  channel.channel_id,
                  e
                )
              }
            ></span>
          ) : (
            <span
              className="relatedHeart"
              uk-icon="icon: heart"
              id={"heart_2344" + channel.channel_id}
              onClick={(e) =>
                this.addToFavourite(
                  "heart_2344" + channel.channel_id,
                  channel.channel_id,
                  e
                )
              }
            ></span>
          )} */}
        </div>
      </li>
    ));
    
    return channels;
  };

  handleKeyPress = (event) => {
    $("#filterrelated>ul>li>a.active").removeClass("active");
    this.setState({
      listDatas: {
        isLoaded: false,
      },
      loadFirst: false,
    });
    var data = {
      search: $("#search_channels").val(),
      user_id: localStorage.getItem("user_id"),
    };
    this.callApi(data);
  };

  mutePlayer = () => {
    this.setState(
      {
        isMuted: true,
      },
      function () {
        $(".muter").show();
        $(".unmuter").hide();
      }
    );
  };

  unmutePlayer = () => {
    this.setState(
      {
        isMuted: false,
      },
      function () {
        $(".muter").hide();
        $(".unmuter").show();
      }
    );
  };

  livePause = () => {
    $(".livePause").show();
    $(".livePlay").hide();
    $("#player").children("video")[0].pause();
  };

  livePlay = () => {
    $(".livePause").hide();
    $(".livePlay").show();
    $("#player").children("video")[0].play();
  };

  // openFullscreen() {
  //     $('.maximizescreen').hide();
  //     $('.minimizescreen').show();
  //   var elem = document.getElementById("player");
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // }
  // exitFullscreen() {
  //     $('.maximizescreen').show();
  //     $('.minimizescreen').hide();
  //     var elem = document.getElementById("player");
  //     elem.webkitExitFullScreen()();
  //   }

  responseFacebook = (response) => {
    
    LocalStorageService._serviceToken(this.signFacebook(response));
  }

  signFacebook = (response) => {
    if (response) {
      axios({
        method: 'POST',
        url: Constant.url + "sign-up",
        // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
        data: { type: 'email', email: response.email, firstname: response.name },
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
      }).then(function (responseval) {

        var getUserData = {};
        getUserData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));
        if (getUserData.data.code === 200) {

          localStorage.setItem('user_id', getUserData.data.data.uid);
          if (getUserData.data.data.complete_profile == 1) {
            localStorage.setItem('complete_profile', 1);
          }
          localStorage.setItem('name', getUserData.data.data.first_name);
          localStorage.setItem('gender', getUserData.data.data.gender);
          localStorage.setItem('picture_url', getUserData.data.data.picture_url);
          localStorage.setItem('mobile', getUserData.data.data.mobile);
          localStorage.setItem('email', getUserData.data.data.email);
          localStorage.setItem('dob', getUserData.data.data.dob);
          localStorage.setItem('user_id', getUserData.data.data.uid);
          localStorage.setItem('source', getUserData.data.data.source);

          window.location.href = "/";
        }
      }).then(function (error) {

      });
    }
  }


  responseGoogle = (response) => {
    $("#googleSign").html('Processing...')
    
    LocalStorageService._serviceToken(this.signGoogle(response));
  }


  signGoogle = (response) => {
    if (response.profileObj) {
      $("#googleSign").html('Redirecting...')
      $("#signerror").html('')
      axios({
        method: 'POST',
        url: Constant.url + "sign-up",
        // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
        data: { type: 'email', email: response.profileObj.email, firstname: response.profileObj.name, image: response.profileObj.imageUrl },
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
      }).then(function (responseval) {

        var getUserData = {};
        getUserData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));
        if (getUserData.data.code === 200) {

          localStorage.setItem('user_id', getUserData.data.data.uid);
          if (getUserData.data.data.complete_profile == 1) {
            localStorage.setItem('complete_profile', 1);
          }
          localStorage.setItem('name', getUserData.data.data.first_name);
          localStorage.setItem('gender', getUserData.data.data.gender);
          localStorage.setItem('picture_url', getUserData.data.data.picture_url);
          localStorage.setItem('mobile', getUserData.data.data.mobile);
          localStorage.setItem('email', getUserData.data.data.email);
          localStorage.setItem('dob', getUserData.data.data.dob);
          localStorage.setItem('user_id', getUserData.data.data.uid);
          localStorage.setItem('source', getUserData.data.data.source);
          // $("#googleSign").html('Continue with Google')
          window.location.href = "/";
        }
      }).then(function (error) {

      });
    } else {
      $("#googleSign").html('Continue with Google')
      $("#signerror").html('<H5 style="color:red;">There is something wrong with google</H5>')
    }
  }

  checkiSignin = () => {
    // if(!localStorage.getItem("user_id")){

    // $("#modal_login").addClass('uk-flex uk-open');

    // }else{

      window.location = '/packages/';   
      
    // }
    {/* Disable By yaseen on request of Aamir bhai */}
    }

  render() {
    return (
      <div>
        <section className="LivetvHeaderPlayer pad_left pad_tb pad_right pad_top_mobile">
          <div className="LivetvHeaderPlayerSection">
            <div className="PlayerForLivetvBox">


              {(localStorage.getItem("package_status") == "false" && this.state.isFree == 0)?
              <div class="tvguideLiveSliderBox liveTvSub">
                <div class="tvguideLiveSliderimg">
                  <div class="tvguideLiveSliderHover">
                    <div>
                      {/* <Link class="SliderSubcribe" to="/packages">Subscribe Now</Link> */}
                      {/* <button onClick={(e) => this.checkiSignin()} className="SliderSubcribe">Subscribe Now</button> */}
                    </div>
                  </div>
                </div>
              </div>
              
              :
              <ReactPlayer
                id={"player"}
                muted={this.state.isMuted ? true : false}
                controls={false}
                playing={(localStorage.getItem("user_id") || this.state.isFree == 1)?true:false}
                url={
                  this.state.channelUrl.isLoaded === true
                    ? this.state.channelUrl.url
                    : ""
                }
               
                className="react-player"
              />
  }
              {/* :<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}  */}
              {this.state.playerBuffer ? (
                <div className="loader_box">
                  <div className="loader">
                    <div className="loader_inner"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="LivetvBarTop">
                <img className="logo-img" src="/images/logo.png" alt="mJunoon.tv" />
              </div>

              {/* <div className="LivetvBarTop">
                <span class="live_span">Live</span>
                <p class="watching_now" id="liveViews">
                  <img src="/images/player_eye.svg" uk-svg="" />{" "}
                  {this.state.channelUrl.isLoaded === true
                    ? this.state.channelUrl.views
                    : 0}
                </p>
              </div> */}


              <div className="LivetvBarBtm">
                <div className="livePlay" onClick={this.livePause}>
                  <img
                    src="/images/player_pause.svg"
                    alt="player_pause"
                    uk-svg=""
                  />
                </div>
                <div
                  className="livePause"
                  onClick={this.livePlay}
                  style={{ display: "none" }}
                >
                  <img
                    src="/images/player_play.svg"
                    alt="player_play"
                    uk-svg=""
                  />
                </div>

                <div
                  className="unmuter volume_style"
                  onClick={this.mutePlayer}
                  style={{ display: "none" }}
                >
                  <img src="/images/volume_up.svg" alt="volume_up" uk-svg="" />
                </div>
                <div className="muter volume_style" onClick={this.unmutePlayer}>
                  <img
                    src="/images/volume_off.svg"
                    alt="volume_off"
                    uk-svg=""
                  />
                </div>
                <div className="liveInlineBar">
                  <span class="live_span">Live</span>
                  <p class="watching_now" id="liveViews">
                    <img src="/images/player_eye.svg" uk-svg="" />{" "}
                    {this.state.channelUrl.isLoaded === true
                      ? this.state.channelUrl.views
                      : 0}
                  </p>
                </div>                
                

                <div className="channelNameNBtn gf_txt">
                  <h4>
                    {this.state.channelUrl.isLoaded === true
                      ? this.state.channelUrl.channelName
                      : ""}
                  </h4>
                  {this.state.channelUrl.isLoaded === true ? (
                    <Link
                      to={
                        this.state.channelUrl.isLoaded === true
                          ? this.state.channelUrl.slug
                          : "/"
                      }
                      className="gf_watch_btn"
                    >
                      Watch Now
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="maximizescreen" onClick = {this.openFullscreen} ><img  src="/images/player_pause.svg" alt="player_pause" uk-svg=""/></div> */}
              {/* <div className="minimizescreen" onClick = {this.exitFullscreen} style={{"display" : "none"}}><img src="/images/player_play.svg" alt="player_play" uk-svg=""/></div> */}
            </div>
            <div className="FilterForLivetvBox">
              <form className="searchChannel">
                <button>
                  <img src="/images/search.svg" uk-svg="" />
                </button>
                <input
                  type="search"
                  id="search_channels"
                  placeholder="Search Channel"
                  onKeyPress={this.handleKeyPress}
                />
              </form>
              <div className="RelatedFilter">
                <div
                  id="filterrelated"
                  className="RelatedFilterBtns"
                  uk-slider="finite: true"
                >
                  <ul class="uk-slider-items">
                    <li>
                      <a id="relatedtab" onClick={this.clickRelated}>
                        All
                      </a>
                    </li>
                    {this.handleTypes()}
                  </ul>
                  <a
                    className="RelatedFilterPrev"
                    href="#"
                    uk-slider-item="previous"
                  >
                    <span uk-icon="icon: chevron-left"></span>
                  </a>
                  <a
                    className="RelatedFilterNext"
                    href="#"
                    uk-slider-item="next"
                  >
                    <span uk-icon="icon: chevron-right"></span>
                  </a>
                </div>
                <div className="RelatedFilterBox">
                  <ul>
                    {this.state.listDatas.isLoaded
                      ? this.handlechannelList()
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
         
        </section>
      </div>
    );
  }
}

export default AllChannels;
