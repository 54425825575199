import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import NoInternetConnect from "../section/NoInternetConnect";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";

class AboutUs extends React.Component{
    constructor(){
        super();
        this.state = {
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''                                               
        }
    }
    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
    }
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    render(){   

        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                <Header/>
                <div className="content_sec"> 
                {/* Random pages Start*/}
                <section className="randdom_header bg_about pad_left pad_right pad_tb">
                  <div className="about_banner_txt">
                    <h1>60+ LIVE CHANNELS</h1>
                    <p>SPORTS, MUSIC, MOVIES, DRAMAS, NEWS, TALK SHOWS, &amp; MUCH MORE ON-THE-GO.</p>
                  </div>
                </section>
                <section className="randdom_body pad_left pad_right pad_tb dark_bg">
                  <div className="about_sec">
                    <h2>About <span>Us</span></h2>
                    <p>Powered by <a target="_blank" href="https://www.convexinteractive.com/">Convex Interactive</a>, mjunoon.tv is one of the Pakistan’s top Live TV and video streaming platforms bringing to you an experience like never before. Enjoy superior quality online viewing and huge variety of unlimited content always available on just a click away.</p>
                    <p>mjunoon.tv is an all-inclusive OTT platform where we have a vast selection of entertainment, sports, news, science, information and dedicated regional content (Sindhi, Punjabi, Pashto, Balochi, Siraiki) to cater to our users from different backgrounds. The aim of mjunoon.tv is to have something for everyone, with varying interests. At mjunoon.tv we strive to transform the OTT experience and give you exclusive features like 7-day playback/rewind. </p>
                    <p>Join the mjunoon.tv family today because there is always more to explore with mjunoon.tv</p>
                  </div>
                  <div className="uk-flex-center uk-height-match" uk-grid="">
                    <div className="uk-width-1-3@m uk-width-1-2@s">
                      <div className="abt_inr"><span><img src="/images/seamless-hd-icon.svg" uk-svg="" alt="Seamless Icon"/></span>
                        <p>HD quality video streaming that seamlessly adjusts to the available bandwidth and delivers best in class viewing experience</p>
                      </div>
                    </div>
                    <div className="uk-width-1-3@m uk-width-1-2@s">
                      <div className="abt_inr"><span><img src="/images/popular-streams-icon.svg" uk-svg="" alt="Stream Icon"/></span>
                        <p>Wide selection of local and international entertainment, news and sports channels with top rated dramas and shows</p>
                      </div>
                    </div>
                    <div className="uk-width-1-3@m uk-width-1-2@s">
                      <div className="abt_inr"><span><img src="/images/tv-reminders-icon.svg" alt="Reminder Icon" uk-svg="" /></span>
                        <p>Personalize your experience by choosing what you want to watch and set reminders to never miss anything no matter where you are</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="randdom_body pad_left pad_right pad_tb">
                  <div className="uk-flex-center uk-height-match" uk-grid="">
                    <div className="uk-width-3-5@s">
                      <div className="rpr_text">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <h3>Playback, pause and rewind! </h3>
                            <p>Playback the 7 days recorded TV programmes of your choice and watch it at your own convenience anytime, anywhere. Also interruptions won’t matter now because you can always pause the streaming to start where you left off.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-2-5@s">
                      <div className="rpr_img">
                        <div className="mdl">
                          <div className="mdl_inner"><img alt="Playback" src="/images/playback-about-us-iconography.svg" uk-svg="" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Random pages End*/} 
                </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(AboutUs);