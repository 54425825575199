import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import $ from "jquery";
import Header from "../common/Header";
import axios from 'axios';
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";
class NowUpnext extends React.Component {
    componentDidMount() {
        new window.Glider(document.querySelector("#NowUpnextSlider"), {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 255,
            itemWidth: 255,
            duration: 0.25,
            draggable: true,
            arrows: {
                prev: "#NowUpnextSlider_prev",
                next: "#NowUpnextSlider_next",
            },
            responsive: [
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: "auto",
                        slidesToScroll: "auto",
                        exactWidth: 255,
                        itemWidth: 255,
                        duration: 0.25,
                    },
                },
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: "auto",
                        slidesToScroll: "auto",
                        exactWidth: 255,
                        itemWidth: 255,
                        duration: 0.25,
                    },
                },
                {
                    breakpoint: 375,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 411,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 1201,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1281,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1401,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1601,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
            ],
        });
    }

    addToFavourite = (heart_id, episodeId) => {    
        let user_id = localStorage.getItem('user_id');    
        if (!localStorage.getItem('user_id')) {
          new Header().openLoginModal();
          return;
        }
        if ($("#" + heart_id).hasClass('active')) {
    
          $("#heart_" + episodeId).removeClass('active')
          $("#" + heart_id).removeClass('active');
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "remove-single-watch-list",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: {user_id: user_id,episode_id: episodeId},
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));            
            if (response.data.code === 200 && response.data.status === true) {
    
    
            }
          })
        } else {
          $("#" + heart_id).addClass('active');
          $("#heart_" + episodeId).addClass('active')
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "add-watch-list",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: {user_id: user_id,episodeId: episodeId},
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));            
            if (response.data.code === 200 && response.data.status === true) {
    
            }
          })
        }
      }   

    handleEpisodeList = () => {
        let episodes = "";
        // console.log(this.props);
        episodes = this.props.data.map((episode, index) => (

            <li key={index} className="">
                <div className="NowUpnextSlide">
                    <div className="NowUpnextSliderInner">
                        <div className="NowUpnextSliderImg">
                            {
                                this.props.tempChannelSlug ? 
                                    <a to={'/' + this.props.tempChannelSlug + '/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail_jpg} src={episode.episode_thumbnail} alt={episode.episode_name} /></a>
                                :
                                <a to={'/watch/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail_jpg} src={episode.episode_thumbnail} alt={episode.episode_name} /></a>
                            }
                        </div>
                        <div className="NowUpnextSliderTxt">
                            <h4>{episode.episode_name}</h4>
                            {
                                this.props.tempChannelSlug ? 
                                <Link to={'/' + this.props.tempChannelSlug + '/' + episode.episode_slug} className="watchingbtn"><img src="/images/watchnow.svg" uk-svg="" /> Watch</Link>                            
                                :
                                <Link to={'/watch/' + episode.episode_slug} className="watchingbtn"><img src="/images/watchnow.svg" uk-svg="" /> Watch</Link>                            
                            }
                            {(episode.episode_fav) ?
                                <span className="hearticon active" id={"heart_" + episode.episode_id} uk-icon="icon: heart" onClick={(e) => this.addToFavourite("heart_" + episode.episode_id, episode.episode_id, e)}></span>
                                : <span className="hearticon" uk-icon="icon: heart" id={"heart_" + episode.episode_id} onClick={(e) => this.addToFavourite("heart_" + episode.episode_id, episode.episode_id, e)}></span>
                            }
                        </div>
                    </div>
                </div>

            </li>

        ));
        return episodes;
    };

    render() {
        return (
            <div>
                <section className="pad_left pad_tb prog_box_sec">
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong>Next Episodes</strong></h2>
                        {/* <Link className="view_all" to="">View All</Link>  */}
                    </div>
                    <div className="slider_new_container drama_sldr_container">
                        <div id="NowUpnextSlider" className="NowUpnextSlider">
                            {this.handleEpisodeList()}
                        </div>
                        <button role="button" aria-label="Previous" id="NowUpnextSlider_prev" className="glider-prev">
                            <img src="/images/left_arrow.png" alt="left arrow" />
                        </button>
                        <button role="button" aria-label="Next" id="NowUpnextSlider_next" className="glider-next">
                            <img src="/images/right_arrow.png" alt="right arrow" />
                        </button>
                    </div>
                </section>

            </div>
        );
    }
}

export default NowUpnext;