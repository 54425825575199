import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Side_Bar_User from '../section/Side_Bar_User';
import List_Episode from '../section/List_Episode';
import Empty from '../section/Empty';
import Meta from '../section/Meta';

class Profile extends React.Component{
    constructor(props){
        super(props);      
        this.UserDataApi = this.UserDataApi.bind(this); 
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);     
        this.state = {
            listDatas: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            uname: '',
            birthday: '',
            email: '',
            phone: '',
            gender: '',
            metaTitle: 'Profile  | mjunoon.tv'
        }
    }
    readURL = (input) =>{
		if (input.files && input.files[0]) {
			var reader = new FileReader();
		
			reader.onload = function(e) {
			  $('#blah').attr('src', e.target.result);
			}
		
			reader.readAsDataURL(input.files[0]);
		  }
	}
	
	clickEditButton = () =>{
		$("#file").click();
	}
    handleChange(event) {
        this.setState({uname: event.target.uname});
        this.setState({birthday: event.target.birthday});
        this.setState({email: event.target.email});
        this.setState({phone: event.target.phone}); 
        this.setState({gender: event.target.gender});        
      }
    
      handleSubmit(event) {
        alert('A name was submitted: ' + this.state.name);
        event.preventDefault();
      }
    componentDidMount(){
        if(localStorage.getItem('user_id') && localStorage.getItem('complete_profile') != 1){
            $("#modal-center").addClass('uk-flex uk-open');
        }
        LocalStorageService._serviceToken(this.UserDataApi);
        $("#file").change(function() {
			// readURL(this);
			if (this.files && this.files[0]) {
				var reader = new FileReader();
			
				reader.onload = function(e) {
				  $('#blah').attr('src', e.target.result);
				}
			
				reader.readAsDataURL(this.files[0]);
			  }
		});
        window.tagManagerEvent("Profile Screen", "Profile", "Profile", {placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: "", user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id:  window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') :'', name : localStorage.getItem('name') ? localStorage.getItem('name') :'',  number : localStorage.getItem('mobile') ? localStorage.getItem('mobile') :'', search_keyword: '', user_custom_id : localStorage.getItem('user_id') ? localStorage.getItem('user_id') :'', user_device_id :''});
    }

    editButton = () =>{
        var edit_btn_txt = $(".edit_btn");
        
        edit_btn_txt.toggleClass('open_edit');
        if(edit_btn_txt.hasClass('open_edit')){
            
            edit_btn_txt.text('Save Changes');  
            $(".disable_fld").prop('disabled', false);
    
        } else {
            
            LocalStorageService._serviceToken(this.saveData);
            
        }
      $('.image_boxprof').toggleClass('edit_now');
      $('.form_profile').toggleClass('edit_now');
    }

    UserDataApi = () => {
        
      let classRef = this;
      let user_id = localStorage.getItem('user_id');
      axios({
          method: 'POST',
          url: Constant.url+"user-info-by-id",
          data: {user_id: user_id},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
            
              classRef.setState({
                  listDatas:{
                      isLoaded : true,
                      list: response.data.data
                  },
                  uname: response.data.data.first_name,
                  birthday: response.data.data.dob,
                  email: response.data.data.email,
                  phone: response.data.data.mobile,
                  gender: response.data.data.gender
              })

              localStorage.removeItem('name');
              localStorage.setItem('name', response.data.data.first_name);                                                        
          }
          window.initializDOBProfile();
      }).then(function (response) {
          
      });
     
    };

    saveData = () => {
        $(".edit_btn").text('Processing...'); 
        $(".edit_btn").attr("disabled", true); 
        $("#save_error").hide();
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        // formData.append("user_id",user_id);
        // formData.append("firstname",$("#name").val());
        // formData.append("dob",$("#birthday").val());
        // formData.append("email",$("#emailval").val());
        // formData.append("mobile",$("#phone").val());
        // formData.append("gender",$("#gender").val());
        // formData.append("type",localStorage.getItem('source'));
        // formData.append("image",(imagefile.files[0]) ? imagefile.files[0] : '');
        var data = {  
            user_id:user_id,
            firstname:$("#name").val(),
            dob:$("#birthday").val(),
            email:$("#emailval").val(),
            mobile:$("#phone").val(),
            gender:$("#gender").val(),
            type:localStorage.getItem('source')
        }
        let paramval = JSON.stringify(data);        
               
		if (imagefile.files[0] === undefined) { } else {
			formData.append('image', imagefile.files[0]);
		};

		let params = JSON.stringify(paramval);
		formData.append('user_id', user_id);
        formData.append('firstname', $("#name").val());
        formData.append('dob', $("#birthday").val());
        formData.append('email', $("#emailval").val());
        formData.append('mobile', $("#phone").val());
        formData.append('gender', $("#gender").val());
        formData.append('type', localStorage.getItem('source'));

		axios.post(Constant.url + "sign-up-2", formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': "bearer " + localStorage.getItem("access_token")
			}
		}).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if(response.data.code === 200){
                
                classRef.setState({
                    listDatas:{
                        isLoaded : true,
                        list: response.data.data
                    },
                    uname: response.data.data.first_name,
                    birthday: response.data.data.dob,
                    email: response.data.data.email,
                    phone: response.data.data.mobile
                })
                localStorage.removeItem('name');       
                localStorage.removeItem('gender');    
                localStorage.removeItem('picture_url');
                
                localStorage.removeItem('dob');
                localStorage.setItem('name',response.data.data.first_name);                
                localStorage.setItem('gender', response.data.data.gender);                
                localStorage.setItem('picture_url', response.data.data.picture_url);                 
                localStorage.setItem('dob', response.data.data.dob); 

                if(localStorage.getItem("source") === "email") {
                    localStorage.removeItem('email');
                    localStorage.setItem('email', response.data.data.email);
                }
                if(localStorage.getItem("source") === "mobile") {
                    localStorage.removeItem('mobile');
                    localStorage.setItem('mobile', response.data.data.mobile);
                }

                $(".edit_btn").text('Edit Profile');
                $(".disable_fld").prop('disabled', true); 
                $("#save_success").show();
                $("#save_success").html('Profile has been updated successfully!');  
                // setTimeout(function() {
                //     window.location.reload("/profile");
                // },1000)                                          
            }else if(response.data.code === 600){
                $(".edit_btn").attr("disabled", false); 
                $(".edit_btn").text('Save Changes'); 
                $("#save_error").show();
                if(localStorage.getItem('source') === 'mobile'){
                    $("#save_error").html('Email already exist!');
                }else if(localStorage.getItem('source') === 'email'){
                    $("#save_error").html('Mobile number already axist!');
                }                                
            }else if(response.data.code === 300){

            }
            else{
                $("#save_error").html('Something went wrong!');
            }
        }).then(function (response) {
            
        });
    };    
    
    logout = () => {
        if(localStorage.getItem('user_id')){            
            var expiry = localStorage.getItem('expiry');
            localStorage.clear();
            localStorage.setItem("isToken",'enable');
            localStorage.setItem('expiry',expiry);
            window.location.href = "/";
        }else{
            
        }
    };

    logoutModal = () =>{
        $("#modal_dialog").addClass('uk-flex uk-open');
    }

    deleteModal = () =>{
        $("#delete_dialog").addClass('uk-flex uk-open');
    }    

    deleteUser = () => {        
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"delete-user-record-web",
            data: {user_id: user_id},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
          }).then(function (result) {              
            //   let response = {};
            //   response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(result.data.code === 200){              
                localStorage.removeItem('userPackageTitle')
                localStorage.removeItem('package_id')
                localStorage.removeItem('userPackageDesc')
                localStorage.removeItem('userPackageExpiryDate')
                localStorage.removeItem('validTillDate')
                localStorage.removeItem('userCreatedAt')
                localStorage.removeItem('subscriptionStatus')
                localStorage.removeItem('userPackageAutoRenewal')
                localStorage.removeItem('package_status')
                localStorage.removeItem('name');
                localStorage.removeItem('gender');
                localStorage.removeItem('picture_url');
                localStorage.removeItem('mobile');
                localStorage.removeItem('email');
                localStorage.removeItem('dob');
                localStorage.removeItem('user_id');
                localStorage.removeItem('source');
                window.location.href = "/";                                                   
            }            
        }).then(function (response) {
            
        });
       
      };

    render(){
        return(
           <div>           
           <Header/> 
           
                {(!localStorage.getItem("user_id"))?
                <div className="content_sec">
                <div uk-height-match="target:  .h_match"> 
                    {/* <Side_Bar_User/> */}
                    <div class="h_match"> 
                        <section className="pad_tb pad_right pad_left bg_nosign trendingSubscribe">
                            <div className="no_signin_inner">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <img src="images/logo.png" alt="Mjunoon TV" />
                                        <h2>Unlimited Entertainment</h2>
                                        <h3>Awaits You</h3>
                                        <Link to="/packages" class="continuewith comp"> Subscribed Now</Link>    
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer/> */}
                    </div>
                </div>
            </div>
                :
           <div class="content_sec">
           <div id="modal_dialog" class="uk-flex-top modal_sharing" uk-modal="">
                  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close=""></button>
                    <div class="sharing_content">
                    <h3>Are you sure, you want to logout?</h3>
                        <button className = "uk-modal-close">NO</button>
                        <button onClick={this.logout}>CONFIRM</button>
                      </div>
                  </div>    
                </div>
                <div id="delete_dialog" class="uk-flex-top modal_sharing" uk-modal="">
                  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close=""></button>
                    <div class="sharing_content">
                    <h3>Are you sure, you want to delete?</h3>
                        <button className = "uk-modal-close">NO</button>
                        <button onClick={this.deleteUser}>CONFIRM</button>
                      </div>
                  </div>    
                </div>                
           <div uk-height-match="target:  .h_match"> 
                <Side_Bar_User/>
                  <div class="h_match asad_bg"> 
                  <section class="pad_left pad_right pad_tb prog_box_sec uk-text-center">
                    <div className="backbar">
                        {/* <a className="back_btn"><img src="images/player_back.svg" uk-svg="" /> Back</a> */}
                        <h1 className="page_title">My Profile</h1>
                        <a onClick = {this.editButton} class="view_all edit_btn uk-margin-remove">Edit Profile</a>
                    </div>
                    
                    <p id="save_error" style={{display:'none'}} class="error_msg_pro"></p>
                    <p id="save_success" style={{display:'none'}} class="success_msg_pro"></p>

                      <div class="image_boxprof">
                        <div class="sidebar_profile_pic">
                      <div class="profile_pic_box">
                      <div class="update_image" id='editimage' onClick = {this.clickEditButton}><span uk-icon="plus"></span></div>                         
                        <div class="profile_img"><img alt="profile image" id="blah" src = {(localStorage.getItem('picture_url') !== "" && localStorage.getItem('picture_url')) ? localStorage.getItem('picture_url') : (localStorage.getItem('gender') === 'Female') ? "/images/icons/ic_female@2x.png" : "/images/icons/ic_male@2x.png"}/></div>
                      </div>
                    <div class="profile_name" onClick = {this.clickEditButton}>Change Profile Photo</div>
                    </div>
                    <form  class="uk-form-horizontal form_profile">
                    <input type='file' id="file" name="file" style={{display:"none"}}/>                         
                    <div class="profileFld">
                        
                        <div class="uk-form-controls">
                        <label class="uk-form-label" for="name">Name</label>
                            <input class="uk-input disable_fld" disabled="disabled" id="name" type="text" placeholder="Enter Name" value={this.state.uname} onChange={this.handleChange}/>
                        </div>
                    </div>
                        
                    <div class="profileFld">
                        
                        <div class="uk-form-controls">
                        <label class="uk-form-label" for="birthday">Birthday</label>
                            <input class="uk-input disable_fld" disabled="disabled" id="birthday" type="text" placeholder="Enter Birthday" value={this.state.birthday} onChange={this.handleChange}/>
                        </div>
                    </div>
                <h5>Personal Information</h5>
                  <div class="profileFld">
                        
                        <div class="uk-form-controls">
                        <label class="uk-form-label" for="email">Email</label>
                            <input readOnly = {(localStorage.getItem("source") === "email") ? true : false} class="uk-input disable_fld" disabled="disabled" id="emailval" type="email" placeholder="Enter Email" value={this.state.email} onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div class="profileFld">
                       
                        <div class="uk-form-controls">
                        <label class="uk-form-label" for="phone">Phone</label>
                            <input readOnly={(localStorage.getItem("source") === "mobile") ? true : false} class="uk-input disable_fld" disabled="disabled" id="phone" type="nymber" placeholder="Enter Phone" value={this.state.phone} onChange={this.handleChange} />
                        </div>
                    </div>
                        
                    <div class="profileFld">
                        
                        <div class="uk-form-controls">
                        <label class="uk-form-label" for="gender">Gender</label>
                            <select class="uk-select disable_fld" disabled="disabled" id="gender">
                                <option>Select Gender</option>
                                <option selected={(this.state.gender === "male" || this.state.gender === "Male") ? "selected" : ""}>Male</option>
                                <option selected={(this.state.gender === "female" || this.state.gender === "Female") ? "selected" : ""}>Female</option>
                            </select>
                        </div>
                    </div>
                        
                </form>  
                <a onClick = {this.logoutModal} class="logout_btn"><img src="/images/logout.svg" uk-svg="" alt="logout"/><span>Log out</span></a>  
                <a onClick = {this.deleteModal} class="logout_btn Delbtn"><img src="/images/delete_forever.svg" uk-svg="" alt="Delete"/><span>Delete Profile</span></a>              
                      </div>
                    </section>
                    <Footer/>  
                  </div>
            </div>    
            </div>        
              }
           </div>
        );
     }    
}

export default withRouter(Profile);