import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import $ from "jquery";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
class MobileEpisode extends React.Component {

  componentDidMount() {
    new window.Glider(document.querySelector('#glider_slider_epi' + this.props.sliderKey), {
      slidesToShow: 'auto',
      slidesToScroll: 'auto',
      exactWidth: 250,
      itemWidth: 250,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: '#glider_slider_epi' + this.props.sliderKey + '_prev',
        next: '#glider_slider_epi' + this.props.sliderKey + '_next'
      },
      responsive: [
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 290,
            itemWidth: 290,
            duration: 0.25,
          }
        }, {
          breakpoint: 375,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
          }
        }, {
          breakpoint: 411,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
          }
        }, {
          breakpoint: 768,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
          }
        }, {
          breakpoint: 1024,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
          }
        }, {
          breakpoint: 1201,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
            draggable: false,
          }
        }, {
          breakpoint: 1281,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
            draggable: false,
          }
        }, {
          breakpoint: 1401,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
            draggable: false,
          }
        }, {
          breakpoint: 1601,
          settings: {
            exactWidth: 290,
            itemWidth: 290,
            draggable: false,
          }
        }
      ]
    })

  }



  handleEpisodeList = () => {
    let episodes = "";
    // console.log("temp channel slug: ", this.props.tempChannelSlug);
    let classRef = this;
    episodes = this.props.data.map((episode, index) => (
      <div key={index}>
        <div className="prog_sldr_box">
          <div className="prog_sldr_img ">
            {
              this.props.tempChannelSlug ? <Link to={'/'+ this.props.tempChannelSlug +'/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
              <div className="duration_time"><span>{episode.episode_duration}</span></div>
              <div className="hvr_box"><span uk-icon="play"></span></div>
            </Link> : <Link to={'/watch/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
              <div className="duration_time"><span>{episode.episode_duration}</span></div>
              <div className="hvr_box"><span uk-icon="play"></span></div>
            </Link> 
            }
          </div>
          <div className="prog_sldr_text">
            <h4 className="homesectionone"><b>
              {
                this.props.tempChannelSlug ? <Link className="anchor" to={'/'+ this.props.tempChannelSlug +'/' + episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link> : <Link className="anchor" to={'/watch/' + episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link>
              }
              
              </b></h4>
            <p><span className="episode_channel">{(episode.program_name === undefined) ? '' : episode.program_name + " ."} </span> <span>{episode.episode_views} views . </span><span>{episode.episode_date}</span> </p>
          </div>
        </div>
      </div>
    ));
    return episodes;
  };
  render() {
    return (
      <div>
        <section className="pad_left pad_bh prog_box_sec">
          <div className="headng_box">
            <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name + ' ' + this.props.type : this.props.name}</strong></h2>
            <Link className="view_all" to={(this.props.livePro) ? '/program/' + this.props.channelSlug : (this.props.isBranded) ? '/' + this.props.channelSlug : '/list?type=episode&topic=' + this.props.name}>{(window.location.pathname === '/' || window.location.pathname === '/karachi-eat') ? 'View All' : ''}</Link> </div>
          <div className="slider_new_container arrow_top">
            <div id={"glider_slider_epi" + this.props.sliderKey} className="prog_sldr_new">
              {this.handleEpisodeList()}
            </div>
            <button role="button" aria-label="Previous" id={"glider_slider_epi" + this.props.sliderKey + "_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow" /></button>
            <button role="button" aria-label="Next" id={"glider_slider_epi" + this.props.sliderKey + "_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrows" /></button>
          </div>
        </section>
        <hr />
      </div>
    );
  }
}

export default MobileEpisode;