import React from 'react';
import {withRouter, Link} from 'react-router-dom';

import Header from "../common/Header";
import Footer from "../common/Footer";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
class PrivacyPolicy extends React.Component{
  constructor(){
    super();
    this.state = {
        metaKeywords: '',
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
        schema: ''                                               
    }
}
componentDidMount(){
  MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
}
callMetaData = (metaResponse) =>{
  
  this.setState({
      metaKeywords : metaResponse.keywords,
      metaTitle : metaResponse.title,
      metaDescription : metaResponse.description,
      metaImage : metaResponse.thumbnail,
      schema : metaResponse.schema
  });
    let classRef = this;
    setTimeout(function () {
        window.metaUpdated();
    }, 1000);
}
    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
              {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
            <Header/>                         
            <div class="content_sec"> 
                <section class="randdom_header pad_left pad_right pad_tb">
                <h1>Privacy Policy</h1>
                <p><strong>POLICY OBJECTIVES</strong></p>
                <p>mjunoon, all its services and affiliates are committed to safe guard the privacy and integrity of its visitors, consumers and vendors in every possible way. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
            </section>
                <section class="randdom_body pad_left pad_right pad_tb">
                <p><strong>Your personal information at mjunoon.tv</strong></p>
                <p>We may collect, store and use the following kinds of personal information:</p>
                <ol>
                  <li>Information about your computer or Internet-capable device and about your visits to this website including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths.</li>
                  <li>Information relating to any purchases you make of our Software and service including your name, company name, billing address, telephone number, email address etc.</li>
                  <li>Information contained in or relating to any communications that you send to us including the communication content and Meta data associated with the communication.</li>
                  <li>Any other personal information that you choose to send to us.</li>
                </ol>
                <p> Before you disclose to us the personal information of another person, you must obtain that person's consent to both the disclosure and the processing of that personal information in accordance with the terms of this policy.</p>
                <p><strong>How mjunoon may use your personal information</strong></p>
                <ol>
                  <li>The personal information that you entrust mjunoon may be used for the following purposes.</li>
                  <li>Administer our website and business which includes our advertisement module as well as vendor management.</li>
                  <li>Personalize the website for vendor whose channels or content we host.</li>
                  <li>Enable your use of the services available on our website.</li>
                  <li>Deliver software and services purchased through our website.</li>
                  <li>Send statements, invoices and payment reminders to you, and collect payments from you.</li>
                  <li>Send you non-marketing commercial communications.</li>
                  <li>Send you marketing communications relating to our business which we think may be of interest to you where you have specifically agreed to this by email or similar technology (you can inform us at any time if you no longer require marketing communications). </li>
                  <li>Provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information).</li>
                  <li>Deal with enquiries and complaints made by or about you relating to our website.</li>
                  <li>Keep our website secure and prevent fraud. </li>
                  <li>Verify compliance with the terms and conditions governing the use of our website. </li>
                  <li>We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing.</li>
                  <li>All the financial information related to ad based revenue along with statistics of the viewer or audience data will be shared with the vendor only.</li>
                </ol>
                <p><strong>Disclosing personal information</strong></p>
                <ol>
                  <li>We may disclose your personal information to any of our employees insofar as reasonably necessary for the purposes set out in this policy.</li>
                  <li>In order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
                  <li>All your personal data however can also be disclosed to Government of Pakistan or PEMRA should court order to reveal such information is presented.</li>
                </ol>
                <p><strong>Safeguarding personal information</strong></p>
                <ol>
                  <li>We will take reasonable technical and organizational precautions to prevent the loss, misuse or alteration of your personal information.</li>
                  <li>We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</li>
                  <li>All electronic financial transactions entered into through our website will be protected by encryption technology.</li>
                  <li>You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</li>
                  <li>You are responsible for keeping the password you use for accessing our website confidential. We will not ask you for your password (except when you log in to our website).</li>
                </ol>
                <p><strong>MODE AND PLACE OF PROCESSING THE DATA</strong></p>
                <p><strong>Data processing methods</strong></p>
                <ol>
                  <li>The data of users is processed by mjunoon in a proper manner and suitable security measures shall be taken for the prevention of unauthorized access, modification, illegitimate data destruction, or disclosure. </li>
                  <li>IT enabled tools and/or computers are used to carry out the data processing which follows procedures and modes of the organization strictly pertinent to the mentioned purposes. In some instances, with addition to the mjunoon, certain types of people in charge might be able to access the data, who are associated with site operations such as legal, sales, administration and marketing. External parties appointed that, if deemed necessary by mjunoon, such as hosting providers, communication agencies, third party technical service providers, and IT companies might also be able to access. mjunoon can be requested at any time for an updated list of such parties. </li>
                  </ol>
                 
                <p><strong>Place</strong></p>
                <ol>
                  <li>The processing of data is carried out at mjunoon’s operating offices and in locations where parties associated with processing are placed. mjunoon can be contacted for any further information. </li>
                </ol>
                <p><strong>Retention time</strong></p>
                <ol>
                  <li>For the purpose of providing specific service to user as requested by the user, data is stored for the time taken to provide the service or it can be stored as per purposes mentioned in this policy. Additionally, the request of suspension or removal of data can always be made by the user. </li>
                </ol>
                <p><strong>ADDITIONAL INFORMATION ABOUT DATA COLLECTION AND PROCESSING</strong></p>
                <p><strong>Legal action</strong></p>
                <ol>
                  <li>mjunoon may use the user’s collected data for legal purposes if and when needed in Court or in the steps leading to a possibility of legal action arising as a result of improper usage of this application/platform and/or provided services. </li>
                  <li>It is therefore declared by the user that the user is aware that mjunoon might have to provide personal data if and when requested by pubic authorities. </li>
                </ol>
                <p><strong>Additional information about User's Personal Data</strong></p>
                <ol>
                  <li>Upon request, the user might be provided with contextual and additional information regarding specific services or personal data collection and processing, in addition to the knowledge provided in this document. </li>
                </ol>
                <p><strong>Maintenance and System logs</strong></p>
                <ol>
                  <li>Files might be collected by this application/platform or other third party services for the purpose of maintenance and operation, with respect to interaction record such as system logs or for the same purpose usage of other personal data (IP address).</li>
                </ol>
                <p><strong>Information not contained in this policy</strong></p>
                <ol>
                  <li>At any time, regarding the processing and collection of data, further details can be requested from mjunoon. Find the contact information mentioned at the end of this policy. </li>
                </ol>
                <p><strong>The rights of Users</strong></p>
                <ol>
                  <li>About the storage of personal data, the users can contact mjunoon at any time to consult and learn about the origin and contents, for the verification of accuracy or ask for supplementation, corrections, and cancellation. They can additionally ask to transform the data into a known or unknown format or even ask for blockage of data that might have been held in violation of law. mjunoon would entertain any request with respect to this purpose when sent on the information listed below.</li>
                </ol>
                <p><strong>Right to amend the policy</strong></p>
                <ol>
                  <li>We may update this policy from time to time by publishing a new version on our website.</li>
                  <li>You should check this page occasionally to ensure you are happy with any changes to this policy.</li>
                  <li>We may notify you of changes to this policy by email.</li>
                </ol>
                <p><strong>Third party websites</strong></p>
                <ol>
                  <li>Our website includes hyperlinks to, and details of, third party websites.</li>
                  <li>We have no control over, and are not responsible for, the privacy policies and practices of third parties.</li>
                </ol>
                <p><strong>Usage of cookies</strong></p>
                <ol>
                  <li>Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites.</li>
                  <li>Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.</li>
                  <li>Since ads are mjunoon’s primary source of monetizing content, the cookies will be served in accordance with the Google AdSense’s universal policies.</li>
                </ol>
            </section>                 
            </div>
            <Footer/>  
            </div>
        );
     }
}
export default withRouter(PrivacyPolicy);