import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";

class PersonalizeTopics extends React.Component {
  constructor() {
    super();
    this.state = {
      topics: ''
    }
  }

  handleTopicList = () => {
    let topics = "";
    topics = this.props.data.map((topic, index) => (
      <a class="tag_box" key={index} id={"tag_box" + index} onClick={(e) => this.topicActive("tag_box" + index, topic.name, e)}><span class="plus_tick"></span> <span>{topic.name}</span></a>
    ));
    return topics;
  };

  topicActive = (e, topic) => {
    if (this.state.topics === '') {
      this.setState({
        topics: topic
      })
    } else {
      this.setState({
        topics: this.state.topics + ',' + topic
      })
    }

    $("#" + e).toggleClass('active');

  }
  addTopic = () => {
    $("#submitbtn").text('Processing...');
    LocalStorageService._serviceToken(this.initializeToken);
  }

  initializeToken = () => {
    let classRef = this;
    let user_id = localStorage.getItem('user_id');
    axios({
      method: 'POST',
      url: Constant.url + "add-favourite-topics",
      data: {
        user_id: user_id,
        topic: this.state.topics
      },
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
      
      if (response.data.code === 200) {
        let params = JSON.stringify({
          mobile: localStorage.getItem('mobile'),
          complete_profile: 1
        });
        axios({
          method: 'POST',
          url: Constant.url + "sign-up",
          data: { dataparams: LocalStorageService.encryptAes(params) },
          headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (resultData) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(resultData.data.eData));
          if (response.data.code === 200) {
            localStorage.removeItem('complete_profile');
            localStorage.setItem('complete_profile', 1);
            // localStorage.setItem('name', result.data.data.first_name);
            window.location.href = "/";
            
          } else {
            localStorage.removeItem('complete_profile');
            localStorage.setItem('complete_profile', 1);
            window.location.href = "/";
          }
        }).then(function (response) {
          localStorage.removeItem('complete_profile');
          localStorage.setItem('complete_profile', 1);
          window.location.href = "/";
        });
      } else {
        axios({
          method: 'POST',
          // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
          url: Constant.url + "sign-up",
          data: {
            type: localStorage.getItem('source'),
            mobile: localStorage.getItem('mobile'),
            email: localStorage.getItem('email'),
            complete_profile: 1
          },
          headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (resultData) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(resultData.data.eData));
          if (response.data.code === 200) {
            localStorage.removeItem('complete_profile');
            localStorage.setItem('complete_profile', 1);
            // localStorage.setItem('name', result.data.data.first_name);
            window.location.href = "/";
            
          } else {
            localStorage.removeItem('complete_profile');
            localStorage.setItem('complete_profile', 1);
            window.location.href = "/";
          }
        }).then(function (response) {
          localStorage.removeItem('complete_profile');
          localStorage.setItem('complete_profile', 1);
          window.location.href = "/";
        });
      }
    }).then(function (response) {
      
    });
  }

  render() {
    return (
      <div class="splash">
        <div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{ display: 'block' }}>
          <header>
            <div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV" /></Link>
              <div class="right_hdr">
                <div class="skip">
                  <Link to='/'>SKIP</Link>
                </div>
              </div>
            </div>
          </header>
          <div class="uk-modal-dialog popup_content">
            <div class="mdl">
              <div class="mdl_inner">
                <div class="uk-container">
                  <h1>Pick topics of your interest</h1>
                  <p>Your experience will improve the more you watch</p>
                  <div class="tags_box topics_tag">
                    {this.handleTopicList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Footer Start --> */}
          <footer>
            <div class="gf_txt  ">
              <a id="submitbtn" onClick={this.addTopic} class="gf_watch_btn">DONE</a>
            </div>
          </footer>
          {/* <!-- Footer End -->      */}
        </div>
      </div>
    )
  }
}
export default withRouter(PersonalizeTopics);