import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class ChannelViewAll extends React.Component{
    handleChannelList = () => {
        let channels = "";
        channels = this.props.data.map((channel,index) => (
            <div key={index} class="channel_live_cont_inner">
            <Link class="channel_sldr_box" to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/"+channel.channel_slug)}>
            <div class="channel_sldr_img"><ImageWebp webp={channel.channel_logo_jpg} src={channel.channel_logo} alt={channel.channel_name}/>
              <div class="chnl_hvr_box"><span uk-icon="play"></span></div>
              <span class="live_span">{(channel.channel_status === 'live' ? channel.channel_status : '')}</span> </div>
            <div class="channel_sldr_txt">
              <h3 class="h3tag">{channel.channel_name}</h3>
            </div>
            </Link>
          </div>
                
        ));        
        return channels;
    };  
    render(){
        return (
            <div>
                <section class="pad_left pad_right pad_tb prog_box_sec">
                              
                  <div class="channel_live_cont">
                    {this.handleChannelList()}
                  </div>
                </section>
            </div>
        );
    }
}

export default ChannelViewAll;