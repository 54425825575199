import React from 'react';
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
class Meta extends React.Component{


    render(){
        return (
            <Helmet>

                <title>{this.props.metaTitle ? this.props.metaTitle : 'Live TV | Live Streaming | Watch TV Channels in Pakistan Online | mjunoon.tv'}</title>
                <link rel="canonical" href={this.props.fullPath} />
                {/* <link rel="amphtml" href="http://localhost:3000/psl/psl-2020-quetta-gladiators-vs-lahore-qalandars-live-score-psl-5-match-16.html"></link> */}
                
        {/* {console.log("Metas Props", this.props.amp)} */}
        {ReactHtmlParser(this.props.amp)}
                <meta name="title" content={this.props.metaTitle ? this.props.metaTitle : 'Live TV | Live Streaming | Watch TV Channels in Pakistan Online | mjunoon.tv'}/>
                <meta name="description" content={this.props.metaDescription ? this.props.metaDescription : "Watch Live TV streaming of Pakistan's most popular dramas, movies, music, news, regional and religious TV channel programs online with many features on the go."}/>
                <meta name="keywords" content={this.props.metaKeywords ? this.props.metaKeywords : "mjunoon.tv,tv"}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={this.props.metaTitle ? this.props.metaTitle : 'Live TV | Live Streaming | Watch TV Channels in Pakistan Online | mjunoon.tv'} />
                <meta property="og:image" content={this.props.metaImage ? this.props.metaImage : 'https://www.mjunoon.tv/images/logo.png'}/>
                <meta property="og:site_name" content="mjunoon.tv"/>
                <meta property="og:image:width" content="1000" />
                <meta property="og:image:height" content="560" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="mjunoon.tv" />
                <meta name="twitter:title" content={this.props.metaTitle ? this.props.metaTitle : 'Live TV | Live Streaming | Watch TV Channels in Pakistan Online | mjunoon.tv'} />
                <meta name="twitter:description" content={this.props.metaDescription ? this.props.metaDescription : "Watch Live TV streaming of Pakistan's most popular dramas, movies, music, news, regional and religious TV channel programs online with many features on the go."} />
                <meta name="twitter:image" content={this.props.metaImage ? this.props.metaImage : 'https://www.mjunoon.tv/images/logo.png'}/>
                <script type='application/ld+json'>{`{"@context":"https://schema.org","@type":"WebSite","@id":"https://www.mjunoon.tv/#website","url":"https://www.mjunoon.tv/","name":"Mjunoon Tv","potentialAction":{"@type":"SearchAction","target":"https://www.mjunoon.tv/search?q={search_term_string}","query-input":"required name=search_term_string"}}`}</script>
                <script type='application/ld+json'>{`{"@context":"https://schema.org","@type":"Organization","url":"https://www.mjunoon.tv/","sameAs":["https://www.facebook.com/mjunoon.tv/"],"@id":"https://www.mjunoon.tv/#organization","name":"Mjunoon","logo":"https://www.mjunoon.tv/assets/images/logo_v.png"}`}</script>
                {this.props.schema?
                <script type="application/ld+json">{
                    `{
                        "@context": "${this.props.schema.context}",
                        "@type": "${this.props.schema.type}",
                        "contentURL": "${this.props.schema.contentURL}",
                        "description": "${this.props.schema.description}",
                        "embedUrl": "${this.props.schema.embedUrl}",
                        "interactionCount": "${this.props.schema.interactionCount}",
                        "name": "${this.props.schema.name}",
                        "thumbnailUrl": "${this.props.schema.thumbnailUrl}",
                        "uploadDate": "${this.props.schema.uploadDate}"
                    }
                    "publication": [{
                    "@type": "${this.props.schema.pType}",
                    "isLiveBroadcast": "${this.props.schema.isLiveBroadcast}",
                    "startDate": "${this.props.schema.startDate}",
                    "endDate": "${this.props.schema.endDate}"
                    },
                    {
                        "@type": "${this.props.schema.NextType}",
                        "isLiveBroadcast": "${this.props.schema.NextIsLiveBroadcast}",
                        "startDate": "${this.props.schema.NextStartDate}"
                    }]`
                }
                </script> : ''}
            </Helmet>

        );
    }
}

export default Meta;