import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class ProgramEpisode extends React.Component{
    handleEpisodeList = () => {
        let episodes = "";
        episodes = this.props.data.map((episode,index) => (
            
            <div key={index} className="uk-width-1-4@l uk-width-1-3@m grd_mob">
              <div className="prog_sldr_box">
                <div className="prog_sldr_img"><Link to={'/watch/'+episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name}/>
                  <div className="duration_time"><span>{episode.episode_duration}</span></div>
                  <div className="hvr_box"><span uk-icon="play"></span></div>
                  </Link> </div>
                <div className="prog_sldr_text">
                  <h4 className="homesectionone"><b><Link className="anchor" to={'/watch/'+episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link></b></h4>
                  {/* <p><span>{episode.channel_name} </span> <span>{episode.episode_views} views</span> </p> */}
                  <p><span className="episode_channel">{(episode.channel_name === undefined )? '' : episode.channel_name+" ." }</span> <span>{episode.episode_views} views . </span><span>{episode.episode_date}</span> </p>
                  
                </div>
              </div>
            </div>
        ));        
        return episodes;
    };  
    render(){
        return (
            <div>
                    <div class="uk-container">
                    <div class="uk-height-match uk-grid-small filter_search_box" uk-grid="">                                    
                    {this.handleEpisodeList()}
                    </div>
                    </div>
            </div>
        );
    }
}

export default ProgramEpisode;