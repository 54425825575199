import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
// import {AdSlot} from "react-dfp";

class Teams extends React.Component{

  constructor(){
    super();
    this.state = {
        metaKeywords: '',
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
        schema: ''   
    }
    
}

componentDidMount(){
  MetaCreation.createMeta('static','psl-teams',(e) => this.callMetaData(e));
}

callMetaData = (metaResponse) =>{
  
  this.setState({
      metaKeywords : metaResponse.keywords,
      metaTitle : metaResponse.title,
      metaDescription : metaResponse.description,
      metaImage : metaResponse.thumbnail,
      schema : metaResponse.schema
  });
  let classRef = this;
  setTimeout(function () {
      window.metaUpdated();
  }, 1000);
}

    playAudio =() => {
        var audio = new Audio('/images/psl/flip.mp3');
        audio.play()
    }

    render(){
        return(
            <div>
              {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                <Header/>
                <div class="content_sec live_sec">
                        <section class="tending_player_sec uk-padding-remove-top">
                            <div class="psl_sec">
                                <div class="box_psl">
                                    <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                        <ul class="uk-slider-items">
                                        <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                        </ul>
                                    </div>
                                    <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>     
                            <div class="teams_psl_sec" uk-scrollspy="cls: uk-animation-fade; target: .teams_psl; delay: 100;"> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/peshawar-zalmi">
                              <div class="teams_psl_img_ring">
                                <div class="teams_psl_img peshawar"><img src="/images/psl/peshawar.png" alt="peshawar" /></div>
                              </div>
                              <h5 class="teams_psl_txt"><span>Peshawar</span> Zalmi</h5>
                              </Link> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/quetta-gladiators">
                                <div class="teams_psl_img_ring">
                                  <div class="teams_psl_img quetta"><img src="/images/psl/quetta.png" alt="quetta" /></div>
                                </div>
                              <h5 class="teams_psl_txt"><span>QUETTA</span> GLADIATORS</h5>
                              </Link> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/islamabad-united">
                                <div class="teams_psl_img_ring">
                                 <div class="teams_psl_img islamabad"><img src="/images/psl/islamabad.png" alt="islamabad" /></div>
                              </div>
                              <h5 class="teams_psl_txt"><span>ISLAMABAD</span> UNITED</h5>
                              </Link> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/karachi-king">
                                <div class="teams_psl_img_ring">
                                 <div class="teams_psl_img karachi"><img src="/images/psl/karachi.png" alt="karachi" /></div>
                              </div>
                              <h5 class="teams_psl_txt"><span>Karachi</span> Kings</h5>
                              </Link> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/lahore-qalandars">
                                <div class="teams_psl_img_ring">
                                  <div class="teams_psl_img lahore"><img src="/images/psl/lahore.png" alt="lahore" /></div>
                              </div>
                              <h5 class="teams_psl_txt"><span>LAHORE</span> QALANDARS</h5>
                              </Link> <Link class="teams_psl delay_btn" onClick={this.playAudio} to="/psl/team-players/multan-sultan">
                                <div class="teams_psl_img_ring">
                                 <div class="teams_psl_img multan"><img src="/images/psl/multan.png" alt="multan" /></div>
                               </div>
                              <h5 class="teams_psl_txt"><span>Multan</span> Sultans</h5>
                              </Link> 
                             </div>
                            </div>
                            </div>
                            <div class="sidebar">
                            </div>
                        </section>
                    </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(Teams);