import React from 'react';
import ChannelShimmer from './ChannelShimmer';
import EpisodeShimmer from './EpisodeShimmer';
import ProgramShimmer from './ProgramShimmer';

class HomeShimmer extends React.Component {    

    render() {
        return (
            <div>
                <EpisodeShimmer/>
                <ChannelShimmer/>
                <ProgramShimmer/>
            </div>
        );
    }
}

export default HomeShimmer;