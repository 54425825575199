import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import {withRouter, Link} from 'react-router-dom';

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";

import Side_Bar_User from '../section/Side_Bar_User';
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import LocalStorageService from "../../utils/LocalStorageService";
class Unsigned  extends React.Component{
    constructor() {
    super();    
    this.state = {
        metaKeywords: '',
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
        schema: ''                                               
    }
    }

    componentDidMount(){
        MetaCreation.createMeta('static','unsigned',(e) => this.callMetaData(e));
    }

    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        },function(){
            // if($('body').hasClass('loadkaro')){
            //     setTimeout(function(){
            //         $('body').removeClass('loadkaro');
            //     },300)
            // }
          });
          let classRef = this;
          setTimeout(function () {
              window.metaUpdated();
          }, 1000);
      }

      signGoogle = (response) =>{ 
        if(response.profileObj){
            $("#googleSign").html('Redirecting...')
            axios({
                method: 'POST',
                url: Constant.url+"sign-up",
                // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
                data: {type:'email',email: response.profileObj.email,firstname:response.profileObj.name,image:response.profileObj.imageUrl},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
                }).then(function (responseval) {
                    
                var getUserData = {};
                getUserData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));
                if(getUserData.data.code === 200){
                
                localStorage.setItem('user_id', getUserData.data.data.uid);
                if(getUserData.data.data.complete_profile == 1){    
                    localStorage.setItem('complete_profile', 1);
                }
                localStorage.setItem('name', getUserData.data.data.first_name);
                localStorage.setItem('gender', getUserData.data.data.gender);
                localStorage.setItem('picture_url', getUserData.data.data.picture_url);
                localStorage.setItem('mobile', getUserData.data.data.mobile);
                localStorage.setItem('email', getUserData.data.data.email);
                localStorage.setItem('dob', getUserData.data.data.dob);
                localStorage.setItem('user_id', getUserData.data.data.uid);
                localStorage.setItem('source', getUserData.data.data.source);

                axios({
                    url : Constant.url+"package-subscription-history",
                    method : 'POST',
                    data : {
                        'user_id' : getUserData.data.data.uid
                    }
                }).then(function(result){
                    var packageData = {};
                    packageData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));
                    if(packageData.data.status == 1)
                    {
                        localStorage.setItem('package_status','active'+'_'+getUserData.data.data.uid);
                        // window.location.href = '/profile';
                        // $("#googleSignInside").html('Continue with Google')
                        window.location.href = '/';
                        
                    }
                })
                
                // window.location.href = "/signed";
                }
                }).then(function (error) {

                });
        }else{
            $("#googleSignInside").html('Continue with Google')
        }
    }
    // clickLogin = () =>{
    //     if(!localStorage.getItem('user_id')){
    //         new Header().openLoginModal();
    //     }
    // }
    responseGoogle = (response) =>{            
        $("#googleSignInside").html('Processing...')
        
        LocalStorageService._serviceToken(this.signGoogle(response));
    }
    
    responseFacebook = (response) =>{
        LocalStorageService._serviceToken(this.signInFacebook(response));
    }

    signInFacebook = (response) => {
        
        if(response){
            axios({
                method: 'POST',
                url: Constant.url+"sign-up",
                // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
                data: {type:'email',email: response.email,firstname:response.name},
                headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
                }).then(function (responseval) {
                    
                var getUserData = {};
                getUserData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));
                if(getUserData.data.code === 200){
                
                localStorage.setItem('user_id', getUserData.data.data.uid);
                if(getUserData.data.data.complete_profile == 1){    
                    localStorage.setItem('complete_profile', 1);
                }
                localStorage.setItem('name', getUserData.data.data.first_name);
                localStorage.setItem('gender', getUserData.data.data.gender);
                localStorage.setItem('picture_url', getUserData.data.data.picture_url);
                localStorage.setItem('mobile', getUserData.data.data.mobile);
                localStorage.setItem('email', getUserData.data.data.email);
                localStorage.setItem('dob', getUserData.data.data.dob);
                localStorage.setItem('user_id', getUserData.data.data.uid);
                localStorage.setItem('source', getUserData.data.data.source);
                
                window.location.href = "/";
                }
                }).then(function (error) {

                });
        }
    }    
    render(){
        return(
            <div>
                <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
                <Header/>
                <div className="content_sec">
                    <div uk-height-match="target:  .h_match"> 
                        {/* <Side_Bar_User/> */}
                        <div class="h_match"> 
                            <section className="pad_tb pad_right pad_left bg_nosign">
                                <div className="no_signin_inner">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <img src="images/logo.png" alt="Mjunoon TV" />
                                            <h2>Unlimited Entertainment</h2>
                                            <h3>Awaits You</h3>
                                            <ul>
                                            <li>
                                                <p>70+ LIVE</p>
                                                <p>TV CHANNELS</p>
                                            </li>
                                            <li>
                                                <p>400+</p>
                                                <p>TITLES</p>
                                            </li>
                                            <li>
                                                <p>5000+</p>
                                                <p>EPISODES</p>
                                            </li>
                                            </ul>
                                            {/* <button class="continuewith"><img src="images/gmail_icon.svg" /> Continue with Google</button> */}
                                            <div>
                                                <GoogleLogin
                                                    clientId="96096407429-e9gh28ou39mhh1fm27qm8bne0cm2nvnl.apps.googleusercontent.com"
                                                    render={renderProps => (
                                                        <a className="continuewith"  onClick={renderProps.onClick} disabled={renderProps.disabled}><img src="images/gmail_icon.svg" /><span id = "googleSignInside">Continue with Google</span></a>
                                                    )}
                                                    buttonText="Login"
                                                    onSuccess={this.responseGoogle}
                                                    onFailure={this.responseGoogle}
                                                    isSignedIn={false}
                                                    // cookiePolicy={'single_host_origin'}
                                                />
                                                {/* <button class="continuewith"><img src="images/fb_icon.svg" /> Continue with Facebook</button> */}
                                                <FacebookLogin 
                                                    appId="1471066116276908"                        
                                                    callback={this.responseFacebook}
                                                    fields="name,email,picture"
                                                    render={renderProps => (
                                                        <a className="continuewith" onClick={renderProps.onClick}><img src="images/fb_icon.svg" /><span>Continue with Facebook</span></a>
                                                    )}
                                                />
                                            </div>
                                            <div className="no_signin_inner_txt">
                                            <p>We will not share your personal details with anyone.</p>
                                            <p>By signing up, you confirm that you agree to our <Link to="/terms-and-conditions">Terms of Use</Link> and have read and understood our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </section>
                            <Footer/>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}
export default withRouter(Unsigned);
