import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
import axios from 'axios';
import ImageWebp from 'react-image-webp';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
class List_Channel extends React.Component{
    constructor() {
        super();        
        this.handlechannelList = this.handlechannelList.bind(this);
        this.state = {
            isDelete: false
        }
    }

    editButton = () =>{
        var edit_btn_txt = $(".edit_btn");
        edit_btn_txt.toggleClass('open_edit');
        if(edit_btn_txt.hasClass('open_edit')){
            edit_btn_txt.text('Save');         
        } else {
           edit_btn_txt.text('Edit');
        }
      $('.channel_sldr_box').toggleClass('for_del');
    }

    deleteChannel = (channelId) =>{
        LocalStorageService._serviceToken((e) => this.deleteChannelApi(channelId,e));  
    }
    
    deleteChannelApi = (channelId) =>{
        let user_id = localStorage.getItem('user_id');
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"remove-favourite",
            headers: {},
            data: {id: channelId, user_id: localStorage.getItem('user_id'),type:'channel'},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}            
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if(response.data.code === 200 && response.data.status === true){
                $("#deleteSection"+channelId).hide();
            }
            
        })
    }

    handlechannelList = () => {
        let channels = "";
        channels = this.props.data.map((channel,index) => (
            <div id={"deleteSection"+channel.channel_id} class="channel_live_cont_inner"> <div class="channel_sldr_box">
            <div class="channel_sldr_img">
            <Link to={'/'+channel.channel_slug} class=""><ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
            <div class="chnl_hvr_box"><span uk-icon="play"></span></div>
            </Link>
              
              <div class="del_box" onClick={(e) => this.deleteChannel(channel.channel_id,e)}><img src="/images/del.svg" alt="delete" uk-svg=""/></div>
               
               </div>
            <div class="channel_sldr_txt">
              <h3 class="h3tag">{channel.channel_name}</h3>
            </div>
            </div> </div>
        ));                
        // $('.edit_btn').click(function(){console.log('javed');});
        return channels;
    };
    render(){
        return (
            <div>
                <section class="pad_left pad_right pad_tb prog_box_sec">
                  <div class="headng_box">
                    <h2 class="h2forhome"><strong>Favorite Channels</strong></h2><a class="edit_btn view_all" onClick={this.editButton}>Edit</a>
                  </div>
                  <div class="channel_live_cont">
                      {this.handlechannelList()}
                  </div>
                </section>
            </div>
        );
    }
}

export default List_Channel;