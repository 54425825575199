import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
class DataNotFound extends React.Component{
    render(){
        return (
            <div>
              <div class="page_notfound"> 
                <div class="h_match asad_bg"> 
                  <section class="pad_left pad_right pad_tb prog_box_sec">      
                    <div class="empty_box">
                      <div class="mdl">
                        <div class="mdl_inner">
                          <img src="/images/no_data.png" alt="No Data"/>
                          <p class="uk-margin-small-bottom">Sorry!</p>
                          <p class="uk-text-small uk-text-muted uk-margin-remove-top">We are unable to find any videos matching your search..<br/>You may do the following:</p>
                          <ul class="uk-text-small uk-text-muted uk-margin-remove-top uk-display-inline-block uk-text-left">
                              <li>Check for spellings</li>
                              <li>Try more specific set of words</li>
                          </ul>
                          <div>
                          {/* <a class="blue_btn" href="/search">Back</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  </div>
              </div>
            </div>
        );
    }
}

export default DataNotFound;