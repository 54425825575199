import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";
import MetaCreation from "../../utils/MetaCreation";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Side_Bar_User from '../section/Side_Bar_User';
import List_Episode from '../section/List_Episode';
import Empty from '../section/Empty';
import Meta from '../section/Meta';

class Watch_History extends React.Component{
    constructor(){
        super();
      
        this.callApi = this.callApi.bind(this);
      
        this.state = {
            listDatas: {
                code: '',
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''   
        }
    }

    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callApi);
        window.tagManagerEvent("Watch History Screen", "Watch History", "Watch History", {placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id:  window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') :'', name : localStorage.getItem('name') ? localStorage.getItem('name') :'',  number : localStorage.getItem('mobile') ? localStorage.getItem('mobile') :'', search_keyword: '', user_custom_id : localStorage.getItem('user_id') ? localStorage.getItem('user_id') :'', user_device_id :''});
    }
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callApi = () => {
      let classRef = this;
      let user_id = localStorage.getItem('user_id');
      axios({
          method: 'POST',
          url: Constant.url+"watch-history",
          data: {user_id: user_id},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
              
              classRef.setState({
                  listDatas:{
                      isLoaded : true,
                      list: response.data.data.episodes
                  }
              })
              window.imgNotFound();
          }else if(response.data.code === 400){
            classRef.setState({
                listDatas:{
                    code : 400
                }
            })
          }
      }).then(function (response) {
          
      });
    };
    
    render(){
        return(
           <div>
            <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>               
           <Header/>
           <div class="content_sec">
           <div uk-height-match="target:  .h_match"> 
                <Side_Bar_User/>
                  <div class="h_match asad_bg"> 
                  <section class="pad_left pad_right pad_tb prog_box_sec">            
                      {this.state.listDatas.isLoaded === true ? <List_Episode name = "Watch History" data = {this.state.listDatas.list}/>:( this.state.listDatas.isLoaded === false && this.state.listDatas.code !== 400) ? <div class="loader_box"><div class="loader"><div class="loader_inner"></div></div></div> :<Empty image = "/images/icons/ic_watchlist.png" name = "Watch History"/>}                      
                  </section>
          </div>
            </div>    
            </div>        
           <Footer/>   
           </div>
        );
     }    
}

export default withRouter(Watch_History);