import React from "react";
import $ from "jquery";

class NewHomeSlider extends React.Component {
  componentDidMount() {
    var slideshow = window.UIkit.slider("#slideshow");
    var $slideItem = $("#slideshow ul > li");
    $slideItem.on("beforeitemshow", function () {
      $slideItem.removeClass("uk-active");
    });
    new window.Glider(document.querySelector("#tvguideLiveSlider"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 90,
      itemWidth: 90,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#tvguideLiveSlider_prev",
        next: "#tvguideLiveSlider_next",
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 264,
            itemWidth: 264,
            duration: 0.25,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 304,
            itemWidth: 304,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 318,
            itemWidth: 318,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 357,
            itemWidth: 357,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 348,
            itemWidth: 348,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 295,
            itemWidth: 295,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 354,
            itemWidth: 354,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 346,
            itemWidth: 346,
            draggable: false,
          },
        },
      ],
    });
    new window.Glider(document.querySelector("#upNextSlider"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 90,
      itemWidth: 90,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#upNextSlider_prev",
        next: "#upNextSlider_next",
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 264,
            itemWidth: 264,
            duration: 0.25,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 304,
            itemWidth: 304,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 318,
            itemWidth: 318,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 357,
            itemWidth: 357,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 348,
            itemWidth: 348,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 295,
            itemWidth: 295,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 354,
            itemWidth: 354,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 346,
            itemWidth: 346,
            draggable: false,
          },
        },
      ],
    });
    new window.Glider(document.querySelector("#ContinueSlider"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 90,
      itemWidth: 90,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#ContinueSlider_prev",
        next: "#ContinueSlider_next",
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 264,
            itemWidth: 264,
            duration: 0.25,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 304,
            itemWidth: 304,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 318,
            itemWidth: 318,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 357,
            itemWidth: 357,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 348,
            itemWidth: 348,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 295,
            itemWidth: 295,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 354,
            itemWidth: 354,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 346,
            itemWidth: 346,
            draggable: false,
          },
        },
      ],
    });
    
  }
  render() {
    return (
      <div>
        {/* Home Slider Start */}
        <section className="NewHomeSlider">
          <div
            id="slideshow"
            className="uk-position-relative uk-visible-toggle uk-light"
            tabIndex={-1}
            uk-slider="velocity:1; center: true; autoplay: true; autoplay-interval: 4000"
          >
            <ul className="uk-slider-items">
              {/* {this.props.data} */}
              <li>
                <div className="NewHomeSlider_inner desktopSlide">
                  <img src="/images/base.png" />
                  <div className="NewHomeSlider_txt">
                    <img src="/images/pbotb.png" />
                    <h3>The Grand Finale Episode 8 | Season 4</h3>
                    <div className="NewHomeSlider_btns gf_txt">
                      <a href="#" className="gf_watch_btn">
                        <img src="images/play.svg" uk-svg="" /> Watch
                      </a>
                      <a href="#" className="gf_watchlist_btn">
                        <img src="images/watchlist_btn.svg" uk-svg="" /> Watch
                        List
                      </a>
                    </div>
                    <p>
                      Our most awaited Grand Finale is finally here! Jam packed
                      with entertainment, this episode has all of...
                      <br />
                      <a href="#">SHOW MORE</a>
                    </p>
                  </div>
                </div>
                <div className="NewHomeSlider_inner mobileSlide">
                  <img src="/images/slider/mobile-header-Awaz.png" />
                </div>
              </li>
              <li>
                <div className="NewHomeSlider_inner desktopSlide">
                  <img src="/images/slider/desktop-header-awaz.png" />
                  <div className="NewHomeSlider_txt">
                    <div className="NewHomeSlider_btns uk-margin-small-bottom">
                      <span className="liveicon">LIVE</span>
                      <span className="viewicon">
                        <img src="images/eye.svg" uk-svg="" /> 23k
                      </span>
                    </div>
                    <img src="/images/slider/desktop-logo-awaz.png" />
                    <h3>
                      Awaz with Ali Haider <br />
                      10pm to 11pm
                    </h3>
                    <div className="NewHomeSlider_btns gf_txt">
                      <a href="#" className="gf_watch_btn">
                        <img src="images/play.svg" uk-svg="" /> Watch
                      </a>
                      <a href="#" className="gf_watchlist_btn">
                        <img src="images/watchlist_btn.svg" uk-svg="" /> Watch
                        List
                      </a>
                    </div>
                    <p>
                      Our most awaited Grand Finale is finally here! Jam packed
                      with entertainment, this episode has all of...
                      <br />
                      <a href="#">SHOW MORE</a>
                    </p>
                  </div>
                </div>
                <div className="NewHomeSlider_inner mobileSlide">
                  <img src="/images/slider/mobile-header-Awaz.png" />
                </div>
              </li>
              <li>
                <div className="NewHomeSlider_inner desktopSlide">
                  <img src="/images/base.png" />
                  <div className="NewHomeSlider_txt">
                    <img src="/images/pbotb.png" />
                    <h3>The Grand Finale Episode 8 | Season 4</h3>
                    <div className="NewHomeSlider_btns gf_txt">
                      <a href="#" className="gf_watch_btn">
                        <img src="images/play.svg" uk-svg="" /> Watch
                      </a>
                      <a href="#" className="gf_watchlist_btn">
                        <img src="images/watchlist_btn.svg" uk-svg="" /> Watch
                        List
                      </a>
                    </div>
                    <p>
                      Our most awaited Grand Finale is finally here! Jam packed
                      with entertainment, this episode has all of...
                      <br />
                      <a href="#">SHOW MORE</a>
                    </p>
                  </div>
                </div>
                <div className="NewHomeSlider_inner mobileSlide">
                  <img src="/images/slider/mobile-header-Awaz.png" />
                </div>
              </li>
              <li>
                <div className="NewHomeSlider_inner desktopSlide">
                  <img src="/images/slider/desktop-header-awaz.png" />
                  <div className="NewHomeSlider_txt">
                    <div className="NewHomeSlider_btns uk-margin-small-bottom">
                      <span className="liveicon">LIVE</span>
                      <span className="viewicon">
                        <img src="images/eye.svg" uk-svg="" /> 23k
                      </span>
                    </div>
                    <img src="/images/slider/desktop-logo-awaz.png" />
                    <h3>
                      Awaz with Ali Haider <br />
                      10pm to 11pm
                    </h3>
                    <div className="NewHomeSlider_btns gf_txt">
                      <a href="#" className="gf_watch_btn">
                        <img src="images/play.svg" uk-svg="" /> Watch
                      </a>
                      <a href="#" className="gf_watchlist_btn">
                        <img src="images/watchlist_btn.svg" uk-svg="" /> Watch
                        List
                      </a>
                    </div>
                    <p>
                      Our most awaited Grand Finale is finally here! Jam packed
                      with entertainment, this episode has all of...
                      <br />
                      <a href="#">SHOW MORE</a>
                    </p>
                  </div>
                </div>
                <div className="NewHomeSlider_inner mobileSlide">
                  <img src="/images/slider/mobile-header-Awaz.png" />
                </div>
              </li>
            </ul>
          </div>
        </section>
         {/* Home Slider End */}
          {/* Live Now Carousel Start */}
        <section className="pad_left pad_b tvguideLiveSec">
          <div className="headng_box">
            <h2 className="h2forhome">
              <span className="livebtn">LIVE</span>
              <strong>Now</strong>
            </h2>
          </div>

          <div className="slider_new_container drama_sldr_container">
            <div id="tvguideLiveSlider" className="tvguideLiveSlider">
              {/* Repeat box Start */}
              <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '40%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                    <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                    
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon active" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: heart"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
            </div>
            <button
              role="button"
              aria-label="Previous"
              id="tvguideLiveSlider_prev"
              className="glider-prev"
            >
              <img src="/images/left_arrow.png" alt="left arrow" />
            </button>
            <button
              role="button"
              aria-label="Next"
              id="tvguideLiveSlider_next"
              className="glider-next"
            >
              <img src="/images/right_arrow.png" alt="right arrow" />
            </button>
          </div>
        </section>
        { /* Live Now Carousel End */}
         {/* Up Next Carousel Start */}
        <section className="pad_left pad_b tvguideLiveSec">
          <div className="headng_box">
            <h2 className="h2forhome">
              <strong>Up Next</strong>
            </h2>
            <a href="#" className="view_all">TV Guide <img src="images/trending.svg" uk-svg="" /></a>
          </div>

          <div className="slider_new_container drama_sldr_container">
            <div id="upNextSlider" className="tvguideLiveSlider">
              {/* Repeat box Start */}
              <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                    <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon active" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Notify me</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <img className="hearticon" src="images/slider/notifications_icon.svg" uk-svg="" />
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
            </div>
            <button
              role="button"
              aria-label="Previous"
              id="upNextSlider_prev"
              className="glider-prev"
            >
              <img src="/images/left_arrow.png" alt="left arrow" />
            </button>
            <button
              role="button"
              aria-label="Next"
              id="upNextSlider_next"
              className="glider-next"
            >
              <img src="/images/right_arrow.png" alt="right arrow" />
            </button>
          </div>
        </section>
        {/* Up Next Carousel End */}
        {/* Continue Watching Carousel Start */}
        <section className="pad_left pad_b tvguideLiveSec">
          <div className="headng_box">
            <h2 className="h2forhome">
              <strong>Continue Watching</strong>
            </h2>
          </div>

          <div className="slider_new_container drama_sldr_container">
            <div id="ContinueSlider" className="tvguideLiveSlider">
              {/* Repeat box Start */}
              <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '40%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                    <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
               {/* Repeat box Start */}
               <div className="tvguideLiveSliderInner">
                <div className="tvguideLiveSliderBox">
                  <a className="tvguideLiveSliderimg" href="#">
                    <img src="https://cimages.mjunoon.tv:10088/news-one-live.webp" alt="Aaj ka Tarka" />
                    <span className="viewerstvg">9.4K viewers</span>
                    <div className="continue_bar"><span style={{width: '10%'}} /></div>
                    <div className="tvguideLiveSliderHover">
                      <div>
                        <img src="images/watchnow.svg" uk-svg="" />
                        <span>Watch Now</span>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:;" className="tvguideLiveSliderTxt">
                    <img
                      src="https://images.mjunoon.tv:8181/logo/News-one.webp"
                      alt="HUM News"
                    />
                    <span className="hearticon" uk-icon="icon: more-vertical"></span>
                    <h4>News One</h4>
                    <span className="views_txt">9.4K viewers</span>
                    
                  </a>
                </div>
              </div>
              {/* Repeat box End */}
            </div>
            <button
              role="button"
              aria-label="Previous"
              id="ContinueSlider_prev"
              className="glider-prev"
            >
              <img src="/images/left_arrow.png" alt="left arrow" />
            </button>
            <button
              role="button"
              aria-label="Next"
              id="ContinueSlider_next"
              className="glider-next"
            >
              <img src="/images/right_arrow.png" alt="right arrow" />
            </button>
          </div>
        </section>
        { /* Continue Watching Carousel End */}
      </div>
    );
  }
}

export default NewHomeSlider;
