import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Side_Bar_User from '../section/Side_Bar_User';
import List_Program from '../section/List_Program';
import Empty from '../section/Empty';
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
class Favourite_Program extends React.Component{
    constructor(){
        super();
      
        this.callApi = this.callApi.bind(this);
      
        this.state = {
            listDatas: {
                code: '',
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''   
        }
    }

    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.initializeToken); 
    }
    initializeToken = () =>{
        this.callApi();
    }
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callApi = () => {
      let classRef = this;
      let user_id = localStorage.getItem('user_id');
      axios({
          method: 'POST',
          url: Constant.url+"favourite-programs",
          data: {user_id: user_id},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
              
              classRef.setState({
                  listDatas:{
                      isLoaded : true,
                      list: response.data.data.programs
                  }
              })
          }else if(response.data.code === 400){
            classRef.setState({
                listDatas:{
                    code : 400
                }
            })
          }
      }).then(function (response) {
          
      });
    };
    
    render(){
        return(
           <div>
               {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
               {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                <Header/>          
                <div class="content_sec">
                        <div uk-height-match="target:  .h_match"> 
                            <Side_Bar_User/>
                            <div class="h_match asad_bg">   
                                <section class="pad_left pad_right pad_tb prog_box_sec">                             
                                    {this.state.listDatas.isLoaded === true ? <List_Program noClass = 'yes' name = "Favorite Programs" data = {this.state.listDatas.list}/> :( this.state.listDatas.isLoaded === false && this.state.listDatas.code !== 400) ?<div class="loader_box"><div class="loader"><div class="loader_inner"></div></div></div> : <Empty image = "/images/icons/ic_favorite_programs.png" name = "Favorite Programs"/>}                      
                                </section>
                            </div>
                        </div>    
                    </div>        
                <Footer/>  
           </div>
        );
     }    
}

export default withRouter(Favourite_Program);