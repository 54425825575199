import React from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
class Side_Bar_User extends React.Component{
    constructor() {
      super();

    }

    handleAutoRenew = (slug) => {
      let classRef = this;    
      axios({
          method: 'POST',
          url: Constant.url+"package-auto-renewal",        
          data: {
            status: $("#switch_btn2").is(":checked"),      
            user_id: localStorage.getItem("user_id")
          },
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
          if($("#switch_btn2").is(":checked") == true){                
            localStorage.setItem('userPackageAutoRenewal',1)
          }else{
            localStorage.setItem('userPackageAutoRenewal',0)
          }
          }
      }).then(function (response) {
          
      });
    };  

    handleSideBar = () => {

      if(localStorage.getItem('user_id')){
        return(   <div>
          {/* After Sign in  */}
          <div className="signin_profile_box">
            <div className="signin_profile_img">
              <img src="/images/icons/ic_male.png" />
              <div className="mj_plus_icon"><img src="/images/mjplus_icon.png" /></div>
            </div>
            <div className="signin_profile_txt">
              <h6>{localStorage.getItem('name')}</h6>
              <p>{localStorage.getItem('mobile')}</p>
              <span>{localStorage.getItem('userCreatedAt') ? 'Joined since '+localStorage.getItem('userCreatedAt'):''}</span>
            </div>
          </div>
          <div className="subscribe_sec">
            <Link className="mj_plus_trans" to="/packages"><img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv Plus <span>My Transaction <img src="/images/ic_right.svg" uk-svg="" /></span></Link>
            {localStorage.getItem('package_status') == "true" ?
            
            <Link to="/packages" className="subscribe_box">
              <div className="subscribe_box_img">
                <img src="/images/mjunoon_tv_icon.png" />
              </div>
              <div className="subscribe_box_txt">
                <div className="mdl">
                  <div className="mdl_inner">
                  <Link to="/packages">
                    <h6>{(localStorage.getItem('userPackageTitle') != "") ?localStorage.getItem('userPackageTitle'):''}</h6>
                    <p>{(localStorage.getItem('userPackageDesc') != "") ?localStorage.getItem('userPackageDesc'):''}</p>
                    <span>{(localStorage.getItem('userPackageExpiryDate') != "") ? 'Valid till: '+localStorage.getItem('validTillDate'):''}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
            :        <div className="subscribe_nosignin">            
            <a href="javascript:;" className="subscribe_nosignin_box">
              <div className="subscribe_nosignin_img">
                <img src="/images/mjunoon_tv_icon.png" />
              </div>
              <span><img src="/images/livetv.svg" uk-svg="" /> Live stream </span>              
              <span><img src="/images/7daysrewind_icon.svg" uk-svg="" /> 7 days Rewind</span>
              <span><img src="/images/mint_recording_icon.svg" uk-svg="" /> 5 minutes Recording</span>
            </a>
            {/* <a className="mj_plus" href="javascript:;"><img src="/images/check_circle_outline.svg" uk-svg="" /> Enjoy 30 Days for Rs.1, Renews at Rs.75/Month</a> */}
          </div>}
          {/* {(localStorage.getItem('userPackageTitle') != "") ?
            <div className="renewal_date">
              
              <p>{(localStorage.getItem('validTillDate')!= "" && localStorage.getItem('subscriptionStatus') == "SUBSCRIPTION_PURCHASED") ?'Next Renewal date: '+localStorage.getItem('userPackageExpiryDate'):''}</p>
              {(localStorage.getItem('validTillDate')!= "" && localStorage.getItem('subscriptionStatus') == "SUBSCRIPTION_PURCHASED") ?
              <div className="switch_box_wrapper">
                <label className="switch_box" htmlFor="switch_btn2">
                  <input type="checkbox" onChange={this.handleAutoRenew} id="switch_btn2" defaultChecked={((localStorage.getItem('userPackageAutoRenewal') == 1) ? true:false)}/>
                  <div className="switch_slider" />
                </label>
              </div>
              :''}
            </div>
            :''} */}
          </div>
          {/* After Sign in  */}
          </div>
        )
        }else{
          return(
            <div>
        {/* No Sign in */}
        <div className="joinfree_profile_box">
          <div className="img_joinfree"><img src="/images/icons/ic_male.png" /></div>
          <div className="text_joinfree">Enjoy 60+ Live TV Channels</div>
          <button className="btn_joinfree" onClick = {this.clickLogin}>Join Free</button>
        </div>
        <div className="subscribe_nosignin">
          <a className="mj_plus" href="javascrtpt:;"><img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv Plus</a>
          <a href="javascript:;" className="subscribe_nosignin_box">
            <div className="subscribe_nosignin_img">
              <img src="/images/psl_stream_icon.png" />
            </div>
            <span><img src="/images/livetv.svg" uk-svg="" /> Live stream PSL</span>
            <span><img src="/images/nobanner_icon.svg" uk-svg="" /> No Banner/Overlay Ads</span>
            <span><img src="/images/7daysrewind_icon.svg" uk-svg="" /> 7 days Rewind</span>
            <span><img src="/images/mint_recording_icon.svg" uk-svg="" /> 5 minutes Recording</span>
          </a>
          <a className="mj_plus" href="javascript:;"><img src="/images/check_circle_outline.svg" uk-svg="" /> Enjoy 3 days FREE, Rs. 300/month afterwards</a>
        </div>
        {/* No Sign in */}
      </div>
         )
        }
      }
    //     return(    
    //     <div class="reward_card_sec">
    //     <div class="reward_card_box">
    //       <div class="card_name_img">
    //         <div class="card_img"><span class="level_show_noti">Lv 2</span><img src={(localStorage.getItem('picture_url')) ? localStorage.getItem('picture_url') : (localStorage.getItem('gender') === 'Female') ? "/images/icons/ic_female@2x.png" : "/images/icons/ic_male@2x.png"} alt={localStorage.getItem('name')} /></div>
    //         <div class="card_name"><h2 className="showdots">{localStorage.getItem('name')}</h2>
    //         <p>Member since 13 Mar 2019</p>
    //       </div>
    //       <div class="total_coin">
    //         <h4>{localStorage.getItem('gender')}<span></span></h4>
    //         <a class="rdm_btn" href="javascript:;"><img src="/images/rdm_icon.svg" alt="rmd icon" uk-svg="" /><span>{localStorage.getItem('dob')}</span></a>
    //       </div>
    //       <div class="achived_level_box">
    //         <div class="achived_level_img"><img src="/images/ic_my_rewards.svg" alt="Reward" uk-svg="" /></div>
    //         <h5>{localStorage.getItem('mobile')}</h5>
    //         <p>{localStorage.getItem('email')}</p>
    //         <a class="click_dtl" href="javascript:;"><img src="/images/next.svg" alt="next" uk-svg="" /></a>
    //       </div>
    //       </div>
    //     </div>
    //   </div>
    //   )
    //   }else{
    //     return(
    //     <div class="sidebar_profile_pic">
    //     <div class="profile_pic_box">
    //       <div class="update_image"><span uk-icon="plus"></span></div>
    //       <div class="profile_img"><img src="/images/icons/ic_female.png" alt="Icon Female"/></div>
    //     </div>
    //     <div class="profile_name">Create Your Profile</div>
    //    </div>)
    //   }
    // }
    openmodal = () =>{
      $("#modal_sharing").addClass('uk-flex uk-open');
    }
    logout = () => {
      if(localStorage.getItem('user_id')){
          
          var expiry = localStorage.getItem('expiry');
          localStorage.clear();
          localStorage.setItem("isToken",'enable');
          localStorage.setItem('expiry',expiry);
          window.location.href = "/";
      }else{
          
      }
  };
    render(){
        return (
            <div>
                <div id="modal_sharing" class="uk-flex-top modal_sharing" uk-modal="">
                  <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close=""></button>
                    <div class="sharing_content">
                    <h3>Share with your Friends & Family !</h3>
                      <ul>
                        <li><a class="icon_facebook" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mjunoon.tv%2F" target="_blank"><span uk-icon="icon: facebook"></span></a></li>
                        <li><a class="icon_whatsapp" href="https://api.whatsapp.com/send?text=Watch%20mjunoon.tv%20to%20stream%20more%20than%2060%20live%20tv%20channels%20absolutely%20free" target="_blank"><span uk-icon="icon: whatsapp"></span></a></li>
                        <li><a class="icon_twitter" href="https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F" target="_blank"><span uk-icon="icon: twitter"></span></a></li>
                        <li><a class="icon_google-plus"href="https://plus.google.com/up/?continue=https://plus.google.com/share?url=https%3A%2F%2Fwww.mjunoon.tv%2F" target="_blank"><span uk-icon="icon: google-plus"></span></a></li>
                        <li><a class="icon_linkedin" href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.mjunoon.tv%2F&source=LinkedIn"  target="_blank"><span uk-icon="icon: linkedin"></span></a></li>
                        {/* <li><a class="icon_instagram" href="javascript:;"><span uk-icon="icon: instagram"></span></a></li> */}
                      </ul>
                      </div>
                  </div>    
                </div>
                  <aside class="sidebar_profile h_match">
                    <div className="subs_profile_sidebar">
                      {this.handleSideBar()}  {/*  UIkit.modal("#modal_login").show(); */}
                      <ul class="sidebar_profile_navi">
                        <li class={(window.location.pathname === '/profile') ? 'active' : ''}><Link to="/profile"><span class="img_a"><img src="/images/ic_my_profile.svg" uk-svg="" alt="profile"/></span> <span>My Profile</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></Link></li>
                        {/* <li><a href="javascript:;"><span class="img_a"><img src="/images/ic_my_rewards.svg" uk-svg="" /></span> <span>My Rewards</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></a></li> */}
                        <li class={(window.location.pathname === '/favourite-topics') ? 'active' : ''}><Link to="/favourite-topics"><span class="img_a"><img src="/images/ic_my_topics.svg" uk-svg="" alt="topics" /></span> <span>My Topics</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></Link></li>
                        <li class={(window.location.pathname === '/favourite-channel') ? 'active' : ''}><Link to="/favourite-channel"><span class="img_a"><img src="/images/ic_fav_channel.svg" uk-svg="" alt="fav channel"/></span> <span>Favorite Channels</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" alt="icon right"/></i></Link></li>
                        <li class={(window.location.pathname === '/favourite-program') ? 'active' : ''}><Link to="/favourite-program"><span class="img_a"><img src="/images/ic_fav_programs.svg" uk-svg="" alt="fav program"/></span> <span>Favorite Programs</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" alt="icon right" /></i></Link></li>
                        <li class={(window.location.pathname === '/watch-list') ? 'active' : ''}><Link to="/watch-list"><span class="img_a"><img src="/images/ic_watch_list.svg" uk-svg="" alt="watch list"/></span> <span>Watch List</span> <i class="icon_arow"><img alt="icon right" src="/images/ic_right.svg" uk-svg="" /></i></Link></li>
                        <li class={(window.location.pathname === '/watch-history') ? 'active' : ''}><Link to="/watch-history"><span class="img_a"><img src="/images/ic_history.svg" uk-svg="" alt="history"/></span> <span>Watch History</span> <i class="icon_arow"><img alt="icon right" src="/images/ic_right.svg" uk-svg="" /></i></Link></li>
                        {/* <li><a href="javascript:;"><span class="img_a"><img src="/images/ic_invite_friends.svg" uk-svg="" /></span> <span>Invite Friends</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></a></li> */}
                        <li><a onClick = {this.openmodal}><span class="img_a"><img src="/images/ic_share.svg" uk-svg="" alt="Share"/></span> <span>Share App</span> <i class="icon_arow"><img src="/images/ic_right.svg" uk-svg="" alt="icon right"/></i></a></li>
                        {/* <li><a onClick = {this.logout}><span class="img_a"><img src="/images/logout.svg" uk-svg="" /></span> <span>Log out</span></a></li> */}
                      </ul>
                    </div>
                  </aside>
            </div>
        );
    }
}

export default Side_Bar_User;