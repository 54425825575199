import React from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Constant from "../../utils/Constant";
import ImageWebp from 'react-image-webp';
class Fav_Channel extends React.Component{

    componentDidMount(){
        $('img').on("error", function() {
          $(this).attr('src', '/images/bg_video.png');
        });
        new window.Glider(document.querySelector('#glider_slider1'), {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 264,
            itemWidth: 264,
            duration: 0.25,
            draggable: true,
            arrows: {
            prev: '#glider_slider1_prev',
            next: '#glider_slider1_next'
            },
            responsive: [
            {
            breakpoint: 360,
            settings: {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 304,
            itemWidth: 304
            }
            },{
            breakpoint: 375,
            settings: {
            exactWidth: 318,
            itemWidth: 318,
            }
            },{
            breakpoint: 411,
            settings: {
            exactWidth: 357,
            itemWidth: 357,
            }
            },{
            breakpoint: 768,
            settings: {
            exactWidth: 232,
            itemWidth: 232,
            }
            },{
            breakpoint: 1024,
            settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
            }
            },{
            breakpoint: 1201,
            settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
            }
            },{
            breakpoint: 1281,
            settings: {
            exactWidth: 295,
            itemWidth: 295,
            draggable: false,
            }
            },{
            breakpoint: 1401,
            settings: {
            exactWidth: 283,
            itemWidth: 283,
            draggable: false,
            }
            },{
            breakpoint: 1601,
            settings: {
            exactWidth: 289,
            itemWidth: 289,
            draggable: false,
            }
            }
            ]
          })
    }

    handleChannelList = () => {
        let channels = "";
        channels = this.props.data.map((channel,index) => (
            <div key={index}> <div className="live_style"><Link className="fav_sld_img" to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)}><ImageWebp className="img_log" webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name} /><ImageWebp webp={channel.channel_live_image} src={channel.channel_live_image_jpg} alt={channel.channel_name}/><div className="hvr_box"><span uk-icon="play"></span></div> </Link>
                    <Link to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)} className="txt_box">
                    <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
                    <h4>{channel.channel_name}</h4>
                    <span className="views_txt">{channel.channel_views} viewers</span>
                    <span className="live_span">{channel.channel_status === 'live' ? 'Live' : ''}</span> </Link>
                     </div></div>       
        ));        
        return channels;
    };
    render(){
        return (
            <div>
        <section className="pad_left pad_tb fav_slider">
            <div className="headng_box">
              <h2 className="h2forhome"><strong>{this.props.name}</strong></h2>
              <a className="view_all" href="/favourite-channel">View All</a> </div>
              <div className="slider_new_container">
                <div id="glider_slider1" className="fav_slider_new">
                {this.handleChannelList()}                                                   
              </div>
              <button role="button" aria-label="Previous" id="glider_slider1_prev" className="glider-prev"><img src="/images/left_arrow.png" alt="Left Arrow"/></button>
              <button role="button" aria-label="Next" id="glider_slider1_next" className="glider-next"><img src="/images/right_arrow.png" alt="Right Arrow"/></button>
             </div>
        </section>
        <hr/>
            </div>
        );
    }
}

export default Fav_Channel;