import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
// import {AdSlot} from "react-dfp";

class TeamPlayers extends React.Component{

    playAudio = () => {
        var audio = new Audio('/images/psl/flip.mp3');
        audio.play()
    }

    handleData = () =>{
        var slug = this.props.match.params.slug;
        var result = "";
        
        if(slug === 'peshawar-zalmi'){
            result = <div class="box_psl">
            <div class="headng_box">
                <h2 class="h2forhome"><strong>PESHAWAR ZALMI</strong></h2>
            </div>
            <div class="team_list uk-grid-small team_peshawar" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Aamir_Khan.webp" src="/images/psl/peshawar/Aamir_Khan.png" alt="Aamir Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Aamir</span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Abrar_Ahmed.webp" src="/images/psl/peshawar/Abrar_Ahmed.png" alt="Abrar Ahmed" /></div>
                        <div class="psl_player_name">
                            <h4><span>Abrar </span> Ahmed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Amad_Butt.webp" src="/images/psl/peshawar/Amad_Butt.png" alt="Amad Butt" /></div>
                        <div class="psl_player_name">
                            <h4><span>Amad </span> Butt </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/David_Miller.webp" src="/images/psl/peshawar/David_Miller.png" alt="David Miller" /></div>
                        <div class="psl_player_name">
                            <h4><span>David </span> Miller </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Haider_Ali.webp" src="/images/psl/peshawar/Haider_Ali.png" alt="Haider Ali" /></div>
                        <div class="psl_player_name">
                            <h4><span>Haider </span> Ali </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Imam-ul-Haq.webp" src="/images/psl/peshawar/Imam-ul-Haq.png" alt="Imam-ul-Haq" /></div>
                        <div class="psl_player_name">
                            <h4><span>Imam </span> ul-Haq </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Kamran_Akmal.webp" src="/images/psl/peshawar/Kamran_Akmal.png" alt="Kamran Akmal" /></div>
                        <div class="psl_player_name">
                            <h4><span>Kamran </span> Akmal </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Mohammad_Imran.webp" src="/images/psl/peshawar/Mohammad_Imran.png" alt="Mohammad Imran" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mohammad </span> Imran </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Mohammad_Imran_Randhawa.webp" src="/images/psl/peshawar/Mohammad_Imran_Randhawa.png" alt="Mohammad Imran Randhawa" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mohammad </span> Imran Randhawa </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Muhammad_Irfan.webp" src="/images/psl/peshawar/Muhammad_Irfan.png" alt="Muhammad Irfan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Muhammad </span> Irfan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Mujeeb_Ur_Rahman.webp" src="/images/psl/peshawar/Mujeeb_Ur_Rahman.png" alt="Mujeeb Ur Rahman" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mujeeb </span> Ur Rahman </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Ravi_Bopara.webp" src="/images/psl/peshawar/Ravi_Bopara.png" alt="Ravi Bopara" /></div>
                        <div class="psl_player_name">
                            <h4><span>Ravi </span> Bopara </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Saqib_Mahmood.webp" src="/images/psl/peshawar/Saqib_Mahmood.png" alt="Saqib Mahmood" /></div>
                        <div class="psl_player_name">
                            <h4><span>Saqib </span> Mahmood </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Sherfane_Rutherford.webp" src="/images/psl/peshawar/Sherfane_Rutherford.png" alt="Sherfane Rutherford" /></div>
                        <div class="psl_player_name">
                            <h4><span>Sherfane </span> Rutherford </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Shoaib_Malik.webp" src="/images/psl/peshawar/Shoaib_Malik.png" alt="Shoaib Malik" /></div>
                        <div class="psl_player_name">
                            <h4><span>Shoaib </span> Malik </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Umaid_Asif.webp" src="/images/psl/peshawar/Umaid_Asif.png" alt="Umaid Asif" /></div>
                        <div class="psl_player_name">
                            <h4><span>Umaid </span> Asif </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Wahab_Riaz.webp" src="/images/psl/peshawar/Wahab_Riaz.png" alt="Wahab Riaz" /></div>
                        <div class="psl_player_name">
                            <h4><span>Wahab </span> Riaz </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/peshawar/Tom_Kohler-Cadmore.webp" src="/images/psl/peshawar/Tom_Kohler-Cadmore.png" alt="Tom Kohler-Cadmore" /></div>
                        <div class="psl_player_name">
                            <h4><span>Tom </span> Kohler-Cadmore </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }else if(slug === 'quetta-gladiators'){
            result =                     <div class="box_psl">
            <div class="headng_box">
                <h2 class="h2forhome"><strong>QUETTA GLADIATORS</strong></h2>
            </div>
            <div class="team_list uk-grid-small team_quetta" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Abdul_Nasir.webp" src="/images/psl/quetta/Abdul_Nasir.png" alt="Abdul Nasir" /></div>
                        <div class="psl_player_name">
                            <h4><span>Abdul </span> Nasir </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Anwar_Ali.webp" src="/images/psl/quetta/Anwar_Ali.png" alt="Anwar Ali" /></div>
                        <div class="psl_player_name">
                            <h4><span>Anwar </span> Ali </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Arish_Ali_Khan.webp" src="/images/psl/quetta/Arish_Ali_Khan.png" alt="Arish Ali Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Arish </span> Ali Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Azam_Khan.webp" src="/images/psl/quetta/Azam_Khan.png" alt="Azam Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Azam </span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Ben_Cutting.webp" src="/images/psl/quetta/Ben_Cutting.png" alt="Ben Cutting" /></div>
                        <div class="psl_player_name">
                            <h4><span>Ben </span> Cutting </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Cameron_Delport.webp" src="/images/psl/quetta/Cameron_Delport.png" alt="Cameron Delport" /></div>
                        <div class="psl_player_name">
                            <h4><span>Cameron </span> Delport </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Chris_Gayle.webp" src="/images/psl/quetta/Chris_Gayle.png" alt="Chris Gayle" /></div>
                        <div class="psl_player_name">
                            <h4><span>Chris </span> Gayle </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Dale_Steyn.webp" src="/images/psl/quetta/Dale_Steyn.png" alt="Dale Steyn" /></div>
                        <div class="psl_player_name">
                            <h4><span>Dale </span> Steyn </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Faf_du_Plessis.webp" src="/images/psl/quetta/Faf_du_Plessis.png" alt="Faf du Plessis" /></div>
                        <div class="psl_player_name">
                            <h4><span>Faf du </span> Plessis </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Mohammad_Hasnain.webp" src="/images/psl/quetta/Mohammad_Hasnain.png" alt="Mohammad Hasnain" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mohammad</span> Hasnain </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Mohammad_Nawaz.webp" src="/images/psl/quetta/Mohammad_Nawaz.png" alt="Mohammad Nawaz" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mohammad </span> Nawaz </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Naseem_Shah.webp" src="/images/psl/quetta/Naseem_Shah.png" alt="Naseem Shah" /></div>
                        <div class="psl_player_name">
                            <h4><span>Naseem </span> Shah </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Qais_Ahmad.webp" src="/images/psl/quetta/Qais_Ahmad.png" alt="Qais Ahmad" /></div>
                        <div class="psl_player_name">
                            <h4><span>Qais </span> Ahmed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Saim_Ayub.webp" src="/images/psl/quetta/Saim_Ayub.png" alt="Saim Ayub" /></div>
                        <div class="psl_player_name">
                            <h4><span>Saim </span> Ayub </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Sarfaraz_Ahmed.webp" src="/images/psl/quetta/Sarfaraz_Ahmed.png" alt="Sarfaraz Ahmed" /></div>
                        <div class="psl_player_name">
                            <h4><span>Sarfaraz </span> Ahmed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Tom_Banton.webp" src="/images/psl/quetta/Tom_Banton.png" alt="Tom Banton" /></div>
                        <div class="psl_player_name">
                            <h4><span>Tom </span> Banton </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Usman_Khan.webp" src="/images/psl/quetta/Usman_Khan.png" alt="Usman Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Usman </span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Usman_Shinwari.webp" src="/images/psl/quetta/Usman_Shinwari.png" alt="Usman Shinwari" /></div>
                        <div class="psl_player_name">
                            <h4><span>Usman </span> Shinwari </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Zahid_Mahmood.webp" src="/images/psl/quetta/Zahid_Mahmood.png" alt="Zahid Mahmood" /></div>
                        <div class="psl_player_name">
                            <h4><span>Zahid </span> Mahmood </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/quetta/Hassan_Khan.webp" src="/images/psl/quetta/Hassan_Khan.png" alt="Hassan Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Hassan </span> Khan </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }else if(slug === 'islamabad-united'){
            result =                     <div class="box_psl">
            <div class="headng_box">
                <h2 class="h2forhome"><strong>ISLAMABAD UNITED</strong></h2>
            </div>
            <div class="team_list uk-grid-small team_islamabad" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Ahmed_Safi_Abdullah.webp" src="/images/psl/islamabad/Ahmed_Safi_Abdullah.png" alt="Ahmed Safi Abdullah" /></div>
                        <div class="psl_player_name">
                            <h4><span>Ahmed </span> Safi Abdullah </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Akif_Javed.webp" src="/images/psl/islamabad/Akif_Javed.png" alt="Akif Javed" /></div>
                        <div class="psl_player_name">
                            <h4><span>Akif </span> Javed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Alex_Hales.webp" src="/images/psl/islamabad/Alex_Hales.png" alt="Alex Hales" /></div>
                        <div class="psl_player_name">
                            <h4><span>Alex </span> Hales </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Ali_Khan.webp" src="/images/psl/islamabad/Ali_Khan.png" alt="Ali Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Ali </span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Asif_Ali.webp" src="/images/psl/islamabad/Asif_Ali.png" alt="Asif Ali" /></div>
                        <div class="psl_player_name">
                            <h4><span>Asif </span> Ali </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Faheem_Ashraf.webp" src="/images/psl/islamabad/Faheem_Ashraf.png" alt="Faheem Ashraf" /></div>
                        <div class="psl_player_name">
                            <h4><span>Faheem </span> Ashraf </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Fawad_Ahmed.webp" src="/images/psl/islamabad/Fawad_Ahmed.png" alt="Fawad Ahmed" /></div>
                        <div class="psl_player_name">
                            <h4><span>Fawad </span> Ahmed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Hasan_Ali.webp" src="/images/psl/islamabad/Hasan_Ali.png" alt="Hasan Ali" /></div>
                        <div class="psl_player_name">
                            <h4><span>Hasan </span> Ali </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Hussain_Talat.webp" src="/images/psl/islamabad/Hussain_Talat.png" alt="Hussain Talat" /></div>
                        <div class="psl_player_name">
                            <h4><span>Hussain </span> Talat </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Iftikhar_Ahmed.webp" src="/images/psl/islamabad/Iftikhar_Ahmed.png" alt="Iftikhar Ahmed" /></div>
                        <div class="psl_player_name">
                            <h4><span>Iftikhar</span> Ahmed </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Lewis_Gregory.webp" src="/images/psl/islamabad/Lewis_Gregory.png" alt="Lewis Gregory" /></div>
                        <div class="psl_player_name">
                            <h4><span>Lewis </span> Gregory </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Mohammad_Waseem.webp" src="/images/psl/islamabad/Mohammad_Waseem.png" alt="Mohammad Waseem" /></div>
                        <div class="psl_player_name">
                            <h4><span>Mohammad </span> Waseem </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Musa_Khan.webp" src="/images/psl/islamabad/Musa_Khan.png" alt="Musa Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Musa </span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Paul_Stirling.webp" src="/images/psl/islamabad/Paul_Stirling.png" alt="Paul Stirling" /></div>
                        <div class="psl_player_name">
                            <h4><span>Paul </span> Stirling </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Philip_Salt.webp" src="/images/psl/islamabad/Philip_Salt.png" alt="Philip Salt" /></div>
                        <div class="psl_player_name">
                            <h4><span>Philip </span> Salt </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box batsman">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Rohail_Nazir.webp" src="/images/psl/islamabad/Rohail_Nazir.png" alt="Rohail Nazir" /></div>
                        <div class="psl_player_name">
                            <h4><span>Rohail </span> Nazir </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Shadab_Khan.webp" src="/images/psl/islamabad/Shadab_Khan.png" alt="Shadab Khan" /></div>
                        <div class="psl_player_name">
                            <h4><span>Shadab </span> Khan </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box alrounder">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Zafar_Gohar.webp" src="/images/psl/islamabad/Zafar_Gohar.png" alt="Zafar Gohar" /></div>
                        <div class="psl_player_name">
                            <h4><span>Zafar </span> Gohar </h4>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                    <div class="psl_player_box bowler">
                        <div class="psl_player_img"><ImageWebp webp="/images/psl/islamabad/Zeeshan_Zameer.webp" src="/images/psl/islamabad/Zeeshan_Zameer.png" alt="Zeeshan Zameer" /></div>
                        <div class="psl_player_name">
                            <h4><span>Zeeshan </span> Zameer </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }else if(slug === 'karachi-king'){
            result =  <div class="box_psl">
                <div class="headng_box">
                    <h2 class="h2forhome"><strong>KARACHI KINGS</strong></h2>
                </div>
                <div class="team_list uk-grid-small team_karachi" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Aamer_Yamin.webp" src="/images/psl/karachi/Aamer_Yamin.png" alt="Aamer Yamin" /></div>
                            <div class="psl_player_name">
                                <h4><span>Aamer </span> Yamin </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Arshad_Iqbal.webp" src="/images/psl/karachi/Arshad_Iqbal.png" alt="Arshad Iqbal" /></div>
                            <div class="psl_player_name">
                                <h4><span>Arshad </span> Iqbal </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Babar_Azam.webp" src="/images/psl/karachi/Babar_Azam.png" alt="Babar Azam" /></div>
                            <div class="psl_player_name">
                                <h4><span>Babar </span> Azam </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Chadwick_Walton.webp" src="/images/psl/karachi/Chadwick_Walton.png" alt="Chadwick Walton" /></div>
                            <div class="psl_player_name">
                                <h4><span>Chadwick </span> Walton </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Colin_Ingram.webp" src="/images/psl/karachi/Colin_Ingram.png" alt="Colin Ingram" /></div>
                            <div class="psl_player_name">
                                <h4><span>Colin </span> Ingram </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Daniel_Christian.webp" src="/images/psl/karachi/Daniel_Christian.png" alt="Daniel Christian" /></div>
                            <div class="psl_player_name">
                                <h4><span>Daniel </span> Christian </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Danish_Aziz.webp" src="/images/psl/karachi/Danish_Aziz.png" alt="Danish Aziz" /></div>
                            <div class="psl_player_name">
                                <h4><span>Danish </span> Aziz </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Imad_Wasim.webp" src="/images/psl/karachi/Imad_Wasim.png" alt="Imad Wasim" /></div>
                            <div class="psl_player_name">
                                <h4><span>Imad </span> Wasim </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Joe_Clarke.webp" src="/images/psl/karachi/Joe_Clarke.png" alt="Joe Clarke" /></div>
                            <div class="psl_player_name">
                                <h4><span>Joe </span> Clarke </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Mohammad_Amir.webp" src="/images/psl/karachi/Mohammad_Amir.png" alt="Mohammad Amir" /></div>
                            <div class="psl_player_name">
                                <h4><span>Mohammad </span> Amir </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Mohammad_Ilyas.webp" src="/images/psl/karachi/Mohammad_Ilyas.png" alt="Mohammad Ilyas" /></div>
                            <div class="psl_player_name">
                                <h4><span>Mohammad </span> Ilyas </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Mohammad_Nabi.webp" src="/images/psl/karachi/Mohammad_Nabi.png" alt="Mohammad Nabi" /></div>
                            <div class="psl_player_name">
                                <h4><span>Mohammad </span> Nabi </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Noor_Ahmad.webp" src="/images/psl/karachi/Noor_Ahmad.png" alt="Noor Ahmad" /></div>
                            <div class="psl_player_name">
                                <h4><span>Noor </span> Ahmad </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Qasim_Akram.webp" src="/images/psl/karachi/Qasim_Akram.png" alt="Qasim Akram" /></div>
                            <div class="psl_player_name">
                                <h4><span>Qasim </span> Akram </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Sharjeel_Khan.webp" src="/images/psl/karachi/Sharjeel_Khan.png" alt="Sharjeel Khan" /></div>
                            <div class="psl_player_name">
                                <h4><span>Sharjeel </span> Khan </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box bowler">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Waqas_Maqsood.webp" src="/images/psl/karachi/Waqas_Maqsood.png" alt="Waqas Maqsood" /></div>
                            <div class="psl_player_name">
                                <h4><span>Waqas </span> Maqsood </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box batsman">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Zeeshan_Malik.webp" src="/images/psl/karachi/Zeeshan_Malik.png" alt="Zeeshan Malik" /></div>
                            <div class="psl_player_name">
                                <h4><span>Zeeshan </span> Malik </h4>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                        <div class="psl_player_box alrounder">
                            <div class="psl_player_img"><ImageWebp webp="/images/psl/karachi/Abbas_Afridi.webp" src="/images/psl/karachi/Abbas_Afridi.png" alt="Abbas Afridi" /></div>
                            <div class="psl_player_name">
                                <h4><span>Abbas </span> Afridi </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        }else if(slug === 'lahore-qalandars'){
            result =  <div class="box_psl">
                    <div class="headng_box">
                        <h2 class="h2forhome"><strong>LAHORE QALANDARS</strong></h2>
                    </div>
                    <div class="team_list uk-grid-small team_lahore" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Samit_Patel.webp" src="/images/psl/lahore/Samit_Patel.png" alt="Samit Patel" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Samit </span> Patel </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Mohammad_Hafeez.webp" src="/images/psl/lahore/Mohammad_Hafeez.png" alt="Mohammad Hafeez" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Mohammad </span> Hafeez </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Joe_Denly.webp" src="/images/psl/lahore/Joe_Denly.png" alt="Joe Denly" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Joe </span> Denly </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/David_Wiese.webp" src="/images/psl/lahore/David_Wiese.png" alt="David Wiese" /></div>
                                <div class="psl_player_name">
                                    <h4><span>David </span> Wiese </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Ben_Dunk.webp" src="/images/psl/lahore/Ben_Dunk.png" alt="Ben Dunk" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Ben </span> Dunk </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Rashid_Khan.webp" src="/images/psl/lahore/Rashid_Khan.png" alt="Rashid Khan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Rashid </span> Khan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Fakhar_Zaman.webp" src="/images/psl/lahore/Fakhar_Zaman.png" alt="Fakhar Zaman" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Fakhar </span> Zaman </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Shaheen_Afridi.webp" src="/images/psl/lahore/Shaheen_Afridi.png" alt="Shaheen Afridi" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Shaheen </span> Afridi </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Salman_Ali_Agha.webp" src="/images/psl/lahore/Salman_Ali_Agha.png" alt="Salman Ali Agha" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Salman </span> Ali Agha </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Sohail_Akhtar.webp" src="/images/psl/lahore/Sohail_Akhtar.png" alt="Sohail Akhtar" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Sohail </span> Akhtar </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Zaid_Alam.webp" src="/images/psl/lahore/Zaid_Alam.png" alt="Zaid Alam" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Zaid </span> Alam </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Haris_Rauf.webp" src="/images/psl/lahore/Haris_Rauf.png" alt="Haris Rauf" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Haris </span> Rauf </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Zeeshan_Ashraf.webp" src="/images/psl/lahore/Zeeshan_Ashraf.png" alt="Zeeshan Ashraf" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Zeeshan </span> Ashraf </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Dilbar_Hussain.webp" src="/images/psl/lahore/Dilbar_Hussain.png" alt="Dilbar Hussain" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Dilbar </span> Hussain </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Maaz_Khan.webp" src="/images/psl/lahore/Maaz_Khan.png" alt="Maaz Khan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Maaz </span> Khan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Tom_Abell.webp" src="/images/psl/lahore/Tom_Abell.png" alt="Tom Abell" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Tom </span> Abell </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Mohammad_Faizan.webp" src="/images/psl/lahore/Mohammad_Faizan.png" alt="Mohammad Faizan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Mohammad </span> Faizan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/lahore/Ahmad_Danyal.webp" src="/images/psl/lahore/Ahmad_Danyal.png" alt="Ahmad Danyal" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Ahmad </span> Danyal </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }else if(slug === 'multan-sultan'){
            result = 
                <div class="box_psl">
                    <div class="headng_box">
                        <h2 class="h2forhome"><strong>MULTAN SULTANS</strong></h2>
                    </div>
                    <div class="team_list uk-grid-small team_multan" uk-grid="" uk-scrollspy="cls: uk-animation-fade; target: .psl_player_box; delay: 50;">
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Adam_Lyth.webp" src="/images/psl/multan/Adam_Lyth.png" alt="Adam Lyth" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Adam </span> Lyth </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/James_Vince.webp" src="/images/psl/multan/James_Vince.png" alt="James Vince" /></div>
                                <div class="psl_player_name">
                                    <h4><span>James </span> Vince </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Imran_Tahir.webp" src="/images/psl/multan/Imran_Tahir.png" alt="Imran Tahir" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Imran </span> Tahir </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Carlos_Brathwaite.webp" src="/images/psl/multan/Carlos_Brathwaite.png" alt="Carlos Brathwaite" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Carlos </span> Brathwaite </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Shahid_Afridi.webp" src="/images/psl/multan/Shahid_Afridi.png" alt="Shahid Afridi" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Shahid </span> Afridi </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Sohail_Tanvir.webp" src="/images/psl/multan/Sohail_Tanvir.png" alt="Sohail Tanvir" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Sohail </span> Tanvir </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Sohail_Khan.webp" src="/images/psl/multan/Sohail_Khan.png" alt="Sohail Khan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Sohail </span> Khan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Shan_Masood.webp" src="/images/psl/multan/Shan_Masood.png" alt="Shan Masood" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Shan </span> Masood </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Mohammad_Imran_Khan.webp" src="/images/psl/multan/Mohammad_Imran_Khan.png" alt="Mohammad Imran Khan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Mohammad </span> Imran Khan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Rilee_Rossouw.webp" src="/images/psl/multan/Rilee_Rossouw.png" alt="Rilee Rossouw" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Rilee </span> Rossouw </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Sohaib_Maqsood.webp" src="/images/psl/multan/Sohaib_Maqsood.png" alt="Sohaib Maqsood" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Sohaib </span> Maqsood </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Mohammad_Rizwan.webp" src="/images/psl/multan/Mohammad_Rizwan.png" alt="Mohammad Rizwan" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Mohammad </span> Rizwan </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box batsman">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Chris_Lynn.webp" src="/images/psl/multan/Chris_Lynn.png" alt="Chris Lynn" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Chris </span> Lynn </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Usman_Qadir.webp" src="/images/psl/multan/Usman_Qadir.png" alt="Usman Qadir" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Usman </span> Qadir </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box alrounder">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Khushdil_Shah.webp" src="/images/psl/multan/Khushdil_Shah.png" alt="Khushdil Shah" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Khushdil </span> Shah </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Sohaibullah.webp" src="/images/psl/multan/Sohaibullah.png" alt="Sohaibullah" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Sohaibullah </span>  </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Mohammad_Umar.webp" src="/images/psl/multan/Mohammad_Umar.png" alt="Mohammad Umar" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Mohammad </span> Umar </h4>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m">
                            <div class="psl_player_box bowler">
                                <div class="psl_player_img"><ImageWebp webp="/images/psl/multan/Shahnawaz_Dhani.webp" src="/images/psl/multan/Shahnawaz_Dhani.png" alt="Shahnawaz Dhani" /></div>
                                <div class="psl_player_name">
                                    <h4><span>Shahnawaz </span> Dhani </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
    return result;
    }

    render(){
        return(
            <div>
                <Header/>
                <div class="content_sec live_sec">
                        <section class="tending_player_sec uk-padding-remove-top">
                            <div class="psl_sec">
                                <div class="box_psl">
                                    <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                        <ul class="uk-slider-items">
                                        <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                        </ul>
                                    </div>
                            {/* <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>      */}
                            {this.handleData()}
                            </div>
                            </div>
                            <div class="sidebar">
                            </div>
                        </section>
                    </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(TeamPlayers);