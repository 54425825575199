import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class Genre extends React.Component{
  componentDidMount(){
    this.loadGlider()
  }
  loadGlider = () =>{
    new window.Glider(document.querySelector('#PopularGenres'), {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        exactWidth: 140,
        itemWidth: 140,
        duration: 0.25,
        draggable: true,
        arrows: {
        prev: '#PopularGenres_prev',
        next: '#PopularGenres_next'
        },
        responsive: [
        {
        breakpoint: 360,
        settings: {
        slidesToShow: 'auto',
        slidesToScroll: 'auto',
        exactWidth: 140,
        itemWidth: 140,
        duration: 0.25,
        }
        },{
        breakpoint: 375,
        settings: {
        exactWidth: 140,
        itemWidth: 140,
        }
        },{
        breakpoint: 411,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        }
        },{
        breakpoint: 768,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        }
        },{
        breakpoint: 1024,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        }
        },{
        breakpoint: 1201,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        draggable: false,
        }
        },{
        breakpoint: 1281,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        draggable: false,
        }
        },{
        breakpoint: 1401,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        draggable: false,
        }
        },{
        breakpoint: 1601,
        settings: {
        exactWidth: 160,
        itemWidth: 160,
        draggable: false,
        }
        }
        ]
    })
  }
    handleGenre = () => {
        let genres = "";
        genres = this.props.data.map((genre,index) => (
            <div key={index}>
                <div className="PopularGenresBox">
                                <Link to={"/search?search="+genre.genre_name}>                                    
                                    <div className="shows_slider_img"><img src={genre.thumbnail} alt="" /> <div className="chnl_hvr_box"><span uk-icon="play"></span></div> </div>
                                    <div className="PopularGenresName"><span>{genre.genre_name}</span></div>
                                </Link>
                </div>
            </div>
        ));        
        return genres;
    };
    render(){
        return (
            <div>
                <section className="pad_left pad_t shows_slider_sec">
                    <div className="headng_box">

                        <h2 className="h2forhome1"><strong>Popular Genres</strong></h2>
                    </div>

                        <div className="slider_new_container drama_sldr_container">
                       <div id={"PopularGenres"} className="drama_sldr_new ">

                            {this.handleGenre()}                          
                        </div>
                        <button role="button" aria-label="Previous" id={"PopularGenres_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow"/></button>
                        <button role="button" aria-label="Next" id={"PopularGenres_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrow" /></button>
                        
                        </div>
                </section>
              
            </div>
        );
    }
}

export default Genre;