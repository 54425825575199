import {Component} from 'react';
import React from 'react';
import {withRouter} from 'react-router-dom';

import Header from '../common/Header';
import Footer from '../common/Footer';
class NoInternet extends React.Component{
    render(){
        return(
            <div>
                <Header/>
                <div className="content_sec">
                    <div className="page_notfound">
                        <div className="h_match asad_bg">
                            {/* Channels Start */}
                            <section className="pad_left pad_right pad_tb prog_box_sec">
                                <div className="empty_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <img alt="Dino" src="/images/dino.png" />
                                            <p className="uk-margin-small-bottom">404</p>
                                            <p className="uk-text-small uk-text-muted uk-margin-remove-top">Page Not Found</p>
                                            {/* <a className="blue_btn" href="javascript:;">RELOAD</a> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* Channels End */}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(NoInternet);

