import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import Constant from "../../utils/Constant";
import $ from "jquery";
import LocalStorageService from "../../utils/LocalStorageService";
import ReactGA from 'react-ga';


class Player extends React.Component{
    constructor(){
        super();
        this.state ={
            channelUrl: {
                isLoaded: false,
                url: "",
                is_playback: false,
                backup_live_stream_url: "",
                addTagUrl: "",
                ad_start_time: 20000,
                ad_repeat_time: 20000,
                ad_horizontal: "",
                ad_vertical: "",
                ad_redirect: "",
                ad_half_screen: "",
                l_shape_status: "0"

            }
        }
    }
    componentDidMount(){
        document.body.style.height = "100%";
        document.body.style.width = "100%";
        document.body.style.padding = "0px";
        document.body.style.margin = "0px";
        
        this.initializeToken();
        var classRef = this;
        setTimeout( function(){
            console.log("inside time out",classRef.state.channelUrl.l_shape_status);
            var adHorizontal = classRef.state.channelUrl.ad_horizontal;
            var adVertical = classRef.state.channelUrl.ad_vertical;
            classRef.setState({ flagetag:1 });
            if(classRef.state.channelUrl.l_shape_status === "1"){
                $(".rmp-i-close").click();
                $('.rmp-content').css({'width': '85%', 'height': '85%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                $('.rmp-ad-container iframe').css({'width': '85%', 'height': '85%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                $('.dyn-add').css({'display' : 'block'});
                $('.dyn-add2').css({'display' : 'block'});
                $('.dyn-add').append('<div class="ad_clck" id="afterclickhor"><img src="'+adHorizontal+'" style="width: 100%;height: 100%;"></img></div>');
                $('.dyn-add2').append('<div class="ad_clck" id="afterclickver"><img src="'+adVertical+'" style="width: 100%;height: 100%;"></img></div>');
                $('#afterclickhor').on('click', function(){ classRef.checkclick(1)});
                $('#afterclickver').on('click', function(){ classRef.checkclick(2)});
                ReactGA.event({ category: 'Samaa TV', action: 'L Shape H Ad Impression'+Constant.version });
                ReactGA.event({ category: 'Samaa TV', action: 'L Shape V Ad Impression'+Constant.version });
            }
        }  , this.state.channelUrl.ad_start_time);
        setInterval(function() {
            if($(".dyn-add3").length != 0){
            }else{
                if(classRef.state.channelUrl.l_shape_status === "1"){
                    $('.dyn-add').html('');
                    $('.dyn-add2').html('');
                    $('.dyn-add').css({'display' : 'none'});
                    $('.dyn-add2').css({'display' : 'none'});
                    $('.rmp-ad-container iframe').css({'width': '100%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                    $('.rmp-content').css({'width': '100%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                }
            }
        }, parseInt(this.state.channelUrl.ad_repeat_time)+10000);

        setInterval( function(){
            if($(".dyn-add3").length != 0 || classRef.state.flagetag == 1){
            }else{
                if(classRef.state.channelUrl.l_shape_status === "1"){
                    var adHorizontal = classRef.state.channelUrl.ad_horizontal;
                    var adVertical = classRef.state.channelUrl.ad_vertical;
                    $(".rmp-i-close").click()
                    // console.log("data here");
                    $('.rmp-content').css({'width': '85%', 'height': '85%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                    $('.rmp-ad-container iframe').css({'width': '85%', 'height': '85%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
                    $('.dyn-add').css({'display' : 'block'});
                    $('.dyn-add2').css({'display' : 'block'});
                    $('.dyn-add').append('<div class="ad_clck" id="afterclickhor"><img src="'+adHorizontal+'" style="width: 100%;height: 100%;"></img></div>');
                    $('.dyn-add2').append('<div class="ad_clck" id="afterclickver"><img src="'+adVertical+'" style="width: 100%;height: 100%;"></img></div>');
                    $('#afterclickhor').on('click', function(){ classRef.checkclick(1)});
                    $('#afterclickver').on('click', function(){ classRef.checkclick(2)});
                    ReactGA.event({ category: 'Samaa TV', action: 'L Shape H Ad Impression'+Constant.version });
                    ReactGA.event({ category: 'Samaa TV', action: 'L Shape V Ad Impression'+Constant.version });
                }
            }
        }, parseInt(this.state.channelUrl.ad_repeat_time)+20000);
    }

    checkclick = (valAd) => {
        let classRef = this;
        var ad_half_screen = this.state.channelUrl.ad_half_screen;
        var ad_redirect = this.state.channelUrl.ad_redirect;
        // console.log("insides where")
        this.setState({ flagetag:2 });
        if(valAd === 1){
            $("#rmpPlayerEmbed").click();
            ReactGA.event({ category: 'Samaa TV', action: 'L Shape H Ad Click'+Constant.version });
        }
        if(valAd === 2){
            ReactGA.event({ category: 'Samaa TV', action: 'L Shape V Ad Click'+Constant.version });
        }
        ReactGA.event({ category: 'Samaa TV', action: 'Half Player Ad Impression'+Constant.version });
        $("#fullscreenbutton").show();
        $(".rmp-outline").attr('style', 'display: none !important');
        $(".rmp-module").attr('style', 'display: none !important');
        $('.rmp-content').css({'width': '50%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
        $('.rmp-ad-container iframe').css({'width': '50%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
        $('.dyn-add').html('');
        $('.dyn-add2').html('');
        $('.dyn-add').css({'display' : 'none'});
        $('.dyn-add2').css({'display' : 'block'});
        $('.dyn-add2').addClass('dyn-add3');
        $('.dyn-add3').removeClass('dyn-add2');
        $(".dyn-add3").attr('onclick', this.clck_plr);
        $('.dyn-add3').css({'width': '50%', 'height': '100%', 'bottom': '0'});
        $('.dyn-add3').append('<a id="afterclickhalf" href="#" class="afterclick"><img src="https://www.mjunoon.tv/assets/images/close.png" style="width: 100%;"></img></a><img src="'+ad_half_screen+'" style="width: 100%;"></img><a class="ad_and_btn" target="_blank" href="'+ad_redirect+'"></a><a class="ad_ios_btn" target="_blank" href="https://itunes.apple.com/us/app/mjunoon-tv/id1107354784?mt=8"></a>');
        $('#afterclickhalf').on('click', function(){ classRef.checkafterclick()});
    }

    clicktomin = () => {
        $("#fullscreenbutton").click();
        $('#fullscreenbutton').removeClass('rmp-i-resize-small');
        $('#fullscreenbutton').addClass('rmp-i-resize-full');
        $('#fullsrc').hide();
    }

    clck_plr = () => {
        $("#rmpPlayerEmbed").click();
    }

    checkafterclick = () =>{
        ReactGA.event({ category: 'Samaa TV', action: 'Half Player Ad Click'+Constant.version });
        // console.log("half ad clicked")
        $("#rmpPlayerEmbed").click();
        $("#fullscreenbutton").hide();
        $(".rmp-outline").css({'display' : 'block'});
        $(".rmp-module").css({'display' : 'block'});
        $('.dyn-add').html('');
        $('.dyn-add3').html('');
        $('.dyn-add').css({'display' : 'none'});
        $('.dyn-add3').css({'display' : 'none'});
        $('.rmp-ad-container iframe').css({'width': '100%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
        $('.rmp-content').css({'width': '100%', 'height': '100%', '-webkit-transition': 'all .50s ease', '-moz-transition': 'all .50s ease', '-o-transition': 'all .50s ease', '-ms-transition': 'all .50s ease', 'transition':' all .50s ease'});
        $('.dyn-add3').addClass('dyn-add2');
        $('.dyn-add2').removeClass('dyn-add3');
        $('.dyn-add2').css({'width': '15%', 'height': '85%', 'top': '0'});
    }

    initializeToken = () =>{
        let slug = this.props.match.params.slug;
        slug = slug.split(".")[0];
        this.callGetChannelUrlApi(slug);
    }

    callGetChannelUrlApi = (slug) => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"embed-url",
            data: {slug: slug},
            // headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            console.log(response,"data url api")
            if(response.data.code === 200 && response.data.status === true){
                let customAdTagUrl = Constant.customAdTagUrl(response.data.data.add_tag_url, {placement_channel: response.data.data.channel_name, placement_program: "", placement_genre: response.data.data.genres, user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime })
                classRef.setState({
                    channelUrl: {
                        isLoaded: true,
                        url: response.data.data.live_stream_url,
                        backup_live_stream_url: response.data.data.backup_live_stream_url,
                        addTagUrl: customAdTagUrl,
                        ad_start_time: response.data.data.ad_start_time*1000,
                        ad_repeat_time: response.data.data.ad_repeat_time*1000,
                        ad_horizontal: response.data.data.ad_horizontal,
                        ad_vertical: response.data.data.ad_vertical,
                        ad_half_screen: response.data.data.ad_half_screen,
                        ad_redirect: response.data.data.ad_redirect,
                        l_shape_status: response.data.data.l_shape_status               
                    }

                });


                // ReactGA.initialize('UA-93668328-1');
                
            }
            classRef.initializePlayer();

        })

    };

    initializePlayer = () => {
        $("#rewind").change(function(){})
        // console.log("INITIALIZE PLAYER CALLED", this.state.channelUrl.addTagUrl);
        var classRef = this;
        var src = {
            hls: this.state.channelUrl.url
        };
        var backupSrc = {
            hls: this.state.backup_live_stream_url
        };
        // Then we set our player settings
        var settings = {
            licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
            src: src,
            backupSrc: backupSrc,
            autoHeightMode: true,
            pip: true,
            delayToFade: 3000,
            gaLabel: 'Live TV',
            logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
            logoWatermark: true,
            logoPosition: 'topleft',
            sharing: true,
            speed: 'Speed',
            isLive: true,
            googleCast: true,
            airplay: true,
            // isLiveDvr: (liveNode =='yes') ? true : false,
            hlsJSMaxBufferLength: 80,
            // debugLevel: 2,
            hlsJSLiveSyncDuration: 12,
            // contentDescription: ticker,
            // contentID: ticker_status,
            detectAutoplayCapabilities: true,
            autoplay: true,
            // mutedAutoplayOnMobile: false,
            // mutedAutoplayOnMacosSafari11Plus: false,
            srcChangeAutoplay: true,
            playsInline: true,
            skin: 's3',
            skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
            skinButtonColor: 'FFFFFF',
            skinAccentColor: 'FFA52D',
            ads: true,
            nav: true,
            adBlockerDetection: true,
            adEnablePreloading: true,
            //adBlockerDetectedPreventPlayback: true,
            adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
            quickRewind: 10,
            adTagUrl: this.state.channelUrl.addTagUrl,
            // adTagWaterfall: [backup_add_tag_url],
            adAutoAlign: false
        };




        // Reference to player container
        var elementID = 'rmpPlayerEmbed';
        // Object creation
        var rmp = window.initializeRMP(elementID);

        // console.log("RMP: "+rmp);
        // Player container which will receive API events
        var rmpContainer = document.getElementById(elementID);
        // console.log("RMP Container: "+rmpContainer);
        rmpContainer.addEventListener('ready', function() {
            // console.log('player ready');
            // Invoke API methods
            // console.log(rmp.getStreamType());
            window.initializeRewindJquery();
            // ReactGA.event({ category: 'Samaa TV', action: 'ready'+Constant.version });
        });
        rmpContainer.addEventListener('playing', function() {
            // console.log('player has received playing event');
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });
        rmpContainer.addEventListener('adimpression', function() {
            // console.log('Player Ad Impression has been served', classRef.state.channelUrl.addTagUrl);
            window.dataLayer.push({event: 'trackAdView', video_ad_view: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl)});
            window.firebaseEvent({video_ad_view: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl)});
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });
        rmpContainer.addEventListener('adclick', function() {
            // console.log('Player Ad Click has been served');
            window.dataLayer.push({event: 'trackAdClick', video_ad_click: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl)});
            window.firebaseEvent({video_ad_click: Constant.getAdTagUrlParams(classRef.state.channelUrl.addTagUrl)});
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });

        rmpContainer.addEventListener('error', function() {
            // console.log('fatal error detected');
            console.log(rmp.getErrorData());
            // ReactGA.event({ category: 'Samaa TV', action: 'error'+Constant.version });
        });
        rmpContainer.addEventListener('warning', function() {
            // console.log('non-fatal error detected');
            console.log(rmp.getErrorData());
            // ReactGA.event({ category: 'Samaa TV', action: 'warning'+Constant.version });
        });

        rmpContainer.addEventListener('destroycompleted', function() {
            // console.log('player has been destroyed');
            // remove player container from DOM
            var parentContainer = rmpContainer.parentNode;
            if (parentContainer) {
                try {
                    parentContainer.removeChild(rmpContainer);
                } catch (e) {
                    console.log(e);
                }
            }
        });
        // Player initialisation - only after API events are registered
        rmp.init(settings);

        window.RMP = rmp;                            
    }

    render(){
        
        return(
            
            <div id="rmpPlayerEmbed" style={{position: 'absolute',height: '100%  !important',width: '100%',padding: '0px',margin: '0 auto !important'}}>
                
            </div>
        );
    }
}
export default Player;