import axios from "axios";
import  Constant from "./Constant";
import LocalStorageService from "./LocalStorageService";

const MetaCreation = (function(){
    const createMeta = function(type,slug,metaData) {
        var metaKeywords = "";
        var metaTitle = "";
        var metaDescription = "";
        var metaImage = "";
        var schema = "";
        var amp = "";
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"metas",
            data: {type:type ,slug: slug},
        }).then(function (result) {            
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            console.log(response.data.data,'Umair meta')
            if(response.data.code === 200){
                // console.log();                        
                metaKeywords =  response.data.data.metas.keywords;
                metaTitle =  response.data.data.metas.title;
                metaDescription =  response.data.data.metas.description;
                metaImage =  response.data.data.metas.thumbnail;
                schema =  response.data.data.metas.schema;  
                amp =  response.data.data.metas.amp;  
                metaData({keywords : metaKeywords,title : metaTitle,description : metaDescription,thumbnail : metaImage,schema : schema,amp:amp})          
            }
        }).then(function (response) {
            // console.log(response);
        });
        
        return {keywords : metaKeywords,title : metaTitle,description : metaDescription,thumbnail : metaImage,schema : schema}
    }

return {
    createMeta : createMeta
 }
})();
export default MetaCreation;
