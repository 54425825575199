import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";

class UserAdvanceDetail extends React.Component{


    componentDidMount(){
        window.initializDOB();
        window.initializeDobPicker(this.onDatePick);

    }

    addInfo = () =>{
        $("#submitbtn").text('Processing...');
        LocalStorageService._serviceToken(this.initializeToken);
      }
      
    initializeToken = () => {

        let mobile = localStorage.getItem('mobile');
        let email = localStorage.getItem('email');
        let params = JSON.stringify({
            type : localStorage.getItem('source'),
            mobile: mobile,
            email: email,
            dob :  $("#year").val()+'-'+$("#month").val()+"-"+$("#day").val()
        });
        axios({
            method: 'POST',
            url: Constant.url+"sign-up-2",
            // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
            // data: { dataparams: LocalStorageService.encryptAes(params)}, // yaseen
            data: { 
                type : localStorage.getItem('source'),
                mobile: mobile,
                email: email,
                dob :  $("#year").val()+'-'+$("#month").val()+"-"+$("#day").val()
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                $("#skipVal").click(); 
                
                // return <Redirect  to='/basicdetail'/>;                                               
            }else{
                $("#submitbtn").text('NEXT');
            }
        }).then(function (response) {
            
        });
    }

    onDatePick = () => {
        var dateArray = $("#datepick").val().split("-");
        if($("#datepick").val()){
            $("#month").val(dateArray[1])
            $("#day").val(dateArray[2])
            $("#year").val(dateArray[0])
        }
    }

    onDateChange = () =>{
        $("#datepick").show()
        $("#datepick").focus();
        $(".dtp").removeClass('hidden');
        $("#datepick").hide();
    }

    render(){
        return(
           <div class="splash">
                <div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{display : 'block'}}>
                <header>
                  <div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV"/></Link>
                    <div class="right_hdr"> 
                      <div class="skip">
                      <p id="skipVal" onClick={this.props.nextVal}>SKIP</p>
                      </div> 
                  	</div>
                  </div>
                </header>	
                <div class="uk-modal-dialog popup_content personlize">
                	<div class="mdl">
                		<div class="mdl_inner"> 
                			<div class="uk-container uk-container-small">
                				<h1>Tell us about yourself</h1>
                				<p>Make your account more personal</p>
                				<div class="avatar">
                					<div class="profile_pic_box">
                						{/* <div class="update_image"><span uk-icon="plus"></span></div> */}
                						<div class="profile_img"><img src={(localStorage.getItem('picture_url')) ? localStorage.getItem('picture_url') : "/images/icons/ic_male@2x.png"} alt="User Img" /></div>
                					</div>
                					<div class="profile_name">{(localStorage.getItem('gender')) ? localStorage.getItem('gender').toUpperCase(): '' }</div>
                				</div>
                				<form>
                                    <input type="text" id="datepick" style={{display : 'none'}}/>
                					<div class="uk-margin">
                						<input class="uk-input" onClick={this.onDateChange} type="text" placeholder="Month" id="month"/>
                					</div>
                					<div class="uk-margin">
                						<input class="uk-input" onClick={this.onDateChange} type="text" placeholder="Day" id="day"/>
                					</div>
                					<div class="uk-margin">
                						<input class="uk-input" onClick={this.onDateChange} type="text" placeholder="Year" id="year"/>
                					</div>
                				</form>
                			</div>
                		</div>
                	</div>
                </div>
                {/* <!-- Footer Start --> */}
                <footer>
                	<div class="gf_txt  ">
                		<a href="#" id="submitbtn" onClick = {this.addInfo} class="gf_watch_btn">NEXT</a>
                	</div>
                </footer>
                {/* <!-- Footer End -->      */}
                </div>               
           </div>
        )
    }
}
export default withRouter(UserAdvanceDetail);