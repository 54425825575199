import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import NoInternetConnect from "../section/NoInternetConnect";

import Header from "../common/Header";
import Footer from "../common/Footer";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
class Faqs extends React.Component{
  constructor(){
    super();
    this.state = {
        metaKeywords: '',
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
        schema: ''                                               
    }
}
componentDidMount(){
  MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
}

callMetaData = (metaResponse) =>{
  
  this.setState({
      metaKeywords : metaResponse.keywords,
      metaTitle : metaResponse.title,
      metaDescription : metaResponse.description,
      metaImage : metaResponse.thumbnail,
      schema : metaResponse.schema
  });
    let classRef = this;
    setTimeout(function () {
        window.metaUpdated();
    }, 1000);
}
    render(){
        return(
            <div>
            <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
            <Header/>                        
            <div className="content_sec"> 
        {/* Random pages Start*/}
        <section className="randdom_header pad_left pad_right pad_tb">
          <h1>Frequently Asked Questions</h1>
          <p><strong>Help and customer support</strong></p>
          <p>Hello,</p>
          <p>We have created a list of FAQs to help you with your queries regarding mjunoon.</p>
          <p>If you don’t find your answer here, please feel free to write to us at <a href="mailto:info@mjunoon.tv">info@mjunoon.tv</a> and we will be glad to help you.</p>
        </section>
        <section className="randdom_body pad_left pad_right pad_tb">
          <p><strong>General enquiries</strong></p>
          <ul uk-accordion = "">
            <li className="uk-open"> <a className="uk-accordion-title" href="#">How does mjunoon work?</a>
              <div className="uk-accordion-content"> <p>Just log on to mjunoon.tv or download the app (available on iTunes and Google Play Store) and browse through your favorite TV channels and videos instantly. </p></div>
            </li>
            <li> <a className="uk-accordion-title" href="#">Is mjunoon available on all devices?</a>
              <div className="uk-accordion-content"> <p>Yes, mjunoon can be viewed on all devices including, mobile phones, tablets, PCs and Smart TVs across all operating systems</p> </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">How fast an internet connection do I need to watch Live TV?</a>
              <div className="uk-accordion-content"> <p>mjunoon works on all Internet connections above 100kbit/s. The platform’s adaptive streaming capabilities allow for uninterrupted streaming on even slower Internet speeds (This means that the quality of the video will vary depending on your internet connection). For an optimum viewing experience, we recommend a 3G/4G mobile access or a fixed line.</p> </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">How can I sign up on mjunoon.tv?</a>
              <div className="uk-accordion-content"><p> Click the  <a>JOIN FREE</a>  button on top right of your screen and sign up using your mobile number to discover a wide selection of Live TV Channels and videos. </p></div>
            </li>
            <li> <a className="uk-accordion-title" href="#">Can I create a list of my favorite channels?</a>
              <div className="uk-accordion-content"> <p>If you are a new user, you will need to sign up on mjunoon.tv by clicking <a>JOIN FREE</a>. After verification you will be directed to personalization window where you can choose the channels of your interest, shows that you want to watch and also select from specific topics according to which the content will be curated on your feed. </p>
                <p>If you are a returning user, you can add/remove channels from your list by clicking the <a>FAVORITE</a> button on each channel’s screen. You will receive a visual feedback for your action. </p>
              </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">How can I access the list of my favorite channels?</a>
              <div className="uk-accordion-content"> <p>On desktop, click <a>More</a> on the Main Menu that is on the left of your screen and from there you can check your favorite channels and programs.</p>
                <p>Whereas on the app or mobile site, you will find the Main menu on the bottom of your screen, follow the same navigation i.e.  More&gt;Favorite Channels.</p>
                <p>Alternatively, you can click the profile picture on top right of your screen and all your selections will appear in a menu that you can edit as well. </p>
              </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">What does the 7 Day Rerun / rewind / record clip program function do?</a>
              <div className="uk-accordion-content"> The 7-Day Re-run function is a feature that allows the users to view any content that was broadcasted in the past 7 days on any channel available on the platform, on demand. </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">How can I playback/rewind to an old program?</a>
              <div className="uk-accordion-content"> On the player screen, at top right; you will find a rewind button. Click the button and then specify the date and time to which you want to playback. Please note that the option is available on select channels.</div>
            </li>
            <li> <a className="uk-accordion-title" href="#">I am using Internet Explorer and I cannot open the website!</a>
              <div className="uk-accordion-content"> Unfortunately mjunoon.tv cannot run on Internet Explorer versions lower than 9. Please update your browser or choose an alternative one. </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">The App tells me that there are network problems, what can I do?</a>
              <div className="uk-accordion-content"> This error occurs when the service cannot detect a stable internet connection. Please check your connection and try again. </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">The streams are stuttering or breaking up, what can I do?</a>
              <div className="uk-accordion-content"> Please check your network connection if the streams are stuttering or breaking up. You can also try to select a different quality to stream the content to reduce the connectivity speed needed. </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">How can I mirror my device’s screen to Chrome Cast?</a>
              <div className="uk-accordion-content"> On Android devices (4.2.2. or higher), download the official Chromecast app, press the Menu button and then select “Cast Screen”.
                On a Windows PC, install the Chrome browser (if you don’t have it already) and add the Google Cast Extension in the Settings/Extensions page. This will add a Cast button in your browser, near the Settings button.
                For more information visit the Chromecast developer website. </div>
            </li>
            <li> <a className="uk-accordion-title" href="#">I am getting a 404 error page; how can I fix this?</a>
              <div className="uk-accordion-content"> Please try to use a different browser or do a "clear all browsing cache and history" on the existing one. For Windows devices, it is the combination ctrl+shift+del. </div>
            </li>
          </ul>
          <p>Have other questions? Message us on <a href="mailto:info@mjunoon.tv"> info@mjunoon.tv</a></p>
        </section>
        {/* Random pages End*/} 
      </div>
            <Footer/>    
            </div>
        );
     }
}
export default withRouter(Faqs);