import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import InfiniteScroll from 'react-infinite-scroll-component';


import MetaCreation from "../../utils/MetaCreation";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
  
class TestScroller extends React.Component{
  state = {
    breweries: [],
    pageNumber: 1,
    items: 15,
    hasMore: true
  };

  componentDidMount() {
      //initial request is sent
    this.fetchData();
  }


  fetchData = () =>{
    let classRef = this;
    axios({
        method: 'POST',
        url: "https://cdn2.mjunoon.tv:9191/v2/pagination",
        data: {user_id: 699981,slug: 'news-one-live',page: this.state.pageNumber,per_page: this.state.items}
    }).then(function (response) {
        if(response.data.code === 200 && response.data.status === true){
            classRef.setState({
                breweries: [...classRef.state.breweries, ...response.data.data.latestEpisode],
                pageNumber: classRef.state.pageNumber + 1
            });            
        }
        
        // classRef.initializePlayer();
    })
    
  }
      render() {
        return (
          <div>
            <Header/>
            <h1>demo: react-infinite-scroll-component</h1>
            <hr />
            <InfiniteScroll style={{overflow: 'none'}}
          dataLength={this.state.breweries.length} //This is important field to render the next data
          next={this.fetchData}
          hasMore={this.state.hasMore}
          loader={<center><h4 style={{color : 'white'}}>Loading...</h4></center>}
        >
          <section class="pad_left pad_right pad_tb prog_box_sec">
          <ul class="continue_watching_d">
          {this.state.breweries.map(brewery => (
        <li>
          <div class="prog_sldr_box"><a href="javascript:;" class="del_btn"><img src="/images/del.svg" alt="delete" uk-svg="" /> </a>
            <div class="prog_sldr_img"><a href="javascript:;"><img src={brewery.episode_thumbnail} alt="Aaj ka Tarka" />
       </a> </div>
            <div class="prog_sldr_text">
              <h4 class="homesectionone"><b><a class="anchor" href="javascript:;" title="Newsone Headlines 12PM  17September2019">GHAROLI-GHOOM CHARAKHRA | Tahseen Sakina and Baluch Twins | Season 5 | 2019</a></b></h4>
              <p><span>{brewery.episode_name}  </span> <span>57K views</span> </p>
            </div>
          </div>
        </li>
          ))}
          
          </ul>
          </section>
        </InfiniteScroll>
            <Footer/>
          </div>
        );
      }
}
export default withRouter(TestScroller);