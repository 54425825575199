import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";

class UserBasicDetail extends React.Component {
	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			uname: localStorage.getItem('name'),
			email: localStorage.getItem('email'),
			mobile: localStorage.getItem('mobile')
		}
	}
	readURL = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();

			reader.onload = function (e) {
				$('#blah').attr('src', e.target.result);
			}

			reader.readAsDataURL(input.files[0]);
		}
	}

	handleChange(event) {
		this.setState({ uname: event.target.uname });
		this.setState({ email: event.target.email });
		this.setState({ mobile: event.target.mobile });
	}
	handleSubmit(event) {
		event.preventDefault();
	}
	clickEditButton = () => {
		$("#file").click();
	}

	componentDidMount() {
		$("#file").change(function () {
			// readURL(this);
			if (this.files && this.files[0]) {
				var reader = new FileReader();

				reader.onload = function (e) {
					$('#blah').attr('src', e.target.result);
				}

				reader.readAsDataURL(this.files[0]);
			}
		});
	}
	addBasicInfo = () => {
		$("#submitbtn").text('Processing...');
		LocalStorageService._serviceToken(this.initializeToken);
	}

	initializeToken = () => {
		var formData = new FormData();
		var imagefile = document.querySelector('#file');
		// let classRef = this;


		let paramval = {
			firstname: $("#uname").val(),
			email: $("#email").val(),
			mobile: $("#mobile").val(),
			type: localStorage.getItem('source')
		}
		if (imagefile.files[0] === undefined) { } else {
			formData.append('image', imagefile.files[0]);
		};

		let params = JSON.stringify(paramval);
		// formData.append('dataparams', LocalStorageService.encryptAes(params));  // yaseen
		// formData.append('data', paramval);

		// axios.post(Constant.url + "sign-up-2", formData, {
		// 	headers: {
		// 		'Content-Type': 'multipart/form-data',
		// 		'Authorization': "bearer " + localStorage.getItem("access_token")
		// 	}
		// })
		
		axios({
            method: 'POST',            
            // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
            url: Constant.url+"sign-up-2",
            // data: { dataparams: LocalStorageService.encryptAes(params)},   //yaseen 
            data : { 
                firstname: $("#uname").val(),
				email: $("#email").val(),
				mobile: $("#mobile").val(),
				type: localStorage.getItem('source')
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
			let response = {};
			response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
			if (response.data.code === 200) {
				
				localStorage.removeItem('name');
				localStorage.removeItem('email');
				localStorage.removeItem('picture_url');
				localStorage.setItem('name', response.data.data.first_name)
				localStorage.setItem('email', response.data.data.email);
				localStorage.setItem('picture_url', response.data.data.picture_url);
				$("#skipVal").click();
			} else {
				$("#submitbtn").text('NEXT');
			}
		});
	}

	render() {
		return (
			<div class="splash">
				<div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{ display: 'block' }}>
					<header>
						<div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV" /></Link>
							<div class="right_hdr">
								<div class="skip">
									<p id="skipVal" onClick={this.props.nextVal}>SKIP</p>
								</div>
							</div>
						</div>
					</header>
					<div class="uk-modal-dialog popup_content personlize">
						<div class="mdl">
							<div class="mdl_inner">
								<div class="uk-container uk-container-small">
									<h1>Tell us about yourself</h1>
									<p>Make your account more personal</p>
									<div class="avatar">
										<div class="profile_pic_box">
											<div class="update_image" id='editimage' onClick={this.clickEditButton}><span uk-icon="plus"></span></div>
											<div class="profile_img"><img id="blah" src={(localStorage.getItem('picture_url')) ? localStorage.getItem('picture_url') : (localStorage.getItem('gender') === 'Female') ? "/images/icons/ic_female@2x.png" : "/images/icons/ic_male@2x.png"} alt="User Img" /></div>
										</div>
										<div class="profile_name">{(localStorage.getItem('gender')) ? localStorage.getItem('gender').toUpperCase() : ''}</div>
									</div>
									<form encType='multipart/form-data'>
										<input type='file' id="file" name="file" style={{ display: "none" }} />
										<div class="uk-margin">
											<input class="uk-input" value={this.state.uname} type="text" id='uname' placeholder="Name" onChange={this.handleChange} />
										</div>
										<div class="uk-margin">
											<input class="uk-input" value={this.state.email} type="text" id='email' placeholder="Email" onChange={this.handleChange} readOnly={(localStorage.getItem('source') === 'email') ? true : false} />
										</div>
										<div class="uk-margin">
											<input class="uk-input" value={this.state.mobile} type="text" id='mobile' placeholder="Mobile Number" onChange={this.handleChange} readOnly={(localStorage.getItem('source') === 'mobile') ? true : false} />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Footer Start --> */}
					<footer>
						<div class="gf_txt  ">
							<a href="#" id="submitbtn" onClick={this.addBasicInfo} class="gf_watch_btn">NEXT</a>
						</div>
					</footer>
					{/* <!-- Footer End -->      */}
				</div>
			</div>
		)
	}
}
export default withRouter(UserBasicDetail);