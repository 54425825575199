import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import {Redirect} from 'react-router';
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";

class Gender extends React.Component{
    constructor(){
        super();      
        this.state = {
            genderValue: 'Male'
        }
    }

    activePiture = (e) =>{
        
        if(e == 'maleImg'){
            $('#femaleImg').removeClass('select');
            $('#maleImg').addClass('select');
            this.setState({genderValue: "Male"});
        }else if(e == 'femaleImg'){
            $('#maleImg').removeClass('select');
            $('#femaleImg').addClass('select');
            this.setState({genderValue: "Female"});
        }
    }

    addGender = (gender) => {
        LocalStorageService._serviceToken((e) => this.initializeTokenGender(gender,e));  
    }

    initializeTokenGender = (gender) => {
        $("#submitbtn").text('Processing...');

        let classRef = this;
        let email = localStorage.getItem('email');
        let mobile = localStorage.getItem('mobile');
        let params = JSON.stringify({
            email: email,
            mobile: mobile,
            gender : gender,
            type : localStorage.getItem('source')
        });
        axios({
            method: 'POST',            
            // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
            url: Constant.url+"sign-up-2",
            // data: { dataparams: LocalStorageService.encryptAes(params)},   //yaseen 
            data : { 
                email: email,
                mobile: mobile,
                gender : gender,
                type : localStorage.getItem('source')
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                localStorage.removeItem('gender');
                localStorage.setItem('gender', response.data.data.gender);
                $("#skipVal").click();     
                                                       
            }
        }).then(function (response) {
            
        });
    }
    
    render(){
        return(
           <div class="splash">
                <div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{display : 'block'}}>
                <header>
                  <div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV"/></Link>
                    <div class="right_hdr"> 
                      <div class="skip">
                      <p id="skipVal" onClick={this.props.nextVal}>SKIP</p>
                      </div> 
                  	</div>
                  </div>
                </header>	
                <div class="uk-modal-dialog popup_content">
                	<div class="mdl">
                		<div class="mdl_inner"> 
                			<div class="uk-container">
                				<h1>Please choose your gender</h1>
                				<p>to create an experience just for you</p>
                				<div class="avatar">
                					<a class={localStorage.getItem('gender') === 'Male' ? "picture select" : 'picture'} id = 'maleImg' onClick={(e) => this.activePiture('maleImg',e)}>
                						<div class="profile_img"><img src="/images/icons/ic_male.png" alt="male" /></div>
                						<div class="text">Male</div>
                					</a>
                                    
                					<a class={localStorage.getItem('gender') === 'Female' ? "picture select" : 'picture'} id = 'femaleImg' onClick={(e) => this.activePiture('femaleImg',e)}>
                						<div class="profile_img"><img src="/images/icons/ic_female.png" alt="female" /></div>
                						<div class="text">Female</div>
                					</a>
                				</div>
                			</div>
                		</div>
                	</div>
                </div>
                {/* <!-- Footer Start --> */}
                <footer>
                	<div class="gf_txt  ">
                		<a id="submitbtn" class="gf_watch_btn" onClick={(e) => this.addGender(this.state.genderValue,e)}>NEXT</a>
                	</div>
                </footer>
                {/* <!-- Footer End -->      */}
                </div>               
           </div>
        )
    }
}
export default withRouter(Gender);