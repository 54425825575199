import React from 'react';
import {withRouter} from 'react-router-dom';
class Redirect extends React.Component{
    componentDidMount(){
        window.location.href = "/";
    }
    render(){
        return(<div></div>)
    }
}
export default withRouter(Redirect);