import React from 'react';
import {Link} from 'react-router-dom';
import Header from "../common/Header";
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class RecommendChannel extends React.Component{
    // handleChannelList = () => {
    //     let channels = "";
    //     channels = this.props.data.map((channel,index) => (
    //         <li key={index}> <Link className="channel_sldr_box" to={'/'+channel.channel_slug}>

    //         <div className="channel_sldr_img"><img src={channel.channel_logo} alt={channel.channel_name}/>

    //           <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
    //           <span className="live_span">{(channel.channel_status === 'live') ? channel.channel_status : ''}</span> </div>
    //         <div className="channel_sldr_txt">
    //           <h3 className="h3tag">{channel.channel_name}</h3>
    //         </div>

    //         </Link> </li>
    //     ));        
    //     return channels;
    // };
    handleEvent = () =>{
      if(!localStorage.getItem('user_id')){
        new Header().openLoginModal();
      }else{
        window.location.href = "/personalize";
      }      
    }
    
    render(){
        return (
            <div>
                <section className="pad_left pad_tb pad_right chnl_like">
                  <div className="uk-height-match" uk-grid=''>
                    <div className="uk-width-3-5@m">
                      <div className="mdl">
                        <div className="mdl_inner"> <a className="chnl_like_img"><ImageWebp webp="/images/channel_slide.webp?a=1" alt="Recommended Channel" src="/images/channel_slide.png?a=1"/></a> </div>
                      </div>
                    </div>
                    <div className="uk-width-2-5@m">
                      <div className="mdl">
                        <div className="mdl_inner">
                          <div className="chnl_like_txt">
                            <h4>Tell us which channels you like</h4>
                            <p>Let's create an experience just for you</p>
                            <a className="lets_do" onClick = {this.handleEvent}>Choose Now</a></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </section>
            </div>
        );
    }
}

export default RecommendChannel;