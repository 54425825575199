import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WC_Episode from "../section/WC_Episode";
import WC_Channel from "../section/WC_Channel";
import WC_Program from "../section/WC_Program";



class Result extends React.Component{
    constructor() {
        super();
        //search
        this.callSearchApi = this.callSearchApi.bind(this);

        this.state = {
            channels: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            programs: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            }
        }
    }
    componentDidMount(){
        this.callSearchApi();
    }
    
    callSearchApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let text = 'karachi';
        axios({
            method: 'POST',
            url: Constant.url+"search",
            data: {user_id: user_id,search:text}
        }).then(function (response) {
            if(response.data.code === 200){
                classRef.setState({
                    channels:{
                        isLoaded : true,
                        list: response.data.data.channels
                    },
                    programs:{
                        isLoaded : true,
                        list: response.data.data.programs
                    },episodes:{
                        isLoaded : true,
                        list: response.data.data.episodes
                    }
                })
            }
        }).then(function (response) {
            // console.log(response);
        });
    };
 
    
    render(){
        return(
           <div>
           <Header/> 
           <div class="search_box pad_left pad_right">
                <form class="">
                    <span class="search_icon" uk-search-icon></span>
                    <input class="" type="search" placeholder="Search mjunoon.tv..."></input>
                </form>
            </div>            
           <hr></hr>                         
           <div class="content_sec"> 
                {this.state.channels.isLoaded === true ?<WC_Channel name="Channels" data={this.state.channels.list}/>:''}
                <hr></hr>
                {this.state.programs.isLoaded === true ?<WC_Program name="Programs" data={this.state.programs.list}/>:''}                                
                <hr></hr>
                {this.state.episodes.isLoaded === true ?<WC_Episode name="Episodes" data={this.state.episodes.list}/>:''}
           </div>                     
           <Footer/>     
           </div>
        );
     }    
}

export default withRouter(Result);