import React from 'react';
import Header from '../common/Header';
import Lottie from 'lottie-react';
import animationData from '../../utils/logo.json';

const Loader = () => {
  return (
    <>
      <div className="uk-flex uk-flex-center uk-flex-middle uk-height-viewport ">
        {/* <div className="loadr_box"> */}
        {/* <video
          src="/img/loader_new.mp4"
          loop
          autoPlay
          muted
          style={{ position: "fixed", left: 0, width: "100%", height: "50%" }}
        /> */}

                <Lottie
                    animationData={animationData}
                    loop={true} // Set to true for looping
                    autoplay={true} // Set to true for autoplay
                    style={{ width: "100%", height: "160px" }}
                  />
        {/* <img src="/img/fianlloader.gif"    
        style={{ position: "fixed", width: "45%", height: "55%" }}
        /> */}

        {/* <div className="loader">
                        <div className="loader_inner">asasasas</div>
                        </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Loader;
