import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'
import './index.css';
import App from './App';
// import LocalStorageService from "./LocalStorageService";
import * as serviceWorker from './serviceWorker';

ReactDOM.hydrate(
    <BrowserRouter>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
