import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import { AdSlot, DFPManager } from 'react-dfp';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import MobileEpisode from '../section/MobileEpisode';
import Channel from '../section/Channel';
import MobileProgram from '../section/MobileProgram';
import $ from "jquery";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser from "react-html-parser";
import { isMobile } from "react-device-detect";
import FavChannelLivetv from "../section/FavChannelLivetv";
import RelatedVODFilter from "../section/RelatedVODFilter";
import RelatedMoviesSidebar from '../section/RelatedMoviesSidebar';
import RelatedMODFilter from '../section/RelatedMODFilter';
import Loader from '../section/loader';

class MoviesWatch extends React.Component {
    constructor() {
        super();
        
        this.handleEpisodeInfo = this.handleEpisodeInfo.bind(this);
        this.handleRelatedEpisodes = this.handleRelatedEpisodes.bind(this);
        this.handleRelatedProgram = this.handleRelatedProgram.bind(this);
        this.handleRelatedMoviesSidebar = this.handleRelatedMoviesSidebar.bind(this);
        this.handleRelatedChannel = this.handleRelatedChannel.bind(this);
        this.handleStaticVideoEnd = this.handleStaticVideoEnd.bind(this);
        // this.handleSkipClick = this.handleSkipClick.bind(this);
        // this.handleUpNext = this.handleUpNext.bind(this);
        this.callGetVodUrlApi = this.callGetVodUrlApi.bind();
        this.initializePlayer = this.initializePlayer.bind();
        this.state = {
            VodUrl: {
                isLoaded: false,
                addTagUrl: "",
                url: "",
                episodeThumbnail: ""
            },
            episodeDetail: {
                isLoaded: false,
                data: {}
            },
            isFav: false,
            topic: '',
            isWatchList: false,
            episodeId: '',
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '',
            upNextVideoSlug: '',
            isAds: false,
            screenOrientation: "portrait",
            channelType: {
                isLoaded: false,
                list: {},
            },
            playerRender: true,
            qualitClicked: true,
            showQty: true,
            isFree: 0,
            showStaticVideo: true,
            countdown: 5, // Initial countdown value
            isButtonDisabled: true,
        }
    }

    componentDidMount() {
        // video ad skip button count down
        this.startCountdown();

        window.addEventListener("orientationchange", this.setScreenOrientation);
        if (document.pictureInPictureElement) {

            document.exitPictureInPicture();
        }
        let slug = this.props.match.params.slug;
        // console.log("slug: ", slug);
        MetaCreation.createMeta('episode', slug, (e) => this.callMetaData(e));
        {/* Disable By yaseen on request of Aamir bhai */}
        // if(localStorage.getItem('package_status') == "true" || this.state.isFree == 1){
            LocalStorageService._serviceToken((e) => this.initializeToken(slug, e));
            DFPManager.load();
            this.channelTypeApi();
        // }
      
    }

    componentWillUnmount() {
        {/* Disable By yaseen on request of Aamir bhai */}
        // if(localStorage.getItem('package_status') == "true" || this.state.isFree == 1){
        if (document.pictureInPictureElement) {

            document.exitPictureInPicture();
        }
        if (window.RMP) {
            window.RMP.destroy();
        }
        // }


    }

    addFavourite = (programId) => {
        if (!localStorage.getItem('user_id')) {
            new Header().openLoginModal();
        }
        if (this.state.isFav) {
            let user_id = localStorage.getItem('user_id');
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "remove-favourite",
                data: { id: programId, user_id: localStorage.getItem('user_id'), type: 'program' },
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if (response.data.code === 200 && response.data.status === true) {
                    $(".favourite").removeClass('active');
                    classRef.setState({
                        isFav: false
                    });
                }

            })
        }
        else {
            let user_id = localStorage.getItem('user_id');
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "add-user-favourite-programs",
                data: { program_id: programId, user_id: user_id },
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if (response.data.code === 200 && response.data.status === true) {
                    classRef.setState({
                        isFav: true
                    });
                    $(".favourite").addClass('active');
                }

            })
        }
    }

    callMetaData = (metaResponse) => {

        this.setState({
            metaKeywords: metaResponse.keywords,
            metaTitle: metaResponse.title,
            metaDescription: metaResponse.description,
            metaImage: metaResponse.thumbnail,
            schema: metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }

    changeTabRefreshAds = (adId) => {

        DFPManager.reload(adId);
    }

    initializeToken = (slug) => {
        {/* Disable By yaseen on request of Aamir bhai */}
        // if(localStorage.getItem('package_status') == "true" || this.state.isFree == 1){
        this.callGetVodUrlApi(slug);
        this.callEpisodeDetailApi(slug);
        // }
    }

    addWatchList = (episodeId) => {
        if (!localStorage.getItem('user_id')) {
            new Header().openLoginModal();
        }
        let user_id = localStorage.getItem('user_id');
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "add-watch-list",
            data: { episodeId: episodeId, user_id: user_id, type: 'vod', topic: this.state.topic },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if (response.data.code === 200 && response.data.status === true) {
                classRef.setState({
                    isWatchList: true
                });
            }

        })
    }

    componentWillReceiveProps(nextProps) {
        {/* Disable By yaseen on request of Aamir bhai */}
        // if(localStorage.getItem('package_status') == "true" || this.state.isFree == 1){
        let classRef = this;
        if (document.pictureInPictureElement) {

            document.exitPictureInPicture();
        }

        DFPManager.reload();
        window.RMP.destroy();
        this.setState({ playerRender: false }, function () {
            classRef.setState({ playerRender: true })
        })
        let slug = nextProps.match.params.slug;
        this.setState({ VodUrl: { isLoaded: false }, episodeDetail: { isLoaded: false } })
        LocalStorageService._serviceToken((e) => this.initializeToken(slug, e));
        this.channelTypeApi();
        // }
    }

    channelTypeApi = () => {
        let classRef = this;
        axios({
            method: 'GET',
            // url: Constant.url + "channel-types",
            url: Constant.url + "movie-types",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                // console.log("movie types: ", response.data);
                classRef.setState({
                    channelType: {
                        isLoaded: true,
                        list: response.data.data.types
                    }
                })
            }
        }).then(function (response) {

        });
    };

    callEpisodeDetailApi = (slug) => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "episode-detail",
            data: { slug: slug, user_id: localStorage.getItem('user_id') },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200 && response.data.status === true) {
                // console.log("episode details: ", response.data.data);
                classRef.setState({
                    episodeDetail: {
                        isLoaded: true,
                        data: response.data.data
                    },
                    upNextVideoSlug: (response.data.data.upnext.length > 0) ? response.data.data.upnext[0].episode_slug : '',
                    topic: response.data.data.episode_details.episode_topic,
                    episodeId: response.data.data.episode_details.episode_id,
                    metaTitle: response.data.data.episode_details.episode_name + ' | mjunoon.tv',
                    isFav: (response.data.data.episode_details.program_fav) ? true : false,
                    isWatchList: (response.data.data.episode_details.episode_fav) ? true : false

                });
                classRef.initializePlayer();
                window.tagManagerEvent(response.data.data.episode_details.episode_name + " Episode", "Program Episode", response.data.data.episode_details.episode_name, { placement_channel: "", placement_program: response.data.data.episode_details.episode_name, placement_genre: response.data.data.episode_details.channel_type, user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) : "", user_interest: "", user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id: window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') : '', name: localStorage.getItem('name') ? localStorage.getItem('name') : '', number: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '', search_keyword: '', user_custom_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '', user_device_id: '' });
                window.firebaseEvent({
                    custom_ga_client_id: '',
                    email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
                    name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
                    number: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '',
                    search_keyword: '',
                    user_custom_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',
                    user_device_id: '',
                    placement_channel: '',
                    placement_program: response.data.data.episode_details.episode_name,
                    placement_genre: response.data.data.episode_details.channel_type,
                    user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "Unknown",
                    user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) : "",
                    user_interest: "",
                    user_type: localStorage.getItem('mobile') ? 'real' : 'temp',
                    user_behavior: Constant.currentEpocTime,

                });
                window.imgNotFound();
            }

        })
    };

    // handleEpisodeInfo = () => {
    //     return (
    //         <div className="info_tab_box">

    //             {/* <a className="hashtag" href="#">{(this.state.episodeDetail.isLoaded === true) ? '#'+this.state.episodeDetail.data.episode_details.episode_name : ''}</a>  */}
    //             <a href="#">
    //                 <h4 className="h1fordramadetail"><b>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_name : ''}</b></h4>
    //             </a>
    //             <div className="abt_content">
    //                 <ShowMoreText lines={2} more='Show more' less='Show less' anchorClass='' onClick={this.executeOnClick} expanded={false} >
    //                     {ReactHtmlParser(this.state.episodeDetail.data.episode_details.episode_desc)}
    //                 </ShowMoreText>
    //             </div>
    //             <div className="info_txt vod_txt"> <Link className="channel_sldr_box" to={(this.state.episodeDetail.isLoaded === true) ? "/program/" + this.state.episodeDetail.data.episode_details.pro_slug : ''}>
    //                 <div className="channel_sldr_img"><ImageWebp webp={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_thumbnail : ''} src={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_thumbnail_jpg : ''} alt={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_name : ''} /> </div>
    //             </Link>
    //                 <h1 className="h1fordramadetail"><b>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_name : ''}</b></h1>
    //                 <p className="info_category_txt">{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.channel_name : ''}</p>
    //                 <a className={(this.state.isFav) ? "favourite active" : "favourite"} onClick={(e) => this.addFavourite(this.state.episodeDetail.data.episode_details.pro_id, e)}>FAVOURITE <span uk-icon="heart"></span></a>
    //             </div>
    //         </div>
    //     )
    // };

    callGetVodUrlApi = (slug) => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "streaming-url",
            data: { slug: slug, is_rewind: "no", type: "episode", user_id: localStorage.getItem('user_id') },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if (response.data.code === 200 && response.data.status === true) {
                // console.log("vod category tags: ", response.data);
                let customAdTagUrl = Constant.customAdTagUrl(response.data.data.add_tag_url, { placement_channel: "", placement_program: response.data.data.channel_name, placement_genre: response.data.data.genres, user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) : "", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime })
                classRef.setState({
                    VodUrl: {
                        isLoaded: true,
                        url: response.data.data.live_stream_url,
                        addTagUrl: customAdTagUrl,
                        episodeThumbnail: response.data.data.episode_thumbnail_jpg
                    },
                    isFree: response.data.data.is_free
                }, function () {

                });
            } else if (response.data.code === 500 || response.data.code === 400) {
                window.location.href = "/";
            }

            // if(!classRef.state.showStaticVideo){
            //     classRef.initializePlayer();
            // }
            // classRef.initializePlayer();
        })
    };

    handleRelatedEpisodes = () => {
        return (this.state.episodeDetail.isLoaded === true) ? <MobileEpisode sliderKey={1} name={this.state.episodeDetail.data.episode_details.pro_name} data={this.state.episodeDetail.data.latestEpisode} /> : '';
    };

    handleRelatedProgram = () => {
        return (this.state.episodeDetail.isLoaded === true) ? <MobileProgram sliderKey={2} name="Related Movies" customSlug="movies" data={this.state.episodeDetail.data.programs} /> : '';
    }
    handleRelatedMoviesSidebar = () => {
        // console.log("episode details: ", this.state.episodeDetail.data)
        return (this.state.episodeDetail.isLoaded === true) ? <RelatedMoviesSidebar sliderKey={4} name="Related Movies" customSlug="movies" data={this.state.episodeDetail.data.programs} /> : '';
    }

    handleRelatedChannel = () => {        
        return (this.state.episodeDetail.isLoaded === true) ? <Channel sliderKey={3} ad_class='a' name="Related Channels" data={this.state.episodeDetail.data.channel} /> : '';
    }

    // handleUpNext = () => {
    //     return (this.state.episodeDetail.isLoaded === true) ? <NowUpnext sliderKey={4} name="" data={this.state.episodeDetail.data.upnext} /> : '';
    // }


    invokeQuality = () => {
        
        let classRef = this;
        // console.log(classRef.state)


        if (isMobile == false) {

            // rmp-overlay-levels-area
            $(".rmp-volume").hide();
            $('.rmp-i-close').hide()
            
            if (classRef.state.showQty) {
                classRef.setState({ showQty: false }, function () {

                    $(".rmp-overlay-wrapper").parent().removeClass('quality_overlay')
                    $(".rmp-overlay-wrapper").parent().addClass('overlay_dec_zindex')
                    $('.rmp-overlay-wrapper').css('display', 'none');
                    if ($(".rmp-abr-active").text() == "720p HD") {
                        $(".qty_btn").addClass('active')
                        // $(".rmp-overlay-wrapper").css('display' , 'none');
                    } else {
                        $(".qty_btn").removeClass('active')
                    }

                    // $('#rmpPlayer').removeClass('rmp-module-open-ui')
                })
            } else {
                classRef.setState({ showQty: true }, function () {

                    
                   
                    $(".rmp-q0").html('');
                    $('.rmp-overlay-wrapper').css('z-index', 1);
                    $(".rmp-q0").html('Auto (' + $(".rmp-abr-active").text() + ')')
                    if ($(".rmp-abr-active").text() == "720p HD" || $(".rmp-abr-active").text() == "1080p HD") {
                        $(".qty_btn").addClass('active')
                    } else {
                        $(".qty_btn").removeClass('active')

                    }
                    $(".rmp-overlay-wrapper").parent().removeClass('overlay_dec_zindex')
                    $(".rmp-overlay-wrapper").parent().addClass('quality_overlay')
                    $('.rmp-overlay-wrapper').css('display', 'block');
                    $('.rmp-quality').click();
                    // $(".rmp-cast").hide();
                    $(".rmp-pip").hide();
                    $('.rmp-control-bar').show();
                    $('.rmp-control-bar').removeClass('rmp-no-display');
                    $('.rmp-overlay-title').hide();
                    $('#rmpPlayer').removeClass('rmp-module-open-ui')
                    $('.rmp-module-overlay-play-pause').hide();
                    $('.rmp-module-overlay-close').attr('onclick')

                    // when a quality is selected overlay is automatically closed #Musfar
                    $('.rmp-overlay-level').on('click', function() {
                        $('.rmp-overlay-wrapper').fadeOut(500);
                    });

                    //active tick 
                    if ($('.rmp-overlay-level svg').length === 0) {
                        var tickSVG = $('<svg width="16"  stroke="#b066fe" stroke-width="2" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.29627 10.7281L3.56574 7.99756L2.84858 8.71471L6.29627 12.1624L13.1514 5.30734L12.4342 4.59019L6.29627 10.7281Z" fill="#b066fe"/></svg>');
                        $('.rmp-overlay-level').append(tickSVG);
                    }
                    
                })

            }

        } else {
            // $('.player_hd_bar').hide();
            $('.rmp-quality').click();
            // $('#vol_btn').hide();
        }

    };
    setScreenOrientation = () => {
        let classRef = this;
        if (window.matchMedia("(orientation: portrait)").matches) {

            this.setState({
                screenOrientation: "landscape",
            });
            //   this.pslMobile(1);
            this.closeBtn();
            $(".mob_overlaybox").slideUp();
            if ($(".land_qty_btn").length == 0) {
                $("#rmpPlayer").append('<button class="qty_btn land_qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/cog_setting.svg" /><span>Video Quality</span></button><button class="pip_btn land_pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button>')
                // $('#rmpPlayer').on('click', '.land_pip_btn', function (e) { $(".player_hd_bar").fadeOut(200); $(".rmp-pip").click() });
                $('#rmpPlayer').on('click', '.land_qty_btn', function (e) { classRef.invokeQuality() });
            }
            $('.rmp-pip').hide()
            $('.rmp-volume').hide()
            $('.rmp-quality').hide()
            

        }

        if (window.matchMedia("(orientation: landscape)").matches) {

            this.setState({
                screenOrientation: "portrait",
            });
            $(".land_qty_btn").remove();
            $(".land_pip_btn").remove();


            //   this.pslMobile(1);
            this.closeBtn();
            $(".mob_overlaybox").slideUp();
        }
    };
    volumeHandle = () => {
        $(".rmp-volume").click();
        if ($('#volume_image').hasClass('active')) {
            $('#volume_image').removeClass('active')
            $("#volume_image").attr("src", "/images/volume_off.svg");
        } else {
            $('#volume_image').addClass('active')
            $("#volume_image").attr("src", "/images/volume_up.svg");
        }
    }

    closeBtn = () => {


        $(".rmp-overlay-wrapper").css("display", "block");
        $('.player_hd_bar').show()
        $('#vol_btn').show()
        $("#rmpPlayer").click();
        $("#fullscreenbutton").hide();
        $(".rmp-outline").attr("style", "display: block !important");
        $(".rmp-module").attr("style", "display: block !important");
        // $(".rmp-pip").attr("style", "display: block !important");
        $(".dyn-add4").remove("");
        $(".rmp-ad-container iframe").css({
            width: "100%",
            height: "100%",
            "-webkit-transition": "all .50s ease",
            "-moz-transition": "all .50s ease",
            "-o-transition": "all .50s ease",
            "-ms-transition": "all .50s ease",
            transition: " all .50s ease",
        });
        $(".rmp-content").css({
            width: "100%",
            height: "100%",
            "-webkit-transition": "all .50s ease",
            "-moz-transition": "all .50s ease",
            "-o-transition": "all .50s ease",
            "-ms-transition": "all .50s ease",
            transition: " all .50s ease",
        });
    };

    initializePlayer = () => {
        var classRef = this;
        var src = {
            hls: this.state.VodUrl.url
        };
        // Then we set our player settings
        var settings = {
            licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
            src: src,
            pip: true,
            autoHeightMode: true,
            delayToFade: 3000,
            gaLabel: 'Live TV',
            // logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
            logoWatermark: true,
            logoPosition: 'topleft',
            sharing: true,
            speed: 'Speed',
            googleCast: true,
            airplay: true,
            //   contentDescription: ticker,
            //   contentID: ticker_status,
            detectAutoplayCapabilities: true,
            autoplay: false,
            isLive: false,
            adEnablePreloading: true,
            //mutedAutoplayOnMobile: false,
            //mutedAutoplayOnMacosSafari11Plus: false,
            srcChangeAutoplay: true,
            playsInline: true,
            skin: 's3',
            skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
            skinButtonColor: 'FFFFFF',
            skinAccentColor: 'FFA52D',
            ads: true,
            nav: true,
            adBlockerDetection: true,
            //adBlockerDetectedPreventPlayback: true,
            adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
            quickRewind: 10,
            quickForward: 10,
            adTagUrl: this.state.VodUrl.addTagUrl ,
            //   adTagWaterfall: [backup_add_tag_url], 
            adAutoAlign: false
        };
        // (localStorage.getItem('package_status') != "true") ? this.state.VodUrl.addTagUrl : '',
        {/* Disable By yaseen on request of Aamir bhai */}

        // Reference to player container
        var elementID = 'rmpPlayer';
        // Object creation
        var rmp = window.initializeRMP(elementID);
        window.RMP = rmp;
        console.log("ratio: ", rmp.getRatio());
           

        // Player container which will receive API events
        var rmpContainer = document.getElementById(elementID);

        rmpContainer.addEventListener('ready', function () {
            $(".rmp-pip").hide();
            $(".rmp-sharing").hide();
            $('.rmp-quality').hide();
            if (classRef.state.qualitClicked) {
                setInterval(function () {
                    $(".rmp-q0").html('');
                    $('.rmp-overlay-wrapper').css('z-index', 1);
                    $(".rmp-q0").html('Auto (' + $(".rmp-abr-active").text() + ')')
                    if ($(".rmp-abr-active").text() == "720p HD" || $(".rmp-abr-active").text() == "1080p HD") {
                        $(".qty_btn").addClass('active')
                    } else {
                        $(".qty_btn").removeClass('active')
                    }
                }, 500)
                classRef.setState({ qualitClicked: false })
            }
            // $(".rmp-control-bar").append('<div class="slash">/</div>');
            if (isMobile == false) {

                
                $('.player_hd_bar').fadeIn(100);
                $(document).mouseup(function (e) {
                    var container = $(".quality_overlay");
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        // classRef.setState({showQty:true},function(){  
                        $(".rmp-overlay-wrapper").parent().removeClass('quality_overlay')
                        $(".rmp-overlay-wrapper").parent().addClass('overlay_dec_zindex')
                        $('.rmp-overlay-wrapper').css('display', 'none');
                        if ($(".rmp-abr-active").text() == "720p HD") {
                            $(".qty_btn").addClass('active')
                        } else {
                            $(".qty_btn").removeClass('active')
                        }
                        // })
                    }

                });
            }
            if ($("#qty_btn").length == 0) {
                if (isMobile == false) {
                    
                    $(".rmp-control-bar").append('<button class="qty_btn" id="qty_btn" uk-tooltip="title: Video Quality; delay: 500; animation: uk-animation-fade;"><img src="/images/cog_setting.svg" uk-svg="" /><span>Video Quality</span></button><button class="pip_btn" id="pip_btn" uk-tooltip="title: Mini Player; delay: 500; animation: uk-animation-fade;"><img src="/images/player_pip.svg" uk-svg="" /><span>Mini Player</span></button><p class="watching_now" id="liveViews"><img alt="views" src="/images/player_eye.svg" uk-svg="" /> <span id="totalViews">234</span></p>')
                    
                    $('.rmp-control-bar').on('click', '.qty_btn', function (e) { classRef.invokeQuality() });
                    $('#rmpPlayer').on('click', '.player_backscreen', function (e) { window.history.back(); });
                    $('.rmp-control-bar').on('click', '.pip_btn', function (e) { $(".player_hd_bar").fadeOut(200); $(".rmp-pip").click() });
                    $('.stream_convert').on('click', '#audio_video', function (e) { classRef.handleAudioSwitch(); });
                }

            }
            if ($('#casttext').length == 0) {
                $('.rmp-cast').wrapInner('<span id="casttext">Cast</span>')
            }
            // Invoke API methods
            // if($(".PlayerVolumeStyle").length == 0){
            if (isMobile == true) {
                $("#rmpPlayer").append('<div id="vol_btn" class="rmp-control-bar PlayerVolumeStyle"><img id="volume_image" src="/images/volume_off.svg" alt="volume_off" uk-svg=""/></div>')
                $('#rmpPlayer').on('click', '#vol_btn', function (e) { classRef.volumeHandle() });
                $('.player_ul').on('click', '.qty_btn', function (e) { classRef.invokeQuality() });

                $('.rmp-module-overlay').on('click', '.rmp-module-overlay-close', function (e) { $(".player_hd_bar").fadeIn(500); });
                $('.stream_convert').on('change', '#audio_video', function (e) { classRef.handleAudioSwitch(); });
                // $('.player_ul').on('click', '.pip_btn', function (e) { $(".rmp-pip").click() });

            }
            if (isMobile == false) {
                $(".rmp-i-live").hide();
                $(".rmp-sharing").hide();
                $(".rmp-quality").hide();
                $(".rmp-pip").hide();
            }
        });
        rmpContainer.addEventListener('playing', function () {
            $(".rmp-volume").hide();
            $(".rmp-sharing").remove();
            $(".rmp-quality").hide();
            $(".rmp-pip").hide();

            document.onkeydown = checkKey;
            function checkKey(e) {

                e = e || window.event;
                
                let currentSeekTime = rmp.getCurrentTime();  
                let currentVolume = rmp.getVolume();  
                let pausedCheck = rmp.getPaused()
                if (e.keyCode == '38') {
                    e.preventDefault();
                    e.stopPropagation();
                    // down arrow key to set volume up
                    rmp.setVolume(currentVolume + 0.1);
                }
                else if (e.keyCode == '40') {
                    e.preventDefault();
                    e.stopPropagation();
                    // down arrow key to set volume down
                    // let currentVolume = rmp.getVolume();                  
                    rmp.setVolume(currentVolume - 0.1);
                }
                else if (e.keyCode == '37') {
                    e.preventDefault();
                    e.stopPropagation();
                    // right arrow key to move 10secs back
                    rmp.seekTo(currentSeekTime - 10000);
                }
                else if (e.keyCode == '39') {
                    e.preventDefault();
                    e.stopPropagation();
                    // right arrow key to move 10secs ahead
                    // let currentSeekTime = rmp.getCurrentTime();  
                    rmp.seekTo(currentSeekTime + 10000);

                }


                // if (e.keyCode == '32') {
                //     // spacebar pause and play
                //     e.preventDefault();
                //     e.stopPropagation();
                //     if(pausedCheck === true){
                //         rmp.play();
                //     }else{
                //         rmp.pause();
                //     }
                // }

            }

        });
        rmpContainer.addEventListener('error', function () {
            var errorData = rmp.getErrorData();

            if (errorData) {
                var data = errorData.event;
                if (data) {

                    $(".rmp-error-text").html('Stream is unable to connect to our servers. Check your internet connection <br/>(Error Code : 4033)')
                    if (data.response.code == 404) {
                        $(".rmp-error-text").html('The stream is currently unavailable. Please comeback later <br/>(Error Code : 4032)')
                    } else if (data.response.code == 0) {
                        $(".rmp-error-text").html('Stream is unable to connect to our servers. Check your internet connection <br/>(Error Code : 4033)')
                    } else {
                        $(".rmp-error-text").html('This stream may not be available in your country.<br/>(Error Code : 4031)')
                    }
                }
                                                               
            }
        });
        rmpContainer.addEventListener('warning', function () {
            $(".rmp-volume").hide();
            $(".rmp-sharing").remove();
            $(".rmp-quality").hide();
            $(".rmp-pip").hide();
        });
        rmpContainer.addEventListener('timeupdate', function () {
            if (isMobile == false) {

                $(".rmp-quick-unmute").remove();
            }
            $(".rmp-volume").hide();
            $(".rmp-sharing").remove();
            $(".rmp-quality").hide();
            $(".rmp-pip").hide();
        });
        rmpContainer.addEventListener('ended', function () {
            if (classRef.state.upNextVideoSlug !== "") {
                window.location.href = (classRef.state.episodeDetail.isLoaded === true) ? "/watch/" + classRef.state.upNextVideoSlug : "";
            }
        });

        rmpContainer.addEventListener('adimpression', function () {

            window.dataLayer.push({ event: 'trackAdView', video_ad_view: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl) });
            window.firebaseEvent({ video_ad_view: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl) });
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });
        rmpContainer.addEventListener('adclick', function () {

            window.dataLayer.push({ event: 'trackAdClick', video_ad_click: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl) });
            window.firebaseEvent({ video_ad_click: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl) });
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });

        rmpContainer.addEventListener('destroycompleted', function () {

            // remove player container from DOM
            var parentContainer = rmpContainer.parentNode;
            if (parentContainer) {
                try {
                    parentContainer.removeChild(rmpContainer);
                } catch (e) {

                }
            }
        });

        rmpContainer.addEventListener('enterfullscreen', function () {
            $(".rmp-control-bar").addClass("controls_txt");

            if (isMobile == false) {
                $(".rmp-volume").hide();
                $('.rmp-overlay-wrapper').css('display', 'none');
            }

            window.fullScreenPlayer();
            $(".rmp-i-live").hide();

            classRef.setState({ share: false })
        })

        rmpContainer.addEventListener('exitfullscreen', function () {
            $(".rmp-control-bar").removeClass("controls_txt");
            $("#custom_pip").remove();
            if (isMobile == false) {
                $(".rmp-volume").hide();
                $('.rmp-overlay-wrapper').css('display', 'none');
            }

            $(".rmp-pip").show();

            $("#related_channel").hide();
            window.exitFullScreenPlayer();
            $("#channel_btn").remove();
            $(".rmp-i-live").hide();
            $(".rmp-sharing").hide();
            $(".rmp-quality").hide();
            // $(".rmp-cast").hide();
            // $(".rmp-time-elapsed").hide();
            classRef.setState({ share: true })
        })
        // Player initialisation - only after API events are registered
        rmp.init(settings);
        $("#sharebutton").on("click", function () {
            if ($("#customshare").length == 0) {
                $("#rmpPlayer").append(
                    '<div id="customshare" class="customshare"><div class="customShareInner"><h3>Share</h3><span class="rmp-i rmp-i-close rmp-module-overlay-icons rmp-module-overlay-close" id="share_close" tabindex="0" role="button" aria-label="Close"></span><ul><li id="fb_share"><span class="rmp-i uk-icon-button fb" uk-icon="facebook" tabindex="0" role="button" aria-label="share to facebook"></span></li><li><a target="_blank" href="https://twitter.com/intent/tweet?url=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button twtX"><img src="/images/x-social-outline.svg" uk-svg="" /></span></a></li><li id="email_share"><span class="rmp-i uk-icon-button" uk-icon="mail" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button lin" uk-icon="linkedin" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://api.whatsapp.com/send?text=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button wa" uk-icon="whatsapp" tabindex="0" role="button" aria-label="Email"></span></a></li></ul><div class="rmp-sharing-link"><span id="copy_share_link" class="rmp-i rmp-i-link"></span><input class="rmp-sharing-input" aria-label="Link to video" id="rmp-sharing-link-rmpPlayer" type="text" value="' +
                    window.location.href +
                    '"></div></div></div>'
                );
            }
            rmp.pause();
            classRef.shareFunctions();
        });
        // copy to clipboard code
        $('#copy_share_link').on("click", function() {
            var textToCopy = $('#rmp-sharing-link-rmpPlayer').text();
            var tempTextarea = $('<textarea>');
            $('body').append(tempTextarea);
            tempTextarea.val(textToCopy).select();
            document.execCommand('copy');
            tempTextarea.remove();
          });

        $(".player_ul").on("click", '#sharebuttonmob', function () {
            if ($("#customshare").length == 0) {
                $("#rmpPlayer").append(
                    '<div id="customshare" class="customshare"><div class="customShareInner"><h3>Share</h3><span class="rmp-i rmp-i-close rmp-module-overlay-icons rmp-module-overlay-close" id="share_close" tabindex="0" role="button" aria-label="Close"></span><ul><li id="fb_share"><span class="rmp-i uk-icon-button fb" uk-icon="facebook" tabindex="0" role="button" aria-label="share to facebook"></span></li><li><a target="_blank" href="https://twitter.com/intent/tweet?url=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button twtX"><img src="/images/x-social-outline.svg" uk-svg="" /></span></a></li><li id="email_share"><span class="rmp-i uk-icon-button" uk-icon="mail" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button lin" uk-icon="linkedin" tabindex="0" role="button" aria-label="Email"></span></a></li><li><a target="_blank" href="https://api.whatsapp.com/send?text=' +
                    encodeURIComponent(window.location.href) +
                    '"><span class="rmp-i uk-icon-button wa" uk-icon="whatsapp" tabindex="0" role="button" aria-label="Email"></span></a></li></ul><div class="rmp-sharing-link"><span id="copy_share_link" class="rmp-i rmp-i-link"></span><input class="rmp-sharing-input" aria-label="Link to video" id="rmp-sharing-link-rmpPlayer" type="text" value="' +
                    window.location.href +
                    '"></div></div></div>'
                );
            }

            classRef.shareFunctions();
        });
        // on skip video button click play the video automatically
        $("#skipVideo").on("click", function(){            
            rmp.play();
            
        });
        // on ads time is up then the video player also be played upon close automatically
        var staticVideoPlayer = document.getElementById("staticVideoPlayer");
        staticVideoPlayer.addEventListener("ended", function(){
            rmp.play();
            // console.log("event fired");
        })
    }

    handleAudioSwitch = () => {
        let classRef = this;
        if (isMobile == false) {
            if ($("#audio_video").is(":checked") == true) {
                $("#audio_image").append('<img src="' + this.state.VodUrl.episodeThumbnail + '"/><div><img src="/images/player_audioicon.svg" uk-svg="" /><P><strong>Audio Stream</strong><span>playing in background<span></P></div>');
                $("#audio_image").addClass('playeraudio');
            } else {
                $("#audio_image").html('');
                $("#audio_image").removeClass('playeraudio');
            }
        } else
            if ($("#audio_video").is(":checked") == true) {
                $("#audio_image").append('<img src="' + this.state.VodUrl.episodeThumbnail + '"/><div><img src="/images/player_audioicon.svg" uk-svg="" /><P><strong>Audio Stream</strong><span>playing in background<span></P></div>');
                $("#audio_image").addClass('playeraudio');
            } else {
                $("#audio_image").html('');
                $("#audio_image").removeClass('playeraudio');
            }
        // if (isMobile == false) {
        //   if ($("#audio_video").is(":checked") == true) {        
        //       $("#rmpPlayer").addClass('playeraudio');
        //       $(".playeraudio:after").css("background-color", "red");
        //   } else {
        //       $("#rmpPlayer").removeClass('playeraudio');  

        //   }
        // }else
        //   if ($("#audio_video_mob").is(":checked") == true) {
        //     $("#rmpPlayer").addClass('playeraudio');
        //     $(".playeraudio:after").css("background-color", "red");
        //   } else {
        //     $("#rmpPlayer").removeClass('playeraudio');       
        //   }

    };

    goBack = () => {
        window.history.back();
    }


    handleEpisodeInfo = () => {
        return (
            <div className="info_tab_box">
                {/* <Link className="hashtag" to={"/search?search="+this.state.channelDetail.data.channel_detail.channel_name}>{'#'+this.state.channelDetail.data.channel_detail.channel_name}</Link> */}
                <div className="abt_content">
                    <div className="InfoTextDesk">
                        {/* <div className="hashtagbox">
                  <a className="hashtag" href={'search?search='}>#CNN</a> <a className="hashtag" href={void(0)}>#News</a>
                </div> */}
                        <div className="seprateheading">
                            <h1>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_name : ''}</h1>
                            <p><span>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_views + ' . ' + this.state.episodeDetail.data.episode_details.episode_date : ''} </span></p>
                        </div>
                    </div>
                    <div className="InfoTextMob">

                        <div className="seprateheading">
                            <p><span>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_views + ' . ' + this.state.episodeDetail.data.episode_details.episode_date : ''} </span></p>
                        </div>
                        {/* <div className="hashtagbox">
                  <a className="hashtag" href={void(0)}>#CNN</a> <a className="hashtag" href={void(0)}>#News</a>
                </div> */}
                    </div>

                    <ShowMoreText
                        lines={2}
                        more={<span click uk-icon='icon: chevron-down'></span>}
                        less={<span uk-icon='icon: chevron-up'></span>}
                        anchorClass=""
                        onClick={this.executeOnClick}
                        expanded={false}
                    >
                        {ReactHtmlParser(this.state.episodeDetail.data.episode_details.episode_desc)}
                    </ShowMoreText>
                </div>
                <div className="info_txt">
                    {/* <Link> */}
                    <div className="channel_sldr_box">
                        <ImageWebp webp={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_thumbnail : ''} src={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_thumbnail_jpg : ''} alt={(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_name : ''} />
                    </div>
                    {/* </Link> */}
                    <h2 className="h1fordramadetail">
                        <b>{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.pro_name : ''} </b>
                    </h2>
                    <p className="info_category_txt">
                        {(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.channel_name : ''}
                    </p>
                    {/* <a
                        className={
                            this.state.isFav
                                ? "favourite active"
                                : "favourite"
                        }
                        onClick={(e) =>
                            this.addFavourite(
                                this.addFavourite(this.state.episodeDetail.data.episode_details.pro_id, e),
                                e
                            )
                        }
                    >
                        FAVOURITE <span uk-icon="heart" />
                    </a> */}
                </div>
            </div>
        );
    };
    shareFunctions = () => {
        $("#fb_share").on("click", function () {
            $(".rmp-i-facebook").click();
        });
        $(".rmp-i-close").on("click", function () {
            $(".player_hd_bar").fadeIn(500);
            $("#customshare").remove();
        });
        $("#email_share").on("click", function () {
            $(".rmp-i-email").click();
        });
        $("#copy_share_link").on("click", function () {
            var share_link = window.location.href;
            navigator.clipboard.writeText(share_link)
        });
    };
    playerControls = () => {
        return (
            <div id="rmpPlayer" style={{display : this.state.showStaticVideo ? 'none' : 'block'}}>

                {(isMobile == false) ?
                    //desktop view player_hd_bar
                    <div className="player_hd_bar" style={{ display: 'none' }}>
                        <button className="player_backscreen" onClick={this.goBack}><img alt="Share" src="/images/player_back.svg" uk-svg="" /></button>
                        <img className='logo-videoPlayer' src='/images/logo.png' alt="mJunoon.tv" />
                        <ul className="player_ul">
                            {/* <li>
                                <a id="castbutton">
                                    <img alt="Share" src="/images/player_cast.svg" uk-svg="" />{" "}
                                    <span>Cast</span>
                                </a>
                            </li> */}
                            <li>
                                <a id="sharebutton">
                                    <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                                    <span>Share</span>
                                </a>
                            </li>
                            <li>
                                <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade;" for="audio_video">
                                    <input
                                        type="checkbox"
                                        id="audio_video"
                                    // onChange={this.handleAudioSwitch}
                                    />

                                    <div class="stream_convert_slider"></div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    :
                    //mobile view player_hd_bar
                    <div className="player_hd_bar rmp-control-bar">
                        <button className="player_backscreen" onClick={this.goBack}><img alt="Share" src="/images/player_back.svg" uk-svg="" /></button>

                        <p className="watching_now" id="liveViews"><img alt="views" src="/images/player_eye.svg" uk-svg="" /> {(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_views : ''}</p>

                        <ul className="player_ul">
                            {/* <li>
                                <a id="castbutton">
                                    <img alt="Share" src="/images/player_cast.svg" uk-svg="" />{" "}

                                </a>
                            </li>  */}
                            {/* {(this.state.screenOrientation == "portrait") ?
                                <li>
                                    <button class="pip_btn" id="pip_btn">
                                        <img src="/images/player_pip.svg" uk-svg="" />
                                    </button>
                                </li>
                                : ""} */}



                            {(this.state.screenOrientation == "portrait") ?
                                <li>
                                    <button class="qty_btn" id="qty_btn">
                                        <img src="/images/cog_setting.svg" />
                                    </button>
                                </li>
                                : ""}
                            {(this.state.screenOrientation == "landscape") ?
                                <li>
                                    <a id="sharebuttonmob">
                                        <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                                        <span>Share</span>
                                    </a>
                                </li>
                                : ""}
                            <li>

                                <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade;" for="audio_video">
                                    <input
                                        type="checkbox"
                                        id="audio_video"
                                    // onChange={this.handleAudioSwitch}
                                    />

                                    <div class="stream_convert_slider"></div>
                                </label>
                            </li>
                        </ul>
                    </div>
                }
                <div id="audio_image"></div>
            </div>
        )
    }

    checkiSignin = () => {
        // if (!localStorage.getItem("user_id")) {

        //     $("#modal_login").addClass('uk-flex uk-open');

        // } else {

            window.location = '/packages/';

        // }
        {/* Disable By yaseen on request of Aamir bhai */}
    }

    handleStaticVideoEnd = () => {
        let classRef = this;
        setTimeout(function(){
            classRef.setState({
                showStaticVideo: false
            });
            // rmp.play();
        }, 10000)
    }
    handleSkipClick = () => {
        this.setState({
            showStaticVideo: false
        });
    }
    startCountdown = () => {
        // Update the countdown every second
        this.countdownInterval = setInterval(() => {
          const { countdown } = this.state;
    
          if (countdown === 0) {
            // If countdown reaches 1, skip the video and reset the countdown
            this.handleSkipClick();
            this.resetCountdown();
          } else {
            // If countdown is greater than 1, decrement it
            this.setState((prevState) => ({
              countdown: prevState.countdown - 1,
            }));
            // console.log(this.state.countdown);
          }
        }, 2000);
      };
      resetCountdown = () => {
        // Reset the countdown to 3 and enable the button
        this.setState({
          countdown: 5,
          isButtonDisabled: false,
        });
      };
    

    render() {
        const { isButtonDisabled, countdown } = this.state;
        return (

            
            <div>
                {this.state.metaTitle !== '' ? <Meta schema={null} metaKeywords={this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription} metaImage={this.state.metaImage} /> : ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                
                {this.state.episodeDetail.isLoaded ? <>    
                <Header />
                {/* localStorage.getItem('package_status') == "true" ||  */}
                {/* {(this.state.isFree == 1) ? */}
                {/* Disable By yaseen on request of Aamir bhai */} 
                    <div uk-height-match="target: > .h_match">
                        <div className="content_sec live_sec h_match">
                            {/* Player Section Start  */}
                                    <section className="player_sec VodSec">
                                        {this.state.showStaticVideo && (
                                            <div className='staticVideoPlayer'>
                                                <video
                                                id="staticVideoPlayer"
                                                controls={false}
                                                muted={true}
                                                autoPlay={true}
                                                onEnded={this.handleStaticVideoEnd()}
                                                width="100%"
                                                height="500px"
                                                >
                                                {/* <source src="https://2050today.org/wp-content/uploads/2020/07/Video-Placeholder.mp4?_=1" type="video/mp4" /> */}
                                                    <source src="/videos/video_ad.mp4" type="video/mp4" />
                                                </video>
                                                <div className='buttonControls'>
                                                    <button className='skipVideo' id="skipVideo"  disabled={isButtonDisabled} onClick={()=>this.handleSkipClick()}>
                                                        Skip {countdown !== 0 && <span>({countdown})</span>}{' '} <span uk-icon="icon: chevron-right;"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {(this.state.playerRender) ? this.playerControls() : ''}

                                        <div className="player_btm_bar">

                                            {/* <p className="watching_now">{(this.state.episodeDetail.isLoaded === true) ? this.state.episodeDetail.data.episode_details.episode_views : ''} Views</p> */}
                                            <ul className="player_ul">
                                                {/* <li><a href="#"><img src={Constant.base_url+"/images/rewind.svg"} uk-svg="" /> Rewind</a></li> */}
                                                <li><a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} target="_blank"><img src="/images/share.svg" alte="share" uk-svg="" /> Share</a></li>
                                                <li><a onClick={(e) => this.addWatchList(this.state.episodeId, e)}><img src={"/images/watchlist.svg"} alt="watchlist" uk-svg="" /> {(this.state.isWatchList) ? 'Remove Watch List' : 'Add Watch List'}</a></li>

                                            </ul>

                                            {/* localStorage.getItem('package_status') != "true" &&  */}
                                            {/* Disable By yaseen on request of Aamir bhai */} 
                                            {!isMobile ?
                                                window.mobilecheck() === false ?
                                                    <div className="ad_container uk-margin-top">
                                                        <div className="desktop-ads desktop_ad">
                                                            <AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90-3" />
                                                        </div>
                                                    </div> :
                                                    <div className="ad_container">
                                                        <div className="desktop-ads desktop_ad">
                                                            <AdSlot slotId="test1" dfpNetworkId="205019744" sizes={[[320, 50]]} adUnit="MJUNOON.TV-320X50-3" />
                                                        </div>
                                                    </div>
                                                : ''}
                                        </div>
                                        {this.state.episodeDetail.isLoaded === true ? (
                                            this.handleEpisodeInfo()
                                        ) : this.state.episodeDetail.isLoaded === false ? (
                                            <div className="loader_box">
                                                <div className="loader">
                                                    <div className="loader_inner"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </section>
                            
                                 

                            {/* {this.state.episodeDetail.isLoaded ? this.handleUpNext() : ''} */}
                            {/* Player Section End */}
                            {/* Mobile sidebar STart */}
                            <div className="sidebar mobile_info_sec">
                                {/* localStorage.getItem('package_status') != "true" &&  */}
                                {/* Disable By yaseen on request of Aamir bhai */} 
                                {(!isMobile) ?
                                    <div className="adbox_sidebar">
                                        <div className="desktop-ads11">
                                            <AdSlot
                                                slotId="adsidebar-desktop-info"
                                                dfpNetworkId="205019744"
                                                sizes={[[300, 250]]}
                                                adUnit="MJUNOON.TV-300x250-3"
                                            />
                                        </div>
                                    </div>
                                    : ''}
                                {isMobile ?
                                    <FavChannelLivetv /> : ""}
                                {isMobile && this.state.channelType.isLoaded === true ? <RelatedVODFilter data={this.state.channelType.list} /> : ''}

                            </div>

                            {/* Mobile sidebar End */}
                            <hr />
                            {/* Related Program Start */}
                            {(this.state.episodeDetail.isLoaded === true && this.state.episodeDetail.data.programs.length > 0) ? this.handleRelatedProgram() : ''}
                            {/* Related Program End */}
                            <hr />
                        </div>
                        <div className="sidebar h_match" id="addExpandClass">
                            {/* localStorage.getItem('package_status') != "true" &&  */}
                            {/* Disable By yaseen on request of Aamir bhai */} 
                            {(!isMobile) ?
                                <div className="adbox_sidebar">
                                    <div className="desktop-ads11">
                                        <AdSlot
                                            slotId="adsidebar-desktop-info"
                                            dfpNetworkId="205019744"
                                            sizes={[[300, 250]]}
                                            adUnit="MJUNOON.TV-300x250-3"
                                        />
                                    </div>
                                </div>
                                : ''}
                            {/* <FavChannelLivetv /> */}
                            {this.state.channelType.isLoaded === true ? <RelatedMODFilter data={this.state.channelType.list} /> : ''} 
                            {/* Related Program Start */}
                            {/* {(this.state.episodeDetail.isLoaded === true && this.state.episodeDetail.data.programs.length > 0) ? this.handleRelatedMoviesSidebar() : ''} */}
                            {/* Related Program End */}


                        </div>

                        <Footer />

                    </div>
                        </> : <Loader/> }
                        
                    {/* :
                    <div className="content_sec">
                        <div uk-height-match="target:  .h_match">
                            <div class="h_match">
                                <section className="pad_tb pad_right pad_left bg_nosign trendingSubscribe">
                                    <div className="no_signin_inner">
                                        <div className="mdl">
                                            <div className="mdl_inner">
                                                <img src="/images/logo.png" alt="Mjunoon TV" />
                                                <h2>Unlimited Entertainment</h2>
                                                <h3>Awaits You</h3>
                                                <button onClick={(e) => this.checkiSignin()} className="continuewith butn phone_btn">Subscribe Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div> 
                }*/}
                    {/* Disable By y aseen on request of Aamir bhai */} 

            </div>
        );
    }
}
export default withRouter(MoviesWatch);