import React, { Component } from 'react'
import Footer from '../common/Footer'
// import url from 'socket.io-client/lib/url'
import axios from 'axios'
import { url } from '../../utils/Constant'
import LocalStorageService from "../../utils/LocalStorageService";
import { Helmet } from 'react-helmet'
import Header from '../common/Header';
import Meta from '../section/Meta';



export class psl9 extends Component {

    constructor(){
        super();
        this.state = {
            redirection : 'asdas',
            isloaded : false ,
            imgurl : ''
        }
    }

    componentDidMount(){
        this.callapi()
    }

    callapi = () => {
        axios.get(url +'psl-9')
        .then((res) => {
            
            let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(res.data.eData));      

            if(response.data.code === 200){
                this.setState({
                    isloaded : true , 
                    redirection : response.data.data[0].href,
                    imgurl : response.data.data[0].thumbnail
                })
            }
        }
            
        )
    }


  render() {
    return (
      <div>
        <Meta
          metaTitle="Pakistan Super League PSL 9 — PSL 2024"
          metaDescription="Stream PSL 9 live matches through the Mjunoon TV live 
          streaming application, available on TV, laptop, and mobile — PSL 2024."
        />
        <Header
          
        />
        <main>
          <div className="">
            <div className="pslposter uk-margin ">
              <article>
                <a href={this.state.redirection}>
                  <img src={this.state.imgurl} style={{ marginTop: "20px" }} />
                </a>
              </article>
            </div>

            <div className="">
              <div className="psltext uk-padding-large uk-text-left uk-light ">
                <h1>Pakistan Super League 2024</h1>
                <p>
                  Pakistan Super League (PSL 2024) is returning with its 9th
                  edition, promising an exciting t20 action tournament with
                  powerhouse performances, nail-biting competition, and an
                  overall sizzling and unbelievable experience.
                </p>
                <h2>Watch PSL9 Now!</h2>
                <p>
                  PSL 9 fans in Pakistan and worldwide can enjoy all the
                  high-octane PSL9 live streaming on Mjunoon TV Live Streaming.
                  Viewers can enjoy PSL live match on their TV, laptop, or
                  mobile using our application
                </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default psl9