import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
import ReactHtmlParser from "react-html-parser";
import {Layout} from "react-amp-components";
// import {AdSlot} from "react-dfp";

class Score extends React.Component{
    constructor(){
        super();
        // this.callApi = this.callApi.bind(this);
        // this.handleData = this.handleData.bind(this);
        this.state = {
            match : {
                isLoaded: false,
                msg: 'Loading...',
                title: '',
                heading: '',
                image: '',
                description: '',
                amp: ''
            },
            score : {
                isLoaded: false,
                list : []
            },
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '' ,
            amp : ''
        }
    }

    componentDidMount(){
        let classRef = this;
        let slug = this.props.match.params.slug;
        MetaCreation.createMeta('psl',window.location.href.substring(window.location.href.lastIndexOf('/') + 1),(e) => this.callMetaData(e));    
        LocalStorageService._serviceToken((e) => this.initializeToken(slug,e));
        // setInterval(function(){
            LocalStorageService._serviceToken((e) => classRef.callScoreApi(slug,e));  
        // },3000)        
    }

    // componentWillReceiveProps(nextProps){
    //     let slug = this.props.match.params.slug;    
    //     this.setState({score : { isLoaded : false}})
    //     LocalStorageService._serviceToken((e) => this.callScoreApi(slug,e));          
    // }

    initializeToken = (slug) =>{
        this.callApi(slug);
        this.callScoreApi(slug);
    }

    callApi = (slug) => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"psl-team-by-slug",
            data: {slug: slug},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if(response.data.code === 200){
                classRef.setState({
                    match:{
                        isLoaded : true,
                        title : response.data.data[0].match_title,
                        description : response.data.data[0].description,
                        image : response.data.data[0].main_image                        
                    },                                        
                })
            }            
        }).then(function () {
            
        });        
    };

    callScoreApi = (slug) => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"psl-team-data",
            data: {slug: slug},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            // console.log(response.data.data[0].data,"adasda");
            if(response.data.code === 200){
                classRef.setState({
                    score:{
                        isLoaded : true,
                        list :response.data.data[0].data,                                              
                    },                                        
                })
            }            
        }).then(function () {
            
        });        
    };

    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema,
            amp : metaResponse.amp,
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }

    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} amp = {this.state.amp} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                {/* <Meta amp ={<link rel="amphtml" href="http://localhost:3000/psl/psl-2020-quetta-gladiators-vs-lahore-qalandars-live-score-psl-5-match-16.html"></link>}/> */}
                <Header/>
                <div class="content_sec live_sec">
                    <section class="tending_player_sec uk-padding-remove-top">
                        <div class="psl_sec">
                            <div class="box_psl">
                                <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                    <ul class="uk-slider-items">
                                    <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                    </ul>
                                </div>
                                {/* <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>      */}
                                <div class="headng_box">
                                    <h2 class="h2forhome"><strong>{this.state.match.title}</strong></h2>
                                </div>
                                <div class="summary_sec">
                                    <img src={this.state.match.image} alt={this.state.match.title} />
                                    <p>{this.state.match.description}</p>                        
                                </div>
                                {(this.state.score.isLoaded) ? ReactHtmlParser(this.state.score.list) : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                            </div>
                        </div>
                        <div class="sidebar">
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(Score);