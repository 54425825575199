import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";
import axios from 'axios';
import $ from 'jquery';
import Header from "../common/Header";
class RelatedVODFilter extends React.Component{
    constructor() {
        super();        
        this.handleTypes = this.handleTypes.bind(this);
        this.handlechannelList = this.handlechannelList.bind(this);
        this.callApi = this.callApi.bind(this);        
        this.state = {            
            listDatas : {
                isLoaded: false,
                list: {},
            } 
        }
    }

    componentDidMount(){
        $("#relatedtab").addClass('active');
        var data= {search: "all",user_id:localStorage.getItem("user_id")}; 
        this.callApi(data)
    }

    componentWillReceiveProps(){
        // var data= {slug: this.props.slug};
        // this.callApi(data)

    }

    clickTypes = (type,selectorVal) =>{
        $("#filterrelated>ul>li>a.active").removeClass("active");        
        $("#"+selectorVal).addClass('active');
        this.setState({
            listDatas: {
              isLoaded: false              
            }
          })
        var data= {type: type,user_id:localStorage.getItem("user_id")};
        this.callApi(data)
    }

    clickAll = () =>{
        $("#filterrelated>ul>li>a.active").removeClass("active");
        $("#relatedtab").addClass('active');
        var data= {search: "all",user_id:localStorage.getItem("user_id")}; 
        this.callApi(data)
    }    

    handleTypes = () => {        
        let types = "";
        let classRef = this;
        types = this.props.data.map((type,index) => (
            <li><a id={"type_"+index} onClick={() => this.clickTypes(type.id,"type_"+index)}>{type.type}</a></li>
        ));                        
        return types;
    };

    callApi = (data) => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');        
        axios({
            method: 'POST',
            url: Constant.url+"get-releated-channels",
            data: data,
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                
                classRef.setState({
                    listDatas:{
                        isLoaded : true,
                        list: response.data.data.related_channels
                    }
                })
            }
        }).then(function (response) {
            
        });
      };
      
      addToFavourite = (heart_id, channelId) => {
        
        if (!localStorage.getItem('user_id')) {
          new Header().openLoginModal();
          return;
        }
        if ($("#" + heart_id).hasClass('active')) {
    
          $("#heart_" + channelId).removeClass('active')
          $("#" + heart_id).removeClass('active');
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "remove-favourite",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            data: { id: channelId, user_id: localStorage.getItem('user_id'),type: "channel" },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
    
            }
          })
        } else {
          $("#" + heart_id).addClass('active');
          $("#heart_" + channelId).addClass('active')
          let params = JSON.stringify({ channel_id: channelId, user_id: localStorage.getItem('user_id') });
          let classRef = this;
          axios({
            method: 'POST',
            url: Constant.url + "add-user-favourite-channels-2",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
            // data: { dataparams: LocalStorageService.encryptAes(params)},
            data: { channel_id: channelId, user_id: localStorage.getItem("user_id") },
          }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if (response.data.code === 200 && response.data.status === true) {
    
            }
          })
        }
      }    

    handlechannelList = () => {
        let channels = "";
        channels = this.state.listDatas.list.map((channel,index) => (
            <li>
            <div className="live_style live_style_full">
               <Link className="fav_sld_img" to={"/"+channel.channel_slug}>
                   <ImageWebp className="img_log" webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/> 
                   <ImageWebp webp={channel.channel_live_image} src={channel.channel_live_image_jpg} alt={channel.channel_name}/>
                   <h4>{channel.channel_name}</h4>
                   <span className="views_txt"><img alt="views" src="/images/player_eye.svg" uk-svg="" /> {channel.channel_views}</span>
               </Link>
                {/* {(channel.is_fav) ?
                        <span className="relatedHeart active" id={"heart_" + channel.channel_id} uk-icon="icon: heart" onClick={(e) => this.addToFavourite("heart_" + channel.channel_id, channel.channel_id, e)}></span>
                        : <span className="relatedHeart" uk-icon="icon: heart" id={"heart_" + channel.channel_id} onClick={(e) => this.addToFavourite("heart_" + channel.channel_id, channel.channel_id, e)}></span>
                } */}
           </div>
            </li>
        ));                
        // $('.edit_btn').click(function(){console.log('javed');});
        return channels;
    };


    render(){
        return (
            <div className="RelatedFilter">
                 <div id="filterrelated" className="RelatedFilterBtns" uk-slider="finite: true">
                    <ul class="uk-slider-items">
                        <li><a id="relatedtab" onClick={this.clickAll}>All</a></li>
                        {this.handleTypes()}
                    </ul>
                    <a className="RelatedFilterPrev" href="#" uk-slider-item="previous"><span uk-icon="icon: chevron-left"></span></a>
                    <a className="RelatedFilterNext" href="#" uk-slider-item="next"><span uk-icon="icon: chevron-right"></span></a>
                 </div>
                 <div className="RelatedFilterBox">
                     <ul>
                     {this.state.listDatas.isLoaded ? this.handlechannelList():<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                     </ul>
                 </div>
            </div>
        );
    }
}

export default RelatedVODFilter;