import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";
import Constant from "../../utils/Constant";
import Gender from '../section/Gender';
import UserBasicDetail from '../section/UserBasicDetail';
import UserAdvanceDetail from '../section/UserAdvanceDetail';
import PersonalizeChannel from '../section/PersonalizeChannel';
import PersonalizeProgram from '../section/PersonalizeProgram';
import PersonalizeTopics from '../section/PersonalizeTopics';
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
class Personalize extends React.Component{
    constructor(){
        super();      
        this.callApi = this.callApi.bind(this); 
        this.handlePage = this.handlePage.bind(this);
        this.handlerGender = this.handlerGender.bind(this);
        this.handlerbasicdetail = this.handlerbasicdetail.bind(this);
        this.handleradvancedetail = this.handleradvancedetail.bind(this);
        this.handlerfavchannel = this.handlerfavchannel.bind(this);
        this.handlerfavprogram = this.handlerfavprogram.bind(this);
        this.state = {
            listDatas: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            slug : 'gender',
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: '' 
        }
    }

    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callApi);
        MetaCreation.createMeta('static','personalize',(e) => this.callMetaData(e));
        // let slug = this.props.match.params.slug;
        this.handlePage();
    }
    componentWillReceiveProps(){
        this.handlePage();
    }
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        })
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }    
    handlerGender() {
        this.setState({slug: 'basicdetail'});
    }  
    handlerbasicdetail() {
        this.setState({slug: 'advancedetail'});
    }  
    handleradvancedetail() {
        this.setState({slug: 'favchannel'});
    }  
    handlerfavchannel() {
        this.setState({slug: 'favprogram'});
    }  
    handlerfavprogram() {
        this.setState({slug: 'favtopic'});
    }  
                       
    callApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"get-personalized-data",
            data: {user_id : user_id},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                
                classRef.setState({
                    listDatas:{
                        isLoaded : true,
                        list: response.data.data
                    }
                })
            }
        }).then(function (response) {
            
        });
      };
      handler(slug) {
        this.setState({
            slug: slug
        });
      }
      handlePage(){
          let slug = this.state.slug;
          
          if(slug === 'gender'){
            return <Gender nextVal = {this.handlerGender}/>
          }else if(slug === 'basicdetail'){
            return <UserBasicDetail nextVal = {this.handlerbasicdetail}/>
          }else if(slug === 'advancedetail'){
            return <UserAdvanceDetail nextVal = {this.handleradvancedetail}/>
          }else if(slug === 'favchannel'){
            return <PersonalizeChannel nextVal = {this.handlerfavchannel} data={this.state.listDatas.list.channels}/>
          }else if(slug === 'favprogram'){
            return <PersonalizeProgram nextVal = {this.handlerfavprogram} data={this.state.listDatas.list.programs}/>
          }else if(slug === 'favtopic'){
            return <PersonalizeTopics data={this.state.listDatas.list.topics}/>
          }
      }
      callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
          let classRef = this;
          setTimeout(function () {
              window.metaUpdated();
          }, 1000);
    }
    render(){
        return(
           <div>
               <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
              {this.state.listDatas.isLoaded === true ? this.handlePage() :  (this.state.listDatas.isLoaded === false) ? <div class="loader_box"><div class="loader"><div class="loader_inner"></div></div></div> : ''}
           </div>
        )
    }
}
export default withRouter(Personalize);