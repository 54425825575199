import React from 'react';

class ChannelShimmer extends React.Component {    

    render() {
        return (
            <div>
                <section className="pad_left pad_tb channel_box_sec CrouselShimmerCont">
                        <div className="headng_box">
                            <h2 className="h2forhome"><strong></strong></h2>
                            <div className="view_all"></div>
                        </div>
                        <div className="overflowscroll">
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                            <div className="channel_sldr_box">
                                <div className="channel_sldr_img"></div>
                                <div className="channel_sldr_txt"></div>
                            </div>
                        </div>
                    </section>
            </div>
        );
    }
}

export default ChannelShimmer;