import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from "jquery";
import Header from "../common/Header";

class NowUpnextLive extends React.Component {
    componentDidMount() {
        new window.Glider(document.querySelector("#NowUpnextSlider"), {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 255,
            itemWidth: 255,
            duration: 0.25,
            draggable: true,
            arrows: {
                prev: "#NowUpnextSlider_prev",
                next: "#NowUpnextSlider_next",
            },
            responsive: [
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: "auto",
                        slidesToScroll: "auto",
                        exactWidth: 255,
                        itemWidth: 255,
                        duration: 0.25,
                    },
                },
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: "auto",
                        slidesToScroll: "auto",
                        exactWidth: 255,
                        itemWidth: 255,
                        duration: 0.25,
                    },
                },
                {
                    breakpoint: 375,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 411,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                    },
                },
                {
                    breakpoint: 1201,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1281,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1401,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
                {
                    breakpoint: 1601,
                    settings: {
                        exactWidth: 255,
                        itemWidth: 255,
                        draggable: false,
                    },
                },
            ],
        });
    }

    addToFavourite = (heart_id, id) => {

        if (!localStorage.getItem('user_id')) {
            new Header().openLoginModal();
            return;
        }
        if ($("#" + heart_id).hasClass('active')) {
            $("#" + heart_id).removeClass('active');
            
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "remove-channel-guide-notifications",
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
                data: { channel_guide_id: id, user_id: localStorage.getItem('user_id') }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                
                if (response.data.code === 200 && response.data.status === true) {


                }
            })
        } else {
            $("#" + heart_id).addClass('active');            
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "add-channel-guide-notifications",
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
                data: { channel_guide_id: id, user_id: localStorage.getItem('user_id') }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                
                if (response.data.code === 200 && response.data.status === true) {

                }
            })
        }
    }

    handleChannelList = () => {
        let channels = "";
        let classRef = this;
        channels = this.props.data.map((channel, index) => (
            <div className="NowUpnextSlide">
                <div className="NowUpnextSliderInner">
                    <div className="NowUpnextSliderImg">
                        <img src="https://images.mjunoon.tv:8181/mjunoon_images_new/DvMqT3ieuan.webp" alt="Aaj ka Tarka" />
                    </div>
                    <div className="NowUpnextSliderTxt">
                        <h4>{channel.program_name}</h4>
                        <p>{channel.time}</p>
                        <span onClick={(e) => this.addToFavourite("heart_next_" + channel.guide_id, channel.guide_id, e)} id={"heart_next_" + channel.guide_id} className={(channel.notify) ? "NotifyBtn active":"NotifyBtn"}>                            
                                <img    className="notyfill" src="/images/notification_active.svg" uk-svg="" />
                                <img  className="notynill" src="/images/notification.svg" uk-svg="" />
                            {/* <img className="notynill" src="/images/notification.svg" uk-svg="" />
                            <img className="notyfill" src="/images/notification_active.svg" uk-svg="" /> */}
                        </span>
                    </div>
                </div>
            </div>
        ));
        return channels;
    }

    render() {
        return (
            <div>
                <section className="pad_left pad_tb prog_box_sec">
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong>{this.props.heading}</strong></h2>
                        {/* <Link className="view_all" to="">View All</Link> */}
                         </div>
                    <div className="slider_new_container drama_sldr_container">
                        <div id="NowUpnextSlider" className="NowUpnextSlider">
                            {/* Repeat box Start */}
                            {/* <div className="NowUpnextSlide">
                        <div className="NowUpnextSliderInner">
                            <div className="NowUpnextSliderImg">
                            <img src="https://images.mjunoon.tv:8181/mjunoon_images_new/DvMqT3ieuan.webp" alt="Aaj ka Tarka" />
                            </div>
                            <div className="NowUpnextSliderTxt">
                                <h4>Samaa Naya Din</h4>
                                <a className="watchingbtn"><img src="/images/watchnow.svg" uk-svg="" /> Watching</a>
                                <span className="hearticon active" uk-icon="icon: heart"></span>
                            </div>
                        </div>
                    </div> */}
                            {/* Repeat box End */}
                            {this.handleChannelList()}

                        </div>
                        <button role="button" aria-label="Previous" id="NowUpnextSlider_prev" className="glider-prev">
                            <img src="/images/left_arrow.png" alt="left arrow" />
                        </button>
                        <button role="button" aria-label="Next" id="NowUpnextSlider_next" className="glider-next">
                            <img src="/images/right_arrow.png" alt="right arrow" />
                        </button>
                    </div>
                </section>

            </div>
        );
    }
}

export default NowUpnextLive;