import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import ReactPlayer from 'react-player';

class TestPlayer extends React.Component{

    render(){
        return(
            <div>               
            <Header/>                                    
            <div class="content_sec"> 
                <section class="randdom_header pad_left pad_right pad_tb">
                <ReactPlayer width="50%" controls={true} height="50%" url="https://cdn5.mjunoon.tv:8087/hls/687/TCcrjXhVmKw/smil:mpfour.smil/playlist.m3u8"/>
                </section>                                      
            </div>
            <Footer/>   
            </div>
        );
     }
}
export default withRouter(TestPlayer);