const now = new Date();
const secondsSinceEpoch = Math.round(now.getTime());
const dateObj = new Date();
const currentDate = dateObj.getFullYear()+'-'+(dateObj.getMonth()+1)+'-'+dateObj.getDate();
const getAdTagUrlParams = function(url){
    let params = url.split('?iu=/');
    params = params[1] .split('&');
    params = params[0].split('/');
    return params[0]+","+params[1];
}
function getDateDiff(time1, time2) {
    var str1= time1.split('-');
    var str2= time2.split('-');

    //                yyyy   , mm       , dd
    var t1 = new Date(str1[0], str1[1]-1, str1[2]);
    var t2 = new Date(str2[0], str2[1]-1, str2[2]);

    var diffMS = t2 - t1;
    

    var diffS = diffMS / 1000;
    

    var diffM = diffS / 60;
    

    var diffH = diffM / 60;
    

    var diffD = diffH / 24;
    
    // alert(Math.trunc(diffD/365));
    return Math.trunc(diffD/365);
}
const setParamsUrl = function(param){
    if(!param){
        return "";
    }
    let params = param.replace(/ /g, "+");
    params = params.replace(/,/g, "%2C");
    params = params.replace(/(\r\n|\n|\r)/gm,"");
    
    return params;
}
const customAdTagUrl =  function(adTagUrl, userProperties){
    
     // adUrl = setParamsUrl(userProperties.user_behavior.toString());
    let adUrl = adTagUrl + "&cust_params=placement_channel%3D"+setParamsUrl(userProperties.placement_channel)+"%26placement_program%3D"+setParamsUrl(userProperties.placement_program)+"%26placement_genre%3D"+setParamsUrl(userProperties.placement_genre)+"%26user_gender%3D"+setParamsUrl(userProperties.user_gender)+"%26user_age%3D"+setParamsUrl(userProperties.user_age.toString())+"%26user_interest%3D"+setParamsUrl(userProperties.user_interest)+"%26user_type%3D"+setParamsUrl(userProperties.user_type)+"%26user_behavior%3D"+setParamsUrl(userProperties.user_behavior.toString());
    
    return adUrl;
}
const shouldPipDisplayed = function(nextURI){
    if(nextURI.indexOf('search') !== -1 ){
        return true;
    }else{
        return false;
    }
}



module.exports = {
    'urll': 'http://cdn2.mjunoon.tv:9192/',
    'url': 'https://cdn2.mjunoon.tv:9191/v2/',
    // 'urll': 'http://staging.mjunoon.tv:9192/',
    // 'url': 'https://staging.mjunoon.tv:9191/v2/',
    'base_url':"http://localhost:3000",
    'version': 'beta',
    'socket_id': '',
    'apipostfix': '2',
    'currentEpocTime': secondsSinceEpoch,
    'currentDate': currentDate,
    'getAdTagUrlParams': (url) => getAdTagUrlParams(url),
    'getDateDiff': (date, currentDate) => getDateDiff(date, currentDate),
    'customAdTagUrl': (url, userProperties) => customAdTagUrl(url, userProperties),
    'shouldPipDisplayed': (nextURI) => shouldPipDisplayed(nextURI)
};