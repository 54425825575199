import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from "jquery";
import Header from "../common/Header";
var on_signal_id="";
class TvGuideUpnext extends React.Component {
  constructor() {
    super();
    this.handleEpisodeList = this.handleEpisodeList.bind(this);
    this.state = {
      on_signal_id : ""
    }
  }  

  componentDidMount(){
    this.rengerGlider();    
  }

  async loadOneSignal(loadApi){
    
    on_signal_id = await window.OneSignal.getUserId();      
    loadApi();
  }

  rengerGlider = () => {
    new window.Glider(document.querySelector("#upNextSlider"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 90,
      itemWidth: 90,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#upNextSlider_prevUpNext",
        next: "#upNextSlider_nextUpNext",
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 264,
            itemWidth: 264,
            duration: 0.25,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 304,
            itemWidth: 304,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 318,
            itemWidth: 318,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 357,
            itemWidth: 357,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 348,
            itemWidth: 348,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 274,
            itemWidth: 274,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 295,
            itemWidth: 295,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 354,
            itemWidth: 354,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 346,
            itemWidth: 346,
            draggable: false,
          },
        },
      ],
    });
    // $('#guidenext').show();
  }


  checkiSignin = () => {
    // if(!localStorage.getItem("user_id")){

    // $("#modal_login").addClass('uk-flex uk-open');

    // }else{

      window.location = '/packages/';   
      
    // }
    {/* Disable By yaseen on request of Aamir bhai */}
    }

   
    


    


  handleEpisodeList = () => {
    let episodes = "";
    episodes = this.props.data.map((episode, index) => (
      // <div key={index}>
      <div className="tvguideLiveSliderInner" key={index}>
        <div className="tvguideLiveSliderBox">
          <div className="tvguideLiveSliderimg">
            {
              episode.thumbnail ? <img src={episode.thumbnail} alt={episode.program_name} onError="this.onerror=null;this.src='/images/bg_video.png';" /> : <img src={"/images/bg_video.png"} alt={episode.program_name} />
            }
            

            {/* <img src={episode.thumbnail} alt={episode.program_name} /> */}
            <span className="viewerstvg">{episode.views + ' Views'}</span>
            <div className="tvguideLiveSliderHover"  onClick={(e) => this.callAddToFavourite("heart_next_" + episode.guide_id, episode.guide_id,episode.program_name,episode.slug,episode.thumbnail,episode.start_time, e)}>
              <div id={"divguide"+episode.guide_id} className={(episode.notify) ?'notify_active' : ''}>
                <img src="images/watchnow.svg" uk-svg="" />
                {(episode.notify) ?
                <span id={"notifiable_"+episode.guide_id}>{'You will be notified when '+episode.program_name+' is Live'}</span>
                :<span id={"notifiable_"+episode.guide_id}>Notify me</span>}
              </div>
            </div>
{/* 
            {(localStorage.getItem("package_status") == "false")?
            <div className="tvguideLiveSliderHover" >
              <div>
                

                <button onClick={(e) => this.checkiSignin()} className="SliderSubcribe">Subscribe Now</button>

           
                
              </div>
            </div>:""} */}

            


          </div>
          <div className="tvguideLiveSliderTxt">
            {/* <Link to="/tv-guide"> */}
              <img
                src={episode.channel_banner}
                alt={episode.program_name}
              />
            {/* </Link> */}
            {(episode.notify) ?
              <i  onClick={(e) => this.callAddToFavourite("heart_next_" + episode.guide_id, episode.guide_id,episode.program_name,episode.slug,episode.thumbnail,episode.start_time, e)}><img id={"heart_next_" + episode.guide_id} className="hearticon active" src="images/slider/notifications_icon.svg" uk-svg=""  /></i>
              : <i  onClick={(e) => this.callAddToFavourite("heart_next_" + episode.guide_id, episode.guide_id,episode.program_name,episode.slug,episode.thumbnail,episode.start_time, e)}><img id={"heart_next_" + episode.guide_id} className="hearticon"  src="images/slider/notifications_icon.svg" uk-svg="" /></i>}
            {/* <Link to="/tv-guide"> */}
              <h4>{episode.program_name}</h4> 
            {/* </Link> */}
            <span className="views_txt">{episode.time}</span>

          </div>
        </div>
      </div>
      // </div>         
    ));
    let classRef = this;
    // setTimeout(function () { 
      // classRef.rengerGlider()                   
    // }, 1000)
    return episodes;
  };

  callAddToFavourite = (heart_id, id,program_name,redirect_url,image,start_time) => {
    
    let classRef = this;
    if (!localStorage.getItem('user_id')) {
      new Header().openLoginModal();
      return;
    }
    try {
      window.OneSignal.push(function() {
        window.OneSignal.isPushNotificationsEnabled(function(isEnabled) {
          if (isEnabled){
            
              classRef.loadOneSignal(function(){
                classRef.setState({
                  on_signal_id:on_signal_id
                },function(){
                  classRef.addToFavourite(heart_id, id,program_name,redirect_url,image,start_time)
                })
              });                           
          }else{
            
            window.OneSignal.showNativePrompt()  
            return;        
          }
        });
      },         
      );          
      } catch (error) {      
      }  
  }

  addToFavourite = (heart_id, id,program_name,redirect_url,image,start_time) => {
    if ($("#" + heart_id).hasClass('active')) {
      $("#" + heart_id).removeClass('active');
      $("#notifiable_" + id).html('Notify me');
      let classRef = this;
      axios({
        method: 'POST',
        url: Constant.url + "remove-channel-guide-notifications",
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
        data: { channel_guide_id: id, user_id: localStorage.getItem('user_id') }
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
        
        if (response.data.code === 200 && response.data.status === true) {


        }
      })
    } else {
      $("#" + heart_id).addClass('active');
      $("#notifiable_" + id).html('You will be notified when '+program_name+' is Live');
      $("#divguide" + id).addClass('notify_active');      
      let classRef = this;
      axios({
        method: 'POST',
        url: Constant.url + "add-channel-guide-notifications",
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
        data: { channel_guide_id: id, user_id: localStorage.getItem('user_id'),program_name:program_name,redirect_url:redirect_url,image:image,starts_at:start_time,on_signal_id:classRef.state.on_signal_id}        
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));        
        if (response.data.code === 200 && response.data.status === true) {

        }
      })
    }
  }
  
  render() {
    return (
      <div>
        {
          // console.log("epidodes: UpNext ", this.props.data)
        }
        {/* {(this.state.guide.isLoaded)? */}
        <section id="guidenext" className={this.props.isliveContent ? "pad_left pad_tb tvguideLiveSec bgchange" :"pad_left pad_tb tvguideLiveSec"}>
          <div className="headng_box">
            <h2 className="h2forhome">
              <strong>Up Next</strong>
            </h2>
            <Link to="#" className="view_all">View All</Link>
            <Link to="/tv-guide" className="view_all">TV Guide <img src="images/trending.svg" uk-svg="" /></Link>
          </div>

          <div className="slider_new_container drama_sldr_container">
            <div id="upNextSlider" className="tvguideLiveSlider" >              
              {this.handleEpisodeList()}              
            </div>
            <button
              role="button"
              aria-label="Previous"
              id="upNextSlider_prevUpNext" className="glider-prev">
              <img src="/images/left_arrow.png" alt="left arrow" />
            </button>
            <button
              role="button"
              aria-label="Next"
              id="upNextSlider_nextUpNext"
              className="glider-next"
            >
              <img src="/images/right_arrow.png" alt="right arrow" />
            </button>
          </div>
        </section>
        {/* :''} */}
      </div>
    );
  }
}

export default TvGuideUpnext;