import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
class FavChannelLivetv extends React.Component {
  constructor() {
    super();    
    this.handlechannelList = this.handlechannelList.bind(this);
    this.callApi = this.callApi.bind(this);
    this.state = {
      listDatas: {
        isLoaded: false,
        list: {},
      }
    }
  }
  componentDidMount() {
    this.callApi()

  }
  

  callApi = () => {
    let classRef = this;
    let user_id = localStorage.getItem('user_id');

    axios({
      method: 'POST',
      url: Constant.url + "favourite-channels",
      data: { user_id: user_id },
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
      if (response.data.code === 200) {        
        classRef.setState({
          listDatas: {
            isLoaded: true,
            list: response.data.data.channel
          }
        })
        classRef.callGlider()
      }
    }).then(function (response) {

    });

  };

  handlechannelList = () => {
    let channels = "";
    channels =  this.state.listDatas.list.map((channel, index) => (
      <div>
      <Link to={'/'+channel.channel_slug} className="channel_sldr_box">
        <div className="channel_sldr_img">
        <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>           
          <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
          <span className="live_span">live</span>
        </div>
      </Link>
    </div> 
    ));
    // $('.edit_btn').click(function(){console.log('javed');});
    return channels;
  };
  callGlider = () => {
    new window.Glider(document.querySelector('#FavChanelSlider'), {
      slidesToShow: 'auto',
      slidesToScroll: 'auto',
      exactWidth: 70,
      itemWidth: 70,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: '#FavChanelSlider_prev',
        next: '#FavChanelSlider_next',
      },
      responsive: [
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 85,
            itemWidth: 85,
            duration: 0.25,
          }
        }, {
          breakpoint: 375,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
          }
        }, {
          breakpoint: 411,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
          }
        }, {
          breakpoint: 768,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
          }
        }, {
          breakpoint: 1024,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
          }
        }, {
          breakpoint: 1201,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
            draggable: false,
          }
        }, {
          breakpoint: 1281,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
            draggable: false,
          }
        }, {
          breakpoint: 1401,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
            draggable: false,
          }
        }, {
          breakpoint: 1601,
          settings: {
            exactWidth: 85,
            itemWidth: 85,
            draggable: false,
          }
        }
      ]
    })
  }
  render() {
    return (
      (this.state.listDatas.isLoaded)?
      <div className="sectionBoxLive">
        <div class="headng_box"><h2 class="h2forhome"><strong>Favorite Channels</strong></h2></div>

        <div className="slider_new_container circle_center">
          <div id="FavChanelSlider" className="FavChanelSlider">
           {(this.state.listDatas.isLoaded) ? this.handlechannelList():<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
          </div>
          <button role="button" aria-label="Previous" id={"FavChanelSlider_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow" /></button>
          <button role="button" aria-label="Next" id={"FavChanelSlider_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrow" /></button>
        </div>
      </div>
      :''
    );
  }
}

export default FavChannelLivetv;