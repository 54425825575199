import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import $ from 'jquery';
class WC_Program extends React.Component {

  componentDidMount() {
    let classRef = this;
    setTimeout(function () {
      classRef.renderHover();
    }, 2000)
  }

  renderHover = () => {
    let classRef = this;
    $.each(classRef.props.data, function (i) { //Loop the array
      $('.HoverNModelImgVid').on('click', '#HoverNModel_vidVolume' + '_' + i, function (e) {
        if ($('#HoverNModel_vidVolume' + '_' + i).hasClass('vidVolumemute')) {
          $('#HoverNModel_vidVolume' + '_' + i).removeClass('vidVolumemute');
          $("#video_url_" + i)[0].muted = true;
        } else {
          $('#HoverNModel_vidVolume' + '_' + i).addClass('vidVolumemute');
          $("#video_url_" + i)[0].muted = false;
        }
      });
      $("#glider_slider_pro" + '_' + i).hover(
        function () {
          
          $("#glider_slider_pro" + '_' + i).addClass('HoverClass');
          $('#glider_slider_pro' + classRef.props.sliderKey).css("overflow", "inherit");
          // if (classRef.props.data[i].episode_video_url != "") {
          //   // {"video_url_"+ '_' + index}
          //   $("#video_url_" + i + " source").attr('src', classRef.props.data[i].episode_video_url);
          //   $("#video_url_" + i)[0].load();

          //   $("#video_url_" + i)[0].play();
          //   $("#video_url_" + i)[0].muted = true;
          //   $("#video_url_" + i)[0].onloadeddata = function () {
          //     $('#HoverNModel_vidVolume' + '_' + i).show()
          //     $('#img_op_' + i).css('opacity', 0);
          //   };
          // }
          // $("#video_url_"+ '_' + i)[0].onended = function() { 
          // $('#img_op_' + i).css('opacity', 0);                               
          // };          
        }, function () {
          
          // $("#video_url_"+ '_' + i)[0].pause();
          if ($(".HoverClass").length > 0) {
            $("#glider_slider_pro" + '_' + i).removeClass('HoverClass');
            $('#glider_slider_pro' + classRef.props.sliderKey).css("overflow", "");
            $('#img_op_' + i).css('opacity', 1);
            if (classRef.props.data[i].episode_video_url != "") {
              $("#video_url_" + i)[0].pause();
              $("#video_url_" + i)[0].muted = true;
            }
          }
        }
      )
    })
  }

  handleProgramList = () => {
    let programs = "";
    programs = this.props.data.map((program, index) => (
      <div key={index} id={"glider_slider_pro" + '_' + index} className="prog_list_cont_inner uk-position-relative">
        <Link class="dramas_box channel_sldr_box" to={"/program/" + program.program_slug}>
          <div class="shows_slider_img channel_sldr_img"><ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} /></div>
          <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
          {/* <div class="shows_slider_txt">
            <h4>{program.program_name}</h4>
          </div> */}
        </Link>
        {/* Overlay Box Start */}
        {/* <div className="HoverNModelOverlay HoverNModelProgram">
          <div className="HoverNModel">
            <div className="HoverNModelImgVid">
              {(program.episode_video_url != "") ?
                <video id={"video_url_" + index} width={1366}>
                  <source src="" type="video/mp4" />

                </video>
                : ''}
              <ImageWebp id={"img_op_" + index} webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} />
              <button className="HoverNModel_vidVolume" id={'HoverNModel_vidVolume' + '_' + index}>
                <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
              </button>

            </div>
            <div className="HoverNModelProgramTxtnIcon">
              <Link to={"/program/" + program.program_slug}><h4>{program.program_name}</h4></Link>
              <Link to={"/program/" + program.program_slug}><p>{program.channel_name}</p></Link>
              <Link to={"/program/" + program.program_slug} className="HoverNModelProgramPlay"><img src="/images/player_play.svg" uk-svg="" /></Link>
              <Link to={"/program/" + program.program_slug} className="HoverNModelProgramAddFav"><img src="/images/watchlist_btn.svg" uk-svg="" /></Link>

              <ul>
                <li><Link to={"/program/" + program.program_slug}><img src="/images/player_share.svg" uk-svg="" /></Link></li>
                <li><Link to={"/program/" + program.program_slug}><img src="/images/player_heart.svg" uk-svg="" /></Link></li>
                <li><Link to={"/program/" + program.program_slug}><img src="/images/player_info.svg" uk-svg="" /></Link></li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* Overlay Box End */}
      </div>

    ));
    return programs;
  };
  render() {
    return (
      <div>
        <section class={(this.props.isClass) ? "pad_left pad_right pad_t prog_box_sec dark_bg m_h" : "pad_left pad_right pad_t prog_box_sec dark_bg"}>
          <div class="headng_box">
            <h2 class="h2forhome"><strong>{this.props.name}</strong></h2>
          </div>
          <div class="prog_list_cont">
            {this.handleProgramList()}
          </div>
        </section>
      </div>
    );
  }
}

export default WC_Program;