import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class UnifiedBanner extends React.Component{
  componentDidMount(){
    
  }


    render(){
        return (
            <div>
                <section className="pad_left pad_right pad_tb ftr_banner">
                    <div className="uk-height-match" uk-grid="">
                        <div className="uk-width-2-5@m">
                            <div className="mdl">
                                <div className="mdl_inner">
                                    <div className="unifiedvideotxt">
                                        <img src="/images/unifiedvideo_text.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-3-5@m">
                            <div className="mdl">
                                <div className="mdl_inner">
                                    <div className="unifiedvideoimg">
                                        <img src="/images/unifiedvideo_img.png" alt="" />
                                        {/* <div className="unifiedvideobtn">
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.convex.mjunoon.TV&amp;hl=en"><img src="/images/googleplay_ftr.png" alt="" /></a>
                                            <a target="_blank" href="https://itunes.apple.com/us/app/mjunoon-tv/id1107354784?mt=8"><img src="/images/appstore_ftr.png" alt="" /></a>
                                            <a target="_blank" href="https://appgallery.huawei.com/#/app/C100874529"><img src="/images/appgallery_ftr.png" alt="" /></a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              <hr/>
            </div>
        );
    }
}

export default UnifiedBanner;