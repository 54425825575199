import React from 'react';
import {Link} from 'react-router-dom';
import LocalStorageService from "../../utils/LocalStorageService";
import axios from 'axios';
import $ from 'jquery';
import Constant from "../../utils/Constant";
class ListTags extends React.Component{

    constructor(){
        super();     
        
        
    }

    componentDidMount(){
    }

    topicActive = (e,topic) => {
        if($("#"+e).hasClass('active')){
            LocalStorageService._serviceToken((e) => this.deleteTopics(topic,e));    
        }else{
            LocalStorageService._serviceToken((e) => this.addTopic(topic,e));    
        }
        $("#"+e).toggleClass('active');
    }

    deleteTopics = (topic) => {
        
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"remove-single-topic",
            data: {user_id: user_id,tag: topic},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                // document.location.reload(true);
            }
        }).then(function (result) {
        });
    }

    addTopic = (topic) => {
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"add-favourite-topics",
            data: {
                user_id: user_id,
                topic : topic
            },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                // console.log('successfully inserted topics');
                // return <Redirect  to='/basicdetail'/>;                                               
            }
        }).then(function (result) {
            // console.log(response);
        });
    }

    clearTopics = () =>{
        // console.log("inside delete");
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"remove-user-topics",
            data: {user_id: user_id},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                document.location.reload(true);
            }
        }).then(function (result) {
        });        
    }
    
    handleTopicsList = () => {
        let topics = "";
        topics = this.props.data.map((topic,index) => (
            <div class="topics_tag_box"><span class="plus_tick active"  id={"plus_tick"+index} onClick={(e) => this.topicActive("plus_tick"+index,topic,e)}></span> <Link to={"/search?search="+topic}>{topic}</Link></div>
            // <a class="tag_box active"  id={"tag_box"+index} onClick={(e) => this.topicActive("tag_box"+index,topic,e)}><span class="plus_tick"></span> <span>{topic}</span></a>
        ));        
        return topics;
    };
    render(){
        return (
            <div>
                <div class="headng_box">
                    <h2 class="h2forhome"><strong>{this.props.name}</strong></h2><a onClick={this.clearTopics} class="view_all uk-margin-remove">Clear all</a>
                    </div> 
                    <div class="tags_box topics_box">
                    {this.handleTopicsList()}
                    </div>
            </div>
        );
    }
}

export default ListTags;