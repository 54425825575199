import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import {withRouter, Link} from 'react-router-dom';

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";

import Side_Bar_User from '../section/Side_Bar_User';
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import LocalStorageService from "../../utils/LocalStorageService";
class SubscribedNow  extends React.Component{
    render(){
        return(
            <div>
                <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
                <Header/>
                <div className="content_sec">
                    <div uk-height-match="target:  .h_match"> 
                        {/* <Side_Bar_User/> */}
                        <div class="h_match"> 
                            <section className="pad_tb pad_right pad_left bg_nosign">
                                <div className="no_signin_inner">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <img src="images/logo.png" alt="Mjunoon TV" />
                                            <h2>Unlimited Entertainment</h2>
                                            <h3>Awaits You</h3>
                                          
                                            <Link to="/packages" class="continuewith comp"><img src="images/gmail_icon.svg" /> Subscribed Now</Link>
                                            
                                            {/* <div className="no_signin_inner_txt">
                                            <p>We will not share your personal details with anyone.</p>
                                            <p>By signing up, you confirm that you agree to our <Link to="/terms-and-conditions">Terms of Use</Link> and have read and understood our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                
                            </section>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(SubscribedNow);
