import React from "react";
import axios from "axios";
import $ from "jquery";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import LocalStorageService from "../../utils/LocalStorageService";
import Meta from "../section/Meta";
import MetaCreation from "../../utils/MetaCreation";


var timer;
class TvGuide extends React.Component {
  constructor() {
    super();
    this.handleChannels = this.handleChannels.bind(this);
    this.handleType = this.handleType.bind(this);
    var left = $("#drag_sec_box").css("left");
    this.state = {
      adjustTime: "",
      options: {
        isLoading: false,
        option: [],
      },
      genre: [],
      type: "",
      widthContainer: $(".drag_sec").width(),
      updatedLeft: undefined,
      tvGuide: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      episodes: {
        isLoading: false,
        episode: [],
      },
      modal_data: {
        title: "",
        slug: "",
        is_live: "",
        start_time: "",
        image: "",
        date: "",
        only_tv_guide : "",
        type : 'channel'
      },
      dayname: "",
      today_date: "",
      hour: "",
      today_status: true,
    };
  }
  handleType(val) {
    let classRef = this;
    classRef.setState({
      episode: {
        isLoaded: false,
        episodeList: [],
      },
    });
    axios({
      method: "POST",
      url: Constant.url + "tv-guide",
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
      data: { day: this.state.dayname, type: val.value },
    }).then(function (response) {
      if (response.data.code === 200) {
        
        // return;
        classRef.setState({
          tvGuide: {
            isLoaded: true,
            list: response.data.data,
          },
        });

        setTimeout(() => {
          // classRef.formatEpisodeWRTTime();
        }, 2000);
      }
    });
  }
  handleInputSearch() {
    let classRef = this;
    classRef.setState({
      episodes: {
        isLoading: false,
        episode: [],
      },
    });
    axios({
      method: "POST",
      url: Constant.url + "tv-guide",
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
      data: { day: this.state.dayname, type: "", search: $("#search").val() },
    }).then(function (response) {
      if (response.data.code === 200) {
        
        // return;
        classRef.setState({
          tvGuide: {
            isLoaded: true,
            list: response.data.data,
          },
        });

        setTimeout(() => {
          classRef.formatEpisodeWRTTime();
        }, 2000);
      }
    });
  }
  componentWillMount() {
    let classRef = this;
    var date = new Date();
    var hour = date.getHours();
    classRef.setState({ hour: hour });
    
    var day_name = [];
    day_name[0] = "Sunday";
    day_name[1] = "Monday";
    day_name[2] = "Tuesday";
    day_name[3] = "Wednesday";
    day_name[4] = "Thursday";
    day_name[5] = "Friday";
    day_name[6] = "Saturday";
    classRef.setState({ dayname: day_name[date.getDay()] });

    classRef.setState({
      date:
        date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate(),
    });
    var curr_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    classRef.setState({ today_date: curr_date });
    this.callApi(day_name[date.getDay()], curr_date);
    this.getGenre();
  }
  componentDidMount() {
    let classRef = this;
    var date = new Date();
    var hour = date.getHours();
    classRef.setState({ hour: hour });
    var day_name = [];
    day_name[0] = "Sunday";
    day_name[1] = "Monday";
    day_name[2] = "Tuesday";
    day_name[3] = "Wednesday";
    day_name[4] = "Thursday";
    day_name[5] = "Friday";
    day_name[6] = "Saturday";
    classRef.setState({ dayname: day_name[date.getDay()] });
    this.init();
    this.setState({ updatedLeft: $("#drag_sec_box").css("left").substr(0, 6) });
    this.setState({ widthContainer: $(".drag_sec").width() });
    $(window).resize(function () {
      classRef.setState({ widthContainer: $(".drag_sec").width() });
      classRef.resizeDraggable();
    });
    setTimeout(() => {
      this.resizeDraggable();
    }, 5000);
    setTimeout(function () {
      window.initializeDragable();
    }, 2000);
    timer = setInterval(() => {
      let classRef = this;
      classRef.adjustTime(2);
      classRef.addJustMarker();
    }, 60000);
    // setInterval(() => {
    //   var date = new Date();
    //   var hour = date.getHours();
    //   classRef.setState({ hour: hour });
    // }, 1000 * 60 * 60);
  }
  resizeDraggable() {
    var container_width = $(".drag_sec").width();
    localStorage.setItem("width", container_width);
    var con_minus = container_width - 13300;
    var con_plus = 13300 - container_width;
    $(".drag_sec_box").css("left", con_plus + "px");
    $(".drag_sec_inner").css("left", con_minus + "px");
    this.adjustTime(1);
    this.addJustMarker();
  }
  adjustTime(test) {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var seconds = date.getSeconds();
    var container_width = localStorage.getItem("width") / 2;
    if (test == 1) {
      var left = $("#drag_sec_box").css("left");
      if (left != "") {
        if(left.length >= 5){
          var new_left = left.substr(0, 5);
        }else{
          var new_left = left;
        }
        
        localStorage.setItem("new_left", new_left);
      }


    }
    var updateLeft =
      parseFloat(localStorage.getItem("new_left")) -
      9.166 * (hour * 60 + minute) +
      container_width -
      100;
    
    $("#drag_sec_box").css("left", updateLeft + "px");
  }
  adjustScreen() {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var seconds = date.getSeconds();
    var left = $("#drag_sec_box").css("left");
    var temp_left = left.substr(0, 5);
    var container_width = localStorage.getItem("width") / 2;
    var temp = this.state.adjustTime.split(":");
    
    var updateLeft =
      (temp[0] < hour) ? (parseInt(temp_left) + parseInt((9.166 * (temp[0] * 60))) + parseInt(temp[1]) + parseInt(container_width) - 100)
        : (parseInt(temp_left) - parseInt((9.166 * (temp[0] * 60))) + parseInt(temp[1]) + parseInt(container_width) - 100);
    
    $("#drag_sec_box").css("left", updateLeft + "px");
  }
  addJustMarker() {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var percent = (hour * 60 + minute) * 9.166 + 100;
    this.setState({ updatedLeft: percent + "px" });
    $(".pin_current_tvguide").css("left", percent + "px");
  }
  backToCurrent = () => {
    this.adjustTime();
    this.addJustMarker();
  };
  moveLeft() {
    var container_width = $(".drag_sec").width();
    var new_container = 13300 - parseInt(container_width);
    var left = $("#drag_sec_box").css("left");
    var new_left = left.substr(0, 7);
    if (parseFloat(new_left) + 550 <= new_container) {
      $("#drag_sec_box").css("left", parseFloat(new_left) + 550 + "px");
    }
  }
  moveRight() {
    var container_width = $(".drag_sec").width();
    var new_container = 13300 - parseInt(container_width);
    var left = $("#drag_sec_box").css("left");
    var new_left = left.substr(0, 7);
    if (parseFloat(new_left) - 550 > 0) {
      $("#drag_sec_box").css("left", parseFloat(new_left) - 550 + "px");
    }
  }
  touchHandler(event) {
    var touch = event.changedTouches[0];

    var simulatedEvent = document.createEvent("MouseEvent");
    simulatedEvent.initMouseEvent(
      {
        touchstart: "mousedown",
        touchmove: "mousemove",
        touchend: "mouseup",
      }[event.type],
      true,
      true,
      window,
      1,
      touch.screenX,
      touch.screenY,
      touch.clientX,
      touch.clientY,
      false,
      false,
      false,
      false,
      0,
      null
    );

    touch.target.dispatchEvent(simulatedEvent);
  }
  init() {
    document.addEventListener("touchstart", this.touchHandler, true);
    document.addEventListener("touchmove", this.touchHandler, true);
    document.addEventListener("touchend", this.touchHandler, true);
    document.addEventListener("touchcancel", this.touchHandler, true);
  }
  populateOptions = () => {
    let options = "";
    options = this.state.options.option.map((types, index) => ({
      value: types.id,
      label: (
        <div>
          <img src="/images/icons/entertainment.svg" uk-svg="" /> {types.type}
        </div>
      ),
    }));
    this.setState({ genre: options });
  };
  callApi = (dayname, date) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tv-guide",
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
      data: { day: dayname, date: date },
    }).then(function (response) {
      if (response.data.code === 200) {
        classRef.setState({
          tvGuide: {
            isLoaded: true,
            list: response.data.data
          },
        });
        setTimeout(() => {
          classRef.formatEpisodeWRTTime();
        }, 2000);
      }
    });
  };
  updateContent = (dayname, date) => {
    let classRef = this;
    classRef.setState({ dayname: dayname });
    classRef.setState({
      tvGuide: {
        isLoaded: false,
        list: [],
      },
    });
    classRef.setState({
      episodes: {
        isLoading: false,
        episode: [],
      },
    });
    
    if (classRef.state.today_date == date) {
      classRef.setState({ today_status: true });
    } else {
      classRef.setState({ today_status: false });
    }
    classRef.setState({ episodes: false });
    axios({
      method: "POST",
      url: Constant.url + "tv-guide",
      headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
      data: { day: dayname, date: date },
    }).then(function (response) {
      if (response.data.code === 200) {
        classRef.setState({
          tvGuide: {
            isLoaded: true,
            list: response.data.data,
          },
        });

        setTimeout(() => {
          classRef.formatEpisodeWRTTime();
          if (classRef.state.today_status) {
            classRef.backToCurrent();
          }
        }, 2000);
      }
    });
  };
  getGenre = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "channel_types",
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      classRef.setState({
        options: {
          isLoading: true,
          option: response["data"]["data"]["types"],
        },
      });
      setTimeout(() => {
        classRef.populateOptions();
      }, 1000);
    });
  };
  handleChannels = () => {
    let channels = "";
    channels = this.state.tvGuide.list.channels.map((channel, index) => (
      <li key={index}>
        <Link to={channel.slug} style={{}}>
          <img
            src={
              "https://images.mjunoon.tv:8181/logo/" + channel.webp + ".webp"
            }
            alt="Samaa TV"
          />
        </Link>
      </li>
    ));
    return channels;
  };
  formatEpisodeWRTTime = () => {
    let classRef = this;
    var episodeList = [];
    if(classRef.state.tvGuide.list.episodes.length !== 'undefined'){
    for (var i = 0; i < classRef.state.tvGuide.list.episodes.length; i++) {
      episodeList[i] = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      for (var j = 0; j < classRef.state.tvGuide.list.episodes[i].length; j++) {
        var index = classRef.state.tvGuide.list.episodes[i][j].start_time.split(
          ":"
        );
        episodeList[i][index[0]] = classRef.state.tvGuide.list.episodes[i][j];
      }
    }
   }

    
    for (var i = 0; i < episodeList.length; i++) {
      for (var j = 0; j < episodeList[i].length; j++) {
        if (episodeList[i][j] != "") {
          var temp = episodeList[i][j].pro_name.split(' ');
          for (var k = 0; k < temp.length; k++) {
            if (temp[k] == $("#search").val()) {
              
              classRef.setState({ adjustTime: episodeList[i][j].start_time });
              classRef.adjustScreen();
            }
          }
        }

      }

    }

    classRef.setState({
      episodes: {
        isLoading: true,
        episode: episodeList,
      },
    });
  };
  handleEpisodes = () => {
    let episodes = "";
    episodes = this.state.episodes.episode.map((slug, index) => (
      <div className="tvguide_program_sec">
        <ul className="clr_news">
          {slug.map((response, index) => {
            let time = "";
            let width;
            let thumbnail;
            if (response != "") {
              let temp_width1 =
                response.end_time.split(":")[0] -
                response.start_time.split(":")[0];
              let temp_width2 =
                response.end_time.split(":")[1] -
                response.start_time.split(":")[1];
              width = ((temp_width1 * 60) + temp_width2) * 9.1666;
              if (response.start_time.split(":")[0] > 12) {
                time =
                  response.start_time.split(":")[0] -
                  12 +
                  ":" +
                  response.start_time.split(":")[1] +
                  " PM";
              } else {
                time =
                  response.start_time.split(":")[0] +
                  ":" +
                  response.start_time.split(":")[1] +
                  " AM";
              }
              if (width >= 200) {
                thumbnail = true;
              } else {
                thumbnail = false;
              }
            }
            return (
              <li>
                {response != "" ? (
                  <a
                    className={
                      response.start_time.split(":")[0] <= this.state.hour &&
                        this.state.today_status
                        ? "tvguide_program_box active"
                        : "tvguide_program_box"
                    }
                    style={{ width: width }}
                    onClick={(e) =>
                      this.populateModal(
                        response.pro_name,
                        response.desc,
                        response.slug,
                        response.start_time,
                        response.is_live,
                        response.thumbnail,
                        response.only_tv_guide,
                        response.type,
                        response.date
                      )
                    }
                  >
                    {thumbnail ? (
                      <div className="tvguide_program_img">
                        <img src={response.thumbnail} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={
                        thumbnail
                          ? "tvguide_program_txt tvguide_program_txt_image"
                          : "tvguide_program_txt"
                      }
                    >
                      <h4 className="prog_title">{response.pro_name}</h4>
                      <p className="prog_time">{time}</p>
                      <div className="tvguide_program_txt_btm">
                        <p className="prog_watch">
                          <img src="/images/watch_tvguide.svg" uk-svg="" />
                          {(response.is_live && thumbnail) ? "Live Now" : (thumbnail) ? "Watch Now" : ""}
                        </p>
                        <p className="prog_info">
                          <img src="images/info_icon.svg" uk-svg="" />
                        </p>
                      </div>
                    </div>
                  </a>
                ) : (
                  ""
                )}
              </li>
            );
          })}
        </ul>
      </div>
    ));
    return episodes;
  };
  populateModal = (name, desc, slug, start_time, is_live, image,only_tv_guide,type,date) => {
    
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var todayDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

    var date1 = todayDate;
    var date2 = date;
    var date1Updated = new Date(date1);  
    var date2Updated = new Date(date2); 
    var cond = 'equal';  
    
    
    if(date2Updated < date1Updated){
      cond = 'less'
    }else
    if(date2Updated > date1Updated){
      cond = 'greater'
    }
    
    if (start_time < time && cond == 'equal') {
      let classRef = this;
      classRef.setState({
        modal_data: {
          title: name,
          is_live: is_live,
          slug: slug,
          start_time: start_time,
          image: image,          
          only_tv_guide : only_tv_guide,
          type : type,
          date : date
        },
      },function() {
        $("#tvguide_modal").addClass("uk-open");
        $("#tvguide_modal").addClass("uk-flex");
      });
      localStorage.setItem("tv-guide", "true");
      localStorage.setItem("time", this.state.today_date + " " + start_time);
    }else if (cond == 'less') {
      let classRef = this;
      classRef.setState({
        modal_data: {
          title: name,
          is_live: is_live,
          slug: slug,
          start_time: start_time,
          image: image,          
          only_tv_guide : only_tv_guide,
          type : type,
          date : date
        },
      },function() {
        $("#tvguide_modal").addClass("uk-open");
        $("#tvguide_modal").addClass("uk-flex");
      });
      localStorage.setItem("tv-guide", "true");
      localStorage.setItem("time", this.state.today_date + " " + start_time);
    } else {
      
    }
  };
  adjustMorning = (e) => {
    var new_left = parseInt(localStorage.getItem("new_left")) - 7 * 550;
    $(".morning_to_night>ul").children("li").removeClass("active");
    $("#morning").toggleClass("active");
    $("#drag_sec_box").css("left", new_left + "px");
  };
  adjustAfternoon = (e) => {
    $(".morning_to_night>ul").children("li").removeClass("active");
    var new_left = parseInt(localStorage.getItem("new_left")) - 12 * 550;
    $("#afternoon").toggleClass("active");
    $("#drag_sec_box").css("left", new_left + "px");
  };
  adjustEvening = (e) => {
    $(".morning_to_night>ul").children("li").removeClass("active");
    var new_left = parseInt(localStorage.getItem("new_left")) - 16 * 550;
    $("#evening").toggleClass("active");
    $("#drag_sec_box").css("left", new_left + "px");
  };
  adjustNight = (e) => {
    $(".morning_to_night>ul").children("li").removeClass("active");
    var new_left = parseInt(localStorage.getItem("new_left")) - 20 * 550;
    $("#night").toggleClass("active");
    if (new_left > 0) {
      $("#drag_sec_box").css("left", new_left + "px");
    } else {
      $("#drag_sec_box").css("left", "0px");
    }
  };
  handleWeekDates = () => {
    var dates = [];
    var date = new Date();
    var months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var month_name = [];
    var day_name = [];
    month_name[0] = "Jan";
    month_name[1] = "Feb";
    month_name[2] = "Mar";
    month_name[3] = "Apr";
    month_name[4] = "May";
    month_name[5] = "Jun";
    month_name[6] = "Jul";
    month_name[7] = "Aug";
    month_name[8] = "Sep";
    month_name[9] = "Oct";
    month_name[10] = "Nov";
    month_name[11] = "Dec";

    day_name[0] = "Sunday";
    day_name[1] = "Monday";
    day_name[2] = "Tuesday";
    day_name[3] = "Wednesday";
    day_name[4] = "Thursday";
    day_name[5] = "Friday";
    day_name[6] = "Saturday";

    var curr = new Date();
    var first = curr.getDate() - curr.getDay();
    var last = first + 6;

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    var dayDate = [];
    var j = 1;
    for (var i = 0; i < 7; i++) {
      if (months[firstday.getMonth()] >= firstday.getDate() + i) {
        dates[i] =
          firstday.getDate() + i + " " + month_name[firstday.getMonth()];
        dayDate[i] =
          firstday.getFullYear() +
          "-" +
          (firstday.getMonth() + 1) +
          "-" +
          (firstday.getDate() + i);
      } else {
        dates[i] = j + " " + month_name[firstday.getMonth() + 1];
        dayDate[i] =
          firstday.getFullYear() + "-" + (firstday.getMonth() + 1) + "-" + j;
        j++;
      }
    }
    dates = dates.map((slug, index) => (
      <li
        className={this.state.dayname == day_name[index] ? "uk-active" : ""}
        onClick={(e) => this.updateContent(day_name[index], dayDate[index])}
      >
        <a href="javascript:;">
          {date.getDate() + " " + month_name[date.getMonth()] == slug
            ? "Today"
            : day_name[index].substr(0, 3)}
          <span>{slug}</span>
        </a>
      </li>
    ));
    return dates;
  };
  componentWillUnmount() {
    clearInterval(timer);
    $("#loader_section").remove();
  }

  redirectStore = (isLive,time,type) =>{    
    if(isLive == 1){
      localStorage.setItem('rewindtime',this.state.today_date+' '+time);
      localStorage.setItem('restart','yes'); 
    }else if(isLive == 0 && type == 'channel'){
      localStorage.setItem('rewindtime',this.state.today_date+' '+time);
      localStorage.setItem('vodrewind','yes'); 
    }    
  }  
  render() {
    return (
      <div>
        <Meta
          schema={null}
          metaKeywords={this.state.metaKeywords}
          metaTitle={this.state.metaTitle}
          metaDescription={this.state.metaDescription}
          metaImage={this.state.metaImage}
        />
        <Header />
        <div className="content_sec">
          <section className="tvguide_sec pad_right pad_left pad_tb">
            <div className="tvguide_box">
              {/* TV Guide Head Start */}
              <div
                className="tvguide_box_head"
                uk-sticky="offset: 60; media: 769"
              >
                <h1>TV Guide</h1>
                <div className="guide_filtersearch">
                  {/* <Select
                    className="genre_select"
                    options={this.state.genre}
                    onChange={this.handleType}
                  /> */}
                  <div className="search_tvguide">
                    <form
                      className="uk-search uk-search-default"
                      action="javascript:;"
                    >
                      <a
                        href="javascript:;"
                        className="uk-search-icon-flip"
                        uk-search-icon=""
                      />
                      <input
                        className="uk-search-input"
                        id="search"
                        type="search"
                        onKeyUp={(e) => this.handleInputSearch()}
                        placeholder="Search TV Guide"
                      />
                    </form>
                  </div>
                </div>
              </div>
              {/* TV Guide Head End */}
              {/* TV Guide Week Tabs Start */}
              <div
                className="tvguide_week_sec"
                uk-sticky="offset: 114; media: 769"
              >
                <ul>{this.handleWeekDates()}</ul>
              </div>
              {/* TV Guide Week Tabs End */}
              {/* TV Guide Week Filters Start */}
              {this.state.episodes.isLoading === true ? (
                <div
                  className="tvguide_filters_sec"
                  uk-sticky="offset: 184; media: 769"
                >
                  <div className="morning_to_night">
                    <div
                      className={
                        this.state.today_status
                          ? "live_now_btn live"
                          : "live_now_btn"
                      }
                      onClick={
                        this.state.today_status
                          ? (e) => this.backToCurrent()
                          : ""
                      }
                    >
                      <a href="javascript:;">
                        <span /> Live Now
                      </a>
                    </div>
                    <ul>
                      <li onClick={(e) => this.adjustMorning()} id="morning">
                        <a href="javascript:;">Morning</a>
                      </li>
                      <li
                        onClick={(e) => this.adjustAfternoon()}
                        id="afternoon"
                      >
                        <a href="javascript:;">Afternoon</a>
                      </li>
                      <li onClick={(e) => this.adjustEvening()} id="evening">
                        <a href="javascript:;">Evening</a>
                      </li>
                      <li onClick={(e) => this.adjustNight()} id="night">
                        <a href="javascript:;">Night</a>
                      </li>
                    </ul>
                  </div>
                  <div className="tvguide_filters_right">
                    <div
                      className="tvguide_filters_box"
                      uk-slider="finite: true"
                    >
                      <ul className="uk-slider-items">
                        <li className="clr_islamic">
                          <a href="javaxcript:;">Islamic</a>
                        </li>
                        <li className="clr_news">
                          <a href="javaxcript:;">News</a>
                        </li>
                        <li className="clr_sport">
                          <a href="javaxcript:;">Sports</a>
                        </li>
                        <li className="clr_music">
                          <a href="javaxcript:;">Music</a>
                        </li>
                        <li className="clr_movie">
                          <a href="javaxcript:;">Movie</a>
                        </li>
                        <li className="clr_kids">
                          <a href="javaxcript:;">Kids</a>
                        </li>
                        <li className="clr_entertainment">
                          <a href="javaxcript:;">Entertainment</a>
                        </li>
                        <li className="clr_cooking">
                          <a href="javaxcript:;">Cooking</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div id="loader_section">
                  <div className="loader_box">
                    <div className="loader">
                      <div className="loader_inner"></div>
                    </div>
                  </div>
                </div>
              )}
              {/* TV Guide Week Filters End */}
              {/* TV Guide Week Tabs Section Start */}
              <div className="tvguide_tabs_sec">
                <ul className="tvguide_tab_inner" style={{ padding: "0px" }}>
                  <li>
                    <div className="drag_sec">
                      <a
                        className="tvguide_btn tv_prev"
                        onClick={this.moveLeft}
                        href="javascript:;"
                      >
                        <img src="images/prev.svg" uk-svg="" />
                      </a>
                      <a
                        className="tvguide_btn tv_next"
                        onClick={this.moveRight}
                        href="javascript:;"
                      >
                        <img src="images/next.svg" uk-svg="" />
                      </a>
                      <div className="drag_sec_inner">
                        <div className="drag_sec_box" id="drag_sec_box">
                          {this.state.today_status ? (
                            <div className="pin_current_tvguide" />
                          ) : (
                            ""
                          )}
                          {/* Hours Start */}
                          <div className="guide_time_list">
                            <ul>
                              <li>
                                <p>12:00 am</p> <span>12:30 am</span>
                              </li>
                              <li>
                                <p>1:00 am</p> <span>1:30 am</span>
                              </li>
                              <li>
                                <p>2:00 am</p> <span>2:30 am</span>
                              </li>
                              <li>
                                <p>3:00 am</p> <span>3:30 am</span>
                              </li>
                              <li>
                                <p>4:00 am</p> <span>4:30 am</span>
                              </li>
                              <li>
                                <p>5:00 am</p> <span>5:30 am</span>
                              </li>
                              <li>
                                <p>6:00 am</p> <span>6:30 am</span>
                              </li>
                              <li>
                                <p>7:00 am</p> <span>7:30 am</span>
                              </li>
                              <li>
                                <p>8:00 am</p> <span>8:30 am</span>
                              </li>
                              <li>
                                <p>9:00 am</p> <span>9:30 am</span>
                              </li>
                              <li>
                                <p>10:00 am</p> <span>10:30 am</span>
                              </li>
                              <li>
                                <p>11:00 am</p> <span>11:30 am</span>
                              </li>
                              <li>
                                <p>12:00 pm</p> <span>12:30 pm</span>
                              </li>
                              <li>
                                <p>1:00 pm</p> <span>1:30 pm</span>
                              </li>
                              <li>
                                <p>2:00 pm</p> <span>2:30 pm</span>
                              </li>
                              <li>
                                <p>3:00 pm</p> <span>3:30 pm</span>
                              </li>
                              <li>
                                <p>4:00 pm</p> <span>4:30 pm</span>
                              </li>
                              <li>
                                <p>5:00 pm</p> <span>5:30 pm</span>
                              </li>
                              <li>
                                <p>6:00 pm</p> <span>6:30 pm</span>
                              </li>
                              <li>
                                <p>7:00 pm</p> <span>7:30 pm</span>
                              </li>
                              <li>
                                <p>8:00 pm</p> <span>8:30 pm</span>
                              </li>
                              <li>
                                <p>9:00 pm</p> <span>9:30 pm</span>
                              </li>
                              <li>
                                <p>10:00 pm</p> <span>10:30 pm</span>
                              </li>
                              <li>
                                <p>11:00 pm</p> <span>11:30 pm</span>
                              </li>
                            </ul>
                          </div>
                          {/* Hours End */}
                          {/* Program List Start */}
                          {this.state.episodes.isLoading === true ? (
                            this.handleEpisodes()
                          ) : (
                            <div
                              id="loader_section"
                              style={{ left: "1454.68px" }}
                            >
                              <div className="loader_box">
                                <div className="loader">
                                  <div className="loader_inner"></div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Program List End */}
                          {/* Program List Start */}
                        </div>
                      </div>
                      {/* Channels logos Start */}
                      <div className="tvguide_channel_logos">
                        <ul>
                          {this.state.episodes.isLoading === true
                            ? this.handleChannels()
                            : ""}
                        </ul>
                      </div>
                      {/* Channels logos End */}
                    </div>
                  </li>
                </ul>
              </div>
              {/* TV Guide Week Tabs Section End */}
            </div>
            {/* TV Guide Modal Start */}
            <div
              id="tvguide_modal"
              className="uk-flex-top tvguide_modal"
              uk-modal=""
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button
                  className="uk-modal-close-default"
                  type="button"
                  uk-close=""
                />
                <div className="tv_hd">
                  <div className="tvguide_modal_image">
                    <img
                      src={
                        this.state.modal_data.image != ""
                          ? this.state.modal_data.image
                          : "https://cdn2.mjunoon.tv:9191/uploads/header-program.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="tvguide_modal_heading">
                    <h3>
                      {this.state.modal_data.title != ""
                        ? this.state.modal_data.title
                        : "Episode"}
                    </h3>
                    <Link
                      to={
                        this.state.modal_data.is_live == 0 && this.state.modal_data.type == 'vod'
                          ? "/watch/" + this.state.modal_data.slug
                          : "/" + this.state.modal_data.slug
                      }
                      onClick={() => this.redirectStore(this.state.modal_data.is_live,this.state.modal_data.start_time,this.state.modal_data.type)}
                      className="blue_btn"
                    >
                      <img src="images/play.svg" uk-svg="" /> Watch
                    </Link>
                    {/* <a href="" className="blue_btn"><img src="images/watchlist_btn.svg" uk-svg="" /> Watch List</a> */}
                  </div>
                </div>
                <div className="tvguide_modal_text">
                  <dir uk-grid="">
                    <div className="uk-width-1-2@s">
                      {/* <p><a href="#">4 Seasons</a> <a href="#">#PepsiBattleoftheBands</a> <a href="#">#TheBattleIsOn</a></p> */}
                    </div>
                    <div className="uk-width-1-2@s">
                      <p>
                        {this.state.modal_data.desc != ""
                          ? this.state.modal_data.desc
                          : "Episode Description"}
                      </p>
                    </div>
                  </dir>
                </div>
              </div>
            </div>
            {/* TV Guide Modal End */}
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(TvGuide);
