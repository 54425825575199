import React from 'react';
import {withRouter, Link} from 'react-router-dom';

import Header from "../common/Header";
import Footer from "../common/Footer";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";

class TermsConditions extends React.Component{
    constructor(){
        super();
        this.state = {
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''                                               
        }
    }
    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
    }
    
    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }

    render(){
        return(
            <div>
            <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
            <Header/>              
            <div class="content_sec"> 
                <section class="randdom_header pad_left pad_right pad_tb">
                    <h1>Terms of Use</h1>
                    <p><strong>Description of service and acceptance of terms of use</strong></p>
                    <p>Convex Interactive Pvt Ltd. is the digital media agency that is the owner and creator of mjunoon.TV and all its relevant services. mjunoon TV is a platform that hosts live TV channels with their streams legally acquired from the channel owners. The streams and video on demand data is provided to the consumers for free with placed ads as the revenue model for the channel vendors and the parent organization</p>
                </section>
                <section class="randdom_body pad_left pad_right pad_tb">
                    <p><strong>The Content is available for permissible viewing on or through the following (collectively, the "Properties"):</strong></p>
                    <p>mjunoon TV.com website (the " mjunoon TV Site").</p>
                    <p>mjunoon TV affiliate and distributor websites.</p>
                    <p>other websites where users or website operators are permitted to embed the Video Player, and mjunoon TV authorized applications mostly on the web use of the mjunoon TV Services (including access to the Content) on the Properties is subject to compliance with these Terms of Use ("Terms of Use" or "Terms") and any end user license agreement that might accompany a mjunoon TV web services. Majority of mjunoon TV Services are provided to you free-of-charge. mjunoon may charge channel vendors a certain fees based on the ad revenues which will be briefed to the vendor at the time of signing of the contract.</p>
                    <p>It is our pleasure to provide the mjunoon TV Services for your personal enjoyment and entertainment in accordance with these Terms of Use. To access and enjoy the mjunoon TV Services, you must agree to, and follow, the terms and conditions set forth in these Terms of Use. By visiting the mjunoon TV Site or using any of the mjunoon TV Services (including accessing any Content) you are agreeing to these Terms. Please take a moment to carefully read through these Terms.</p>
                    <p><strong>Changes to the terms of use by mjunoon</strong></p>
                    <p>mjunoon tv may amend these terms of use at any time by posting the amended terms of use on the mjunoon tv site. If we make a material amendment to these terms of use, we will notify you by posting notice of the amendment on the mjunoon tv site. Any material amendment to these terms of use shall be effective automatically 30 days after it is initially posted or, for users who register or otherwise provide opt-in consent during this 30 day period, at the time of registration or consent, as applicable.</p>
                    <p><strong>Access and use of the mjunoon tv services</strong></p>
                    <p><strong>Age limitations</strong></p>
                    <p>The mjunoon tv services are not intended to be used by children without involvement and approval of a parent or guardian. If you are under the age of 13, you are not permitted to register with mjunoon tv’s registration needed services or provide your personal information to mjunoon tv. If you are at least 13 and under 18 years of age, you may register with mjunoon tv only if you have the consent of your parent or guardian.</p>
                    <p><strong>The content</strong></p>
                    <p>You may only access and view the content personally and for a non-commercial purpose in compliance with these terms. You may not either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any copyright, trademark, or other proprietary notices marked on the content or any digital rights management mechanism, device, or other content protection or access control measure associated with the content including geo-filtering mechanisms. You may not either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, stream capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the content unless expressly permitted by mjunoon tv in writing. You may not incorporate the content into, or stream or retransmit the content via, any hardware or software application or make it available via frames or in-line links unless expressly permitted by mjunoon tv in writing. Furthermore, you may not create, recreate, distribute or advertise an index of any significant portion of the content unless authorized by mjunoon tv. You may not build a business utilizing the content, whether or not for profit. The content covered by these restrictions includes without limitation any text, graphics, layout, interface, logos, photographs, audio and video materials, and stills. In addition, you are strictly prohibited from creating derivative works or materials that otherwise are derived from or based on in any way the content, including montages, mash-ups and similar videos, wallpaper, desktop themes, greeting cards, and merchandise, unless it is expressly permitted by mjunoon tv in writing. This prohibition applies even if you intend to give away the derivative materials free of charge.</p>
                    <p><strong>mjunoon player</strong></p>
                    <p>You may not modify, enhance, remove, interfere with, or otherwise alter in any way any portion of the video player, its underlying technology, any digital rights management mechanism, device, or other content protection or access control measure incorporated into the video player. This restriction includes, without limitation, disabling, reverse engineering, modifying, interfering with or otherwise circumventing the video player in any manner that enables users to view the content without:</p>
                    <ul>
                    <li>Displaying visibly both the video player and all surrounding elements (including the graphical user interface, any advertising, copyright notices, and trademarks) of the webpage where the video player is located.</li>
                    <li>Having full access to all functionality of the video player, including, without limitation, all video quality and display functionality and all interactive, elective, or click-through advertising functionality.</li>
                    </ul>
                    <p><strong>Embedding a video using the video player</strong></p>
                    <p>Where mjunoon tv has incorporated an embed option in connection with content on the mjunoon tv services, you may embed videos using the video player, provided you do not embed the video player on any website or other location that:</p>
                    <ul>
                    <li>Contains or hosts content that is unlawful, infringing, pornographic, obscene, defamatory, libelous, threatening, harassing, vulgar, indecent, profane, hateful, racially or ethnically offensive, encourages criminal conduct, gives rise to civil liability, violates any law, rule, or regulation, infringes any right of any third party including intellectual property rights, or is otherwise inappropriate or objectionable to mjunoon tv (in mjunoon tv 's sole discretion), or</li>
                    <li>Links to infringing or unauthorized content (collectively, "unsuitable material"). You may not embed the video player into any hardware or software application, even for non-commercial purposes. mjunoon tv reserves the right to prevent embedding to any website or other location that mjunoon tv finds inappropriate or objectionable (as determined by mjunoon tv in its sole discretion).</li>
                    </ul>
                    <p><strong>Ownership</strong></p>
                    <p>You agree that mjunoon tv owns and retains all rights to the mjunoon tv services. You further agree that the content you access and view as part of the mjunoon tv services is owned or controlled by mjunoon tv and mjunoon tv’s licensors. The mjunoon tv services and the content are protected by copyright, trademark, and other intellectual property laws.</p>
                    <p><strong>Consumer’s responsibilities</strong></p>
                    <p>In order for us to keep the mjunoon tv services safe and available for everyone to use, we all have to follow the same rules of the road. You and other users must use the mjunoon tv services for lawful, non-commercial, and appropriate purposes only. Your commitment to this principle is critical. You agree to observe the mjunoon tv services, content, video player and embedding restrictions detailed above, and further agree that you will not access the mjunoon tv site or use the mjunoon tv services in a way that:</p>
                    <ul>
                    <li>Violates the rights of others, including patent, trademark, trade secret, copyright, privacy, publicity, or other proprietary rights.</li>
                    <li>Uses technology or other means to access, index, frame or link to the mjunoon tv services (including the content) that is not authorized by mjunoon tv (including by removing, disabling, bypassing, or circumventing any content protection or access control mechanisms intended to prevent the unauthorized download, stream capture, linking, framing, reproduction, access to, or distribution of the mjunoon tv services).</li>
                    <li>Involves accessing the mjunoon tv services (including the content)through any automated means, including "robots," "spiders," or "offline readers" (other than by individually performed searches on publicly accessible search engines for the sole purpose of, and solely to the extent necessary for, creating publicly available search indices - but not caches or archives - of the mjunoon tv services and excluding those search engines or indices that host, promote, or link primarily to infringing or unauthorized content).</li>
                    <li>Introduces viruses or any other computer code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.</li>
                    <li>Damages, disables, overburdens, impairs, or gains unauthorized access to the mjunoon tv services, including mjunoon tv 's servers, computer network, or user accounts.</li>
                    <li>Removes, modifies, disables, blocks, obscures or otherwise impairs any advertising in connection with the mjunoon tv services (including the content).</li>
                    <li>Uses the mjunoon tv services to advertise or promote services that are not expressly approved in advance in writing by mjunoon tv.</li>
                    <li>Collects personally identifiable information in violation of mjunoon tv 's privacy policy.</li>
                    <li>Encourages conduct that would constitute a criminal offense or give rise to civil liability.</li>
                    <li>Violates these terms or any guidelines or policies posted by mjunoon tv.</li>
                    <li>Interferes with any other party's use and enjoyment of the mjunoon tv services, or attempts to do any of the foregoing.</li>
                    </ul>
                    <p>If mjunoon tv determines in its sole discretion that you are violating any of these terms, we may</p>
                    <ol>
                    <li>Notify you, and</li>
                    <li>Use technical measures to block or restrict your access or use of the mjunoon tv services. In either case, you agree to immediately stop accessing or using in any way (or attempting to access or use) the mjunoon tv services, and you agree not to circumvent, avoid, or bypass such restrictions, or otherwise restore or attempt to restore such access or use. </li>
                    </ol>
                    <p><strong>No spam/unsolicited communications.</strong></p>
                    <p>We know how annoying and upsetting it can be to receive unwanted email or instant messages from people you do not know. Therefore, no one may use the mjunoon tv services to harvest information about users for the purpose of sending, or to facilitate or encourage the sending of, unsolicited bulk or other communications. You understand that we may take any technical remedies to prevent spam or unsolicited bulk or other communications from entering, utilizing, or remaining within our computer or communications networks. If you post or otherwise send spam, advertising, or other unsolicited communications of any kind through the mjunoon tv services, you acknowledge that you will have caused substantial harm to mjunoon tv and that the amount of such harm would be extremely difficult to measure.</p>
                    <p><strong>Suspension/discontinuation</strong></p>
                    <p>We hope not to, but we may change, suspend, or discontinue - temporarily or permanently - some or all of the mjunoon tv services (including the content and the devices through which the mjunoon tv services are accessed), with respect to any or all users, at any time without notice. You acknowledge that mjunoon tv may do so in mjunoon tv’s sole discretion. You also agree that mjunoon tv will not be liable to you for any modification, suspension, or discontinuance of the mjunoon tv services.</p>
                    <p><strong>Internet access charges</strong></p>
                    <p>You are responsible for any costs you incur to access the internet.</p>
                    <p><strong>Accounts and registration</strong></p>
                    <p>We may from time to time offer various features that require registration or the creation of an account with mjunoon tv. If at any time you choose to register or create an account with us, the additional terms and conditions set forth below also will apply.</p>
                    <p>You are responsible for all use of your account, including use of your account by other members of your household. By allowing others to access your account, you agree to be responsible for ensuring that they comply with these terms of use and you agree to be responsible for their activity using the mjunoon tv services.</p>
                    <p>All registration information you submit must be accurate and updated. Please keep your password confidential. You will not have to reveal it to any mjunoon tv representative. Because you are responsible for all use of your account, including unauthorized use by any third party, please be very careful to guard the security of your password</p>
                    <p>We reserve the right to immediately terminate or restrict your account or your use of the mjunoon tv services or access to content at any time, without notice or liability, if mjunoon tv determines in its sole discretion that you have breached these terms of use, violated any law, rule, or regulation, engaged in other inappropriate conduct, or for any other business reason. We also reserve the right to terminate your account or your use of the when video or channels show buffer sign user unable to back from that screen services or access to content if such use places an undue burden on our networks or servers.</p>
                    <p><strong>Linked destinations and advertising</strong></p>
                    <p>Third party destinations. If we provide links or pointers to other websites or destinations, you should not infer or assume that mjunoon tv operates, controls, or is otherwise connected with these other websites or destinations. When you click on a link within the mjunoon tv services, we will not warn you that you have left the mjunoon tv services and are subject to the terms and conditions (including privacy policies) of another website or destination. In some cases, it may be less obvious than others that you have left the mjunoon tv services and reached another website or destination. Please be careful to read the terms of use and privacy policy of any other website or destination before you provide any confidential information or engage in any transactions. You should not rely on these terms to govern your use of another website or destination.</p>
                    <p>mjunoon tv is not responsible for the content or practices of any website or destination other than the mjunoon tv site, even if it links to the mjunoon tv site and even if the website or destination is operated by a company affiliated or otherwise connected with mjunoon tv. By using the mjunoon tv services, you acknowledge and agree that mjunoon tv is not responsible or liable to you for any content or other materials hosted and served from any website or destination other than the mjunoon tv site.</p>
                    <p><strong>Advertisements</strong></p>
                    <p>mjunoon tv takes no responsibility for advertisements or any third party material posted on any of the properties, nor does it take any responsibility for the products or services provided by advertisers. Any dealings you have with advertisers found while using the mjunoon tv services are between you and the advertiser, and you agree that mjunoon tv is not liable for any loss or claim that you may have against an advertiser.</p>
                    <p><strong>Trademarks</strong></p>
                    <p>mjunoon tv, the mjunoon tv logo, www.mjunoon.tv, and other mjunoon tv marks, graphics, logos, scripts, and sounds are trademarks of mjunoon tv. None of the mjunoon tv trademarks may be copied, downloaded, or otherwise exploited.</p>
                </section>                 
            </div>
            <Footer/>   
            </div>
        );
     }
}
export default withRouter(TermsConditions);