import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from "jquery";
import Header from "../common/Header";
class MobileTvGuideNow extends React.Component {
  constructor() {
    super();
    this.handleEpisodeList = this.handleEpisodeList.bind(this);
    this.state = {
      guide: {
        isLoaded: false,
        msg: 'Loading...',
        list: []
      },
      url : '',
      channelUrl: {
        url: '',
        channelName: '',                
      },
    }
  }
  componentDidMount() {
    this.callLiveGuideApi();
  }

  rengerGlider = () => {
    new window.Glider(document.querySelector("#tvguideLiveSlider"), {
      slidesToShow: "auto",
      slidesToScroll: "auto",
      exactWidth: 245,
      itemWidth: 245,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: "#tvguideLiveSlider_prev",
        next: "#tvguideLiveSlider_next",
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 245,
            itemWidth: 245,
            duration: 0.25,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            exactWidth: 245,
            itemWidth: 245,
            duration: 0.25,
          },
        },
        {
          breakpoint: 375,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
          },
        },
        {
          breakpoint: 411,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
          },
        },
        {
          breakpoint: 768,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
          },
        },
        {
          breakpoint: 1201,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
            draggable: false,
          },
        },
        {
          breakpoint: 1281,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
            draggable: false,
          },
        },
        {
          breakpoint: 1401,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
            draggable: false,
          },
        },
        {
          breakpoint: 1601,
          settings: {
            exactWidth: 245,
            itemWidth: 245,
            draggable: false,
          },
        },
      ],
    });
  }

  callGetChannelUrlApi = (slug) => {
    let classRef = this;
    axios({
        method: 'POST',
        url: Constant.url+"streaming-url",
        data: {slug: slug, is_rewind: "no", type: "channel", user_id: ""},
        headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
    }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

        if(response.data.code === 200 && response.data.status === true){
            classRef.setState({
                channelUrl: {
                    isLoaded: true,
                    url: response.data.data.live_stream_url,                    
                    channelName: response.data.data.channel_name,                 
                }
            });

            
        }
    })
};

  callLiveGuideApi = () => {
    let classRef = this;
    axios({
      method: 'POST',
      url: Constant.url + "guide-home-live",
      data: {user_id: localStorage.getItem('user_id')},
      headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

      if (response.data.code === 200 && response.data.status === true) {
        
        classRef.setState({
          guide: {
            isLoaded: true,
            list: response.data.data.body[0].guide_live
          }
        });
        classRef.rengerGlider()  
      }
    })
  };

  redirectStore = (date,time) =>{
    localStorage.setItem('rewindtime',date+' '+time);
    localStorage.setItem('restart','yes'); 
  }

  handleEpisodeList = () => {
    let episodes = "";
    episodes = this.state.guide.list.map((episode, index) => (
      // <div key={index}>
      <div className="tvguideLiveSliderInner" id={'gliderNow_'+index}>
        <div className="tvguideLiveSliderBox">
        <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug} className="tvguideLiveSliderimg">
            <img src={episode.thumbnail} alt={episode.program_name} />
            <span className="viewerstvg">{episode.views+' Views'}</span>
            {/* <div className="continue_bar"><span style={{ width: '40%' }} /></div> */}
          </Link>
          <div  className="tvguideLiveSliderTxt">
          <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}>
            <img
              src={episode.channel_banner}
              alt={episode.program_name}
            />
            </Link>
            {(episode.is_fav)? 
            <span className="hearticon active" id={"heart_"+episode.guide_id} uk-icon="icon: heart" onClick={(e) => this.addToFavourite("heart_"+episode.guide_id,episode.guide_id,e)}></span>
            :<span className="hearticon" uk-icon="icon: heart" id={"heart_"+episode.guide_id} onClick={(e) => this.addToFavourite("heart_"+episode.guide_id,episode.guide_id,e)}></span>}
            <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}>
              <h4>{episode.program_name}</h4>              
            </Link>
            <span className="views_txt">{episode.time}</span>
          </div>         
        </div>
      </div>
      // </div>         
    ));
    return episodes;
  };

  addToFavourite = (heart_id,id) => {  
    
    if(!localStorage.getItem('user_id')){
      new Header().openLoginModal();
      return;      
    }
    if($("#"+heart_id).hasClass('active')){ 
      
      $("#heart_"+id).removeClass('active')
      $("#"+heart_id).removeClass('active');      
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"remove-channel-guide-favourite",
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")},
          data: {channel_guide_id: id, user_id: localStorage.getItem('user_id')}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData)); 
          
          if(response.data.code === 200 && response.data.status === true){         
                 
                
          }          
      })         
    }else{            
      $("#"+heart_id).addClass('active');     
      $("#heart_"+id).addClass('active')       
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"add-channel-guide-favourite",
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")},
          data: {channel_guide_id: id, user_id: localStorage.getItem('user_id')}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          
          if(response.data.code === 200 && response.data.status === true){             
              
          }          
      })
    }          
  }
  
  render() {
    return (
      
      <div>
        {(this.state.guide.isLoaded)?
        <section className="pad_left pad_b tvguideLiveSec">
          <div className="headng_box">
            <h2 className="h2forhome">
              <span className="livebtn">LIVE</span>
              <strong>Now</strong>
            </h2>
          </div>

          <div className="slider_new_container drama_sldr_container">
            <div id="tvguideLiveSlider" className="tvguideLiveSlider">
            {this.state.guide.isLoaded ? this.handleEpisodeList() : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}              
            </div>
            <button
              role="button"
              aria-label="Previous"
              id="tvguideLiveSlider_prev" className="glider-prev">
              <img src="/images/left_arrow.png" alt="left arrow" />
            </button>
            <button
              role="button"
              aria-label="Next"
              id="tvguideLiveSlider_next"
              className="glider-next"
            >
              <img src="/images/right_arrow.png" alt="right arrow" />
            </button>
          </div>
        </section>
        :""}
      </div>
    
    );
  }
}

export default MobileTvGuideNow;