import React from 'react'
import {Link} from 'react-router-dom'
import Constant from "../../utils/Constant";
import ImageWebp from 'react-image-webp';
class ChannelRelated extends React.Component {
    handleChannelRelated = () =>{
        let channelRelated = "";
        channelRelated = this.props.data.map((channel, index) => (
            <li key={index}> <div className="live_style live_style_full"><Link className="fav_sld_img" to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)}><ImageWebp className="img_log" webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name} /><ImageWebp webp={channel.channel_live_image} src={channel.channel_live_image_jpg} alt={channel.channel_name}/><div className="hvr_box"><span uk-icon="play"></span></div> </Link>
                    <Link to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/channel/"+channel.channel_slug)} className="txt_box">
                    <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name} />
                    <h4>{channel.channel_name}</h4>
                    <span className="views_txt">{channel.channel_views} viewers</span>
                    <span className="live_span">{channel.channel_status === 'live' ? 'Live' : ''}</span> </Link>
                     </div></li>   
            )
        );

        return channelRelated;
    }

    render(){
        return (
            <ul className="related_tab_box">
                {this.handleChannelRelated()}
            </ul>
        );
    }
}
export default ChannelRelated;