import React from 'react';
import {Link} from 'react-router-dom';
class NoInternetConnect extends React.Component{
   
    render(){
        return (
            <div>
                 <div className="content_sec">
                    <div className="page_notfound">
                        <div className="h_match asad_bg">
                            <section className="pad_left pad_right pad_tb prog_box_sec">
                                <div className="empty_box">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <img src="/images/dino.png" alt="dino" />
                                            <p className="uk-margin-small-bottom">Page Couldn't Be Loaded</p>
                                            <p className="uk-text-small uk-text-muted uk-margin-remove-top">Hey, check your connection and <br />
                                                try reloading.</p>
                                            <a className="blue_btn" href="/">RELOAD</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>             
            </div>
        );
    }
}

export default NoInternetConnect;