import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import LocalStorageService from "../../utils/LocalStorageService";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WC_Episode from '../section/WC_Episode';
import WC_EpisodeMobile from "../section/WC_EpisodeMobile";
import WC_Channel from '../section/WC_Channel';
import MetaCreation from "../../utils/MetaCreation";
import Meta from "../section/Meta"
import $ from 'jquery';
import { isMobile } from "react-device-detect";
import WC_Movie from '../section/WC_Movie';
import Loader from '../section/loader';
import WC_Program from '../section/WC_Program';
import WC_ProgramList from '../section/WC_ProgramList';
// import {AdSlot} from "react-dfp";


class ProgramList extends React.Component{
    constructor(){
        super();

        this.callProgramApi = this.callProgramApi.bind(this);

        this.state = {
            latest_episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            popular_episodes1: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            channel: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            detail1: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            isFav : false
        }
    }

    
    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callProgramApi);
        DFPManager.load();
        // console.log("redirected")
        // debugger;
    }

    componentWillReceiveProps(nextProps){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        this.setState({latest_episodes:{isLoaded : false},popular_episodes1:{isLoaded : false},channel:{isLoaded : false},detail:{isLoaded : false}})
        LocalStorageService._serviceToken(this.callProgramApi);
        DFPManager.load()
    }

    callMetaData = (metaResponse) =>{

        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callProgramApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"programs",
            data: {genre:10},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            // console.log("response: ", response.data);
            if(response.data.code === 200){
                console.log('umair',response.data.data.body)
                classRef.setState({
                    // latest_episodes:{
                    //     isLoaded : true,
                    //     list: response.data.data.body[0].latest
                    // },  
                    popular_episodes1:{
                        isLoaded : true,
                        list: response.data.data.body.programs
                    },
                    // channel:{
                    //     isLoaded : true,
                    //     list: response.data.data.body[0].related_channels
                    // },
                    detail1:{
                        isLoaded : true,
                    }
                })
                if(!isMobile){
                    // classRef.loadVideo();
                } 
            }else if(response.data.code === 500 || response.data.code === 400){
                window.location.href = "/";
            }
        }).then(function (error) {
            console.log('error =>',error);
        });
    };

    // addFavourite = (programId) =>{
    //     let user_id = localStorage.getItem('user_id');
    //     let classRef = this;
    //     axios({
    //         method: 'POST',
    //         url: Constant.url+"add-user-favourite-programs",
    //         headers: {},
    //         data: {program_id: programId, user_id: user_id}
    //     }).then(function (result) {
    //         let response = {};
    //         response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

    //         if(response.data.code === 200 && response.data.status === true){
    //             classRef.setState({
    //                 isFav: true
    //             });
    //         }

    //     })
    // }

   

    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                
                
                {this.state.detail1.isLoaded ? <>
                <Header/>

                
                <div class="content_sec">
                {/* {(this.state.detail.isLoaded === true) ?
                        <section class={(this.state.detail.list.channel_banner != '') ? "channel_feature_sec m_h" : "channel_feature_sec no_feature m_h"}>
                            <div className="NewHomeSlider_inner desktopSlide">
                                <img src={(this.state.detail.isLoaded === true && this.state.detail.list.channel_banner != '') ? this.state.detail.list.channel_cover : this.state.detail.list.channel_cover} alt={(this.state.detail.isLoaded === true) ? this.state.detail.list.channel_name : ''} width={1200} height={560} color={'#ffffff'} />
                                
                                <div className="NewHomeSliderBg"></div>
                                <div className="NewHomeSlider_txt">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="NewHomeSlider_btns uk-margin-small-bottom">
                                            </div>
                                            <img src={(this.state.detail.isLoaded === true && this.state.detail.list.channel_logo_jpg != '') ? this.state.detail.list.channel_logo_jpg : ''} />
                                            <h3>{(this.state.detail.isLoaded === true) ? this.state.detail.list.channel_name : ''} <br />
                                            </h3>
                                            <div className="NewHomeSlider_btns gf_txt">
                                                
                                                <Link className="gf_watch_btn" to={(this.state.latest_episodes.isLoaded === true && this.state.latest_episodes.list.length > 0) ? "/watch/" + this.state.latest_episodes.list[0].episode_slug : ''}>
                                                    <img src="/images/play.svg" uk-svg="" /> Watch
                                                </Link>
                                               
                                            </div>
                                            <p>
                                               
                                            </p>
                                        </div></div>
                                </div>
                            </div>
                            <div className="NewHomeSlider_inner mobileSlide">
                                <img src="/images/slider/mobile-header-Awaz.png" />
                                <div className="NewHomeSliderMobBtns">
                                    <div className="NewHomeSliderMobBtnsInner">
                                     
                                        <button className="LiveNowBtn"><img src="/images/play.svg" uk-svg="" /> <span>Live Now</span></button>
                                     
                                    </div>
                                </div>
                            </div>
                        </section>
                        : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>
                        } */}
                    <hr/>                    
                    {window.mobilecheck() === false  ? <div className="ad_container uk-margin-bottom">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970X250-6" />
                        </div></div> : <div className="ad_container uk-margin-bottom">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob"} dfpNetworkId="205019744" sizes={[ [320, 100]]} adUnit="MJUNOON.TV-320X100-6" />
                        </div>
                    </div>}
                    <hr/>                    
                    {/* {(this.state.popular_episodes.isLoaded === true && isMobile) ?
                        <WC_EpisodeMobile  name="Most Popular Videos" data={this.state.popular_episodes.list} />
                            : ''} */}
                    {(this.state.popular_episodes1.isLoaded === true && !isMobile) ?
                       
                            <WC_ProgramList name="Dramas" data={this.state.popular_episodes1.list} />  
                            : ''}
                    <hr/>
                    {/* {this.state.popular_episodes.isLoaded === true ? <WC_Channel name ={this.state.detail.list.channel_type+" Channels"} data={this.state.channel.list}/>:''} */}
                </div>
                <Footer/>
                </>
                :
                <Loader/>
                }
            </div>
        );
    }
}
export default withRouter(ProgramList);
