import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
class Event_Banner extends React.Component{
    handleEventList = () => {
        let events = "";
        events = this.props.data.map((event,index) => (
            <div key={index} className="uk-height-match" uk-grid="">
                <div className="uk-width-3-5@m">
                    <div className="mdl">
                        <div className="mdl_inner"> <Link className="gf_img" to={"/"+event.event_watch}><ImageWebp webp={event.event_banner} src={event.event_banner_jpg} alt={event.event_name}/> </Link> </div>
                    </div>
                </div>
                <div className="uk-width-2-5@m">
                    <div className="mdl">
                        <div className="mdl_inner">
                            <div className="gf_txt">
                                <h2>{event.event_name}</h2>
                                <Link to={"/"+event.event_watch} className="gf_watch_btn"><span uk-icon="play"></span> Watch</Link> <Link to={"/"+event.event_watch_list} className="gf_watchlist_btn"><img src="/images/watchlist_btn.svg" uk-svg=""/> Watch List</Link>
                                <p><b>{event.event_name}</b></p>
                                <p>{event.event_desc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
        return events;
    };
    render(){
        return (
            <div>
                <section className="gf_sec pad_left pad_tb pad_right" style = {{'backgroundImage' : 'url('+this.props.data[0].event_background+')'}}>
                    {this.handleEventList()}
                </section>
            </div>
        );
    }
}

export default Event_Banner;