import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class UpNext extends React.Component{
    handleEpisodeList = () => {
        let episodes = "";
        episodes = this.props.data.map((episode,index) => (
            
            <li key={index} className="">
              <div className="prog_sldr_box">
                <div className="prog_sldr_img"><a href={'/watch/'+episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail_jpg} src={episode.episode_thumbnail} alt="Aaj ka Tarka"/>
                  <div className="duration_time"><span>{episode.episode_duration}</span></div>
                  <div className="hvr_box"><span uk-icon="play"></span></div>
                  </a> </div>
                <div className="prog_sldr_text">
                  <h4 className="homesectionone"><b><a className="anchor" href={'/watch/'+episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</a></b></h4>
                  <p><span>{episode.program_name} </span> <span>{episode.episode_views} views . </span><span>{episode.episode_date}</span> </p>
                </div>
              </div>
            </li>
                
        ));        
        return episodes;
    };  
    render(){
        return (
            <div>
                <ul className="upnext_ul continue_watching_d">
                    {this.handleEpisodeList()}
                </ul>
                <ul className="upnext_ul continue_watching_m">
                    {this.handleEpisodeList()}
                </ul>
            </div>
        );
    }
}

export default UpNext;