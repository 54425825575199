import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import LocalStorageService from "../../utils/LocalStorageService";
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WC_Episode from '../section/WC_Episode';
import WC_EpisodeMobile from "../section/WC_EpisodeMobile";
import WC_Channel from '../section/WC_Channel';
import MetaCreation from "../../utils/MetaCreation";
import Meta from "../section/Meta"
import $ from 'jquery';
import { isMobile } from "react-device-detect";
import WC_Movie from '../section/WC_Movie';
import Loader from '../section/loader';
import Program from './Program';

// import {AdSlot} from "react-dfp";


class MoviesList extends React.Component{
    constructor(){
        super();

        this.callProgramApi = this.callProgramApi.bind(this);

        this.state = {
            latest_episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            popular_episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            web_series : {
                list:[],
                isLoaded : false,
            },
            channel: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            detail: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            isFav : false
        }
    }
    componentDidMount(){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callProgramApi);
        DFPManager.load();
        
    }

    componentWillReceiveProps(nextProps){
        MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));
        this.setState({latest_episodes:{isLoaded : false},popular_episodes:{isLoaded : false},channel:{isLoaded : false},detail:{isLoaded : false}})
        LocalStorageService._serviceToken(this.callProgramApi);
        DFPManager.load()
    }

    callMetaData = (metaResponse) =>{

        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callProgramApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let slug = window.location.pathname.replace(/^\/|\/$/g, '');

        {/** remove this condition this is temporarily in place just to make the url functional at the moment */}
        if(slug === "movies"){
            slug = "lollywood";
        }
        console.log("path name: ", window.location.pathname.replace(/^\/|\/$/g, ''));
        axios({
            method: 'POST',
            url: Constant.url+"movies-v3",
            data: {user_id: user_id, slug: slug},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            // console.log("response: ", response.data);
            if(response.data.code === 200){
                console.log(response.data.data.body[0])
                console.log( response.data.data)
                classRef.setState({
                    // latest_episodes:{
                    //     isLoaded : true,
                    //     list: response.data.data.body[0].latest
                    // },  
                    popular_episodes:{
                        isLoaded : true,
                        list: response.data.data.body[0].popular
                    },

                    web_series:{
                        isLoaded : true,
                        list : response.data.data.body[0].webseries
                    },

                    
                    // channel:{
                    //     isLoaded : true,
                    //     list: response.data.data.body[0].related_channels
                    // },
                    detail:{
                        isLoaded : true,
                        list: response.data.data.body[0].channel
                    }
                })
                if(!isMobile){
                    // classRef.loadVideo();
                } 
            }else if(response.data.code === 500 || response.data.code === 400){
                window.location.href = "/";
            }
        }).then(function (response) {

        });
    };
 

    addFavourite = (programId) =>{
        let user_id = localStorage.getItem('user_id');
        let classRef = this;
        
        
        
        axios({
            method: 'POST',
            url: Constant.url+"add-user-favourite-programs",
            headers: {},
            data: {program_id: programId, user_id: user_id}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if(response.data.code === 200 && response.data.status === true){
                classRef.setState({
                    isFav: true
                });
            }

        })
    }

    // loadVideo() {
    //     let classRef =this;
    //     setTimeout(function(){    
                                                              
    //         var vid = document.getElementById("slider_video_program");  
    //         $("#slider_video_program  source").attr('src', classRef.state.latest_episodes.list[0].episode_video_url);                                                                              
    //         $("#slider_video_program")[0].load();
    //         $("#slider_video_program")[0].play();
    //         $("#slider_video_program")[0].muted = true;
    //         $("#slider_video_program")[0].onloadeddata = function () {
    //             $('#NewHomeSlider_vidVolume_program').show() 
    //             vid.play();
    //             vid.muted = true;
    //             $('.channel_feature_sec').addClass('SlideActive')               
    //             $('#slider_video_program').show();            
    //             $('.NewHomeSlider_vid').on('click', '#NewHomeSlider_vidVolume_program', function(e) {if($('#NewHomeSlider_vidVolume_program').hasClass('vidVolumemute')){$('#NewHomeSlider_vidVolume_program').removeClass('vidVolumemute');vid.muted = true;}else{$('#NewHomeSlider_vidVolume_program').addClass('vidVolumemute');vid.muted = false;}});                                                                            
    //         };           
    //         vid.onended = function() {        
    //             vid.pause();          
    //             $('.channel_feature_sec').removeClass('SlideActive')                                      
    //         };                                      
    //     },2000) 
    // }

    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                {/*<Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>*/}
                
                
                {this.state.detail.isLoaded ? <>
                <Header/>

                
                <div class="content_sec">
                {(this.state.detail.isLoaded === true) ?
                        <section class={(this.state.detail.list.channel_banner != '') ? "channel_feature_sec m_h" : "channel_feature_sec no_feature m_h"}>
                            <div className="NewHomeSlider_inner desktopSlide">
                                {/* <div className="NewHomeSlider_vid">
                                    <video style={{ display: 'none' }} id={"slider_video_program"} width={1366}>
                                        <source src="" type="video/mp4" />

                                    </video>
                                    <button className="NewHomeSlider_vidVolume" style={{ display:'none' }} id={"NewHomeSlider_vidVolume_program"}>
                                        <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                                        <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                                    </button>
                                </div> */}
                                {/* {isWebpSupported() ? <Image src={banner.thumbnail} alt={banner.name} width={1200} height={560} color='#ffffff'  /> : <img src={banner.thumbnail_jpg} alt={banner.name} width={1200} height={560} color={'#ffffff'}  />}  */}
                                <img src={(this.state.detail.isLoaded === true && this.state.detail.list.channel_banner != '') ? this.state.detail.list.channel_cover : this.state.detail.list.channel_cover} alt={(this.state.detail.isLoaded === true) ? this.state.detail.list.channel_name : ''} width={1200} height={560} color={'#ffffff'} />
                                
                                <div className="NewHomeSliderBg"></div>
                                <div className="NewHomeSlider_txt">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="NewHomeSlider_btns uk-margin-small-bottom">
                                            </div>
                                            <img src={(this.state.detail.isLoaded === true && this.state.detail.list.channel_logo_jpg != '') ? this.state.detail.list.channel_logo_jpg : ''} />
                                            <h3>{(this.state.detail.isLoaded === true) ? this.state.detail.list.channel_name : ''} <br />
                                            </h3>
                                            <div className="NewHomeSlider_btns gf_txt">
                                                
                                                <Link className="gf_watch_btn" to={(this.state.popular_episodes.isLoaded === true && this.state.popular_episodes.list.length > 0) ? '/movies/' + this.state.popular_episodes.list[0].episode_slug  : '/watch-live-tv-channels/'}>
                                                    <img src="/images/play.svg" uk-svg="" /> Watch
                                                </Link>
                                                {/* <a id="adtofaval" class="gf_watch_btn adtofav" onClick={(e) => this.addFavourite(this.state.detail.list.channel_id, e)}><img src={"/images/heart.svg"} alt="heart" uk-svg="" /> {(this.state.isFav) ? 'Remove Favourites' : 'Add to Favourites'}</a> */}
                                            </div>
                                            <p>
                                                {/* {(this.state.detail.isLoaded === true && this.state.detail.list.channel_banner != '') ? this.state.detail.list.program_desc : ''} */}
                                            </p>
                                        </div></div>
                                </div>
                            </div>
                            <div className="NewHomeSlider_inner mobileSlide">
                                <img src="/images/slider/mobile-header-Awaz.png" />
                                <div className="NewHomeSliderMobBtns">
                                    <div className="NewHomeSliderMobBtnsInner">
                                        {/* <button className="InfoBtn"  onClick={(e)=>this.showDetailsInfo("HomeSliderInfoBox_"+index,e)}><img src="/images/player_info.svg" uk-svg="" /> <span>Details</span></button> */}
                                        <button className="LiveNowBtn"><img src="/images/play.svg" uk-svg="" /> <span>Live Now</span></button>
                                        {/* <button className="FavBtn"><img src="/images/player_heart.svg" uk-svg="" /> <span>Favorite</span></button> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                    <hr/>                    
                    {window.mobilecheck() === false  ? <div className="ad_container uk-margin-bottom">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970X250-6" />
                        </div></div> : <div className="ad_container uk-margin-bottom">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob"} dfpNetworkId="205019744" sizes={[ [320, 100]]} adUnit="MJUNOON.TV-320X100-6" />
                        </div>
                    </div>}
                    <hr/>                    
                    {/* {(this.state.popular_episodes.isLoaded === true && isMobile) ?
                        <WC_EpisodeMobile  name="Most Popular Videos" data={this.state.popular_episodes.list} />
                            : ''} */}
                    {(this.state.popular_episodes.isLoaded === true && !isMobile) ?
                        // <WC_Episode  name="Most Popular Videos" data={this.state.popular_episodes.list} />
                        <WC_Movie name="Most Popular Videos" data={this.state.popular_episodes.list} />
                        
                            : ''}
                    {(this.state.popular_episodes.isLoaded === true && !isMobile) ?
                        // <WC_Episode  name="Most Popular Videos" data={this.state.popular_episodes.list} />
                         <WC_Movie name="Web Series" data={this.state.web_series.list} />
                        
                            : ''}

                {/* if (home.type === 'programs') {
                if(isMobile){
                    return <MobileProgram key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.programs} />
                }else{
                    return <Program key={key} sliderKey={this.state.pageNumber + index} name={home.heading} data={home.programs} />
                }
            }  */}

                    
                    <hr/>

                    {/* {this.state.popular_episodes.isLoaded === true ? <WC_Channel name ={this.state.detail.list.channel_type+" Channels"} data={this.state.channel.list}/>:''} */}
                </div>
                <Footer/>
                </>:<Loader/>}
            </div>
        );
    }
}
export default withRouter(MoviesList);