import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { isMobile } from "react-device-detect";
import { AdSlot, DFPManager } from 'react-dfp';
import LocalStorageService from "../../utils/LocalStorageService";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import LiveChannels from "../section/LiveChannels";
import TypesFilter from "../section/TypesFilter";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import AllChannels from '../section/AllChannels';
import LiveTVShimmer from '../section/LiveTVShimmer';
import TypesFilterShimmer from '../section/TypesFilterShimmer';
import Loader from '../section/loader';



var adTimerInterval;
var initializeAdTimer;
class Live_Tv extends React.Component {
    constructor() {
        super();
        let classRef = this;
        this.callChannelApi = this.callChannelApi.bind(this);
        this.handleChannelList = this.handleChannelList.bind(this);
        // this.initializeCarousel = this.initializeCarousel.bind(this);
        this.handleCarouselBanner = this.handleCarouselBanner.bind(this);
        this.currentCarouselPosition = this.currentCarouselPosition.bind(this);
        this.state = {
            mastHead: {
                url: '',
                image: ''
            },
            channels: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            carouselBanners: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            channelUrl: {
                url: '',
                channelName: '',
                slug: '',
                trendingUp: 1,
                trendingDown: 5,
                isLoaded: false,
                views: ''
            },
            allTypes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            isAds: false,
            isMuted: true,
            channelType: {
                isLoaded: false,
                list: {},
            },
            article: []
        }

    }
    componentDidMount() {
        MetaCreation.createMeta('static', window.location.pathname.replace(/^\/|\/$/g, ''), (e) => this.callMetaData(e));
        LocalStorageService._serviceToken(this.callChannelApi);
        DFPManager.load();
        let classRef = this;
        this.channelTypeApi();

    }

    componentWillUnmount() {
        
        // window.destroyPlayers();
        clearInterval(adTimerInterval);
        clearInterval(initializeAdTimer);
    }

    componentDidUpdate() {
        // this.initializeCarousel();
    }

    channelTypeApi = () => {
        let classRef = this;
        axios({
            method: 'GET',
            url: Constant.url + "/channel-types",
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                classRef.setState({
                    channelType: {
                        isLoaded: true,
                        list: response.data.data.types
                    }
                })
            }
        }).then(function (response) {
            
        });
    };

    callMetaData = (metaResponse) => {

        this.setState({
            metaKeywords: metaResponse.keywords,
            metaTitle: metaResponse.title,
            metaDescription: metaResponse.description,
            metaImage: metaResponse.thumbnail,
            schema: metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callChannelApi = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url + "all-channels",
            data: { user_id: user_id },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                
                classRef.setState({
                    mastHead: {
                        url: response.data.mast_head_url,
                        image: response.data.mast_head_image
                    },
                    channels: {
                        isLoaded: true,
                        list: response.data.data.body
                    },
                    carouselBanners: {
                        isLoaded: true,
                        list: response.data.data.frameChannel
                    },
                    allTypes: {
                        isLoaded: true,
                        list: response.data.data.types
                    },
                    article:response.data.data.article
                });
                if (response.data.is_mast == '1') {
                    adTimerInterval = setTimeout(function () {
                        $(".ad_cont").addClass('ad_act_ii');
                        classRef.initializeAd();
                    }, 3000);
                }
                
                classRef.callGetChannelUrlApi(classRef.state.carouselBanners.list[0].channel_slug);
                // $('.filter_btn').click(function(){
                //     $('.filter_box').slideToggle();
                // });
                // $('.filter_box ul li a').click(function(){
                //     $('.filter_box').slideToggle();
                // });
                $(document).click(function () {
                    $('.filter_box').slideUp();
                    $('.filter_btn').removeClass('active');
                    $('.filter_box ul li a').removeClass('active');
                });

                $('.filter_btn').click(function (event) {
                    $('.filter_box').slideToggle();
                    $(this).toggleClass('active');
                    event.stopPropagation();
                });
                $('.filter_box ul li a').click(function (event) {
                    $('.filter_box').slideToggle();
                    $(this).toggleClass('active');
                    event.stopPropagation();
                });
                $('.filter_box').click(function (event) {
                    event.stopPropagation();
                });
                $('.fltr_close_btn').click(function (event) {
                    $('.filter_box').slideToggle();
                    $(this).toggleClass('active');
                    event.stopPropagation();
                });
            }
        }).then(function (response) {
            
        });




    };


    initializeAd = () => {
        var classRef = this;
        initializeAdTimer = setTimeout(function () {
            

            // var src = {
            //     hls: this.state.channelUrl.url
            // };
            // var backupSrc = {
            //     hls: this.state.backup_live_stream_url
            // };
            // Then we set our player settings
            


            var adSettings = {
                licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
                width: 640,
                height: 360,
                skin: 's3',
                autoplay: true,
                ads: true,
                adTagReloadOnEnded: false,
                // by default we use Google IMA but we can also use rmp-vast for outstream ads
                // adParser: 'rmp-vast',
                adOutStream: true,
                // adTagUrl: 'https://pubads.g.doubleclick.net/gampad/live/ads?iu=/205019744/MJUNOON.TV_MastheadAd&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
                adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/205019744/MJUNOON.TV-PrerollAds&description_url=[placeholder]&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
                // we use client-side waterfalling in this case (optional)
                // adTagWaterfall: [
                //     'https://pubads.g.doubleclick.net/gampad/live/ads?iu=/205019744/MJUNOON.TV_MastheadAd&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
                // ]
            };

            // Reference to player container
            var elementID = 'adPlayer';
            // Object creation
            var rmp = window.initializeRMP(elementID);
            var container = document.getElementById(elementID);

            var _trace = function (input) {
                if (window.console.trace && input) {
                    window.console.trace(input);
                }
            };

            var _removeElement = function (element) {
                var parent = element.parentNode;
                if (parent) {
                    try {
                        parent.removeChild(element);
                    } catch (e) {
                        _trace(e);
                    }
                }
            };

            // when destroy method finishes we clear listeners and remove player from DOM
            var _onDestroyCompleted = function () {
                container.removeEventListener('destroycompleted', _onDestroyCompleted);
                _removeElement(container);
            };

            // player needs be removed from page
            // first we need to destroy it
            var _removePlayer = function () {

                $('.ad_cont').removeClass("ad_act_ii");
                $('.sidebar').removeClass("ad_act");
                
                container.addEventListener('destroycompleted', _onDestroyCompleted);
                rmp.destroy();
            };

            // function to fade out player
            var _endPlayer = function () {
                container.removeEventListener('autoplayfailure', _endPlayer);
                container.removeEventListener('adcontentresumerequested', _endPlayer);
                container.removeEventListener('addestroyed', _endPlayer);
                // nicely fade out player and remove it from DOM
                container.style.opacity = 0;
                container.style.visibility = 'hidden';
                setTimeout(function () {
                    _removePlayer();
                }, 400);
            };

            container.addEventListener('ready', function () {
                // if Google IMA has been blocked by an ad-blocker or failed to load
                // we need to remove the player from DOM
                // $('.ad_space ').slideToggle('slow').addClass( "active" );
                // $('.sidebar').css("top","420px");
                // $('.sidebar').addClass('ad_act');
                // $('.ad_cont').addClass('ad_act_ii');
                // document.documentElement.scrollTop = 0;
                $('html, body').animate({
                    scrollTop: $("body").offset().top
                }, 1000)
                
                if (rmp.getAdParserBlocked()) {
                    _removePlayer();
                    return;
                }
            });

            // when ad ends - adcontentresumerequested event for Google IMA or addestroyed event for rmp-vast
            // we fade out player and remove it from DOM
            // in case of autoplayfailure event we also need to remove it - note that autoplayfailure should
            // be infrequent if you are using muted autoplay as recommended
            // whichever comes first
            container.addEventListener('autoplayfailure', _endPlayer);
            container.addEventListener('adcontentresumerequested', _endPlayer);
            container.addEventListener('addestroyed', _endPlayer);

            // Player initialisation - only after API events are registered
            rmp.init(adSettings);
            window.ADRMP = rmp;
        }, 10000)


    }

    handleChannelList = () => {
        let channels = "";
        let index = 0;
        let channellength = this.state.channels.list.length - 1;
        
        channels = this.state.channels.list.map((channelVal, i) => {
            if (channelVal.type === 'favourite_channel') {
                if (channellength === i) {
                    return (channelVal.channel) ? <LiveChannels key={i} ad_class='a' name={channelVal.heading} data={channelVal.channel} /> : '';
                } else {
                    return (channelVal.channel) ? <LiveChannels key={i} name={channelVal.heading} data={channelVal.channel} /> : '';
                }
            }
             else if (channelVal.type === 'channel') {
                if (channellength === i) {
                    return (channelVal.channel) ? <LiveChannels key={i} typeid={channelVal.type_id} ad_class='a' name={channelVal.heading} data={channelVal.channel} /> : '';
                } else {
                    return (channelVal.channel) ? <LiveChannels key={i} typeid={channelVal.type_id} name={channelVal.heading} data={channelVal.channel} /> : '';
                }
            } 
            else if (channelVal.type === 'google_ad' && i !== 0) {
                return (window.mobilecheck() === false && this.state.isAds === true && !isMobile) ? <div key={i} className="ad_container uk-margin-top">
                    <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad" + i} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90-4" />
                    </div></div> : (this.state.isAds === true) ? <div className="ad_container uk-margin-top">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob" + i} dfpNetworkId="205019744" sizes={[[320, 50]]} adUnit="MJUNOON.TV-320X50-4" />
                        </div>
                    </div>
                    : ''
                
                // return (<div className="ad_container uk-margin-top">
                //     <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"+key} dfpNetworkId="205019744" sizes={[[728, 90]]} adUnit="MJUNOON.TV-728X90" />
                //     </div></div>)
            }

        });
        return channels;
    };

    handleCarouselBanner = () => {
        let banners = [];
        // banners = this.state.carouselBanners.list.map((banner, index) => {
        //     // return <div className="carousel-item" href="#"><video className="live_channel_player" id={"channel_stream"+index} poster={banner.channel_live_image} src="https://yootheme.com/site/images/media/yootheme-pro.mp4"  playsinline ></video></div>
        //     return <div className="carousel-item" href="#">
        //         <video id={"channel_stream"+index} poster={banner.channel_live_image} className="video-js vjs-default-skin" muted controls preload="auto" width="670"
        //                height="380"
        //                data-setup='{}'>
        //             <source src="http://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8"
        //                     type="application/x-mpegURL"/>
        //         </video></div>
        // });

        this.state.carouselBanners.list.map((banner, index) => {
            // return <div className="carousel-item" href="#"><video className="live_channel_player" id={"channel_stream"+index} poster={banner.channel_live_image} src="https://yootheme.com/site/images/media/yootheme-pro.mp4"  playsinline ></video></div>
            banners.push((isWebpSupported) ? banner.channel_live_image : banner.channel_live_image_jpg);
        });

        // this.initializeCarousel();
        
        return banners;
    };


    // initializeCarousel = () =>{
    //     window.initializeCarousel();
    
    //
    // };

    currentCarouselPosition = (data) => {


        let activeStream = $(data).index();
        var i = 7;
        if (activeStream < 0) {
            activeStream = activeStream * -1;
            if (activeStream > 6) {
                activeStream = activeStream % 7;
            }
            activeStream = i - activeStream;
        }
        if (activeStream > 6) {
            activeStream = activeStream % 7;
        }
        
        //
        while (i > 0) {
            i--;
        }
        // if(activeStream != 0) {
        this.callGetChannelUrlApi(this.state.carouselBanners.list[activeStream].channel_slug)
        // }
    };


    callGetChannelUrlApi = (slug) => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "streaming-url",
            data: { slug: slug, is_rewind: "no", type: "channel", user_id: "" },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if (response.data.code === 200 && response.data.status === true) {
                classRef.setState({
                    channelUrl: {
                        isLoaded: true,
                        url: response.data.data.live_stream_url,
                        slug: slug,
                        channelName: response.data.data.channel_name,
                        trendingUp: response.data.data.trending_up,
                        trendingDown: response.data.data.trending_down,
                        views: response.data.data.views
                    }
                });

                
            }
        })
    };

    mutedTrue = () => {
        $("#mute").hide()
        $("#sound").show()
        this.setState({ isMuted: true })
    }

    mutedFalse = () => {
        $("#sound").hide()
        $("#mute").show()
        this.setState({ isMuted: false })
    }

    render() {
        return (
            <div>
                <Meta schema={null} metaKeywords={this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription} metaImage={this.state.metaImage} />
                
                
                {this.state.channels.isLoaded ?   
                <>
                <Header />
                <div className="ad_cont ">
                    <div className="img_before" style={{ backgroundImage: 'url(https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg)' }} />
                    <div className="ad_space ">
                        <div className="mastHead" />
                        <div id="adPlayer" />
                    </div>
                </div>
                <div className="content_sec">

                    {(window.mobilecheck() === false && this.state.isAds === true && !isMobile) ? <div className="ad_container uk-margin-top">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970X250-4" />
                        </div></div> : (this.state.isAds === true) ? <div className="ad_container uk-margin-top">
                            <div className="desktop-ads11 mob_ad">
                                {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                                <AdSlot slotId={"content-ad-mob"} dfpNetworkId="205019744" sizes={[[320, 100]]} adUnit="MJUNOON.TV-320X100-4" />
                            </div>
                        </div> : ''}
                    {this.state.channelType.isLoaded === true && this.state.allTypes.isLoaded === true? <AllChannels data={this.state.channelType.list} article={this.state.article}/> : <LiveTVShimmer />}
                    {/* <section className="carousel_sec pad_left pad_tb pad_right">
                        {this.state.channelUrl.isLoaded === true ? <Carousel images={this.handleCarouselBanner()} options={{indicators: true,onCycleTo: (e) => this.currentCarouselPosition(e)}}/> : ''}
                        <div className="live-tv-player">
                        <Link to={this.state.channelUrl.slug}>
                        <span class="live_span">Live</span>                        
                        <span class="views_txt"><img src="/images/eye.svg" alt="Eye" uk-svg="" />{this.state.channelUrl.isLoaded === true ? this.state.channelUrl.views : ''}</span>
                            <ReactPlayer url={this.state.channelUrl.isLoaded === true ? this.state.channelUrl.url : ''} playing={true} muted = {(this.state.isMuted === true) ? true : false} controls={true}  config={{ file: { attributes: { controlsList: 'nodownload'} } }} pip={false} className='react-player'  width='665px' height='380px'/>      
                            <div className="trnd_box">
                            <div className="trnd_box_inner">
                            <div className="trnd_number"><span>{this.state.channelUrl.isLoaded === true ? this.state.channelUrl.trendingDown : ''}</span>
                            <i uk-icon="icon: arrow-up"></i>{this.state.channelUrl.isLoaded === true ? this.state.channelUrl.trendingUp : ''}
                            </div>
                            <h1>{this.state.channelUrl.isLoaded === true ? "Trending" : ''}</h1>
                            <p>{this.state.channelUrl.isLoaded === true ?  this.state.channelUrl.channelName : ''}</p>
                            
                        </div></div></Link>
                        <p className="mutebtn1" id="sound" onClick={this.mutedFalse} ><img src="/images/icons/mute.svg" uk-svg="" /></p>
                        <p className="mutebtn2" style={{'display' :'none'}} id="mute" onClick={this.mutedTrue} ><img src="/images/icons/sound.svg" uk-svg="" /></p>
                        </div>
                    </section>:''} */}


                    {this.state.allTypes.isLoaded === true ? <TypesFilter data={this.state.allTypes.list} /> : <TypesFilterShimmer />}
                    {this.state.channels.isLoaded === true ? this.handleChannelList() : (this.state.channels.isLoaded === false) ? '' : ''}
                    <section className="pad_left pad_tb pad_right">
                        <div className="LivetvContentSec">
                            <h2>{(this.state.allTypes.isLoaded === true)?this.state.article[1].heading:''}</h2>
                            <p>{(this.state.allTypes.isLoaded === true)?this.state.article[1].desc:''}</p>                            
                            <h2>{(this.state.allTypes.isLoaded === true)?this.state.article[2].heading:''}</h2>
                            <p>{(this.state.allTypes.isLoaded === true)?this.state.article[2].desc:''}</p>
                        </div>
                    </section>
                </div>
                <Footer />
                </> : <Loader/> }
            </div>
        );
    }
}

export default withRouter(Live_Tv);