import React from 'react';

class ProgramShimmer extends React.Component {    

    render() {
        return (
            <div>
                <section className="pad_left pad_tb shows_slider_sec CrouselShimmerCont">
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong></strong></h2>
                        <div className="view_all"></div>
                    </div>
                    <div className="CrouselShimmerBox overflowscroll">
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        <div className="CrouselShimmerBoxinner ProgShimer">
                            <div className="shows_slider_img">  
                            </div>
                            <div className="shows_slider_txt">
                               <h4></h4>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
        );
    }
}

export default ProgramShimmer;