import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import ImageWebp from 'react-image-webp';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";

class PersonalizeChannel extends React.Component{
    constructor(){
        super();      
        this.state = {
          channals : []
        }
    }
    
    handleChannelList = () => {
      let channels = "";
      channels = this.props.data.map((channel,index) => (
        <div class="channel_live_cont_inner"> <a class={(channel.channel_fav) ? "channel_sldr_box active" : "channel_sldr_box"} id = {"channel_sldr_box" +index} onClick={(e) => this.channelActive("channel_sldr_box"+index,channel.channel_id,e)}>
          <div class="channel_sldr_img"><ImageWebp webp={channel.channel_banner} src={channel.channel_banner_jpg} alt={channel.channel_name}/>
            <div class="del_box"><img src="/images/del.svg" alt="delete" uk-svg=""/></div>
             </div>
          <div class="channel_sldr_txt">
            <h3 class="h3tag">{channel.channel_name}</h3>
          </div>
          </a> 
        </div>
      ));        
      return channels;
    };

    channelActive = (e,channelid) => { 
      
      this.setState({
        channals: this.state.channals.concat(channelid)
      })
      
      $("#"+e).toggleClass('active');
    }
    addChannel = () =>{
      $("#submitbtn").text('Processing...');
      LocalStorageService._serviceToken(this.initializeToken);
    }
    
    initializeToken = () => {
      
      let classRef = this;
      let user_id = localStorage.getItem('user_id');
      let params = JSON.stringify({channels : this.state.channals, user_id: user_id });
      axios({
          method: 'POST',
          url: Constant.url+"add-favourite-channels-2",
          data: { dataparams: LocalStorageService.encryptAes(params)},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200){
              $("#skipVal").click();   
              // console.log('successfully inserted channels');
              // return <Redirect  to='/basicdetail'/>;                                               
          }else{
              // $("#submitbtn").text('NEXT');
              $("#skipVal").click(); 
          }
      }).then(function (result) {
          // console.log(response);
      });
    }

    render(){
        return(
           <div class="splash">
                <div id="modal-full" class="popup uk-modal-full uk-modal uk-open" uk-modal style={{display : 'block'}}>
                <header>
                  <div class="uk-container uk-container-expand"> <Link class="logo" to="/"><img src="/images/logo.png" alt="Mjunoon TV"/></Link>
                    <div class="right_hdr"> 
                      <div class="skip">
                      <p id="skipVal" onClick={this.props.nextVal}>SKIP</p>
                      </div> 
                  	</div>
                  </div>
                </header>	
                    <div class="uk-modal-dialog popup_content personlize ">
                    	<div class="">
                    		<div class=""> 
                        <section class="pad_left pad_right pad_tb prog_box_sec margin_personlize">
                        	<h1>Choose your favorite Channels</h1>
                    				<p>Your experience will improve the more you watch</p>
                          <div class="channel_live_cont">
                            {this.handleChannelList()}
                          </div>
                        </section>
                    		</div>
                    	</div>
                    </div>
                {/* <!-- Footer Start --> */}
                <footer>
                	<div class="gf_txt  ">
                  
                		<a id="submitbtn" onClick = {this.addChannel} class="gf_watch_btn">NEXT</a>
                	</div>
                </footer>
                {/* <!-- Footer End -->      */}
                </div>               
           </div>
        )
    }
}
export default withRouter(PersonalizeChannel);