import React from 'react';
import { Link } from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import ShowMoreText from "react-show-more-text";
import $ from "jquery";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
class WC_Episode extends React.Component {

  componentDidMount() {
    let classRef = this;
    setTimeout(function () {
      classRef.renderHover();
    }, 2000)

    function removeSlugAndSlash(inputString) {
        // Remove "/" from the string
        const withoutSlash = inputString.replace(/\//g, '');
        
        // Remove ":slug" from the string
        const withoutSlug = withoutSlash.replace(/:slug/g, '');
        
        return withoutSlug;
    }
    const { location } = this.props;
    // const currentURL = location.pathname;
    // let slug = this.props.match.params.slug;
    // variable to clean slug and remove / from :slug to get parent url/path
    // let channel_slug = removeSlugAndSlash(this.props.match.path);

    
    console.log("pathname: ", this.props.channelSlug);
  }

  // renderHover = () => {
  //   let classRef = this;
  //   let keywal = 1000;
  //   $.each(classRef.props.data, function (i) { //Loop the array
      
  //     $("#glider_slider_epi"+ '_' + keywal + '_' + i).hover(
  //       function () {


  //         $("#glider_slider_epi"+ '_' + keywal + '_' + i).addClass('HoverClass');
  //         $('#glider_slider_epi').css("overflow", "inherit");

  //         // {"video_url_" + '_' + keywal + '_' + index}
  //         if (classRef.props.data[i].episode_video_url != "") {
  //           $("#video_url_" + '_' + keywal + '_' + i + " source").attr('src', classRef.props.data[i].episode_video_url);
  //           $("#video_url_" + '_' + keywal + '_' + i)[0].load();
  //           $("#video_url_" + '_' + keywal + '_' + i)[0].play();
  //           $("#video_url_" + '_' + keywal + '_' + i)[0].muted = true;
  //           if (!$('#HoverNModel_vidVolume'+ '_' + keywal + '_' + i).hasClass('addvolume')) {
  //             $('.HoverNModelImgVid').on('click', '#HoverNModel_vidVolume' + '_' + keywal + '_' + i,
  //               function (e) {
  //                 if ($('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).hasClass('vidVolumemute')) {
  //                   $('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).removeClass('vidVolumemute');
  //                   $("#video_url_" + '_' + keywal + '_' + i)[0].muted = true;
  //                 } else {
  //                   $('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).addClass('vidVolumemute');
  //                   $("#video_url_" + '_' + keywal + '_' + i)[0].muted = false;
  //                 }
  //               }
  //             );
  //           }
  //           $("#video_url_" + '_' + keywal + '_' + i)[0].onloadeddata = function () {
  //             $('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).show()
  //             $('#img_op_' + '_' + keywal + '_' + i).css('opacity', 0);
  //           };


  //         }
  //         // $("#video_url_" + '_' + i)[0].onended = function() { 
  //         // $('#img_op_' + i).css('opacity', 0);                               
  //         // };          
  //       }, function () {

  //         // $("#video_url_" + '_' + i)[0].pause();
  //         if ($(".HoverClass").length > 0) {
  //           $('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).hide()
  //           $("#glider_slider_epi" + '_' + keywal + '_' + i).removeClass('HoverClass');
  //           $('#glider_slider_epi').css("overflow", "");
  //           $('#img_op_' + '_' + keywal + '_' + i).css('opacity', 1);
  //           if (classRef.props.data[i].episode_video_url != "") {
  //             $("#video_url_" + '_' + keywal + '_' + i)[0].pause();
  //             $("#video_url_" + '_' + keywal + '_' + i)[0].muted = true;
  //           }
  //           $('#HoverNModel_vidVolume' + '_' + keywal + '_' + i).removeClass('vidVolumemute');
  //         }
  //       }
  //     )
  //   })
  // }

  handleEpisodeList = () => {
    let episodes = "";
    let keywal = 1000;

    episodes = this.props.data.map((episode, index) => (
      
      <div key={index} className="uk-width-1-5@xl uk-width-1-4@m uk-width-1-3@s uk-width-1-2">
        <div key={index} id={"glider_slider_epi" + '_' + keywal + '_' + index} >
          <div className="prog_sldr_box">
            <div className="prog_sldr_img">
              {this.props.channelSlug ? 
                <Link to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
                  <div className="duration_time"><span>{episode.episode_duration}</span></div>
                  <div className="hvr_box"><span uk-icon="play"></span></div>
                </Link> : 
                <Link to={'/watch/' + episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
                  <div className="duration_time"><span>{episode.episode_duration}</span></div>
                  <div className="hvr_box"><span uk-icon="play"></span></div>
                </Link>
              }
            
            </div>
            <div className="prog_sldr_text">
              <h4 className="homesectionone"><b>
               {
                this.props.channelSlug ? <Link className="anchor" to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link>
                : <Link className="anchor" to={'/watch/' + episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link>
               }
                </b></h4>
              {/* <p><span>{episode.channel_name} </span> <span>{episode.episode_views} views</span> </p> */}
              <p><span className="episode_channel">{(episode.channel_name === undefined) ? '' : episode.channel_name + " ."}</span> <span>{episode.episode_views} views . {episode.episode_date} </span> </p>

            </div>
          </div>
          {/* Overlay Box Start */}
          <div className="HoverNModelOverlay HoverNModelEpisode">
            <div className="HoverNModel">
              <div className="HoverNModelImgVid">
                {
                  this.props.channelSlug ? <Link className="redirectLink" to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug}></Link> : <Link className="redirectLink" to={'/watch/' + episode.episode_slug}></Link>
                }
                {(episode.episode_video_url != "") ?
                  <video id={"video_url_" + '_' + keywal + '_' + index} width={1366}>
                    <source src="" type="video/mp4" />

                  </video>
                  : ''}
                <ImageWebp id={'img_op_' + '_' + keywal + '_' + index} webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name} />
                {/* <img id={'img_op_'+index} src="https://images.mjunoon.tv:8181/mjunoon_images_new/XMYOWUQNKTY.webp" alt=""/> */}
                <span className="viewerstvg">{episode.episode_views + "Views"}</span>
                {/* <div className="continue_bar"><span style={{ width: '40%' }} /></div> */}
                <button className="HoverNModel_vidVolume" style={{ display: 'none' }} id={'HoverNModel_vidVolume' + '_' + keywal + '_' + index}>
                  <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                  <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                </button>
              </div>
              <div className="HoverNModelTxt">
                <div className="HoverNModelTxtHead">
                  {
                    this.props.channelSlug ? <Link to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug} className="HoverNModelHeading"><h4>{episode.channel_name}</h4>
                    <span>{episode.channel_name + " . " + episode.episode_views + " " + "views" + " . " + episode.episode_date}</span>
                  </Link> :
                  <Link to={'/watch/' + episode.episode_slug} className="HoverNModelHeading"><h4>{episode.channel_name}</h4>
                    <span>{episode.channel_name + " . " + episode.episode_views + " " + "views" + " . " + episode.episode_date}</span>
                  </Link>
                  }
                  <ul>
                    {
                      this.props.channelSlug ? <>                      
                        <li><Link to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug}><img src="/images/player_share.svg" uk-svg="" /></Link></li>
                        <li><Link to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug}><img src="/images/player_heart.svg" uk-svg="" /></Link></li>
                      </>
                      : <>                      
                        <li><Link to={'/watch/' + episode.episode_slug}><img src="/images/player_share.svg" uk-svg="" /></Link></li>
                        <li><Link to={'/watch/' + episode.episode_slug}><img src="/images/player_heart.svg" uk-svg="" /></Link></li>
                      </>

                    }
                    <li>
                      {
                        this.props.channelSlug ? <Link to={'/program/' + this.props.channelSlug + '/' + episode.episode_slug}><img src="/images/player_info.svg" uk-svg="" /></Link> :
                        <Link to={'/watch/' + episode.episode_slug}><img src="/images/player_info.svg" uk-svg="" /></Link>
                      }
                      <button>
                        <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                        <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="ptag">
                  <ShowMoreText
                    lines={1}
                    more="Show more"
                    less="Show less"
                    anchorClass=""
                    // onClick={<Link to={'/watch/' + episode.episode_slug} />}
                    expanded={false}
                  >
                    {ReactHtmlParser(episode.episode_description)}
                  </ShowMoreText>
                </div>
              </div>
            </div>
          </div>
          {/* Overlay Box End */}
        </div>
      </div>

    ));
    return episodes;
  };
  render() {
    return (
      <div>
        <section className={(this.props.name) ? "pad_left pad_right pad_tb prog_box_sec episodeSection " + (this.props.isClass ? this.props.isClass : '') : "pad_left pad_right pad_tb prog_box_sec"} style={{position: "relative", zIndex: "99"}}>
          <div className="headng_box">
            <h2 className="h2forhome"><strong>{this.props.name}</strong></h2>
          </div>
          <div className="uk-height-match uk-grid-small" uk-grid="">
            {this.handleEpisodeList()}
          </div>
        </section>
      </div>
    );
  }
}

export default WC_Episode;