import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
class TypesFilter extends React.Component{

    handletypeList = () => {      
        let types = "";
        types = this.props.data.map((type,index) => (
            <li key={index}><a href={"#type"+type.id} uk-scroll="offset: 20;">{type.type}</a></li>
        ));        
        return types;
    };
    render(){
        return (
            <div>
                <section className="pad_left pad_t pad_right fav_slider uk-position-relative">
                  <a href="javascript:;" className="filter_btn" uk-sticky="offset: 65">Filter <span uk-icon="settings"></span></a>
                  <div id="filter_box" className="filter_box">
                    <h3>Category</h3><a class="fltr_close_btn"><img src="/images/close_icon.svg" uk-svg="" /></a>
                    <ul>
                      {this.handletypeList()}
                    </ul>
                  </div>
                </section>
            </div>
        );
    }
}

export default TypesFilter;