import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class Channel extends React.Component{
  componentDidMount(){
    this.loadGlider()
    
  }
  loadGlider = () =>{
    new window.Glider(document.querySelector('#glider_slider_channel'+this.props.sliderKey), {
      // slidesToShow: 9,
      // slidesToScroll: 1,
      duration: 0.25,
      draggable: true,
      arrows: {
        prev: '#glider_slider_channel'+this.props.sliderKey+'_prev',
        next: '#glider_slider_channel'+this.props.sliderKey+'_next',
      },
      responsive: [
      {
      breakpoint: 360,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',
          duration: 0.25,
        }
      },{
      breakpoint: 375,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',

        }
      },{
      breakpoint: 411,
        settings: {
          slidesToShow: 'auto',
          slidesToScroll: 'auto',

        }
      },
      ,{
      breakpoint: 912,
        settings: {
          slidesToShow: '5',
          slidesToScroll: 'auto',

        }
      },{
      breakpoint: 768,
        settings: {
          slidesToShow: '6',
          slidesToScroll: 'auto',

        }
      }
      
      ,{
      breakpoint: 1024,
        settings: {
          slidesToShow: '4',
          slidesToScroll: 'auto',

        }
      },{
      breakpoint: 1201,
        settings: {
          slidesToShow: '3',
          slidesToScroll: 'auto',

        }
      },{
      breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 'auto',
        }
      },{
      breakpoint: 1401,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 'auto',
        }
      },{
      breakpoint: 1601,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 'auto',
        }
      },{
        breakpoint: 1920,
        settings: {
          exactWidth: true,
          itemWidth: 230,
          slidesToShow: 8,
          slidesToScroll: 'auto',
        }
      }
      ]
    })
  }
    // handleChannelList = () => {
    //     let channels = "";
    //     channels = this.props.data.map((channel,index) => (
    //         <div key={index}> <Link className="channel_sldr_box" to={(channel.channel_status === 'live' ? '/'+channel.channel_slug : "/"+channel.channel_slug)}>

    //         <div className="channel_sldr_img"><ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
    //           <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
    //           <span className="live_span">{(channel.channel_status === 'live') ? channel.channel_status : ''}</span> </div>
    //           {/* <div class="channel_sldr_txt"><h3 class="h3tag">{channel.channel_name}</h3></div> */}
    //         </Link> 
    //         </div>      
            
            
    //     ));        
    //     return channels;
    // };

    // handleProgramList = () => {
    //   let programs = "";
    //   let classRef = this;
    //   programs = this.props.data.map((program, index) => (
    //     <div key={index} id={"glider_slider_pro" + classRef.props.sliderKey + '_' + index} className='ProgramsCard'>
    //       <Link className="newProgBox" to={"/program/" + program.program_slug}>
    //         <div className="shows_slider_img">
    //           <ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} />
    //           <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
    //         </div>
    //         {/* <div class="shows_slider_txt"><h4>{program.program_name}</h4></div> */}
    //       </Link>
    //     </div>
    //   ));
    //   return programs;

    mergeChannelAndProgramList = () => {
      const { data } = this.props;
      const { datapro } = this.props;
      // console.log('BX', datapro);
    
      // Channel list
      let channels = data.map((channel, index) => (
        <div key={index}>
          <Link className="channel_sldr_box" to={channel.channel_status === 'live' ? `/${channel.channel_slug}` : `/${channel.channel_slug}`}>
            <div className="channel_sldr_img">
              <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
              <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
              <span className="live_span">{channel.channel_status === 'live' ? channel.channel_status : ''}</span>
            </div>
          </Link>
        </div>
      ));
    
      // Program list
      let programs = [];
      if (datapro !== undefined) {
        programs = datapro.map((program, index) => (
          <div key={index}>
            <Link className="newProgBox" to={`/programs/${program.program_slug}`}>
              <div className="shows_slider_img">
                <ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} />
                <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
              </div>
            </Link>
          </div>
        ));
      }
    
      const mergedData = channels.concat(programs);
      // console.log('umair', mergedData);
    
      return mergedData;
    };
    mergeChannelAndProgramList = () => {
      const { data } = this.props;
      const { datapro } = this.props;
      // console.log('BX', datapro);
    
      // Channel list
      let channels = data.map((channel, index) => (
        <div key={index}>
          <Link className="channel_sldr_box" to={channel.channel_status === 'live' ? `/${channel.channel_slug}` : `/${channel.channel_slug}`}>
            <div className="channel_sldr_img">
              <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
              <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
              <span className="live_span">{channel.channel_status === 'live' ? channel.channel_status : ''}</span>
            </div>
          </Link>
        </div>
      ));
    
      // Program list
      let programs = [];
      if (datapro !== undefined) {
        programs = datapro.map((program, index) => (
          <div key={index}>
            <Link className="channel_sldr_box" to={`/program/${program.program_slug}`}>
              <div className="channel_sldr_img">
                <ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name} />
                <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
              </div>
            </Link>
          </div>
        ));
      }
    
      const mergedData = channels.concat(programs);
      // console.log('umair', mergedData);
    
      return mergedData;
    };
        
  
    render(){
        return (
            <div>
              <section  className={(window.location.pathname.split('/')[1] === 'headline-updates') ? 'newp40 pad_t channel_box_sec' : 'pad_bh channel_box_sec'}>
                <div className="headng_box" id={'heading'+this.props.sliderKey}>
                  <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name+' '+this.props.type : this.props.name }</strong></h2>
                  {this.props.name !== "Kids" &&
                   this.props.name !== "Sports" &&
                   this.props.name !== 'Entertainment' && 
                   this.props.name !== 'Music' &&
                   this.props.name !== 'Islamic' && this.props.name !== 'Regional TV'  ? <Link className="view_all" to={(this.props.redirection) ? '/watch-live-tv-channels' :(window.location.pathname === '/') ? "/list?type=channel&topic="+this.props.name : ''}>{(window.location.pathname === '/') ?'View All' : ''}</Link> :''}

</div>
                  <div className="slider_new_container circle_center">
                  <div id={'glider_slider_channel'+this.props.sliderKey} className="">
                    {this.mergeChannelAndProgramList()}
                  </div>
                  <button role="button" aria-label="Previous" id={"glider_slider_channel"+this.props.sliderKey+"_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow"/></button>
                  <button role="button" aria-label="Next" id={"glider_slider_channel"+this.props.sliderKey+"_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrow"/></button></div>
              </section>
              <hr/>
            </div>
        );
    }
}

export default Channel;