import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { AdSlot, DFPManager } from 'react-dfp';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import WC_Episode from '../section/WC_Episode';
import WC_Program from '../section/WC_Program';
import WC_ProgramMobile from "../section/WC_ProgramMobile";
import WC_EpisodeMobile from "../section/WC_EpisodeMobile";
import MetaCreation from "../../utils/MetaCreation";
import Meta from '../section/Meta';
import ProgramEpisode from '../section/ProgramEpisode';
import MoreLikeProgram from '../section/MoreLikeProgram';
import { isMobile } from "react-device-detect";
import SliderShimmer from '../section/SliderShimmer';
import EpisodeShimmer from '../section/EpisodeShimmer';
// import {AdSlot} from "react-dfp";
import Loader from '../section/loader';
import SeriesEpisodes from '../section/SeriesEpisodes';
import WC_Program_series from './WC_Program_series';



class Series extends React.Component {
    constructor(props) {
        super(props);
        
        this.callProgramApi = this.callProgramApi.bind(this);



        this.state = {
            latest_episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            episodes: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            programs: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            detail: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            isFav: false,
            isAds: false,
            noDataFound: false,
            tempSlug: ''
        }
    }
    componentDidMount() {
        console.log(this.state.episodes.list)
        console.log(this.props)
        let slug = this.props.match.params.slug;
        console.log("slug from program: ", slug);
        this.setState({tempSlug : slug})
        
        const { location } = this.props;
        const currentURL = location.pathname;
        // console.log("slug from program: ", currentURL);

        MetaCreation.createMeta('program', slug, (e) => this.callMetaData(e));
        LocalStorageService._serviceToken((e) => this.callProgramApi(slug, e));
        // DFPManager.load();
    }

    componentWillReceiveProps(nextProps) {
        
        let slug = nextProps.match.params.slug;
        MetaCreation.createMeta('program', slug, (e) => this.callMetaData(e));
        this.setState({ noDataFound: false, latest_episodes: { isLoaded: false }, episodes: { isLoaded: false }, programs: { isLoaded: false }, detail: { isLoaded: false } })
        LocalStorageService._serviceToken((e) => this.callProgramApi(slug, e));
    }

    callMetaData = (metaResponse) => {

        this.setState({
            metaKeywords: metaResponse.keywords,
            metaTitle: metaResponse.title,
            metaDescription: metaResponse.description,
            metaImage: metaResponse.thumbnail,
            schema: metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }
    callProgramApi = (slug) => {
        let classRef = this;

        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url + "single-program",
            data: { data_type :'series' , user_id: user_id, slug: slug },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {

                if (response.data.data.latest) {
                    classRef.setState({
                        latest_episodes: {
                            isLoaded: true,
                            list: response.data.data.latest
                        },
                        isFav: (response.data.data.isFav) ? true : false

                    })
                    if (!isMobile) {
                        classRef.loadVideo();
                    }
                } else {
                    classRef.setState({ noDataFound: true })
                }
                if (response.data.data.more) {
                    classRef.setState({
                        episodes: {
                            isLoaded: true,
                            list: response.data.data.more
                        }
                    })

                }
                if (response.data.data.more_like_programs) {
                    classRef.setState({
                        programs: {
                            isLoaded: true,
                            list: response.data.data.more_like_programs
                        },
                    })

                }
                if (response.data.data) {

                    classRef.setState({
                        detail: {
                            isLoaded: true,
                            list: response.data.data
                        }
                    },function(){
                        // if($('body').hasClass('loadkaro')){
                        //     setTimeout(function(){
                        //         $('body').removeClass('loadkaro');
                        //     },300)
                        // }
                    });

                }
            } else if (response.data.code === 500 || response.data.code === 400) {
                window.location.href = "/";
            }
            window.imgNotFound();
            window.tagManagerEvent(response.data.data.program_name + " - " + response.data.data.channel_name + " - Program", "Program", response.data.data.program_name, { placement_channel: "", placement_program: "", placement_genre: "", user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "Unknown", user_age: localStorage.getItem('dob') ? localStorage.getItem('dob') : "", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime, custom_ga_client_id: window.ga_client_id !== '' ? window.ga_client_id : '', email: localStorage.getItem('email') ? localStorage.getItem('email') : '', name: localStorage.getItem('name') ? localStorage.getItem('name') : '', number: localStorage.getItem('mobile') ? localStorage.getItem('mobile') : '', search_keyword: '', user_custom_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '', user_device_id: '' });
            // classRef.setState({
            //     latest_episodes:{
            //         isLoaded : true,
            //         list: response.data.data.latest
            //     },
            //     episodes:{
            //         isLoaded : true,
            //         list: response.data.data.more
            //     },
            //     programs:{
            //         isLoaded : true,
            //         list: response.data.data.more_like_programs
            //     },
            //     detail:{
            //         isLoaded : true,
            //         list: response.data.data
            //     }
            // })
            // }
        }).then(function () {

        });
        $("#filter_search").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".filter_search_box .grd_mob").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
        $("#filter_search2").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".filter_search_box2 .uk-width-auto").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    };

    loadVideo() {
        // let classRef = this;
        // setTimeout(function () {
        //     var vid = document.getElementById("slider_video_program");
        //     $("#slider_video_program  source").attr('src', classRef.state.detail.list.latest[0].episode_video_url);
        //     $("#slider_video_program")[0].load();
        //     $("#slider_video_program")[0].play();
        //     $("#slider_video_program")[0].muted = true;
        //     $("#slider_video_program")[0].onloadeddata = function () {
        //         $('#NewHomeSlider_vidVolume_program').show()
        //         vid.play();
        //         vid.muted = true;
        //         $('.channel_feature_sec').addClass('SlideActive')
        //         $('#slider_video_program').show();
        //         $('.NewHomeSlider_vid').on('click', '#NewHomeSlider_vidVolume_program', function (e) { if ($('#NewHomeSlider_vidVolume_program').hasClass('vidVolumemute')) { $('#NewHomeSlider_vidVolume_program').removeClass('vidVolumemute'); vid.muted = true; } else { $('#NewHomeSlider_vidVolume_program').addClass('vidVolumemute'); vid.muted = false; } });
        //     };
        //     vid.onended = function () {
        //         vid.pause();
        //         $('.channel_feature_sec').removeClass('SlideActive')
        //     };
        // }, 2000)
    }

    addFavourite = (programId) => {
        if (!localStorage.getItem('user_id')) {
            new Header().openLoginModal();
        }
        if (this.state.isFav) {
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "remove-favourite",
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
                data: { id: programId, user_id: localStorage.getItem('user_id'), type: 'program' }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                if (response.data.code === 200 && response.data.status === true) {
                    classRef.setState({
                        isFav: false
                    });
                    $("#adtofaval").html('');
                    $("#adtofaval").append('<img src="/images/heart.svg" alt="heart" uk-svg=""/>Add Favourites');
                }

            })
        } else {
            let user_id = localStorage.getItem('user_id');
            let classRef = this;
            axios({
                method: 'POST',
                url: Constant.url + "add-user-favourite-programs",
                data: { program_id: programId, user_id: user_id },
                headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
            }).then(function (result) {
                let response = {};
                response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

                if (response.data.code === 200 && response.data.status === true) {
                    classRef.setState({
                        isFav: true
                    });
                    $("#adtofaval").html('');
                    $("#adtofaval").append('<img src="/images/heart.svg" alt="heart" uk-svg=""/>Remove Favourites');
                }

            })
        }
    }

    render() {
        return (
            <div>
                {this.state.episodes.isLoaded ?      
                <>
                <Meta schema={null} metaKeywords={this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription} metaImage={this.state.metaImage} />
                <Header />
                <div class="content_sec">
                    {/* web banner section */}
                    {(this.state.detail.isLoaded === true) ?
                        <section class={(this.state.detail.list.program_banner != '') ? "channel_feature_sec m_h" : "channel_feature_sec no_feature m_h"}>
                            <div className="NewHomeSlider_inner desktopSlide">
                                {/* <div className="NewHomeSlider_vid">
                                    <video style={{ display: 'none' }} id={"slider_video_program"} width={1366}>
                                        <source src="" type="video/mp4" />

                                    </video>
                                    <button className="NewHomeSlider_vidVolume" style={{ display: 'none' }} id={"NewHomeSlider_vidVolume_program"}>
                                        <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                                        <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                                    </button>
                                </div> */}
                                {/* {isWebpSupported() ? <Image src={banner.thumbnail} alt={banner.name} width={1200} height={560} color='#ffffff'  /> : <img src={banner.thumbnail_jpg} alt={banner.name} width={1200} height={560} color={'#ffffff'}  />}  */}
                                {/* <img src={(this.state.detail.isLoaded === true && this.state.detail.list.program_banner != '') ? this.state.detail.list.episode_thumbnail_jpg : ''} alt={(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name : ''} width={1200} height={560} color={'#ffffff'} /> */}
                                <div className="NewHomeSliderBg"></div>
                                <div className="NewHomeSlider_txt">
                                    <div className="mdl">
                                        <div className="mdl_inner">
                                            <div className="NewHomeSlider_btns uk-margin-small-bottom">
                                            </div>
                                            <img src={(this.state.detail.isLoaded === true && this.state.detail.list.program_thumbnail_jpg != '') ? this.state.detail.list.program_thumbnail_jpg : ''} />
                                            <h3>{(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name : ''} <br />
                                            </h3>
                                            <div className="NewHomeSlider_btns gf_txt">
                                                <Link className="gf_watch_btn" to={(this.state.latest_episodes.isLoaded === true) ? "/program/" + this.state.tempSlug + "/" + this.state.latest_episodes.list[0].episode_slug : ''}>
                                                    <img src="/images/play.svg" uk-svg="" /> Watch
                                                </Link>
                                                {/* <a id="adtofaval" class="gf_watch_btn adtofav" onClick={(e) => this.addFavourite(this.state.detail.list.program_id, e)}><img src={"/images/heart.svg"} alt="heart" uk-svg="" /> {(this.state.isFav) ? 'Remove Favourites' : 'Add to Favourites'}</a> */}
                                            </div>
                                            <p>
                                                {(this.state.detail.isLoaded === true && this.state.detail.list.program_banner != '') ? this.state.detail.list.program_desc : ''}
                                            </p>
                                        </div></div>
                                </div>
                            </div>
                            
                            <div className="NewHomeSlider_inner mobileSlide">
                                <img src="/images/slider/mobile-header-Awaz.png" />
                                <div className="NewHomeSliderMobBtns">
                                    <div className="NewHomeSliderMobBtnsInner">
                                        {/* <button className="InfoBtn"  onClick={(e)=>this.showDetailsInfo("HomeSliderInfoBox_"+index,e)}><img src="/images/player_info.svg" uk-svg="" /> <span>Details</span></button> */}
                                        <button className="LiveNowBtn"><img src="/images/play.svg" uk-svg="" /> <span>Live Now</span></button>
                                        <button className="FavBtn"><img src="/images/player_heart.svg" uk-svg="" /> <span>Favorite</span></button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :(!isMobile)? 
                        <SliderShimmer/>
                        :<div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                    {/* mobile banner section ended*/}
                    {/* mobile banner section */}
                    {/* {(this.state.detail.isLoaded === false) ? <EpisodeShimmer/>:''} */}
                    <section class={(this.state.detail.isLoaded === true && this.state.detail.list.program_banner != '') ? "channel_feature_sec channel_feature_sec_mob" : "channel_feature_sec no_feature channel_feature_sec_mob"}>
                        <div class="channel_feature_img"><img alt={(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name : ''} src={(this.state.detail.isLoaded === true && this.state.detail.list.program_banner != '') ? this.state.detail.list.program_banner : ''} /></div>
                        <div class="channel_feature_txt">
                            <h2>{(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name : ''}</h2>
                            <p>{(this.state.detail.isLoaded === true) ? this.state.detail.list.channel_name : ''}</p>
                            <div class="channel_feature_btns"> <a class="info_btn" href="#info_model" uk-toggle=""><img src="/images/info.svg" alt="info" uk-svg="" /></a>
                                <Link class="blue_btn" to={(this.state.latest_episodes.isLoaded === true) ? "/watch/" + this.state.latest_episodes.list[0].episode_slug : ''}>PLAY NOW</Link>
                                <a class="adtofav_mob" onClick={(e) => this.addFavourite(this.state.detail.list.program_id, e)}>
                                    {/* <img src="/images/heart.svg" uk-svg=""/> */}
                                    {(this.state.isFav) ? <span className="uk-icon active" uk-icon="heart"></span> : <span className="uk-icon" uk-icon="heart"></span>}
                                </a>
                            </div>
                        </div>
                    </section>
                    <div id="info_model" class="uk-flex-top info_model" uk-modal="">
                        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                            <button class="uk-modal-close-default" type="button" uk-close=""></button>
                            <h3>{(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name : ''}</h3>
                            <p>{(this.state.detail.isLoaded === true) ? this.state.detail.list.program_desc : ''}</p>
                        </div>
                    </div>
                    {/* mobile banner section ended*/}
                    <hr />
                    {/* {(this.state.latest_episodes.isLoaded === true) ? <WC_Episode isClass={"m_h"} name ={"Latest Episodes"} data={this.state.latest_episodes.list}/>:''} */}
                    {(window.mobilecheck() === false && this.state.isAds === true) ? <div className="ad_container uk-margin-bottom">
                        <div className="desktop-ads desktop_ad"><AdSlot slotId={"content-ad"} dfpNetworkId="205019744" sizes={[[970, 250]]} adUnit="MJUNOON.TV-970X250-5" />
                        </div></div> : <div className="ad_container ">
                        <div className="desktop-ads11 mob_ad">
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            {/*<AdSlot slotId="test2" dfpNetworkId="205019744" sizes={[[728, 90], [300, 250]]} adUnit="MJUNOON.TV-300X250" />*/}
                            <AdSlot slotId={"content-ad-mob"} dfpNetworkId="205019744" sizes={[[320, 100]]} adUnit="MJUNOON.TV-320X100-5" />
                        </div>
                    </div>}
                    <hr />
                    {console.log(this.state.episodes.list)}
                    {(this.state.episodes.isLoaded === true && isMobile) ?
                        <WC_EpisodeMobile isClass={"m_h"} name={(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name + " Episodes":''} data={this.state.episodes.list} /> : (this.state.latest_episodes.isLoaded === false && this.state.noDataFound === false) ? ""
                            : ''}
                    {(this.state.episodes.isLoaded === true && !isMobile) ?
                        <WC_Program_series channelSlug={this.state.tempSlug} isClass={"m_h"} name={(this.state.detail.isLoaded === true) ? this.state.detail.list.program_name + " Episodes":''} data={this.state.episodes.list} /> : (this.state.latest_episodes.isLoaded === false && this.state.noDataFound === false) ? ""
                            : ""}
                            
                            {/* //EpisodeShimmer removed */}

                            {/* {(this.state.episodes.isLoaded === true && !isMobile) ?
                            
                        <SeriesEpisodes  data={this.state.episodes.list} /> : (this.state.latest_episodes.isLoaded === false && this.state.noDataFound === false) ? ""
                            : ""}         */}

                    {/* <h3 style ={{color:"white"}} className="uk-text-center">No Data Found</h3>                       */}
                    <hr />
                    {/* {(this.state.programs.isLoaded === true) ? <WC_Program isClass={"m_h"} name="More like this" data={this.state.programs.list} /> : ''} */}



                    {/* web program detail body */}
                    {/* mobile program detail body */}
                    <section class="prog_mob">
                        <ul class="sidebar_tab_btns sidebar_tab_btns_half" uk-switcher="connect: .prog_tab">
                            <li class="uk-active"><a href="#">Episodes</a></li>
                            <li><a href="#">More like this</a></li>
                        </ul>
                        <ul class="prog_tab sidebar_tab uk-switcher">
                            <li>
                                <div class="search_sec"> <img src="/images/search.svg" alt="search" uk-svg="" />
                                    <input type="text" class="filter_search" placeholder="Search Episodes" id="filter_search" />
                                </div>
                                {(this.state.episodes.isLoaded === true) ? <ProgramEpisode data={this.state.episodes.list} /> : ''}
                            </li>
                            <li>
                                <div class="search_sec"> <img src="/images/search.svg" alt="search" uk-svg="" />
                                    <input type="text" class="filter_search" placeholder="Search Program" id="filter_search2" />
                                </div>
                                {(this.state.programs.isLoaded === true) ? <MoreLikeProgram data={this.state.programs.list} /> : (this.state.programs.isLoaded === false) ? <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div> : ''}
                            </li>
                        </ul>
                    </section>
                    {/* mobile program detail body ended*/}
                </div>
                <Footer />
                </>: <Loader/> }
                
            </div>
        );
    }
}
export default withRouter(Series);
