import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import copy from "copy-to-clipboard";

import Header from '../common/Header';
import Footer from '../common/Footer';
import Constant from "../../utils/Constant";
import {isMobile} from "react-device-detect";
// import style from '../../css/watchparty.css';
import LocalStorageService from "../../utils/LocalStorageService";

class WatchParty extends React.Component{
    constructor(){
        super();
        this.state = {
            playlist: {
                isLoading : false,
                playlistData : []
            },
            liveChannel:{
                isLoading : false,
                liveChannelData : []
            },
            programs: {
                isLoading : false,
                programsData : []
            },
            watched: {
                isLoading : false,
                watchedData : []
            },
            playlistResponse : '',
            VodUrl: {
                isLoaded: false,
                url: ''
            },
            channelUrl: {
                isLoaded: false,
                url: "",
                backup_live_stream_url: ""
            },
            playerRender : true,
            channel_status : false
        }
    }
    componentDidMount(){
        this.getLiveChannels();
        this.getPrograms();
        this.getWatchList();
        if(this.state.playlist.playlistData.length > 0)
        {
            if(this.state.playlist.playlistData[0].flag == 'channel')
            {

            }else{
                this.callGetVodUrlApi(this.state.playlist.playlistData[0].slug);
            }
        }
    }

    getLiveChannels = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"get-channels",
            data: {user_id: user_id},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if(response.data.code === 200){
                classRef.setState({
                    liveChannel: {
                        isLoading : true,
                        liveChannelData : response.data.data.channels
                    }
                })
            }
        })
    }
    
    getPrograms = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"topic-base-data",
            data: {
                    topic: "Nashpati Exclusive Shows",
                    type: "program",
                    user_id: user_id},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                classRef.setState({
                    programs: {
                        isLoading : true,
                        programsData : response['data']['data']['body'][0]['programs']
                    }
                })
            }
        })
    }

    getWatchList = () => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"watch-history",
            data: {
                    user_id: user_id
                },
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                classRef.setState({
                    watched: {
                        isLoading : true,
                        watchedData : response['data']['data']['episodes']
                    }
                })
            }
        })
    }

    populatePlaylist = (id,image,name,slug,flag) => {
        let classRef = this;
        let user_id = localStorage.getItem('user_id');
        let data = [];
        
        data = (classRef.state.playlist.playlistData.length > 0)?classRef.state.playlist.playlistData:[];
        if($("#"+id).is(":checked") == true){
            if(flag == 'program'){
                axios({
                    method: 'POST',
                    url: Constant.url+'single-program',
                    data:{user_id: user_id,slug: slug},
                    headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
                }).then(function (result) {
                    let response = {};
                    response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
                    
                    if(response.data.code === 200){
                        
                        if(response.data.data.latest){
                            for(var i = 0;i < response.data.data.latest.length;i++){
                                data.push({'id':response.data.data.latest[i].episode_id,'image':response.data.data.latest[i].episode_thumbnail_jpg,'name':response.data.data.latest[i].episode_name,'slug':response.data.data.latest[i].episode_slug,'flag':'episode'})
                            }
                        }
                        classRef.setState({
                            playlist: {
                                isLoading : true,
                                playlistData : data
                            }
                        })
                    }
                });
                
                
                
            }else{
                data.push({'id':id,'image':image,'name':name,'slug':slug,'flag':flag});
                classRef.setState({
                    playlist: {
                        isLoading : true,
                        playlistData : data
                    }
                })
            }
        }else{
            for(var i = 0;i < data.length;i++)
            {
                if(data[i].id == id)
                {
                    data.splice(i, 1);
                }
            }
            classRef.setState({
                playlist: {
                    isLoading : true,
                    playlistData : data
                }
            })
        }
        
    }

    removePlaylist = (id) => {
        let classRef = this;
        let data = classRef.state.playlist.playlistData;

        for(var i = 0; i < data.length; i++)
        {
            if(data[i].id == id)
            {
                data.splice(i, 1);
            }
        }
        classRef.setState({
            playlist: {
                isLoading : true,
                playlistData : data
            }
        })
    }

    channelsLoad = () => {
        let channels = "";
        channels = this.state.liveChannel.liveChannelData.map((channel,index) => (
            <li key={index}>
            <label className="advid_list_box" htmlFor={channel.channel_id}>
                                <div className="checkbox_wp">
                                  <input className="uk-checkbox" id={channel.channel_id} onClick = {(e)=>this.populatePlaylist(channel.channel_id,channel.channel_logo_jpg,channel.channel_name,channel.channel_slug,'channel')} type="checkbox" />
                                </div>
                                <div className="advid_list_img">
                                <ImageWebp webp={channel.channel_logo_jpg} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
                                  <span className="live_span">Live</span>
                                  {/* <span className="views_txt">9.4K viewers</span> */}
                                  <span uk-icon="icon: heart"></span>
                                </div>
                                <div className="advid_list_txt">
                                  <div className="advid_list_txt_inner">
                                    <h4>{channel.channel_name}</h4>
                                    {/* <p>9 PM - 9:45 PM</p> */}
                                  </div>
                                </div>
                              </label>
        </li>
        ));        
        return channels;
    }

    programsLoad = () => {  
        let programs = "";
        programs = this.state.programs.programsData.map((programs,index) => (
            <li key={index}>
            <label className="advid_list_box" htmlFor={programs.program_id}>
                                <div className="checkbox_wp">
                                  <input className="uk-checkbox" id={programs.program_id} onClick = {(e)=>this.populatePlaylist(programs.program_id,programs.program_thumbnail_jpg,programs.program_name,programs.program_slug,'program')} type="checkbox" />
                                </div>
                                <div className="advid_list_img">
                                <ImageWebp webp={programs.program_thumbnail} src={programs.program_thumbnail_jpg} alt={programs.program_name}/>
                                  {/* <span className="live_span">Live</span> */}
                                  {/* <span className="views_txt">9.4K viewers</span> */}
                                  <span uk-icon="icon: heart"></span>
                                </div>
                                <div className="advid_list_txt">
                                  <div className="advid_list_txt_inner">
                                    <h4>{programs.program_name}</h4>
                                    {/* <p>9 PM - 9:45 PM</p> */}
                                  </div>
                                </div>
                              </label>
        </li>
        ));        
        return programs;
    }

    watchedLoad = () => {
        let watched = "";
        watched = this.state.watched.watchedData.map((watched,index) => (
            <li key={index}>
            <label className="advid_list_box" htmlFor={watched.episode_id}>
                                <div className="checkbox_wp">
                                  <input className="uk-checkbox" id={watched.episode_id} onClick = {(e)=>this.populatePlaylist(watched.episode_id,watched.episode_thumbnail_jpg,watched.episode_name,watched.episode_slug,'episode')} type="checkbox" />
                                </div>
                                <div className="advid_list_img">
                                <ImageWebp webp={watched.episode_thumbnail} src={watched.episode_thumbnail_jpg} alt={watched.episode_name}/>
                                  {/* <span className="live_span">Live</span> */}
                                  {/* <span className="views_txt">9.4K viewers</span> */}
                                  <span uk-icon="icon: heart"></span>
                                </div>
                                <div className="advid_list_txt">
                                  <div className="advid_list_txt_inner">
                                    <h4>{watched.episode_name}</h4>
                                    {/* <p>9 PM - 9:45 PM</p> */}
                                  </div>
                                </div>
                              </label>
        </li>
        ));        
        return watched;
    }

    queueLoad = () => {
        let playlist = "";
        playlist = this.state.playlist.playlistData.map((playlist,index) => (
            <li key={index}>
                        <div className="advid_list_box">
                            <div className="checkbox_wp">
                                <span></span>
                            </div>
                            <div className="advid_list_img">
                                <img src={playlist.image} alt={playlist.name} />
                                {(playlist.flag == 'channel')?<span className="live_span">Live</span>:''}
                                {/* <span className="views_txt">9.4K viewers</span> */}
                                <span uk-icon="icon: heart"></span>
                            </div>
                            <div className="advid_list_txt">
                                <div className="advid_list_txt_inner">
                                    <h4>{playlist.name}</h4>
                                    {/* <p>9 PM - 9:45 PM</p> */}
                                </div>
                            </div>
                            <a className="del_forever" href="javascript:;" onClick={(e)=>this.removePlaylist(playlist.id)}><img src="/images/delete_forever.svg" uk-svg="" /></a>
                        </div>
                    </li>
        ))
        return playlist;
    }

    callGetVodUrlApi = (slug,flag) => {
        if(flag == 1){
            this.setState({channel_status:false});
            window.RMP.destroy();
            $('<div id="rmpPlayer"></div>').insertAfter( ".player_hd_bar" );
        }
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"streaming-url",
            data: {slug: slug, is_rewind: "no", type: "episode", user_id: localStorage.getItem('user_id')},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if(response.data.code === 200 && response.data.status === true){
                let customAdTagUrl = Constant.customAdTagUrl(response.data.data.add_tag_url, {placement_channel: "", placement_program: response.data.data.channel_name, placement_genre: response.data.data.genres, user_gender: localStorage.getItem('gender') ? localStorage.getItem('gender') :"Unknown", user_age: localStorage.getItem('dob') ? Constant.getDateDiff(localStorage.getItem('dob'), Constant.currentDate) :"", user_interest: window.getUserTopics() !== "" ? window.getUserTopics() : '', user_type: localStorage.getItem('mobile') ? 'real' : 'temp', user_behavior: Constant.currentEpocTime })
                classRef.setState({
                    VodUrl: {
                        isLoaded: true,
                        url: response.data.data.live_stream_url
                    }
                });
            }else if(response.data.code === 500 || response.data.code === 400){
            }
            
            classRef.initializePlayer(slug);
        })
    };

    callGetChannelUrlApi = (slug,flag) => {
        if(flag == 1){
            window.RMP.destroy();
            $('<div id="rmpPlayer"></div>').insertAfter( ".player_hd_bar" );
        }
        if(window.location.pathname === "/ten-sports-live"){
            window.location.href = "/watch-live-tv-channels";
        }
        if(window.location.pathname === "/psl-live"){
            window.location.href = "/";
        }
        if(window.location.pathname === '/coronavirus-live-updates'){
            window.location.href = '/coronavirus-updates-live';
        }
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"streaming-url",
            data: {slug: slug, is_rewind: "no", type: "channel", user_id: ""},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            if(response.data.code === 200 && response.data.status === true){
                classRef.setState({
                    channelUrl: {
                        isLoaded: true,
                        url: response.data.data.live_stream_url,
                        backup_live_stream_url: response.data.data.backup_live_stream_url
                    }
                });
                }

            // if(flag == 1){
                classRef.initializePlayer();
            // }
            // classRef.initializeAd();

        })

        // socket = io('https://srv3.mjunoon.tv:8002/'+slug);
        // socket.on('connect', function () {
        
        //     socket.on('counter', function (data) {
        
        //         classRef.setState({
        //             liveViews: data.count
        //         })

        //     })
        // });

    };

    playlistLoad = () => {
        let playlist = "";
        let temp = this.state.playlist.playlistData.reverse();
        playlist = temp.map((playlist,index) => (
            <li key={index}>
                            <div className="wp_playlist_box" onClick = {(playlist.flag == 'channel')?(e)=>this.callGetChannelUrlApi(playlist.slug,1):(e)=>this.callGetVodUrlApi(playlist.slug,1)}>
                                <div className="wp_playlist_img">
                                <img src={playlist.image} alt={playlist.name} />
                                {(playlist.flag == 'channel')?<span className="live_span">Live</span>:''}
                                    {/* <span class="views_txt">9.4K viewers</span> */}
                                    <span uk-icon="icon: heart"></span>
                                </div>
                                <div className="wp_playlist_txt">
                                    <div className="wp_playlist_txt_inner">
                                    <h4>{playlist.name}</h4>
                                        {/* <p>9 PM - 9:45 PM</p> */}
                                    </div>
                                </div>
                            </div>
                        </li>
        ))
        // this.setState({playlistResponse:playlist});
        return playlist;
    }

    copyLink = (flag) => {
        var share_link = window.location.href;
        copy(share_link);
        if(flag){
            $("#copied_partyModal").addClass('uk-flex uk-open');
            setTimeout(()=>{$("#copied_partyModal").removeClass('uk-flex uk-open')},3000)
        }
    }

    exitWatchPart = () => {
        window.location = '/';
    }

    startWatchParty = () => {
        if(this.state.playlist.playlistData.length > 0)
        {
            alert('watch party starting');
            if(this.state.playlist.playlistData[0].flag == 'channel')
            {
                this.setState({channel_status : true});
                LocalStorageService._serviceToken((e) => this.initializeChannelToken(this.state.playlist.playlistData[0].slug,e))            
            }else{
                LocalStorageService._serviceToken((e) => this.initializeToken(this.state.playlist.playlistData[0].slug,e)); 
            }
        }
    }

    initializeToken = (slug) => {
        this.callGetVodUrlApi(slug,0);
    }
    initializeChannelToken = (slug) =>{
        this.callGetChannelUrlApi(slug);
    }
    initializePlayer = (slug) => {
        var classRef = this;
        if(classRef.state.channel_status){
            var src = {
                hls: classRef.state.channelUrl.url
            };
            var backupSrc = {
                hls: classRef.state.backup_live_stream_url
            };
            var settings = {
                licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
                src: src,
                backupSrc: backupSrc,
                autoHeightMode: true,
                quality:false,
                pip: true,
                audioOnly:(classRef.state.isAudio == true)?true:false,
                delayToFade: 3000,
                gaLabel: 'Live TV',
                logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
                logoWatermark: true,
                logoPosition: 'topleft',
                sharing: true,
                sharingNetworks: ['facebook', 'twitter', 'linkedin','Whatsapp','email'],
                speed: 'Speed',
                isLive: (this.state.livenode === true) ? true : false,
                googleCast: true,
                airplay: true,
                hlsJSMaxBufferLength: 80,
                hlsJSLiveSyncDuration: 12,
                detectAutoplayCapabilities: true,
                autoplay: true,
                srcChangeAutoplay: true,
                playsInline: true,
                skin: 's3',
                skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
                skinButtonColor: 'FFFFFF',
                skinAccentColor: 'FFA52D',
                ads: true,
                nav: true,
                adBlockerDetection: true,
                adEnablePreloading: true,
                adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
                adTagUrl: this.state.channelUrl.addTagUrl,
                adAutoAlign: false
            };
        }else{
            var src = {
                hls: this.state.VodUrl.url
            };
            var settings = {
                licenseKey: 'Kl8laXE4a2VlazI3OT9yb201ZGFzaXMzMGRiMEElXyo=',
                src: src,
                pip: true,
                autoHeightMode: true,
                delayToFade: 3000,
                gaLabel: 'Live TV',
                logo: 'https://www.mjunoon.tv/assets/images/logo_grayscale.png?a=4',
                logoWatermark: true,
                logoPosition: 'topleft',
                sharing: true,
                speed: 'Speed',
                googleCast: true,
                airplay: true,
              //   contentDescription: ticker,
              //   contentID: ticker_status,
                detectAutoplayCapabilities: true,
                autoplay: true,
                isLive: false,
                adEnablePreloading:true,
                //mutedAutoplayOnMobile: false,
                //mutedAutoplayOnMacosSafari11Plus: false,
                srcChangeAutoplay: true,
                playsInline: true,
                skin: 's3',
                skinBackgroundColor: 'rgba(0, 0, 0, 0.7)',
                skinButtonColor: 'FFFFFF',
                skinAccentColor: 'FFA52D',
                ads: true,
                nav: true,
                adBlockerDetection: true,
                //adBlockerDetectedPreventPlayback: true,
                adBlockerDetectedMessage: 'Ad-blocker software detected. Please disable Ad-blocker to watch stream.',
                quickRewind: 10,
                quickForward: 10,
                adTagUrl: this.state.VodUrl.addTagUrl,
              //   adTagWaterfall: [backup_add_tag_url], 
                adAutoAlign: false
            };
        }
        // Then we set our player settings
        
        // Reference to player container
        var elementID = 'rmpPlayer';
        // Object creation
        var rmp = window.initializeRMP(elementID);
        window.RMP = rmp;
        
        // Player container which will receive API events
        var rmpContainer = document.getElementById(elementID);
        
        rmpContainer.addEventListener('ready', function() {
            
            $(".rmp-pip").hide();
            $(".rmp-sharing").hide();
            $('.rmp-quality').hide();
            // $(".rmp-control-bar").append('<div class="slash">/</div>');
            $(".rmp-control-bar").append('<button id="qty_btn"><img src="/images/hd_icon.svg" uk-svg="" />Video Quality</button>')
            $('#qty_btn').on('click', function(){ classRef.invokeQuality()});
            
            // Invoke API methods
            
        });
        rmpContainer.addEventListener('playing', function() {
            
            
            // rmp.setBitrate(0);
            
            $(".rmp-pip").hide();
            $(".rmp-sharing").hide();
            $('.rmp-quality').hide();
        });

        rmpContainer.addEventListener('ended', function() {
            let index = 0;
            for(var i = 0;i < classRef.state.playlist.playlistData.length; i++)
            {
                if(classRef.state.playlist.playlistData[i].slug == slug)
                {
                    index = i+1;
                    break;
                }
            }
            
            if(index >= classRef.state.playlist.playlistData.length)
            {
                
                window.RMP.destroy();
                $('<div id="rmpPlayer"></div>').insertAfter( ".player_hd_bar" );
                $("#rmpPlayer").append('<p>Watch Paty Ended. Come back again.</p>')
            }else{
                LocalStorageService._serviceToken((e) => classRef.initializeToken(classRef.state.playlist.playlistData[index].slug,e));
            }
        });

        rmpContainer.addEventListener('adimpression', function() {
            
            window.dataLayer.push({event: 'trackAdView', video_ad_view: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl)});
            window.firebaseEvent({video_ad_view: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl)});
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });
        rmpContainer.addEventListener('adclick', function() {
            
            window.dataLayer.push({event: 'trackAdClick', video_ad_click: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl)});
            window.firebaseEvent({video_ad_click: Constant.getAdTagUrlParams(classRef.state.VodUrl.addTagUrl)});
            // rmp.setBitrate(0);
            // ReactGA.event({ category: 'Samaa TV', action: 'playing'+Constant.version });
        });

        rmpContainer.addEventListener('destroycompleted', function() {
            
            // remove player container from DOM
            var parentContainer = rmpContainer.parentNode;
            if (parentContainer) {
                try {
                    parentContainer.removeChild(rmpContainer);
                } catch (e) {
                    
                }
            }
        });

        rmpContainer.addEventListener('enterfullscreen', function () {
            window.fullScreenPlayer();
        })

        rmpContainer.addEventListener('exitfullscreen', function () {
            window.exitFullScreenPlayer();
        })
        // Player initialisation - only after API events are registered
        rmp.init(settings);
    }

    playerControls = () =>{
        return(
          <div id="rmpPlayer">
                    {(isMobile == false)?
                    //desktop view player_hd_bar
                  <div className="player_hd_bar" style={{display:'none'}}>
                    <button className="player_backscreen" onClick={this.goBack}><img alt="Back" src="/images/player_back.svg" uk-svg="" /></button>
                    {this.state.livenode === true ? (
                      <span className="live_span">Live</span>
                    ) : (
                      <span
                        onClick={this.callAfterRewind}
                        className="live_span live_btn"
                      >
                        Live
                      </span>
                    )}
                    {this.state.livenode === true ? (
                      <p className="watching_now" id="liveViews"></p>
                    ) : (
                      ""
                    )}
                    <ul className="player_ul">
                      <li className="playershare">
                        <a id="sharebutton">
                          <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                          <span>Share</span>
                        </a>
                      </li>
                      <li>
                        <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade; pos: top-right" for="audio_video">
                          <input
                            type="checkbox"
                            id="audio_video"
                            // onChange={this.handleAudioSwitch}
                          />
                          
                          <div class="stream_convert_slider"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  :
                  //mobile view player_hd_bar
                  <div className="rmp-control-bar player_hd_bar">
                    <button className="player_backscreen" onClick={this.goBack}><img alt="Back" src="/images/player_back.svg" uk-svg="" /></button>
                    {this.state.livenode === true ? (
                      <span className="live_span">Live</span>
                    ) : (
                      <span
                        onClick={this.callAfterRewind}
                        className="live_span live_btn"
                      >
                        Live
                      </span>
                    )}
                    {this.state.livenode === true ? (
                      <p className="watching_now" id="liveViews"></p>
                    ) : (
                      ""
                    )}
                    <ul className="player_ul">
                    {/* <li>
                        <a id="castbutton">
                          <img alt="Share" src="/images/player_cast.svg" uk-svg="" />{" "}
                          
                        </a>
                      </li>                   */}
                      {/* {(this.state.screenOrientation == "portrait")?
                    <li>
                      <button class="pip_btn" id="pip_btn">
                        <img src="/images/player_pip.svg" uk-svg="" />
                      </button>
                    </li>
                    :""} */}
                    
    
                      
                      {(this.state.screenOrientation == "portrait")?
                      <li>
                        <button class="qty_btn" id="qty_btn">
                          <img src="/images/player_quality.svg" uk-svg="" />
                        </button>
                      </li>
                      :""}
                      <li>
                        {this.state.channelUrl.is_playback === true ? (
                          <button
                            className="rewind"
                            style={{ cursor: "pointer" }}
                            id="rewind"
                          >
                            <img
                              alt="Rewind"
                              src="/images/player_rewind.svg"
                              uk-svg=""
                            />{" "}
                            
                          </button>
                        ) : (
                          ""
                        )}
                      </li>
                      {(this.state.screenOrientation == "landscape")?
                      <li>
                        <a id="sharebuttonmob">
                          <img alt="Share" src="/images/player_share.svg" uk-svg="" />{" "}
                          <span>Share</span>
                        </a>
                      </li>
                      :""}
                      <li>
                        
                        <label class="stream_convert" uk-tooltip="title: Switch to Audio; delay: 500; animation: uk-animation-fade; pos: top-right" for="audio_video">
                          <input
                            type="checkbox"
                            id="audio_video"
                            // onChange={this.handleAudioSwitch}
                          />
                          
                          <div class="stream_convert_slider"></div>
                        </label>
                      </li>
                    </ul>
                  </div>
                  }
                    
                    {/* <div id="fullscreenbutton" onClick={this.fullscrn} style={{display:'none'}} className="rmp-fullscreen rmp-i rmp-i-resize-full after_max" tabindex="0" role="button" aria-label="Enter fullscreen"></div> */}
                    <div id="audio_image"></div>
                    <div id="restart_watch_live" className="restart_watch_live">
                    <div>                
                    <p id="restart_button">
                    <button id='livenow'><img src="/images/play.svg" uk-svg="" /><span>Watch Now</span></button>
                    <button id='restartpro'><img src="/images/restartprog.svg" uk-svg="" /><span>Restart Program</span></button></p>
                    </div>
                    </div>
                  </div>
        );
      }

    render(){
        return (
            <>
            <Header />
        <div className="mob_overlaybox">
            <a href="javascript:;" className="frameclose">X</a>
            <iframe src="http://srv3.mjunoon.tv:10088/PSL-Frame/psl_tabs.php"></iframe>
        </div>

        <div className="content_sec live_sec h_match">
            <section className="player_sec">
                {(this.state.playerRender)?this.playerControls():''}     
            </section>
            <div className="sidebar mobile_info_sec">
                <ul className="sidebar_tab_btns watch_btns" uk-switcher="">
                    <li><a href="#">Watch Party</a></li>
                    <li><a href="#">Play list</a></li>
                </ul>
                <div className="party_icons">
                    <a href="#advid_partyModal" uk-toggle=""><img src="/images/watchlist_btn.svg" uk-svg="" /></a>
                    <a className="copied_btn"  onClick={(e)=>this.copyLink(true)}  uk-toggle=""><img src="/images/link_icon.svg" uk-svg="" /></a>
                    <a href="#exit_partyModal" uk-toggle=""><img src="/images/exit_btn.svg" uk-svg="" /></a>
                </div>
                <ul className="sidebar_tab uk-switcher">
                    <li className="chat_watch">
                        <div className="comments_sec">
                            <div className="commnts_answer_box">
                                <ul>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Shujaat Wasti . </h5>
                                                    <span className="commnts_time">53 min ago</span></div>
                                                <div className="commnts_answer_body status_watchparty">Has created this watch party!</div>

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Kshama Dharmani . </h5>
                                                    <span className="commnts_time">53 min ago</span></div>
                                                <div className="commnts_answer_body status_watchparty">Has joined your party</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>STanveer Ahmed . </h5>
                                                    <span className="commnts_time">2 hours ago</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Shujaat Wasti . </h5>
                                                    <span className="commnts_time">Just now</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Kshama Dharmani . </h5>
                                                    <span className="commnts_time">53 min ago</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>STanveer Ahmed . </h5>
                                                    <span className="commnts_time">2 hours ago</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Shujaat Wasti . </h5>
                                                    <span className="commnts_time">Just now</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer">
                                            <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Kshama Dharmani . </h5>
                                                    <span className="commnts_time">53 min ago</span></div>
                                                <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="commnts_answer ended_party">
                                            <div className="comments_user_pic"><img src="/images/party_icon.svg" uk-svg="" /></div>
                                            <div className="commnts_answer_txt">
                                                <div className="commnts_answer_head">
                                                    <h5>Watch Party Ended . </h5>
                                                    <span className="commnts_time">Just now</span></div>
                                                <div className="commnts_answer_body">The party just ended! <br /> Hope you had fun!</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="comments_write">
                                <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                                <textarea placeholder="Say something..."></textarea>
                                <button className="btn_send_chat"><img src="/images/send_btn.svg" uk-svg="" /></button>
                            </div>
                        </div>
                    </li>
                    <li>
                        <ul className="wp_playlist">
                            {(this.state.playlist.isLoading)?this.playlistLoad():<li>Playlist is empty</li>}
                        </ul>
                    </li>
                </ul>

            </div>
        </div>
        <div className="sidebar h_match">
            <ul className="sidebar_tab_btns watch_btns" uk-switcher="">
                <li>
                    <a href="#"><img src="/images/party_icon.svg" uk-svg="" /> Watch Party</a>
                </li>
                <li>
                    <a href="#"><img src="/images/party_playlist_icon.svg" uk-svg="" /> Play list</a>
                </li>
            </ul>
            <div className="party_icons">
                <a href="#advid_partyModal" uk-toggle=""><img src="/images/watchlist_btn.svg" uk-svg="" /></a>
                <a className="copied_btn" href="#copied_partyModal" onClick={(e)=>this.copyLink(true)}  uk-toggle=""><img src="/images/link_icon.svg" uk-svg="" /></a>
                <a href="#exit_partyModal" uk-toggle=""><img src="/images/exit_btn.svg" uk-svg="" /></a>
            </div>
            <ul className="sidebar_tab uk-switcher">
                <li className="chat_watch">
                    <div className="comments_sec">
                        <div className="commnts_answer_box">
                            <ul>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Shujaat Wasti . </h5>
                                                <span className="commnts_time">53 min ago</span></div>
                                            <div className="commnts_answer_body status_watchparty">Has created this watch party!</div>

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Kshama Dharmani . </h5>
                                                <span className="commnts_time">53 min ago</span></div>
                                            <div className="commnts_answer_body status_watchparty">Has joined your party</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>STanveer Ahmed . </h5>
                                                <span className="commnts_time">2 hours ago</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Shujaat Wasti . </h5>
                                                <span className="commnts_time">Just now</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Kshama Dharmani . </h5>
                                                <span className="commnts_time">53 min ago</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>STanveer Ahmed . </h5>
                                                <span className="commnts_time">2 hours ago</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="https://lh3.googleusercontent.com/-c4-01wSYxrM/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucn0Z5gCm_Qxfg-A4Y9088PTsTHpmg/s96-c/photo.jpg" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Shujaat Wasti . </h5>
                                                <span className="commnts_time">Just now</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer">
                                        <div className="comments_user_pic"><img src="/images/shows/user3.png" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Kshama Dharmani . </h5>
                                                <span className="commnts_time">53 min ago</span></div>
                                            <div className="commnts_answer_body">Wallah such kaha agr HM bholy Hu to dunia ma Kitna sakon or Aman ho Jaye...I'm crying. To watch end episode.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="commnts_answer ended_party">
                                        <div className="comments_user_pic"><img src="/images/party_icon.svg" uk-svg="" /></div>
                                        <div className="commnts_answer_txt">
                                            <div className="commnts_answer_head">
                                                <h5>Watch Party Ended . </h5>
                                                <span className="commnts_time">Just now</span></div>
                                            <div className="commnts_answer_body">The party just ended! <br /> Hope you had fun!</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="comments_write">
                            <div className="comments_user_pic"><img src="/images/shows/user1.png" /></div>
                            <textarea placeholder="Say something..."></textarea>
                            <button className="btn_send_chat"><img src="/images/send_btn.svg" uk-svg="" /></button>
                        </div>
                    </div>
                </li>
                <li>
                    <ul className="wp_playlist">
                        {(this.state.playlist.isLoading)?this.playlistLoad():<li>Playlist is empty</li>}
                    </ul>
                </li>
            </ul>
        </div>
        <div id="exit_partyModal" className="uk-flex-top exit_partyModal" uk-modal="esc-close: false; bg-close: false;">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <h4>End Watch Party?</h4>
                <p>Your friends will no longer be able to watch videos in this party!</p>
                <button className="uk-modal-close" type="button">Cancel</button>
                <button className="uk-modal-close" type="button" onClick={(e)=>this.exitWatchPart()}>Exit</button>
            </div>
        </div>
    <div id="copied_partyModal" className="uk-flex-top exit_partyModal copied_partyModal" uk-modal="">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <h4>URL Copied</h4>
            <p>Share the URL with friends to join your watch party.</p>
        </div>
    </div>
    <div id="advid_partyModal" className="uk-flex-top advid_partyModal" uk-modal="bg-close: false;">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="advid_partyModal_head">
                <button className="uk-modal-close back_wparty" type="button"><span uk-icon="chevron-left"></span></button>
                <h4>Add Videos To Your Watch Party</h4>
            </div>
            <div className="advid_party_tabbar">
                <form className="uk-search uk-search-default">
                    <span className="uk-search-icon-flip" uk-search-icon=""></span>
                    <input className="uk-search-input" type="search" placeholder="Search for videos" />
                </form>
                <ul className="advid_party_tabs" uk-switcher="connect: .advid_party_tabbox">
                    <li><a href="#">Live Channels</a></li>
                    <li><a href="#">Programs</a></li>
                    <li><a href="#">Watched</a></li>
                </ul>
            </div>
            <div className="advid_party_tabbox_container">
                <ul className="uk-switcher advid_party_tabbox">
                    <li>
                        <ul>
                            {(this.state.liveChannel.isLoading)?this.channelsLoad():''}
                        </ul>
                    </li>
                    <li>
                        <ul>
                            {(this.state.programs.isLoading)?this.programsLoad():''}
                        </ul>
                    </li>
                    <li>
                        <ul>
                            {(this.state.watched.isLoading)?this.watchedLoad():''}
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="advid_partyModal_foot">
                <div className="advid_partyftr_txt">
                    <p>Get the party started, and share the party URL to invite friends.</p>
                </div>
                <div className="advid_partyftr_btn">
                    <a href="#invite_watchpartyModal" onClick = {(e) => this.startWatchParty()} uk-toggle=""><img src="/images/party_icon.svg" uk-svg="" /> Start The Party</a>
                </div>
                <div className="advid_partyftr_checkbox">
                    <label className="ftr_checkbox" htmlFor="ftr_checkbox">
            <input className="uk-checkbox" id="ftr_checkbox" type="checkbox" />
            only I have control
          </label>
                </div>
                <div className="advid_partyftr_lnk"><a href="#Queue_partyModal" uk-toggle="">View Queue ({(this.state.playlist.isLoading)?this.state.playlist.playlistData.length:0})</a></div>
            </div>
        </div>
    </div>
    <div id="Queue_partyModal" className="uk-flex-top advid_partyModal" uk-modal="esc-close: false; bg-close: false;">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="advid_partyModal_head">
                <button className="back_wparty" type="button" uk-toggle="target: #advid_partyModal"><span uk-icon="chevron-left"></span></button>
                <h4>Edit Your Queue</h4>
            </div>
            <div className="queue_list">
                <h5>{(this.state.playlist.isLoading)?this.state.playlist.playlistData.length:0}</h5>
                <ul>
                    {(this.state.playlist.isLoading)?this.queueLoad():'Queue is empty'}
                </ul>
            </div>
            <div className="advid_partyModal_foot">
                <div className="advid_partyftr_txt">
                    <p>Get the party started, and share the party URL to invite friends.</p>
                </div>
                <div className="advid_partyftr_btn">
                    <a href="#invite_watchpartyModal" uk-toggle=""><img src="/images/party_icon.svg" uk-svg="" /> Start The Party</a>
                </div>
                <div className="advid_partyftr_checkbox">
                    <label className="ftr_checkbox" htmlFor="ftr_checkbox2">
          <input className="uk-checkbox" id="ftr_checkbox2" type="checkbox" />
          only I have control
        </label>
                </div>
                <div className="advid_partyftr_lnk"><a href="#advid_partyModal" uk-toggle="">Add More Videos</a></div>
            </div>
        </div>
    </div>

    <div id="invite_watchpartyModal" className="uk-flex-top invite_watchpartyModal" uk-modal="esc-close: false; bg-close: false;">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <h4>Your watch party</h4> <button className="uk-modal-close close_invite_watchparty" type="button"><img src="/images/cancel.svg" uk-svg="" /></button>
            <div className="link_copies">
                <input type="text" id="share_link" value={window.location.href} />
                <a href='javescript:;' onClick={(e) => this.copyLink(false)}><img src="/images/link_icon.svg" uk-svg="" /></a>
            </div>
            <p>Share the URL with others with friends to join the party.</p>
            <div className="invite_watchpartbtn">
                <button className="uk-modal-close" type="button">Disconnect</button>
                <button type="button" uk-toggle="target: #invite_watchpartyModal2">Invite Friends</button>
            </div>
        </div>
    </div>
    <div id="invite_watchpartyModal2" className="uk-flex-top invite_watchpartyModal2" uk-modal="esc-close: false; bg-close: false;">
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <h4>Invite friends to your watch party</h4> <button className="uk-modal-close close_invite_watchparty" type="button"><img src="/images/cancel.svg" uk-svg="" /></button>
            <ul>
                <li>
                    <a href="javascript:;"><img src="/images/code_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/whatsapp_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/messenger_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/facebook_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/twitter_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/instagram_icon.svg" uk-svg="" /></a>
                </li>
                <li>
                    <a href="javascript:;"><img src="/images/email_icon.svg" uk-svg="" /></a>
                </li>
            </ul>
            <div className="link_copies2">
                <input type="text" value={window.location.href} /><button className="copy_btn">Copy</button>
                <a href='javescript:;'><img src="/images/link_icon.svg" uk-svg="" /></a>
            </div>
        </div>
    </div>
            <Footer />
            </>
        )
    }

}

export default WatchParty;