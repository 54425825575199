import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import ReactGA from "react-ga";
import ImageWebp from "react-image-webp";
import Constant from "../../utils/Constant";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import LocalStorageService from "../../utils/LocalStorageService";
import { relative } from "path";
import UIkit from 'uikit';

class Header extends React.Component {
  constructor() {
    super();
    this.keywordHandler = this.keywordHandler.bind(this);
    this.channelHandler = this.channelHandler.bind(this);
    this.episodeHandler = this.episodeHandler.bind(this);
    this.handleChannelDropdown = this.handleChannelDropdown.bind(this);
    this.handleTypeDropdown = this.handleTypeDropdown.bind(this);
    this.searchSecRef = React.createRef();
    this.searchinputref = React.createRef();
    this.state = {
      otpCode: 0,
      packages: {
        isLoading: false,
        package_data: [],
      },
      channelDropDown: {
        isLoading: false,
        list: [],
      },
      typesDropDown: {
        isLoading: false,
        list: [],
      },
      programDropDown: {
        isLoading: false,
        list: [],
      },
      suggestion: {
        hasError: false,
        isLoading: true,
        searchKeyword: [],
        searchChannels: [],
        searchEpisodes: [],
      },
    };
    // LocalStorageService._serviceToken();
    ReactGA.initialize("UA-118978593-1");
    // ReactGA.pageview(window.location.pathname+'-beta');
    // localStorage.setItem('mobile', '923413143691');
    // localStorage.setItem('user_id', 1857938);
    // localStorage.setItem('complete_profile', 1);
    // localStorage.clear();
  }

  logout = () => {
    if (localStorage.getItem("user_id")) {
      var expiry = localStorage.getItem("expiry");
      localStorage.clear();
      localStorage.setItem("isToken", "enable");
      localStorage.setItem("expiry", expiry);
      localStorage.removeItem("userPackageTitle");
      localStorage.removeItem("package_id");
      localStorage.removeItem("userPackageDesc");
      localStorage.removeItem("userPackageExpiryDate");
      localStorage.removeItem("validTillDate");
      localStorage.removeItem("userCreatedAt");
      localStorage.removeItem("subscriptionStatus");
      localStorage.removeItem("userPackageAutoRenewal");
      localStorage.removeItem("package_status");
      localStorage.removeItem("name");
      localStorage.removeItem("gender");
      localStorage.removeItem("picture_url");
      localStorage.removeItem("mobile");
      localStorage.removeItem("email");
      localStorage.removeItem("dob");
      localStorage.removeItem("user_id");
      localStorage.removeItem("source");
      window.location.href = "/";
    } else {
    }
  };

  fetchIP = () => {
    console.log("outside")

    axios.get("https://ffmpegimages.mjunoon.tv/geolocation/public/api/ipdetails")
      .then((response) => {
        // const { ip, country } = response.data;
        // console.log("ip data: ", response.data);
        // this.setState({ userIP: ip, userCountry: country });

        if(response.data.data.data.country_code === 'BD'){
          console.log("inside")
            window.location.href = "https://bd.mjunoon.tv"
        }

      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  };

  

  componentDidMount() {


    const header = document.getElementsByClassName("HomeHeader");
    window.addEventListener("scroll" , function(){
      if(this.window.scrollY === 0 ){
        header.style.background = "#0a0a0a"
        header.style.background = "linear-gradient(to bottom, rgba(19, 20, 36, 0.7) 10%, rgba(19, 20, 36, 0))"

      }
      else{
        
      }
  })
  

    this.fetchIP();

    document.addEventListener("keydown", this.handleOutsideClick);
    document.body.addEventListener("click", this.handleOutsideClick);


    if (localStorage.getItem("user_id")) {
      this.callApi();
      // this.callTypeDropDown();
      this.getUserDetail();
      // $('.toggle_class_btn').click(function() {
      //     $('.subs_for_mob').slideToggle();
      // });
      $(".cancel_plan_btn").click(function () {
        $(".subs_for_mob").slideToggle();
      });
    } else {
      // this.openLoginModal();
      localStorage.setItem("userPackageTitle", "");
      localStorage.setItem("package_id", "");
      localStorage.setItem("userPackageDesc", "");
      localStorage.setItem("userPackageExpiryDate", "");
      localStorage.setItem("validTillDate", "");
      localStorage.setItem("userCreatedAt", "");
      localStorage.setItem("subscriptionStatus", "");
      localStorage.setItem("userPackageAutoRenewal", "");
      localStorage.setItem("package_status", false);
    }
    // if(localStorage.getItem('user_id') && localStorage.getItem('complete_profile') != 1){
    //     $("#modal-center").addClass('uk-flex uk-open');
    // }
    var ga_client_id = "";
    ReactGA.ga((tracker) => {
      ga_client_id = tracker.get("clientId");
      window.ga_client_id = ga_client_id;
    });
    window.firebaseEvent({
      custom_ga_client_id: ga_client_id,
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
      name: localStorage.getItem("name") ? localStorage.getItem("name") : "",
      number: localStorage.getItem("mobile")
        ? localStorage.getItem("mobile")
        : "",
      search_keyword: "",
      user_custom_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : "",
      user_device_id: "",
      placement_channel: "",
      placement_program: "",
      placement_genre: "",
      user_gender: localStorage.getItem("gender")
        ? localStorage.getItem("gender")
        : "",
      user_age: localStorage.getItem("dob")
        ? Constant.getDateDiff(
            localStorage.getItem("dob"),
            Constant.currentDate
          )
        : "",
      user_interest: "",
      user_type: localStorage.getItem("mobile") ? "real" : "temp",
      user_behavior: Constant.currentEpocTime,
    });

    // window.initializeEvents(this.redirect);
    window.initializFromSearchFilterDate();
    window.initializToSearchFilterDate();
    


    // below code chunk for copying url from share modal by Musfar
    document.querySelector("#copy-button").onclick = function() {
      
      const loc = window.location.href;
      // Use the Clipboard API to copy the URL to the clipboard
      navigator.clipboard.writeText(loc).then(function() {
        UIkit.notification({
          message : 'Copied Successfully' ,
          // status : 'success' ,
          pos  : 'bottom-left' ,
          timeout : 3000 , 
        })
      }, function(err) {
        console.error('Could not copy text: ', err);
      });
    };    
  }

  callChannelDropDown = () => {
    let type = $("#typeDropDown").val();
    let classRef = this;
    axios({
      url: Constant.url + "get-channels-by-type",
      method: "POST",
      data: { type: type },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      if (response.data.code === 200) {
        classRef.setState({
          channelDropDown: {
            isLoading: true,
            list: response.data.data.channels,
          },
        });
      }
    });
  };

  handleChannelDropdown = () => {
    let result = "";
    let index = 0;
    result = this.state.channelDropDown.list.map((response, index) => (
      <option value={response.channel_id} key={index}>
        {response.channel_name}
      </option>
    ));
    return result;
  };

  callTypeDropDown = () => {
    let classRef = this;
    axios({
      url: Constant.url + "channel-types",
      method: "GET",
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      if (response.data.code === 200) {
        classRef.setState({
          typesDropDown: {
            isLoading: true,
            list: response.data.data.types,
          },
        });
      }
    });
  };

  getUserDetail = () => {
    let classRef = this;
    let params = JSON.stringify({ user_id: localStorage.getItem("user_id") });
    axios({
      url: Constant.url + "user-package-detail-v2",
      method: "POST",
      // data: { dataparams: LocalStorageService.encryptAes(params)},
      data: { user_id: localStorage.getItem("user_id") },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );

      if (response.data.code === 200) {
        if (response.data.data[0].user.package.active_package == 1) {
          localStorage.setItem(
            "userPackageTitle",
            response.data.data[0].user.package.title
          );
          localStorage.setItem(
            "package_id",
            response.data.data[0].user.package.id
          );
          localStorage.setItem(
            "userPackageDesc",
            response.data.data[0].user.package.description
          );
          localStorage.setItem(
            "userPackageExpiryDate",
            response.data.data[0].user.package.expiry_date
          );
          localStorage.setItem(
            "validTillDate",
            response.data.data[0].user.package.valid_till_date
          );
          localStorage.setItem(
            "userCreatedAt",
            response.data.data[0].user.created_at
          );
          localStorage.setItem(
            "subscriptionStatus",
            response.data.data[0].user.package.subscription_status
          );
          localStorage.setItem(
            "userPackageAutoRenewal",
            response.data.data[0].user.package.auto_renewal
          );
          localStorage.setItem("package_status", true);
        } else {
          localStorage.setItem("userPackageTitle", "");
          localStorage.setItem("package_id", "");
          localStorage.setItem("userPackageDesc", "");
          localStorage.setItem("userPackageExpiryDate", "");
          localStorage.setItem("validTillDate", "");
          localStorage.setItem("userCreatedAt", "");
          localStorage.setItem("subscriptionStatus", "");
          localStorage.setItem("userPackageAutoRenewal", "");
          localStorage.setItem("package_status", false);
        }
      }
    });
  };

  handleTypeDropdown = () => {
    let result = "";
    let index = 0;
    result = this.state.typesDropDown.list.map((response, index) => (
      <option value={response.id} key={index}>
        {response.type}
      </option>
    ));
    return result;
  };

  callProgramDropDown = () => {
    let channel_id = $("#channelDropDown").val();
    let classRef = this;
    axios({
      url: Constant.url + "program-by-channel",
      method: "POST",
      data: { channel_id: channel_id },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      if (response.data.code === 200) {
        classRef.setState({
          programDropDown: {
            isLoading: true,
            list: response.data.data,
          },
        });
      }
    });
  };

  handleProgramDropdown = () => {
    let result = "";
    let index = 0;
    result = this.state.programDropDown.list.map((response, index) => (
      <option value={response.id} key={index}>
        {response.name}
      </option>
    ));
    return result;
  };

  callApi = () => {
    let classRef = this;
    let params = JSON.stringify({
      type: "mobile",
      user_id: localStorage.getItem("user_id"),
    });
    axios({
      url: Constant.url + "packages-v2",
      method: "POST",
      data: { dataparams: LocalStorageService.encryptAes(params) },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(
        LocalStorageService.decryptAes(result.data.eData)
      );
      if (response.data.code === 200) {
        classRef.setState({
          packages: {
            isLoading: true,
            package_data: response.data.data.packages,
          },
        });
      }
    });
  };

  handlePackage() {
    return;
    let result = "";
    let index = 0;
    let classRef = this;
    result = this.state.packages.package_data.map(
      (response, index) => (
        <div className="package_checkbox" id="mobileCheck" key={index}>
          <input
            id={"pkg_checkbox" + response.id}
            type="radio"
            value={
              response.id +
              "_" +
              response.title +
              "_" +
              response.renew_price +
              "_" +
              response.renew_desc
            }
            name="pkg_checkbox"
            defaultValue={response.id}
            defaultChecked={response.active_package == 1 ? true : false}
          />
          <label htmlFor={"pkg_checkbox" + response.id}>
            <i className="pkg_icon"></i>
            {response.description}
            <span>{response.premium_text}</span>
          </label>
        </div>
      ),
      $.each(classRef.state.packages.package_data, function (i) {
        if (classRef.state.packages.package_data[i].active_package == 1) {
          $("#subMob").hide();
          $("#unsubMob").show();
        }
      })
    );
    $(".subscribe_packages").on("change", ".package_checkbox", function (e) {
      let package_det = $("input[name='pkg_checkbox']:checked").val();
      if (package_det[0] == localStorage.getItem("package_id")) {
        $("#subMob").hide();
        $("#unsubMob").show();
      } else {
        $("#unsubMob").hide();
        $("#subMob").show();
      }
    });
    return result;
  }

  // mobile header
  handleSideBar = () => {
    if (localStorage.getItem("user_id")) {
      return (
        <div>
          {/* After Sign in  */}
          <div className="mob_sidebar_topsec">
            <div className="signin_profile_box">
              <div className="signin_profile_img">
                <img src={localStorage.getItem("picture_url")} />
                <div className="mj_plus_icon">
                  <img src="/images/mjplus_icon.png" />
                </div>
              </div>
              <div className="signin_profile_txt">
                <h6>{localStorage.getItem("name")}</h6>
                <p>{localStorage.getItem("mobile")}</p>
                <span>
                  {localStorage.getItem("userCreatedAt")
                    ? "Joined since " + localStorage.getItem("userCreatedAt")
                    : ""}
                </span>
              </div>
            </div>
            <div className="subscribe_sec">
              {/* <a className="mj_plus_trans toggle_class_btn" href="javascrtpt:;"><img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv Plus <span>My Transaction <img src="/images/ic_right.svg" uk-svg="" /></span></a> */}
              <Link className="mj_plus_trans toggle_class_btn" to="/packages">
                <img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv
                Plus{" "}
                <span>
                  <a href="#sidebar_profile_mob" uk-toggle="">
                    My Transaction{" "}
                  </a>
                  <img src="/images/ic_right.svg" uk-svg="" />
                </span>
              </Link>
              {localStorage.getItem("package_status") == "true" ? (
                <a href="javascript:;" className="subscribe_box">
                  {/* <div className="subscribe_box_img">
                            <img src="/images/mjunoon_tv_icon.png" />
                        </div> */}
                  <div className="subscribe_box_txt">
                    <div className="mdl">
                      <div className="mdl_inner">
                        <h6>
                          <a href="#sidebar_profile_mob" uk-toggle="">
                            {localStorage.getItem("userPackageTitle")}
                          </a>
                        </h6>
                        <p>{localStorage.getItem("userPackageDesc")}</p>
                        <span>
                          {localStorage.getItem("userPackageExpiryDate") != ""
                            ? "Valid till: " +
                              localStorage.getItem("validTillDate")
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="subscribe_nosignin">
                  {/* <a className="mj_plus toggle_class_btn" href="javascrtpt:;"><img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv Plus</a> */}

                  <a href="javascript:;" className="subscribe_nosignin_box">
                    <div className="subscribe_nosignin_img">
                      <img src="/images/psl_stream_icon.png" />
                    </div>
                    <span>
                      <img src="/images/livetv.svg" uk-svg="" /> Live stream PSL
                    </span>
                    <span>
                      <img src="/images/nobanner_icon.svg" uk-svg="" /> No
                      Banner/Overlay Ads
                    </span>
                    <span>
                      <img src="/images/7daysrewind_icon.svg" uk-svg="" /> 7
                      days Rewind
                    </span>
                    <span>
                      <img src="/images/mint_recording_icon.svg" uk-svg="" /> 5
                      minutes Recording
                    </span>
                  </a>
                  <a className="mj_plus" href="javascript:;">
                    <img src="/images/check_circle_outline.svg" uk-svg="" />{" "}
                    Enjoy 3 days FREE, Rs. 300/month afterwards
                  </a>
                </div>
              )}
              {/* {(localStorage.getItem('userPackageTitle') != "") ?
                            <div class="renewal_date">
                                <p>{'Next Renewal date: ' + localStorage.getItem('userPackageExpiryDate')}</p>
                                <div class="switch_box_wrapper">
                                    <label class="switch_box" for="switch_btn">
                                        <input type="checkbox" id="switch_btn" defaultChecked={((localStorage.getItem('userPackageAutoRenewal') == 1) ? true : false)} />
                                        <div class="switch_slider"></div>
                                    </label>
                                </div>
                            </div>
                            : ''} */}
            </div>
          </div>
          {/* After Sign in  */}
        </div>
      );
    } else {
      return (
        <div>
          {/* No Sign in */}
          <div className="mob_sidebar_topsec">
            <div className="joinfree_profile_box">
              <div className="img_joinfree">
                <img src="/images/icons/ic_male.png" />
              </div>
              <div className="text_joinfree">Enjoy 60+ Live TV Channels</div>
              <button className="btn_joinfree">Join Free</button>
            </div>
            <div className="subscribe_nosignin">
              {/* <a className="mj_plus toggle_class_btn" href="javascrtpt:;"><img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv Plus</a> */}
              <Link className="mj_plus toggle_class_btn" to="/packages">
                <img src="/images/watch_tvguide.svg" uk-svg="" /> mjunoon.tv
                Plus
              </Link>
              <a href="javascript:;" className="subscribe_nosignin_box">
                <div className="subscribe_nosignin_img">
                  <img src="/images/psl_stream_icon.png" />
                </div>
                <span>
                  <img src="/images/livetv.svg" uk-svg="" /> Live stream PSL
                </span>
                <span>
                  <img src="/images/nobanner_icon.svg" uk-svg="" /> No
                  Banner/Overlay Ads
                </span>
                <span>
                  <img src="/images/7daysrewind_icon.svg" uk-svg="" /> 7 days
                  Rewind
                </span>
                <span>
                  <img src="/images/mint_recording_icon.svg" uk-svg="" /> 5
                  minutes Recording
                </span>
              </a>
              <a className="mj_plus" href="javascript:;">
                <img src="/images/check_circle_outline.svg" uk-svg="" /> Enjoy 3
                days FREE, Rs. 300/month afterwards
              </a>
            </div>
          </div>
          {/* No Sign in */}
        </div>
      );
    }
  };
  // mobile header end
  // assetsModal = () => {
  //   $("#modal-center").addClass('uk-flex uk-open');
  // }

  openLoginModal = () => {
    // return;
    $(".phone_btn").show();
    $("#resend_code_email").hide();
    $("#resend_code_phone").hide();
    $(".login_first").show();
    $("#login_via_phone").hide();
    $(".login_pin_phone").hide();
    $(".login_via_email").hide();
    $(".login_pin_email").hide();
    $(".digit_fld input").bind("input", function () {
      var $this = $(this);
      setTimeout(function () {
        if ($this.val().length >= parseInt($this.attr("maxlength"), 10))
          $this.next("input").focus();
      }, 0);
    });
    $(".phone_btn").click(function () {
      $(".phone_btn").hide();
      $("#login_via_phone").show();
    });

    $(".login_back_btn").click(function () {
      $(".login_first").show();
      $("#login_via_phone").hide();
      $(".login_via_email").hide();
      $(".phone_btn").show();
    });
    //   $('.login_for_pin').click(function(){
    //     $('.login_pin_phone').show();
    //     $('.login_via_phone').hide();
    //   });
    $(".email_btn").click(function () {
      $(".login_first").hide();
      $(".login_via_email").show();
    });
    //   $('.login_for_pin_email').click(function(){
    //     $('.login_pin_email').show();
    //     $('.login_via_email').hide();
    //   });
    // $('.before_login').click(function () {
    //     $('.login_first').show();
    //     $('#login_via_phone').hide();
    //     $('.login_pin_phone').hide();
    //     $('.login_via_email').hide();
    //     $('.login_pin_email').hide();
    // });
    // $("#modal_login").addClass('uk-flex uk-open');
    {
      /* Disable By yaseen on request of Aamir bhai 25-08-2023 */
    }
  };

  openmodal = () => {
    $("#modal_sharing").addClass("uk-flex uk-open");
  };
  closeModal = () => {
    $("#modal_sharing").removeClass("uk-flex uk-open");
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  resendCode = (receipient, type) => {
    // $("#resend_code_email").show();
    // $("#resend_code_phone").show();

    $("#login_via_phone").hide();

    $(".login_via_phone").hide();

    this.resendingOTP(receipient, type);
    $(".login_via_phone").hide();
  };

  resendingOTP = (receipient, type) => {
    // if (type == 'email') {
    //     if (receipient === "" || this.validateEmail(receipient) === false) {
    //         $("#error_email").show();
    //         $(".login_fld").addClass('error_fld'); return;
    //     }
    //     $("#error_email").hide();
    //     $(".login_fld").removeClass('error_fld');
    //     $('#emailbtn').html('Sending...');
    // }

    if (type === "phone") {
      var receipientphone = "92" + receipient;

      if (
        receipient === "" ||
        receipient.length != 10 ||
        receipientphone.substring(0, 3) != "923" ||
        receipient.length <= 11
      ) {
        $("#error_phn").show();
        $(".login_fld").addClass("error_fld");

        return;
      }

      $("#error_phn").hide();
      $("#login_via_phone").show();

      $(".login_fld").removeClass("error_fld");
      $("#phonebtn").html("Sending...");
    }

    $("#loader_box").css("display", "block");

    this.setState({ otpCode: 0 });
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "sendOTP",
      data: {
        receipient: receipientphone,
        type: type,
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );

        console.log(response, "cheeecking");

        $("#loader_box").css("display", "block");

        // $("#error_phn_zong").show();

        if (response.data.code === 200) {
          $("#resend_code_phone").show();

          $("#loader_box").css("display", "none");

          console.log("sajdain here 200");
          // if (type == 'email') {
          //     $('#emailbtn').html('NEXT');
          //     $('.login_pin_email').show();
          //     $('.login_via_email').hide();
          //     // $("#resend_code_email").hide();
          //     // $("#resend_code_phone").hide();
          // }

          if (type == "phone") {
            $("#phonebtn").html("NEXT");
            $(".login_pin_phone").show();
            $(".login_via_phone").hide();
            $("#singinbutton").hide();
            // login_via_phone
            // $('.login_via_phone').css("display",'block');
            // $("#singinbutton").css("display", "none");
          }
        } else {
          $("#loader_box_one").css("display", "none");
          console.log("sajdain here 400");
          $("#phonebtn").html("NEXT");
          $("#login_via_phone").show();
          $("#error_phn_zong").show();
          $("#singinbutton").hide();
          $(".login_fld").addClass("error_fld");
          return;
        }
      })
      .then(function (response) {});
  };

  sendOTP = (receipient, type) => {
    // if (type == 'email') {
    //     if (receipient === "" || this.validateEmail(receipient) === false) {
    //         $("#error_email").show();
    //         $(".login_fld").addClass('error_fld'); return;
    //     }
    //     $("#error_email").hide();
    //     $(".login_fld").removeClass('error_fld');
    //     $('#emailbtn').html('Sending...');
    // }

    if (type === "phone") {
      var receipientphone = "92" + receipient;

      if (
        receipient === "" ||
        receipient.length != 10 ||
        receipientphone.substring(0, 3) != "923"
      ) {
        $("#error_phn").show();
        $(".login_fld").addClass("error_fld");

        return;
      }

      $("#error_phn").hide();
      $("#login_via_phone").show();

      $(".login_fld").removeClass("error_fld");
      $("#phonebtn").html("Sending...");
    }

    $("#loader_box_one").css("display", "block");

    this.setState({ otpCode: 0 });
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "sendOTP",
      data: {
        receipient: receipientphone,
        type: type,
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );

        console.log(response, "cheeecking");

        $("#loader_box_one").css("display", "block");

        // $("#error_phn_zong").show();

        if (response.data.code === 200) {
          $("#loader_box_one").css("display", "none");

          console.log("sajdain here 200");
          // if (type == 'email') {
          //     $('#emailbtn').html('NEXT');
          //     $('.login_pin_email').show();
          //     $('.login_via_email').hide();
          //     // $("#resend_code_email").hide();
          //     // $("#resend_code_phone").hide();
          // }

          if (type == "phone") {
            $("#phonebtn").html("NEXT");
            $(".login_pin_phone").show();
            $(".login_via_phone").hide();
            $("#singinbutton").hide();
            // login_via_phone
            // $('.login_via_phone').css("display",'block');
            // $("#singinbutton").css("display", "none");
          }
        } else {
          $("#loader_box_one").css("display", "none");
          console.log("sajdain here 400");
          $("#phonebtn").html("NEXT");
          $("#login_via_phone").show();
          $("#error_phn_zong").show();
          $("#singinbutton").hide();
          $(".login_fld").addClass("error_fld");
          return;
        }
      })
      .then(function (response) {});
  };

  checkOTP = (receipient, type, code) => {
    let classRef = this;
    $("#email_btn").html("Processing...");
    $("#phn_btn").html("Processing...");
    $("#email_btn").attr("disabled", true);
    $("#phn_btn").attr("disabled", true);
    axios({
      method: "POST",
      url: Constant.url + "getOTP",
      data: {
        receipient: receipient,
        type: type,
        code: code,
      },
    })
      .then(function (result) {
        let response = {};

        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );

        if (response.data.code === 200) {
          classRef.verifyOTP(receipient, type, code);
        } else {
          $("#error_code").show();
          $("#error_code_email").show();
          $("#email_btn").html("Login");
          $("#phn_btn").html("Login");
          $("#email_btn").attr("disabled", false);
          $("#phn_btn").attr("disabled", false);
          return;
        }
      })
      .then(function (response) {});
  };

  verifyOTP = (receipient, type, code) => {
    $("#loader_box_onee").css("display", "block");

    let params = JSON.stringify({ mobile: receipient, type: type });
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "sign-up-2",
      // data: { dataparams: LocalStorageService.encryptAes(params)},
      data: {
        mobile: receipient,
        type: type,
        code: code,
      },

      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );

        if (response.data.code === 200) {
          $("#loader_box_onee").css("display", "none");

          var param = { mobile: receipient };

          if (type === "phone") {
            var param = { mobile: receipient };
            let params = JSON.stringify({ mobile: receipient, type: "mobile" });

            localStorage.setItem("mobile", response.data.data.mobile);
            localStorage.setItem("source", response.data.data.source);
            localStorage.setItem("user_id", response.data.data.uid);

            window.location.href = "/";

            //     axios({
            //         method: 'POST',
            //         // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
            //         url: Constant.url + "sign-up-2",
            //         data: { dataparams: LocalStorageService.encryptAes(params)},
            //         headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
            //     }).then(function (responseval) {
            //         var getUserData = {};
            //         getUserData["data"] = JSON.parse(LocalStorageService.decryptAes(responseval.data.eData));

            //         localStorage.setItem('user_id', getUserData.data.data.uid);
            //         if (getUserData.data.data.complete_profile == 1) {

            //             localStorage.setItem('complete_profile', 1);
            //         }
            //         localStorage.setItem('name', getUserData.data.data.first_name);
            //         localStorage.setItem('gender', getUserData.data.data.gender);
            //         localStorage.setItem('picture_url', getUserData.data.data.picture_url);
            //         localStorage.setItem('mobile', getUserData.data.data.mobile);
            //         localStorage.setItem('email', getUserData.data.data.email);
            //         localStorage.setItem('dob', getUserData.data.data.dob);
            //         localStorage.setItem('user_id', getUserData.data.data.uid);
            //         localStorage.setItem('source', getUserData.data.data.source);
            //         // $("#email_btn").html('Login');
            //         // $("#phn_btn").html('Login');

            //         window.location.href = "/";
            //     }).then(function (error) {
            //         $("#email_btn").html('Login');
            //         $("#phn_btn").html('Login');
            //     });
          }
          if (type === "email") {
            let params = JSON.stringify({ email: receipient, type: "email" });
            axios({
              method: "POST",
              url: Constant.url + "sign-up-2",
              // data: { dataparams: LocalStorageService.encryptAes(params)},  // yaseen
              data: { email: receipient, type: "email" },
              headers: {
                Authorization: "bearer " + localStorage.getItem("access_token"),
              },
            })
              .then(function (responseval) {
                var getUserData = {};
                getUserData["data"] = JSON.parse(
                  LocalStorageService.decryptAes(responseval.data.eData)
                );
                if (getUserData.data.code === 200) {
                  localStorage.setItem("user_id", getUserData.data.data.uid);
                  if (getUserData.data.data.complete_profile == 1) {
                    localStorage.setItem("complete_profile", 1);
                  }
                  localStorage.setItem(
                    "name",
                    getUserData.data.data.first_name
                  );
                  localStorage.setItem("gender", getUserData.data.data.gender);
                  localStorage.setItem(
                    "picture_url",
                    getUserData.data.data.picture_url
                  );
                  localStorage.setItem("mobile", getUserData.data.data.mobile);
                  localStorage.setItem("email", getUserData.data.data.email);
                  localStorage.setItem("dob", getUserData.data.data.dob);
                  localStorage.setItem("user_id", getUserData.data.data.uid);
                  localStorage.setItem("source", getUserData.data.data.source);

                  window.location.href = "/";
                }
              })
              .then(function (error) {});
          }
        } else {
          $("#error_code").show();
          $("#error_code_email").show();
          $("#email_btn").html("Login");
          $("#phn_btn").html("Login");
          $("#email_btn").attr("disabled", false);
          $("#phn_btn").attr("disabled", false);
          //  $(".login_fld").addClass('error_fld');return;
        }
      })
      .then(function (result) {});
  };

  responseFacebook = (response) => {
    LocalStorageService._serviceToken(this.signFacebook(response));
  };

  signFacebook = (response) => {
    if (response) {
      let params = JSON.stringify({
        type: "email",
        email: response.email,
        firstname: response.name,
      });
      axios({
        method: "POST",
        url: Constant.url + "sign-up-2",
        // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
        // data: { dataparams: LocalStorageService.encryptAes(params)},  // yaseen
        data: {
          type: "email",
          email: response.email,
          firstname: response.name,
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
        .then(function (responseval) {
          var getUserData = {};
          getUserData["data"] = JSON.parse(
            LocalStorageService.decryptAes(responseval.data.eData)
          );
          if (getUserData.data.code === 200) {
            localStorage.setItem("user_id", getUserData.data.data.uid);
            if (getUserData.data.data.complete_profile == 1) {
              localStorage.setItem("complete_profile", 1);
            }
            localStorage.setItem("name", getUserData.data.data.first_name);
            localStorage.setItem("gender", getUserData.data.data.gender);
            localStorage.setItem(
              "picture_url",
              getUserData.data.data.picture_url
            );
            localStorage.setItem("mobile", getUserData.data.data.mobile);
            localStorage.setItem("email", getUserData.data.data.email);
            localStorage.setItem("dob", getUserData.data.data.dob);
            localStorage.setItem("user_id", getUserData.data.data.uid);
            localStorage.setItem("source", getUserData.data.data.source);

            window.location.href = "/";
          }
        })
        .then(function (error) {});
    }
  };

  responseGoogle = (response) => {
    $("#googleSign").html("Processing...");

    LocalStorageService._serviceToken(this.signGoogle(response));
  };

  signGoogle = (response) => {
    if (response.profileObj) {
      $("#googleSign").html("Redirecting...");
      $("#signerror").html("");
      let params = JSON.stringify({
        type: "email",
        email: response.profileObj.email,
        firstname: response.profileObj.name,
        image: response.profileObj.imageUrl,
      });
      axios({
        method: "POST",
        url: Constant.url + "sign-up-2",
        // url: "https://cdn2.mjunoon.tv:9191/v2/dev/sign-up",
        // data: { dataparams: LocalStorageService.encryptAes(params)},   // yaseen
        data: {
          type: "email",
          email: response.profileObj.email,
          firstname: response.profileObj.name,
          image: response.profileObj.imageUrl,
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
        .then(function (responseval) {
          var getUserData = {};
          getUserData["data"] = JSON.parse(
            LocalStorageService.decryptAes(responseval.data.eData)
          );
          if (getUserData.data.code === 200) {
            localStorage.setItem("user_id", getUserData.data.data.uid);
            if (getUserData.data.data.complete_profile == 1) {
              localStorage.setItem("complete_profile", 1);
            }
            localStorage.setItem("name", getUserData.data.data.first_name);
            localStorage.setItem("gender", getUserData.data.data.gender);
            localStorage.setItem(
              "picture_url",
              getUserData.data.data.picture_url
            );
            localStorage.setItem("mobile", getUserData.data.data.mobile);
            localStorage.setItem("email", getUserData.data.data.email);
            localStorage.setItem("dob", getUserData.data.data.dob);
            localStorage.setItem("user_id", getUserData.data.data.uid);
            localStorage.setItem("source", getUserData.data.data.source);
            // $("#googleSign").html('Continue with Google')
            window.location.href = "/";
          }
        })
        .then(function (error) {});
    } else {
      $("#googleSign").html("Continue with Google");
      $("#signerror").html(
        '<H5 style="color:red;">There is something wrong with google</H5>'
      );
    }
  };

  // Searh Bar Show
  searchBarShow = () => {
    $(".searchSec").addClass("showSearchBar");
    if(this.searchinputref.current){
      this.searchinputref.current.focus();
    }
  };
  searchBarHide = () => {
    $(".searchSec").removeClass("showSearchBar");
    $(".searchSec").removeClass("noBtmRadius");
    $(".searchSec").removeClass("ShowFilterBox");
    $(".searchSec").trigger("reset");
  };

  // Searh Filter Show
  searchFilterShow = () => {
    this.callTypeDropDown();
    $(".searchSec").addClass("ShowFilterBox");
    $(".searchSec").removeClass("noBtmRadius");
    // $('.SearchFilterbtn').hide();
    $(".SearchFilterClosebtn").show();
    $(".search_btn").hide();
  };

  // Searh Filter Show
  searchFilterHide = () => {
    // $('.searchSec').addClass('noBtmRadius');
    $(".searchSec").removeClass("ShowFilterBox");
    // $('.SearchFilterbtn').show();
    $(".search_btn").show();
    $(".SearchFilterClosebtn").hide();
  };

  searchPrediction = (keyword) => {
    let classRef = this;

    // console.log(keyword.search.length);
    if (keyword.search.length >= 3) {
      axios({
        method: "POST",
        url: Constant.url + "search-prediction",
        data: keyword,
        timeout: 5000,
        headers: {
          Authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
        .then(function (result) {
          let response = {};
          response["data"] = JSON.parse(
            LocalStorageService.decryptAes(result.data.eData)
          );
          console.log(response.data.data);
          if (response.data.code === 200 && response.data.data !== undefined) {
            classRef.setState({
              suggestion: {
                hasError: false,
                isLoading: false,
                searchKeyword: response.data.data.suggestions
                  ? response.data.data.suggestions
                  : [],
                searchChannels: response.data.data.channels
                  ? response.data.data.channels
                  : [],
                searchEpisodes: response.data.data.episodes
                  ? response.data.data.episodes
                  : [],
              },
            });
          } else {
            classRef.setState({
              suggestion: {
                hasError: true,
                isLoading: false,
                searchKeyword: [],
                searchChannels: [],
                searchEpisodes: [],
              },
            });
          }
        })
        .catch(function (error) {
          console.log("error: ", error);
          classRef.setState({
            suggestion: {
              hasError: true,
              isLoading: false,
              searchKeyword: [],
              searchChannels: [],
              searchEpisodes: [],
            },
          });
        });
    }
  };

  keywordHandler = () => {
    let keywords = "";
    keywords = this.state.suggestion.searchKeyword.map((keyword, index) => (
      <li key={index}>
        <a href={"/search?search=" + keyword.search}>{keyword.search}</a>
      </li>
    ));
    return keywords;
  };

  channelHandler = () => {
    let searchChannels = "";
    searchChannels = this.state.suggestion.searchChannels.map(
      (channels, index) => (
        <li key={index}>
          <Link to={"/" + channels.channel_slug}>
            <div className="searchChannelList">
              <img src={channels.channel_logo_jpg} />
              <div className="searchChannelText">
                <p>{channels.channel_name}</p>
                <span>News Channel</span>
              </div>
            </div>
          </Link>
        </li>
      )
    );
    return searchChannels;
  };

  episodeHandler = () => {
    let episodes = "";
    episodes = this.state.suggestion.searchEpisodes.map((episode, index) => (
      <li key={index}>
        <Link to={"/watch/" + episode.episode_slug}>
          <div className="searchVideoList">
            <img src={episode.episode_thumbnail} />
            <div className="searchVideoText">
              <p>{episode.episode_name}</p>
              <span>{episode.channel_name + " " + episode.episode_date}</span>
            </div>
          </div>
        </Link>
      </li>
    ));
    return episodes;
  };

  handleKeyPress = (event) => {
    // console.log("search clicked");
    let inputVal = $("#search_filter").val();
    if (inputVal.length >= 3) {
      // $('.searchSec').addClass('disabled');
      $(".searchSec").addClass("noBtmRadius");
      $(".searchSec").removeClass("ShowFilterBox");
      // $('.SearchFilterbtn').show();
      $(".search_btn").show();
      $(".SearchFilterClosebtn").hide();
      $("#filterrelated>ul>li>a.active").removeClass("active");
      $("#search_filter").removeClass("uk-form-danger");
    } else {
      $(".searchSec").removeClass("noBtmRadius");
    }
    this.setState({
      suggestion: {
        hasError: false,
        isLoading: true,
        searchKeyword: [],
        searchChannels: [],
        searchEpisodes: [],
      },
      showLoginModal: false,
    });

    function removeSpecialCharacters(str) {
      // Use a regular expression to match special characters and replace them with an empty string
      return str.replace(/[^\w\s]/gi, "");
    }
    function validateSearch(id) {
      var searchInput = document.getElementById(id).value;
      if (searchInput.trim() === "") {
        // alert('Please enter a search term');
        return false;
      }

      // Additional validation rules can be added here

      return true;
    }

    let validatedSearch = validateSearch("search_filter");
    let searchString = removeSpecialCharacters(inputVal);

    if (validatedSearch === true) {
      var data = { search: searchString };
      this.searchPrediction(data);
    }
  };
  searchClick = () => {
    function removeSpecialCharacters(str) {
      // Use a regular expression to match special characters and replace them with an empty string
      return str.replace(/[^\w\s]/gi, "");
    }
    function validateSearch(id) {
      var searchInput = document.getElementById(id).value;
      if (searchInput.trim() === "") {
        // alert('Please enter a search term');
        return false;
      }

      // Additional validation rules can be added here

      return true;
    }

    let inputVal = $("#search_filter").val();
    let validatedSearch = validateSearch("search_filter");
    let searchString = removeSpecialCharacters(inputVal);
    if (validatedSearch === true && inputVal !== "") {
      window.location.href = "/search?search=" + searchString;
      $("#search_filter").removeClass("uk-form-danger");
    } else {
      $("#search_filter").addClass("uk-form-danger");
    }
  };

  advanceSearch = () => {
    var searchInput = $("#search_filter").val();
    var fromDate = $("#FromSearchDateFilter").val();
    var toDate = $("#ToSearchDateFilter").val();
    var typeContent = $("#typeDropDown").val();
    var channelName = $("#channelDropDown").val();
    var programName = $("#programDropDown").val();

    const queryParams = [];

    if (searchInput) {
      queryParams.push(`search=${searchInput}`);
    }
    if (fromDate) {
      queryParams.push(`searchtype=advance&from=${fromDate}`);
    }
    if (toDate) {
      queryParams.push(`to=${fromDate}`);
    }
    if (typeContent) {
      queryParams.push(`type=${typeContent}`);
    }
    if (channelName) {
      queryParams.push(`channel=${channelName}`);
    }
    if (programName) {
      queryParams.push(`program=${programName}`);
    }

    const queryString = queryParams.join("&");
    const finalUrl = `/search?${queryString}`;

    if (searchInput === "") {
      // $("#search_filter").attr("uk-tooltip", "title: Hello World; pos: top; cls: uk-active;");
      $("#search_filter").addClass("uk-form-danger");
      $(".FilterSearchBtn").attr("disabled", "true");
    } else {
      $("#search_filter").removeClass("uk-form-danger");
      $(".FilterSearchBtn").attr("disabled", "false");
      window.location.href = finalUrl;
    }
    // window.location.href = "/search?search=" + $("#search_filter").val() + "&searchtype=advance&from=" + $('#FromSearchDateFilter').val() + "&to=" + $('#ToSearchDateFilter').val() + "&type=" + $('#typeDropDown').val() + "&channel=" + $('#channelDropDown').val() + "&program=" + $('#programDropDown').val();
  };


  // for search bar anywhere click close

  // componentDidMount() {
 
  // }

  componentWillUnmount() {
    document.addEventListener("keydown" ,this.handleOutsideClick )
    document.body.removeEventListener("click", this.handleOutsideClick);
  }

  //for share modal anywhere close 
  handleOutsideClick = (event) => {
    // Check if the click target is outside the search bar

   
    // if (
    //   this.searchSecRef.current &&
    //   !this.searchSecRef.current.contains(event.target)
    // ) {
    //   this.searchBarHide();
    // }

    if (
      this.searchSecRef.current &&
      !this.searchSecRef.current.contains(event.target)
    ) {
      this.closeModal();
    }
    if(event.key === 'Escape ' || event.key === 'Esq' ){
      this.closeModal();
    }

  };
  ///////////////////////

  selectPackage = () => {
    return;
    let classRef = this;
    if ($("input[name='pkg_checkbox']:checked").val() != undefined) {
      let package_detail = $("input[name='pkg_checkbox']:checked").val();
      let package_detail_split = package_detail.split("_");
      localStorage.setItem("mobile_package_id", package_detail_split[0]);
      localStorage.setItem(
        "mobile_package_renew_title",
        package_detail_split[1]
      );
      localStorage.setItem(
        "mobile_package_renew_price",
        package_detail_split[2]
      );
      localStorage.setItem(
        "mobile_package_renew_desc",
        package_detail_split[3]
      );
      localStorage.setItem(
        "mobile_package_renew_desc",
        package_detail_split[3]
      );
      localStorage.setItem("mobileSelectdPackage", "yes");
    }
  };

  deleteModal = () => {
    $("#delete_dialog").addClass("uk-flex uk-open");
  };

  deleteUser = () => {
    let classRef = this;
    let user_id = localStorage.getItem("user_id");
    axios({
      method: "POST",
      url: Constant.url + "delete-user-record-web",
      data: { user_id: user_id },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        //   let response = {};
        //   response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
        if (result.data.code === 200) {
          localStorage.removeItem("userPackageTitle");
          localStorage.removeItem("package_id");
          localStorage.removeItem("userPackageDesc");
          localStorage.removeItem("userPackageExpiryDate");
          localStorage.removeItem("validTillDate");
          localStorage.removeItem("userCreatedAt");
          localStorage.removeItem("subscriptionStatus");
          localStorage.removeItem("userPackageAutoRenewal");
          localStorage.removeItem("package_status");
          localStorage.removeItem("name");
          localStorage.removeItem("gender");
          localStorage.removeItem("picture_url");
          localStorage.removeItem("mobile");
          localStorage.removeItem("email");
          localStorage.removeItem("dob");
          localStorage.removeItem("user_id");
          localStorage.removeItem("source");
          window.location.href = "/";
        }
      })
      .then(function (response) {});
  };

  render() {
    return (
      <div>
        {/*<Helmet>*/}
        {/*<title>MJUNOON LIVE TV</title>*/}
        {/*<meta name="description" content="MJUNOON application" />*/}
        {/*</Helmet>*/}
        {/* mbk removed sticky here : uk-sticky={(window.location.pathname === '/') ? "" : "media: 769"} */}
        <header
          // id = "mainHeader"
          className={
            window.location.pathname === "/"
              ? "main_header  HomeHeader"
              : "main_header  "
          }
          // uk-sticky="start: 0; offset: 20; animation: uk-animation-slide-bottom"
          
          >
          <div className="uk-container uk-container-expand">
            <div className="logoandsearch">
              <div>
                {/* <Link style={{zIndex:'1000'}} to="/" className="logo" ><ImageWebp webp={"/images/logo.webp"} src={"/images/logo.png"} alt="Mjunoon TV" /></Link> */}
                <Link style={{ zIndex: "1000" }} to="/" className="logo">
                  <ImageWebp webp={"/images/Loader-2.gif"} src={"/images/Loader-2.gif"} alt="Mjunoon TV" />
                  {/* <img src={"/images/Loader-2.gif"} alt="Mjunoon TV" /> */}
                 
                </Link>
                {/* <Link className="Mobileheader_search_btn" to="/suggestions">
                            <img src="/images/search.svg" uk-svg="" alt="search" />
                        </Link> */}
              </div>

              <div className="headernav">
                <ul>
                  <li
                    className={window.location.pathname === "/" ? "active" : ""}
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/watch-live-tv-channels"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/watch-live-tv-channels">LiveTV</Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/latest-news-headlines"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/latest-news-headlines">Feed</Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/movies" ? "active" : ""
                    }
                  >
                    <Link to="/movies">Movies</Link>
                  </li>
                  <li
                    className={
                      window.location.pathname === "/mgames" ? "active" : ""
                    }
                  >
                    <Link to="/mgames">
                      Mgames <span className="uk-badge">new</span>{" "}
                    </Link>
                  </li>
                  {/* <li><NavLink to="/" className={isNavLinkActive('/') ? 'active' : ''}>Home</NavLink></li>
          <li><NavLink to="/watch-live-tv-channels" className={isNavLinkActive('/watch-live-tv-channels') ? 'active' : ''}>LiveTV</NavLink></li>
          <li><NavLink to="/latest-news-headlines" className={isNavLinkActive('/latest-news-headlines') ? 'active' : ''}>Feed</NavLink></li>
          <li><NavLink to="/movies" className={isNavLinkActive('/movies') ? 'active' : ''}>Movies</NavLink></li>
          <li><NavLink to="/mgames" className={isNavLinkActive('/mgames') ? 'active' : ''}>Mgames <span className='uk-badge'>new</span></NavLink></li> */}
                </ul>
              </div>

              <div className="rightside_header">
                <div className="right_hdr">
                  {/* <p className="mob_hide">Download App</p> */}
                  {/* <a className="mob_hide" target="_blank" href="https://appgallery.huawei.com/#/app/C100874529"><img src="/images/Badge-Black@3x.png" alt="Mjunoon Huawei App" /></a>
                            <a className="mob_hide" target="_blank" href="https://play.google.com/store/apps/details?id=com.convex.mjunoon.TV&amp;hl=en"><img src={"/images/googleplay.png"} alt="Mjunoon Androiod App" /></a>
                            <a className="mob_hide" target="_blank" href="https://itunes.apple.com/us/app/mjunoon-tv/id1107354784?mt=8"><img src={"/images/apstore.png"} alt="Mjunoon IOS App" /></a> */}

                  <a className="top_share_btn" onClick={this.openmodal}>
                    <img
                      src="/images/share.svg"
                      width={"24px"}
                      height={"24px"}
                      alt="share"
                      uk-svg=""
                    />{" "}
                    SHARE
                  </a>
                  <Link className="Mobileheader_search_btn" to="/suggestions">
                    <img src="/images/search.svg" uk-svg="" alt="search" />
                  </Link>
                  {/* <a onClick={!localStorage.getItem('user_id') ? this.openLoginModal : this.nothing} data-toggle="modal" data-target="#signup_box" id="modelSignupClick">{(localStorage.getItem('user_id')) ? <div className="after_login active"><Link to="/profile"><span className="login_txt"></span><span className="login_img"><img src={(localStorage.getItem('picture_url') !== "" && localStorage.getItem('picture_url')) ? localStorage.getItem('picture_url') : (localStorage.getItem('gender') === 'Female') ? "/images/icons/ic_female@2x.png" : "/images/icons/ic_male@2x.png"} /></span></Link></div> : 'JOIN FREE'}</a> */}
                  {/* Disable By yaseen on request of Aamir bhai 25-08-2023 */}
                </div>
                <div className="searchBarContainer">
                  <form
                    className="searchSec showSearchBar"
                    ref={this.searchSecRef}
                    action="javascript:void(0);"
                  >
                    {/* <form className="searchSec noBtmRadius"> */}
                    <a
                      className="header_search_btn"
                      onClick={this.searchBarShow}
                    >
                      <img src="/images/search.svg" uk-svg="" alt="search" />
                    </a>
                    {/* <a className="search_close_btn" onClick={this.searchBarHide}><img src="/images/closefilled.svg" uk-svg="" alt="search" /></a> */}
                    <div
                      className="SearchFldBox "
                      style={{ display: "visible" }}
                    >
                      <input
                        ref = {this.searchinputref}
                        type="search"
                        autoComplete="off"
                        id="search_filter"
                        placeholder="Search for Movies, Shows, Channels etc."
                        maxLength={255}
                        onChange={this.handleKeyPress}
                      />

                      <a
                        className="SearchFilterClosebtn"
                        onClick={this.searchFilterHide}
                      >
                        <img
                          src="/images/closefilled.svg"
                          uk-svg=""
                          alt="search Filter"
                        />
                      </a>
                      {/* <a className="SearchFilterbtn" onClick={this.searchFilterShow}><img src="/images/playlist_play.svg" uk-svg="" alt="search Filter" /></a> */}
                      <a className="search_btn" onClick={this.searchClick}>
                        <img src="/images/search.svg" uk-svg="" alt="search" />
                      </a>
                    </div>
                    {/* Predictions Search Start */}
                    {this.state.suggestion.isLoading ? (
                      <ul className="SearchPredictions">
                        <div className="loader_box">
                          <div className="loader">
                            <div className="loader_inner"></div>
                          </div>
                        </div>
                      </ul>
                    ) : (
                      <ul className="SearchPredictions">
                        {this.state.suggestion.searchKeyword.length > 0
                          ? this.keywordHandler()
                          : ""}
                        {this.state.suggestion.searchChannels.length > 0 ? (
                          <li>
                            <h4>Channels</h4>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.suggestion.searchChannels.length > 0
                          ? this.channelHandler()
                          : ""}
                        {this.state.suggestion.searchEpisodes.length > 0 ? (
                          <li>
                            <h4>Videos</h4>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.suggestion.searchEpisodes.length > 0
                          ? this.episodeHandler()
                          : ""}
                        {this.state.suggestion.hasError ? (
                          <li>
                            <a href="javascript:void(0)">
                              No Search Results Found
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        <li>
                          {/* <h5>Related search predictions</h5> */}
                        </li>
                      </ul>
                    )}
                    {/* Predictions Search End */}
                    {/* Filter Search Start */}
                    {/* <div className="SearchFilterSection">
                                    <div className="SearchFilterInner">
                                        <div className="SearchFilterflds">
                                            <div className="uk-grid-medium" uk-grid="">
                                                <div className="uk-width-auto">
                                                    <label>Duration</label>
                                                </div>
                                                <div className="uk-width-expand">
                                                    <input
                                                        className="uk-input SearchDate"
                                                        id="FromSearchDateFilter"
                                                        type="text"
                                                        placeholder="From"
                                                    />
                                                </div>
                                                <div className="uk-width-expand">
                                                    <input
                                                        className="uk-input SearchDate"
                                                        id="ToSearchDateFilter"
                                                        type="text"
                                                        placeholder="To"
                                                    />
                                                </div>
                                            </div>
                                            <div className="uk-grid-medium" uk-grid="">
                                                <div className="uk-width-auto">
                                                    <label>Channel</label>
                                                </div>
                                                <div className="uk-width-expand">
                                                    <select id="typeDropDown" onChange={this.callChannelDropDown} className="uk-select">
                                                        <option value="">Select Type</option>
                                                        {(this.state.typesDropDown.isLoading) ? this.handleTypeDropdown() : ''}
                                                    </select>
                                                </div>
                                                <div className="uk-width-expand">
                                                    <select id="channelDropDown" onChange={this.callProgramDropDown} className="uk-select">
                                                        <option value="">Select Channel</option>
                                                        {(this.state.channelDropDown.isLoading) ? this.handleChannelDropdown() : ''}
                                                    </select>
                                                </div>

                                            </div>
                                            <div className="uk-grid-medium" uk-grid="">
                                                <div className="uk-width-auto">
                                                    <label>Programs</label>
                                                </div>
                                                <div className="uk-width-expand">
                                                    <select id="programDropDown" className="uk-select">
                                                        <option value="">Select Program</option>
                                                        {(this.state.programDropDown.isLoading) ? this.handleProgramDropdown() : ''}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="uk-grid-medium" uk-grid="">
                                                <div className="uk-width-expand">
                                                    <button className="FilterSearchBtn" onClick={this.advanceSearch}>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}
                    {/* Filter Search End */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* //sidebar */}
        {/* <aside className="side_menu">
                    <div className="mdl">
                        <div className="mdl_inner">
                            <ul>


                                {/* {/* <li className = {(window.location.pathname === '/search') ? 'active' : ''}> <Link to="/search"><img src={"/images/search.svg"} alt="search" uk-svg=""/> <span>SEARCH</span></Link> </li> */}
        {/* <li className={(window.location.pathname === '/') ? 'active' : ''}> <Link to="/"><img src={"/images/home.svg"} alt="home" uk-svg="" /> <span>HOME</span></Link> </li>
                                <li className={(window.location.pathname === '/latest-news-headlines' || window.location.pathname === '/latest-news-headlines' || window.location.pathname === '/family' || window.location.pathname === '/talk-shows' || window.location.pathname === '/comedy' || window.location.pathname === '/entertainment' || window.location.pathname === '/music' || window.location.pathname === '/cooking' || window.location.pathname === '/events' || window.location.pathname === '/trending' || window.location.pathname === '/strepsils-stereo' || window.location.pathname === '/featured' || window.location.pathname === '/cricket-worldcup-2019' || window.location.pathname === '/pepsi-battle-of-the-bands-s4' || window.location.pathname === '/global' || window.location.pathname === '/technology') ? 'active' : ''}> <Link to="/latest-news-headlines"><img src={"/images/trending.svg"} alt="trending" uk-svg="" /> <span>FEED</span></Link> </li>
                                <li className={(window.location.pathname === '/watch-live-tv-channels') ? 'active' : ''}> <Link className="live_tv_icon" to="/watch-live-tv-channels"><div className="menu_icon"><img src={"/images/livetv.svg"} alt="live tv" uk-svg="" /></div> <span>LIVE TV</span></Link> </li> */}
        {/* <li className={(window.location.pathname === '/tv-guide') ? 'active' : ''}> <Link to="/tv-guide"><img src={"/images/tvguideicon.svg"} alt="live tv" uk-svg="" /> <span>TV GUIDE</span></Link> </li> */}
        {/* <li className={(window.location.pathname === '/movies') ? 'active' : ''}> <Link to="/movies"><img src={"/images/category-icon.svg"} alt="Movies" uk-svg="" /> <span>MOVIES</span></Link> </li>
                                <li className={(window.location.pathname === '/m-games') ? 'active' : ''}> <Link to="/mgames" ><img src={"/images/mgames-icon.svg"} alt="MGames" uk-svg="" /> <span>MGAMES</span></Link> </li> */}
        {/* {localStorage.getItem('user_id') ?
                                <li className = {(window.location.pathname === '/watch-list') ? 'active' : ''}  > <Link to="/watch-list"><img src="/images/watchlist.svg" uk-svg=""/> <span>WATCH LIST</span></Link><div></div></li>
                                :<li> <a onClick={this.openLoginModal}><img src="/images/watchlist.svg" uk-svg=""/> <span>WATCH LIST</span></a><div></div></li>} */}
        {/* <li className = {(window.location.pathname === '/psl/live-updates' || window.location.pathname === '/psl/points' || window.location.pathname === '/psl/matches' || window.location.pathname === '/psl/teams') ? 'active' : ''}  > <Link to="/psl/live-updates"><img width={40} src={"/images/psl/psl.webp"} alt="psl"/> <span>HBL PSL</span></Link> </li> */}
        {/* <li style={{marginTop: '5px'}} className = {(window.location.pathname === '/psl/live-updates' || window.location.pathname === '/psl/points' || window.location.pathname === '/psl/matches' || window.location.pathname === '/psl/teams') ? 'active' : ''}  > <Link to="/psl/live-updates"><ImageWebp webp={"/images/psl/psl.webp"} src={"/images/psl/psl.png"} alt="psl" width={40}/> </Link> </li> */}
        {/* <li className={(window.location.pathname === '/profile') || (window.location.pathname === '/favourite-topics') || (window.location.pathname === '/favourite-channel') || (window.location.pathname === '/favourite-program') || (window.location.pathname === '/watch-list') || (window.location.pathname === '/watch-history') ? 'more_desk active' : 'more_desk'} > <Link to={localStorage.getItem('user_id') ? "/profile" : ""} onClick={localStorage.getItem('user_id') ? "" : this.openLoginModal()}><img src={"/images/more.svg"} alt="more" uk-svg="" /> <span>MORE</span></Link> </li>
                                <li className={(window.location.pathname === '/profile') || (window.location.pathname === '/favourite-topics') || (window.location.pathname === '/favourite-channel') || (window.location.pathname === '/favourite-program') || (window.location.pathname === '/watch-list') || (window.location.pathname === '/watch-history') || (window.location.pathname === '/unsigned') ? 'more_mob active' : 'more_mob'}> {localStorage.getItem('user_id') ? <a href="#sidebar_profile_mob" uk-toggle=''><img src="/images/more.svg" alt="more" uk-svg="" /> <span>MORE</span></a> : <Link to={'/unsigned'}><img src="/images/more.svg" alt="more" uk-svg="" /> <span>MORE</span></Link>} </li>
                            </ul>
                        </div>
                    </div>
                </aside> */}

        {/* mobile profile side bar start */}
        <div
          id="delete_dialog"
          className="uk-flex-top modal_sharing"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            ></button>
            <div className="sharing_content">
              <h3>Are you sure, you want to delete?</h3>
              <button className="uk-modal-close">NO</button>
              <button onClick={this.deleteUser}>CONFIRM</button>
            </div>
          </div>
        </div>
        <div
          id="sidebar_profile_mob"
          className="sidebar_profile_mob"
          uk-offcanvas=""
        >
          <div className="uk-offcanvas-bar">
            {this.handleSideBar()}
            <ul className="sidebar_profile_navi">
              <li
                className={
                  window.location.pathname === "/profile" ? "active" : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id") ? "/profile" : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      src="/images/ic_my_profile.svg"
                      uk-svg=""
                      alt="profile"
                    />
                  </span>{" "}
                  <span>My Profile</span>{" "}
                  <i className="icon_arow">
                    <img
                      src="/images/ic_right.svg"
                      uk-svg=""
                      alt="icon right"
                    />
                  </i>
                </Link>
              </li>
              {/* <li><a href="javascript:;"><span className="img_a"><img src="/images/ic_my_rewards.svg" uk-svg="" /></span> <span>My Rewards</span> <i className="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></a></li> */}
              <li
                className={
                  window.location.pathname === "/favourite-topics"
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id")
                      ? "/favourite-topics"
                      : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      alt="topics"
                      src="/images/ic_my_topics.svg"
                      uk-svg=""
                      alt="icon right"
                    />
                  </span>{" "}
                  <span>My Topics</span>{" "}
                  <i className="icon_arow">
                    <img src="/images/ic_right.svg" uk-svg="" alt="ic_right" />
                  </i>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/favourite-channel"
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id")
                      ? "/favourite-channel"
                      : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      src="/images/ic_fav_channel.svg"
                      uk-svg=""
                      alt="fav channel"
                    />
                  </span>{" "}
                  <span>Favorite Channels</span>{" "}
                  <i className="icon_arow">
                    <img src="/images/ic_right.svg" uk-svg="" alt="ic_right" />
                  </i>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/favourite-program"
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id")
                      ? "/favourite-program"
                      : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      src="/images/ic_fav_programs.svg"
                      uk-svg=""
                      alt="fav program"
                    />
                  </span>{" "}
                  <span>Favorite Programs</span>{" "}
                  <i className="icon_arow">
                    <img src="/images/ic_right.svg" uk-svg="" alt="ic_right" />
                  </i>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/watch-list" ? "active" : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id")
                      ? "/watch-list"
                      : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      src="/images/ic_watch_list.svg"
                      uk-svg=""
                      alt="ic_watch_list"
                    />
                  </span>{" "}
                  <span>Watch List</span>{" "}
                  <i className="icon_arow">
                    <img src="/images/ic_right.svg" uk-svg="" alt="ic_right" />
                  </i>
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === "/watch-history" ? "active" : ""
                }
              >
                <Link
                  to={
                    localStorage.getItem("user_id")
                      ? "/watch-history"
                      : "/unsigned"
                  }
                >
                  <span className="img_a">
                    <img
                      src="/images/ic_history.svg"
                      uk-svg=""
                      alt="ic_history"
                    />
                  </span>{" "}
                  <span>Watch History</span>{" "}
                  <i className="icon_arow">
                    <img src="/images/ic_right.svg" uk-svg="" alt="ic_right" />
                  </i>
                </Link>
              </li>
              {/* <li><a href="javascript:;"><span className="img_a"><img src="/images/ic_invite_friends.svg" uk-svg="" /></span> <span>Invite Friends</span> <i className="icon_arow"><img src="/images/ic_right.svg" uk-svg="" /></i></a></li> */}
              <li>
                <a onClick={this.openmodal}>
                  <span className="img_a">
                    <img src="/images/ic_share.svg" uk-svg="" alt="ic_share" />
                  </span>{" "}
                  <span>Share App</span>{" "}
                  <i className="icon_arow">
                    <img
                      src="/images/ic_right.svg"
                      uk-svg=""
                      alt="icon right"
                    />
                  </i>
                </a>
              </li>
              <li>
                <a onClick={this.logout}>
                  <span className="img_a">
                    <img src="/images/logout.svg" uk-svg="" alt="logout" />
                  </span>{" "}
                  <span>Log out</span>
                </a>
              </li>
              <li>
                <a onClick={this.deleteModal}>
                  <span className="img_a">
                    <img
                      src="/images/delete_forever.svg"
                      uk-svg=""
                      alt="Delete"
                    />
                  </span>{" "}
                  <span>Delete Profile</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="modal-center"
          onClick={this.assetsModal}
          className=" popup_home uk-flex-top popup_ii"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="content">
              <img src="/images/icons/ic_personalized.png" />
              <h3>Personalizing your experience</h3>
              <p>
                Tell us what you want to see and <br />
                let us customize the content for your feed
              </p>
              <div className="gf_txt  ">
                <Link to="/personalize" className="gf_watch_btn">
                  Go
                </Link>
              </div>
            </div>
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            ></button>
          </div>
        </div>
        <div
          id="modal_sharing"
          className="uk-flex-top modal_sharing"
          uk-modal=""
          sel-close
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default "
              type="button"
              uk-close=""
            ></button>
            <div className="sharing_content">
              <h3>Share with your Friends & Family !</h3>
              <ul>
                <li>
                  <a
                    className="icon_facebook"
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mjunoon.tv%2F"
                    target="_blank"
                  >
                    <span uk-icon="icon: facebook"></span>
                  </a>
                </li>
                <li>
                  <a
                    className="icon_whatsapp"
                    href="https://api.whatsapp.com/send?text=Watch%20mjunoon.tv%20to%20stream%20more%20than%2060%20live%20tv%20channels%20absolutely%20free"
                    target="_blank"
                  >
                    <span uk-icon="icon: whatsapp"></span>
                  </a>
                </li>
                {/* <li><a className="icon_twitter" href="https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F" target="_blank"><span uk-icon="icon: twitter"></span></a></li> */}
                <li>
                  <a
                    className="icon_twitterX"
                    href="https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      viewBox="0 0 512 462.799"
                    >
                      <path
                        fillRule="nonzero"
                        d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="icon_linkedin"
                    href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.mjunoon.tv%2F&source=LinkedIn"
                    target="_blank"
                  >
                    <span uk-icon="icon: linkedin"></span>
                  </a>
                </li>
                {/* <li><a className="icon_instagram" href="javascript:;"><span uk-icon="icon: instagram"></span></a></li> */}
              </ul>
              {/* <button class="uk-button uk-button-default" type="button" onclick="UIkit.notification({message: 'Bottom Left…', pos: 'bottom-left'})">Bottom Left</button> */}
              <button id = 'copy-button' > <span uk-icon="icon: copy"></span> </button>                  

            </div>
          </div>
        </div>
        <div
          style={{ display: "none" }}
          id="interNetmsgBox"
          className="msg_box"
        >
          <div className="ai_recom">Check your Internet Connection !</div>
        </div>
        <div style={{ display: "none" }} id="onlinemsgBox" className="msg_box">
          <div className="ai_recom_green">You are now back Online</div>
        </div>

        {/* mobile profile side bar end */}
        {/* Login Modal */}
        {this.state.showLoginModal === true && (
          <>
            <div
              id="modal_login"
              className="uk-flex-top modal_login"
              uk-modal=""
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button
                  className="uk-modal-close-default"
                  type="button"
                  uk-close=""
                ></button>
                <div className="no_signin_inner">
                  <div className="mdl">
                    <div className="mdl_inner">
                      <img src="/images/logo.png" alt="Mjunoon TV" />
                      <h2>Unlimited Entertainment</h2>
                      <h3>Awaits You</h3>
                      <ul>
                        <li>
                          <p>70+ LIVE</p>
                          <p>TV CHANNELS</p>
                        </li>
                        <li>
                          <p>400+</p>
                          <p>TITLES</p>
                        </li>
                        <li>
                          <p>5000+</p>
                          <p>EPISODES</p>
                        </li>
                      </ul>
                      {/* <button class="continuewith"><img src="images/gmail_icon.svg" /> Continue with Google</button> */}
                      <div>
                        {/* <GoogleLogin
                                                clientId="96096407429-e9gh28ou39mhh1fm27qm8bne0cm2nvnl.apps.googleusercontent.com"
                                                render={renderProps => (
                                                    <a className="continuewith" onClick={renderProps.onClick} disabled={renderProps.disabled}><img src="images/gmail_icon.svg" /><span id="googleSign">Continue with Google</span></a>
                                                )}
                                                buttonText="Login"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            /> */}
                        {/* <button class="continuewith"><img src="images/fb_icon.svg" /> Continue with Facebook</button> */}
                        {/* <FacebookLogin
                                                appId="1471066116276908"
                                                callback={this.responseFacebook}
                                                fields="name,email,picture"
                                                render={renderProps => (
                                                    <a className="continuewith" onClick={renderProps.onClick}><img src="images/fb_icon.svg" /><span>Continue with Facebook</span></a>
                                                )}
                                            /> */}
                        <a
                          id="singinbutton"
                          className="continuewith butn phone_btn"
                        >
                          <span>SIGNIN WITH NUMBER</span>
                        </a>
                      </div>
                      <br />
                      <span id="signerror"></span>
                    </div>
                  </div>
                </div>
                {/* First Screen */}
                {/* <div className="login_modal_box login_first">
                    <h3>Log into mjunoon.tv</h3>
                    <p>60+ Live TV Channels, 400+ Titles, 5000+ Episodes, Biggest events of the country PSL!
                        All this personalized to your taste!
                        So what are you waiting for, Log in to enjoy unlimited entertainment!</p>
                    <div className="btn_modal">
                        <FacebookLogin 
                            appId="1471066116276908"                        
                            callback={this.responseFacebook}
                            fields="name,email,picture"
                            render={renderProps => (
                                <a className="butn" onClick={renderProps.onClick}><i uk-icon="icon: facebook"></i><span>Continue with Facebook</span></a>
                            )}
                        />
                    {/* <a className="butn" href="javascript:;"><i uk-icon="icon: facebook"></i><span>Continue with Facebook</span></a> */}
                {/* <GoogleLogin
                        clientId="96096407429-e9gh28ou39mhh1fm27qm8bne0cm2nvnl.apps.googleusercontent.com"
                        render={renderProps => (
                            <a className="butn"  onClick={renderProps.onClick} disabled={renderProps.disabled}><i uk-icon="icon: google"></i><span>Continue with Google</span></a>
                        )}
                        buttonText="Login"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    /> */}

                {/* <a className="butn phone_btn"><span>Continue with Phone</span></a> */}
                {/* <a className="butn email_btn"><span>Continue with Email</span></a> */}
                {/* </div>
                    <p className="smalltxt_modal">We won't share your personal details with anyone</p>
                    <p>If you continue, you are accepting mjunoon.tv <Link to='/terms-and-conditions'>Terms and Conditions</Link> and  <Link to='/privacy-policy'>Privacy Policy</Link></p>
                </div>  */}
                {/* First Screen End  */}
                {/* Mobile Number Screen  */}
                <div
                  className="login_modal_box login_via_phone loginCustom"
                  id="login_via_phone"
                >
                  <a className="login_back_btn" id="loginbtnphone">
                    <span uk-icon="icon: arrow-left"></span>
                  </a>
                  {/* <img width="70" src="/images/logo_popup.png" alt="mjunoon.tv" /> */}
                  {/* <h2>Login</h2> */}
                  <h3>Enter Your Zong Number</h3>
                  <div className="login_fld">
                    <span>+92</span>
                    <input
                      className="login_fld_number"
                      id="phonenum"
                      type="number"
                      name=""
                      placeholder="Phone Number"
                      maxLength="11"
                    />
                    <p>
                      By tapping continue you agreed to receive an SMS to
                      confirm your phone number.
                    </p>
                    <p
                      id="error_phn"
                      style={{ display: "none" }}
                      className="error_msg"
                    >
                      This field is mandatory and length should be 12 digit with
                      92
                    </p>
                    <p
                      id="error_phn_zong"
                      style={{ display: "none" }}
                      className="error_msg"
                    >
                      Phone number must be of zong
                    </p>
                  </div>

                  <button
                    id="phonebtn"
                    className="blue_btn login_for_pin"
                    onClick={(e) =>
                      this.sendOTP($("#phonenum").val(), "phone", e)
                    }
                  >
                    NEXT
                  </button>
                </div>
                <div
                  className="loader_box"
                  id="loader_box_one"
                  style={{ display: "none" }}
                >
                  <div className="loader">
                    <div className="loader_inner"></div>
                  </div>
                </div>
                {/* Mobile Number Screen End  */}
                {/* four Digit Number Screen  */}
                <div className="login_modal_box login_pin_phone loginCustomOne">
                  {/* <img width="70" src="/images/logo_popup.png" alt="mjunoon.tv" /> */}
                  <h3>Enter your confirmation code</h3>
                  <p>
                    Enter the 4-digit code we sent you to{" "}
                    <b>{"92" + $("#phonenum").val()}</b>
                  </p>
                  <div className="digit_fld">
                    <input
                      type="number"
                      id="phonecode1"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="phonecode2"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="phonecode3"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="phonecode4"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                  </div>

                  <button
                    id="phn_btn"
                    className="blue_btn "
                    onClick={(e) =>
                      this.checkOTP(
                        "92" + $("#phonenum").val(),
                        "phone",
                        $("#phonecode1").val() +
                          $("#phonecode2").val() +
                          $("#phonecode3").val() +
                          $("#phonecode4").val(),
                        e
                      )
                    }
                  >
                    LOGIN
                  </button>
                  <p
                    id="error_code"
                    style={{ display: "none" }}
                    className="error_msg"
                  >
                    Enterted Code Is Invalid
                  </p>
                  <p className="btn_resend">
                    <a
                      onClick={(e) =>
                        this.resendCode($("#phonenum").val(), "phone", e)
                      }
                    >
                      Resend code by sms
                    </a>
                  </p>
                  <p
                    id="resend_code_phone"
                    style={{ display: "none" }}
                    className="error_msg"
                  >
                    Code has been resent
                  </p>
                  {/* <p className="btn_resend"><a href="javascript:;">Resend code by call</a></p> */}
                </div>
                <div
                  className="loader_box"
                  id="loader_box_onee"
                  style={{ display: "none" }}
                >
                  <div className="loader">
                    <div className="loader_inner"></div>
                  </div>
                </div>

                <div
                  className="loader_box"
                  id="loader_box"
                  style={{ display: "none" }}
                >
                  <div className="loader">
                    <div className="loader_inner"></div>
                  </div>
                </div>

                {/* four Digit Number Screen End  */}
                {/* Email Screen  */}
                <div className="login_modal_box login_via_email">
                  <a className="login_back_btn" id="loginbtnemail">
                    <span uk-icon="icon: arrow-left"></span>
                  </a>
                  <img
                    width="70"
                    src="/images/logo_popup.png"
                    alt="mjunoon.tv"
                  />
                  <h3>Enter your Email</h3>
                  <div className="login_fld">
                    <input
                      className="login_fld_email"
                      id="email"
                      type="email"
                      name=""
                      placeholder="Email"
                    />
                    <p
                      id="error_email"
                      style={{ display: "none" }}
                      className="error_msg"
                    >
                      This field is mandatory
                    </p>
                  </div>
                  <p
                    id="error_code"
                    style={{ display: "none" }}
                    className="error_msg"
                  >
                    Enterted Code Is Invalid
                  </p>
                  <button
                    id="emailbtn"
                    className="blue_btn login_for_pin_email"
                    onClick={(e) => this.sendOTP($("#email").val(), "email", e)}
                  >
                    NEXT
                  </button>
                  <p className="smalltxt_modal">
                    We won't reveal your email to anyone else nor use it to send
                    you spam
                  </p>
                </div>
                {/* Email Screen End  */}
                {/* four Digit Email Screen  */}
                <div className="login_modal_box login_pin_email">
                  <img
                    width="70"
                    src="/images/logo_popup.png"
                    alt="mjunoon.tv"
                  />
                  <h3>Enter your confirmation code</h3>
                  <p>
                    Enter the 4-digit code we sent you to{" "}
                    <b>{$("#email").val()}</b>
                  </p>
                  <div className="digit_fld">
                    <input
                      type="number"
                      id="emailcode1"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="emailcode2"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="emailcode3"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                    <input
                      type="number"
                      id="emailcode4"
                      name=""
                      maxLength="1"
                      placeholder="-"
                    />
                  </div>
                  <p
                    id="error_code_email"
                    style={{ display: "none" }}
                    className="error_msg"
                  >
                    Enterted Code Is Invalid
                  </p>
                  <button
                    id="email_btn"
                    className="blue_btn "
                    onClick={(e) =>
                      this.checkOTP(
                        $("#email").val(),
                        "email",
                        $("#emailcode1").val() +
                          $("#emailcode2").val() +
                          $("#emailcode3").val() +
                          $("#emailcode4").val(),
                        e
                      )
                    }
                  >
                    LOGIN
                  </button>
                  <p className="btn_resend">
                    <a
                      onClick={(e) =>
                        this.resendCode($("#email").val(), "email", e)
                      }
                    >
                      Request a new code
                    </a>
                  </p>
                  <p
                    id="resend_code_email"
                    style={{ display: "none" }}
                    className="error_msg"
                  >
                    Code has been resent
                  </p>
                </div>

                {/* four Digit Email Screen End  */}
              </div>
            </div>
            <div className="subs_for_mob">
              <div className="subs_for_mob_inner">
                <div className="subs_topbar">
                  <h5>Payment Option</h5>{" "}
                  <a className="cancel_plan_btn" href="javascript:;">
                    Cancel Plan
                  </a>
                </div>
                <div className="subscribe_nosignin">
                  <a href="javascript:;" className="subscribe_nosignin_box">
                    <div className="subscribe_nosignin_img">
                      <img src="/images/psl_stream_icon.png" />
                    </div>
                    <span>
                      <img src="/images/livetv.svg" uk-svg="" /> Live stream PSL
                    </span>
                    <span>
                      <img src="/images/nobanner_icon.svg" uk-svg="" /> No
                      Banner/Overlay Ads
                    </span>
                    <span>
                      <img src="/images/7daysrewind_icon.svg" uk-svg="" /> 7
                      days Rewind
                    </span>
                    <span>
                      <img src="/images/mint_recording_icon.svg" uk-svg="" /> 5
                      minutes Recording
                    </span>
                  </a>
                </div>
                <div className="subscribe_packages">
                  {this.state.packages.isLoading ? this.handlePackage() : ""}
                  <Link
                    className="buywith"
                    onClick={() => this.selectPackage}
                    id="subMob"
                  >
                    Subscribe
                  </Link>
                  <div>
                    <button
                      className="buywith"
                      id="unsubMob"
                      disabled
                      style={{ display: "none" }}
                    >
                      Unsubscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Login Modal End */}
      </div>
    );
  }
}

export default Header;
