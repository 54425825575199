import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class MoreLikeProgram extends React.Component{
    handleProgramList = () => {
        let programs = "";
        programs = this.props.data.map((program,index) => (
            <div class="prog_list_cont_inner">
            <Link class="dramas_box" to={"/program/"+program.program_slug}>
            <div class="shows_slider_img"><ImageWebp webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name}/></div>
            <div class="shows_slider_txt">
              <h4>{program.program_name}</h4>
            </div>
            </Link>
          </div>  
                
        ));        
        return programs;
    };  
    render(){
        return (
            <div>
                <div class="uk-container">
                    <div class="prog_list_cont">
                        {this.handleProgramList()}
                    </div>
                </div>                
            </div>
        );
    }
}

export default MoreLikeProgram;