import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from "jquery";
import Header from "../common/Header";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import TvGuideUpnext from './TvGuideUpnext';
class TvGuideNow extends React.Component {
  constructor() {
    super();
    this.handleEpisodeList = this.handleEpisodeList.bind(this);
    this.state = {
      guide: {
        isLoaded: false,
        msg: 'Loading...',
        list: []
      },
      url : '',
      channelUrl: {
        url: '',
        channelName: '',                
      },
      fbUrl : "",
      whatsappUrl : "",
      twitterUrl : "",
      googleUrl : "",
      linkedinUrl : ""
    }
  }
  componentDidMount() {
    this.callLiveGuideApi();
  }

  rengerGlider = () => {    
    let classRef = this; 
    setTimeout(function(){
      classRef.renderHover();     
    },1000) 
  }

  openModalInner = (url) =>{ 
    url.replace("/", "W3Schools");   
  this.setState({
    fbUrl: 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mjunoon.tv%2F'+url,
    whatsappUrl : "https://api.whatsapp.com/send?text=Watch%20mjunoon.tv%20to%20stream%20more%20than%2060%20live%20tv%20channels%20absolutely%20free",
    twitterUrl : "https://twitter.com/intent/tweet/?url=https%3A%2F%2Fwww.mjunoon.tv%2F"+url,
    googleUrl : "https://plus.google.com/up/?continue=https://plus.google.com/share?url=https%3A%2F%2Fwww.mjunoon.tv%2F"+url,
    linkedinUrl : "https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.mjunoon.tv%2F"+url+"&source=LinkedIn"
  }, function () {
      $("#modal_sharing_inner").addClass('uk-flex uk-open');
  });      
  }

  callGetChannelUrlApi = (slug) => {
    let classRef = this;
    axios({
        method: 'POST',
        url: Constant.url+"streaming-url",
        data: {slug: slug, is_rewind: "no", type: "channel", user_id: ""},
        headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
    }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

        if(response.data.code === 200 && response.data.status === true){
            classRef.setState({
                channelUrl: {
                    isLoaded: true,
                    url: response.data.data.live_stream_url,                    
                    channelName: response.data.data.channel_name,                 
                }
            });

            
        }
    })
};
  renderHover = () => {
    let classRef = this;
    $.each(this.state.guide.list, function (i) { //Loop the array
      $("#gliderNow_"+i).hover(
        function () {          
          // classRef.callGetChannelUrlApi(classRef.state.guide.list[i].slug)
          $("#gliderNow_"+i).addClass('HoverClass');
          $("#tvguideLiveSlider").css("overflow", "inherit");
          // $("#video_url_" + i).children('video').attr('src',classRef.state.channelUrl.url);          
          // $("#video_url_" + i).children('video')[0].load();
          // $("#video_url_" + i).children('video')[0].muted = true;
          
              
          // $("#video_url_" + i).children('video')[0].onloadeddata = function() {
          //   $("#video_url_" + i).children('video')[0].play();      
          //   $('#img_op_' + i).css('opacity', 0);
          // };
            
          // $('.HoverNModelImgVid').on('click', '#HoverNModel_vidVolume_' + i, function(e) {if($('#HoverNModel_vidVolume_' + i).hasClass('vidVolumemute')){$('#HoverNModel_vidVolume_' + i).removeClass('vidVolumemute');$("#video_url_" + i).children('video')[0].muted = true;}else{$('#HoverNModel_vidVolume_' + i).addClass('vidVolumemute');$("#video_url_" + i).children('video')[0].muted = false;}});                        
          // $("#video_url_" + i).children('video')[0].onended = function() { 
          //   $('#img_op_' + i).css('opacity', 0);                               
          //   };          
        }, function () {
          
          // $("#video_url_"+ classRef.props.sliderKey + '_' + i)[0].pause();
          if ($(".HoverClass").length > 0) {
            $("#gliderNow_"+i).removeClass('HoverClass');
            $("#tvguideLiveSlider").css("overflow", "");
            $('#img_op_'+i).css('opacity', 1);
            // $("#video_url_" + i).children('video')[0].pause();
            // $("#video_url_" + i).children('video')[0].muted = true;
          }
        }
      )
    })
  }
  callLiveGuideApi = () => {
    let classRef = this;
    let params = JSON.stringify({user_id: localStorage.getItem("user_id") });
    axios({
      method: 'POST',
      url: Constant.url + "guide-home-live-new",
      data: { dataparams: LocalStorageService.encryptAes(params)},
      headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
    }).then(function (result) {
      let response = {};
      response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

      if (response.data.code === 200 && response.data.status === true) {
        
        classRef.setState({
          guide: {
            isLoaded: true,
            list: response.data.data.body[0].guide_live
          }
        });
        classRef.rengerGlider()  
      }
    })
  };

  redirectStore = (date,time) =>{
    localStorage.setItem('rewindtime',date+' '+time);
    localStorage.setItem('restart','yes'); 
  }

  handleEpisodeList = () => {
    let episodes = "";
    episodes = this.state.guide.list.map((episode, index) => (
      // <div key={index}>
      <div className="tvguideLiveSliderInner" id={'gliderNow_'+index}>
        <div className="tvguideLiveSliderBox">
          <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug} className="tvguideLiveSliderimg">
            <img src={episode.thumbnail} alt={episode.program_name} />
            <span className="viewerstvg">{episode.views+' Views'}</span>
            {/* <div className="continue_bar"><span style={{ width: '40%' }} /></div> */}
          </Link>
          <div  className="tvguideLiveSliderTxt">
          <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}>
            <img
              src={episode.channel_banner}
              alt={episode.program_name}
            />
            </Link>
            {(episode.is_fav)? 
            <span className="hearticon active" id={"heart_"+episode.guide_id} uk-icon="icon: heart" onClick={(e) => this.addToFavourite("heart_"+episode.guide_id,episode.guide_id,e)}></span>
            :<span className="hearticon" uk-icon="icon: heart" id={"heart_"+episode.guide_id} onClick={(e) => this.addToFavourite("heart_"+episode.guide_id,episode.guide_id,e)}></span>}
            <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}>
              <h4>{episode.program_name}</h4>              
            </Link>
            <span className="views_txt">{episode.time}</span>
          </div>
          {/* Overlay Box Start */}
          <div className="HoverNModelOverlay">
              <div className="HoverNModel">
                <div className="HoverNModelImgVid">
                <Link className="redirectLink" onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}></Link>
                {/* <ReactPlayer  className="HoverNModeVidInner" id={"video_url_" + index} controls={false} url={this.state.channelUrl.url}/> */}

                  <img id={"img_op_" + index} src={episode.thumbnail} alt=""/>
                  <span className="viewerstvg">{episode.views+' Views'}</span>
                  {/* <div className="continue_bar"><span style={{width: '40%'}} /></div> */}
                  {/* <button className="HoverNModel_vidVolume">
                    <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                    <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                    </button>  */}
                </div>
                <div className="HoverNModelTxt">
                  <div className="HoverNModelTxtHead">
                   <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug} className="HoverNModelLogo"><img src={episode.channel_banner} alt={episode.program_name} /></Link>
                   <Link onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug} className="HoverNModelHeading"><h4>{episode.program_name}</h4>
                  <span>{episode.time}</span>
                  </Link>
                    <ul>
                    <li><a onClick={(e)=>this.openModalInner(episode.slug)}><img src="/images/player_share.svg" uk-svg="" /></a></li>

                      <li><a className={(episode.is_fav)? 'active':''} id={"heart_player_"+episode.guide_id} onClick={(e) => this.addToFavourite("heart_player_"+episode.guide_id,episode.guide_id,e)}><img src="/images/player_heart.svg" uk-svg="" /></a></li>
                      <li>
                      <Link uk-tooltip={"title: " + episode.description} onClick={() => this.redirectStore(episode.date,episode.start_time)}  to={'/'+episode.slug}><img src="/images/player_info.svg" uk-svg="" /></Link>
                          <button>
                            <img className="VidMuteIcon" src="/images/player_volume_off.svg" uk-svg="" />
                            <img className="VidUnmuteIcon" src="/images/player_volume_up.svg" uk-svg="" />
                            </button>
                        </li>
                    </ul>
                  </div>
                  <div className="ptag">
                <ShowMoreText
                  lines={1}
                  more="Show more"
                  less="Show less"
                  anchorClass=""                  
                  // onClick={<Link to={'/watch/' + episode.episode_slug} />}
                  expanded={false}
                >
                  {ReactHtmlParser(episode.description)}
                </ShowMoreText>
                </div>                                    
                </div>
              </div>
            </div>
            {/* Overlay Box End */}
        </div>
      </div>
      // </div>         
    ));
    return episodes;
  };

  addToFavourite = (heart_id,id) => {  
    
    if(!localStorage.getItem('user_id')){
      new Header().openLoginModal();
      return;      
    }
    if($("#"+heart_id).hasClass('active')){ 
      
      $("#heart_"+id).removeClass('active')
      $("#"+heart_id).removeClass('active');      
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"remove-channel-guide-favourite",
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")},
          data: {channel_guide_id: id, user_id: localStorage.getItem('user_id')}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData)); 
          
          if(response.data.code === 200 && response.data.status === true){         
                 
                
          }          
      })         
    }else{            
      $("#"+heart_id).addClass('active');     
      $("#heart_"+id).addClass('active')       
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"add-channel-guide-favourite",
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")},
          data: {channel_guide_id: id, user_id: localStorage.getItem('user_id')}
      }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          
          if(response.data.code === 200 && response.data.status === true){             
              
          }          
      })
    }          
  }
  
  render() {
    return (
      
      <div>
        {(this.state.guide.isLoaded)?
        <section className="pad_left pad_b tvguideLiveSec">
          <div className="headng_box">
            <h2 className="h2forhome">
              <span className="livebtn">LIVE</span>
              <strong>Now</strong>
            </h2>
          </div>

          <div className="slider_new_container drama_sldr_container">            
            <div className="tvguideLiveSlider" uk-slider='finite: true; sets: true;'>
              <div className="uk-slider-items">
            {this.state.guide.isLoaded ? this.handleEpisodeList() : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}              
            </div>
            <a className="CarouselPrevBtn" href="#" uk-slider-item="previous"><img src="/images/left_arrow.png" alt="right arrow"/></a>
            <a className="CarouselNextBtn" href="#" uk-slider-item="next"><img src="/images/right_arrow.png" alt="right arrow"/></a>
            </div>
          </div>
          <div id="modal_sharing_inner" className="uk-flex-top modal_sharing" uk-modal="">
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button" uk-close=""></button>
                    <div className="sharing_content">
                    <h3>Share with your Friends & Family !</h3>
                      <ul>
                        <li><a className="icon_facebook" href={this.state.fbUrl} target="_blank"><span uk-icon="icon: facebook"></span></a></li>
                        <li><a className="icon_whatsapp" href={this.state.whatsappUrl} target="_blank"><span uk-icon="icon: whatsapp"></span></a></li>
                        <li><a className="icon_twitter" href={this.state.twitterUrl} target="_blank"><span uk-icon="icon: twitter"></span></a></li>
                        <li><a className="icon_google-plus" href={this.state.googleUrl} target="_blank"><span uk-icon="icon: google-plus"></span></a></li>
                        <li><a className="icon_linkedin" href={this.state.linkedinUrl}  target="_blank"><span uk-icon="icon: linkedin"></span></a></li>
                        {/* <li><a className="icon_instagram" href="javascript:;"><span uk-icon="icon: instagram"></span></a></li> */}
                      </ul>
                      </div>
                  </div>    
                </div>
        </section>
        :""}
      {<TvGuideUpnext isliveContent={(this.state.guide.list.length > 0)?true:false} data={this.props.dataUpnext}/>}
      </div>
    
    );
  }
}

export default TvGuideNow;