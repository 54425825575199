import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
class RecommendProgram extends React.Component{
    // handleChannelList = () => {
    //     let channels = "";
    //     channels = this.props.data.map((channel,index) => (
    //         <li key={index}> <Link class="channel_sldr_box" to={'/'+channel.channel_slug}>

    //         <div class="channel_sldr_img"><img src={channel.channel_logo} alt={channel.channel_name}/>

    //           <div class="chnl_hvr_box"><img src="//images/play.svg" uk-svg=""/></div>
    //           <span class="live_span">{(channel.channel_status === 'live') ? channel.channel_status : ''}</span> </div>
    //         <div class="channel_sldr_txt">
    //           <h3 class="h3tag">{channel.channel_name}</h3>
    //         </div>

    //         </Link> </li>
    //     ));        
    //     return channels;
    // };
    render(){
        return (
            <div>
                <section class="pad_left pad_tb pad_right chnl_like chnl_like2">
                  <div class="uk-height-match" uk-grid=''>
                    <div class="uk-width-3-5@m">
                      <div class="mdl">
                        <div class="mdl_inner"> <a href="javascript:;" class="chnl_like_img"><img alt="channel slider" src="/images/channel_slide2.png"/></a> </div>
                      </div>
                    </div>
                    <div class="uk-width-2-5@m">
                      <div class="mdl">
                        <div class="mdl_inner">
                          <div class="chnl_like_txt">
                            <h4>Tell us your favorite programs</h4>
                            <p>to create an experience just for you</p>
                            <Link class="lets_do" to="/personalize">LET'S DO</Link></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </section>
            </div>
        );
    }
}

export default RecommendProgram;