import React, { Component } from 'react';
import {withRouter, Switch, Route, BrowserRouter} from 'react-router-dom';

import Home from "./components/pages/Home";
import Live from "./components/pages/Live";
import Watch from "./components/pages/Watch";
import Search from "./components/pages/Search";
import Result from "./components/pages/Result";
import Program from "./components/pages/Program";
import Watch_List from "./components/pages/Watch_List";
import Watch_History from "./components/pages/Watch_History";
import Favorite_Channel from "./components/pages/Favorite_Channel";
import Live_Tv from "./components/pages/Live_Tv";
import List from "./components/pages/List";
import Program_Live from "./components/pages/Program_Live";
import Trending from "./components/pages/Trending";
import Unsigned from "./components/pages/Unsigned";
import Packages from "./components/pages/Packages";
import Favourite_Program from "./components/pages/Favourite_Program";
import Profile from "./components/pages/Profile";
import Topics from "./components/pages/Topics";
import Personalize from "./components/pages/Personalize";
import AboutUs from './components/pages/AboutUs';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Faqs from './components/pages/Faqs';
import TermsConditions from './components/pages/TermsConditions';
import Contact from './components/pages/Contact';
import ScrollToTop from "react-router-scroll-top";
import TestScroller from './components/pages/TestScroller';
import Embed from './components/embed/Player';
import NoInternet from "./components/pages/NoInternet";
import Redirect from "./components/pages/Redirect";
import Teams from './components/pages/Teams';
import TeamPlayers from './components/pages/TeamPlayers';
import Matches from './components/pages/Matches';
import Points from './components/pages/Points';
import Psl from "./components/pages/Psl";
import Score from "./components/pages/Score";
import TvGuide from './components/pages/TvGuide';
import NewHomeSlider from './components/pages/NewHomeSlider';
import TestPlayer from './components/pages/TestPlayer';
import MobileSearchSuggestions from './components/pages/MobileSearchSuggestions';
import WatchParty from './components/pages/WatchParty';
import TvGuideNew from './components/pages/TvGuideNew';
import SubscribedNow from './components/pages/SubscribedNow';
import GeneralCatPage from './components/pages/GeneralCatPage';
import GeneralCatWatch from './components/pages/GeneralCatWatch';
import MoviesWatch from './components/pages/MoviesWatch';
import MoviesList from './components/pages/MoviesList';
import GeneralProgramWatch from './components/pages/GeneralProgramWatch';

import Mgame from './components/pages/Mgame';
import Loader from './components/section/loader';
import ProgramList from './components/pages/ProgramList';
import psl9 from './components/pages/psl9';
import Reel from './components/section/Reel';
import series from './components/pages/Series';
import ComedyList from './components/pages/ComedyList';



class App extends Component {    

    render() {
        return (
                    <Switch>
                        {/* psl */}
                        {/* <Route exact path = "/loader" component={Loader}/>  */}
                        <Route exact path="/psl/live-updates" component={Psl}/>
                        <Route exact path="/psl/teams" component={Teams}/>
                        <Route exact path="/psl/matches" component={Matches}/>
                        <Route exact path="/psl/points" component={Points}/>
                        <Route exact path="/psl/team-players/:slug" component={TeamPlayers}/>
                        <Route path="/psl/:slug" component={Score}/>
                        <Route path="/psl-9/" component={psl9}/>
                        <Route path="/reels" component={Reel}/>

                        {/* psl */}
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/new-home" component={NewHomeSlider}/>
                        <Route exact path="/trending-dramas" component={Redirect}/>
                        <Route exact path="/pakistani-talk-shows" component={Redirect}/>
                        <Route exact path="/entertainment-dramas-online" component={Redirect}/>
                        {/* <Route path="/watch/:slug" component={Watch}/> */}
                        <Route path="/watch-party/:slug" component={WatchParty}/>
                        <Route exact path="/search/" component={Search}/>
                        <Route exact path="/suggestions" component={MobileSearchSuggestions} />
                        <Route exact path="/watch-live-tv-channels/" component={Live_Tv}/>


                        {/* Removed By Syed Musfar on 10/6/24  */}
                        {/* <Route exact path="/unsigned/" component={Unsigned}/> */}


                        {/* <Route exact path="/unsigned/" component={SubscribedNow}/> */}
                        {/* <Route exact path="/packages/" component={Packages}/> */}
                        <Route path="/watch-list/" component={Watch_List}/>
                        <Route path="/watch-history/" component={Watch_History}/>
                        <Route path="/profile/" component={Profile}/>
                        <Route path="/favourite-program/" component={Favourite_Program}/>
                        <Route path="/favourite-channel/" component={Favorite_Channel}/>
                        <Route path="/favourite-topics/" component={Topics}/>
                        <Route path="/search/result" component={Result}/>
                        <Route path="/watch/:slug" component={Watch}/>
                        <Route exact path="/program/:slug" component={Program}/>
                        <Route exact path="/series/:slug" component={series}/>

                        <Route exact path="/dramas/:slug" component={Program}/>
                        <Route exact path="/program/:slug1/:slug2" component={GeneralProgramWatch}/>
                        <Route path="/list/" component={List}/>
                        {/* MBK Created this page */}
                        <Route exact path="/headline-updates/" component={GeneralCatPage}/>
                        
                        <Route path="/headline-updates/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/talk-shows/" component={GeneralCatPage}/>
                        <Route path="/talk-shows/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/sports-reviews/" component={GeneralCatPage}/>
                        <Route path="/sports-reviews/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/nashpati-spotlight/" component={GeneralCatPage}/>
                        <Route path="/nashpati-spotlight/:slug" component={GeneralCatWatch}/>


                        <Route exact path="/comedy-nights-with-usman-mazhar-show/" component={ComedyList}/>
                        <Route path="/comedy-nights-with-usman-mazhar-show/:slug" component={GeneralCatWatch}/>




                        {/* <Route exact path="/dramas/" component={GeneralCatPage}/>
                        <Route path="/dramas/:slug" component={GeneralCatWatch}/> */}
                        <Route exact path="/mjunoon.tv-originals/" component={GeneralCatPage}/>
                        <Route path="/mjunoon.tv-originals/:slug" component={GeneralCatWatch}/>

                        <Route exact path="/movies" component={MoviesList}/>
                        <Route exact path="/movies/:slug" component={MoviesWatch}/>
                        <Route exact path="/lollywood" component={MoviesList}/>
                        <Route exact path="/lollywood/:slug" component={MoviesWatch}/>
                        {/* MBK Created this page */}

                        {/* 6/10/24  Page Closed by Musfar  */}
                        {/* <Route path="/personalize/" component={Personalize}/> */}
                        <Route path="/about-us/" component={AboutUs}/>
                        <Route exact path="/dramas/" component={ProgramList}/>
                        <Route path="/privacy-policy/" component={PrivacyPolicy}/>
                        <Route path="/faqs/" component={Faqs}/>
                        <Route path="/terms-and-conditions/" component={TermsConditions}/>
                        <Route path="/contact-us/" component={Contact}/>
                        <Route path="/test-player/" component={TestPlayer}/>
                        <Route path="/test-page/" component={TestScroller}/>

                        {/* trending start  */}                        
                        <Route exact path="/latest-news-headlines" component={Trending}/>
                        <Route exact path="/latest-news-headlines/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/talk-shows" component={Trending}/>
                        <Route exact path="/talk-shows/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/entertainment" component={Trending}/>
                        <Route exact path="/entertainment/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/featured" component={Trending}/>
                        <Route exact path="/featured/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/mjunoon-tv-originals" component={Trending}/>
                        <Route exact path="/mjunoon-tv-originals/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/Islamic" component={Trending}/>
                        <Route exact path="/Islamic/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/trending" component={Trending}/>
                        <Route exact path="/trending/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/global" component={Trending}/>
                        <Route exact path="/global/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/technology" component={Trending}/>
                        <Route exact path="/technology/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/music" component={Trending}/>
                        <Route exact path="/music/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/pepsi-battle-of-the-bands-s4" component={Trending}/>
                        <Route exact path="/pepsi-battle-of-the-bands-s4/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/comedy" component={Trending}/>
                        <Route exact path="/comedy/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/cooking" component={Trending}/>
                        <Route exact path="/cooking/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/cricket-worldcup-2019" component={Trending}/>
                        <Route exact path="/cricket-worldcup-2019/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/fpw-2019" component={Trending}/>
                        <Route exact path="/fpw-2019/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/karachi-eat" component={Trending}/>
                        <Route exact path="/karachi-eat/:slug" component={GeneralCatWatch}/>
                        <Route exact path="/nashpati-prime-trending" component={Trending}/>
                        <Route exact path="/nashpati-prime-trending/:slug" component={GeneralCatWatch}/>
                        <Route exact path = "/mgames" component={Mgame} />

                        <Route exact path="/ramadan-2021" component={Trending}/>
                        <Route exact path="/coronavirus-updates" component={Trending}/>
                        <Route exact path="/ramadan-2021" component={Trending}/>
                        <Route exact path="/naat-mustafa-saws-2021" component={Trending}/>
                        <Route exact path="/tafseer-e-quran-2021" component={Trending}/>
                        <Route exact path="/family" component={Trending}/>
                        <Route exact path="/events" component={Trending}/>
                        <Route exact path="/strepsils-stereo" component={Trending}/>
                        <Route exact path="/digi-leaders" component={Trending}/>
                        
                        {/* <Route exact path="/" component={Trending}/> */}
                        {/* trending end  */}

                        {/* live channel without livastream start */}
                        {/*<Route exact path="/karachi-eat" component={Program_Live}/>*/}
                        {/* <Route exact path="/coronavirus-updates" component={Program_Live}/> */}
                        <Route exact path="/karachi-eat-2020" component={Program_Live}/>
                        <Route exact path="/karachi-eat-2019" component={Program_Live}/>
                        <Route exact path="/karachi-eat-2022" component={Program_Live}/>
                        {/* <Route exact path="/karachi-eat-2020" component={Program_Live}/> */}
                        <Route exact path="/trending-mjunoon" component={Program_Live}/>
                        <Route exact path="/nescafe-basement" component={Program_Live}/>
                        <Route exact path="/strepsils-stereo-channel" component={Program_Live}/>
                        <Route exact path="/7-up-refest" component={Program_Live}/>
                        <Route exact path="/effie-pakistan-awards" component={Program_Live}/>
                        <Route exact path="/featured-12" component={Program_Live}/>
                        <Route exact path="/stand-up-comedy" component={Program_Live}/>
                        <Route exact path="/kya-class-hai" component={Program_Live}/>
                        <Route exact path="/icc-world-cup" component={Program_Live}/>
                        <Route exact path="/pepsi-battle-of-the-bands" component={Program_Live}/>
                        <Route exact path="/mountaindew-live" component={Program_Live}/>
                        <Route exact path="/nashpati-prime" component={Program_Live}/>
                        <Route exact path="/fashion-pakistan-week" component={Program_Live}/>
                        <Route exact path="/mjunoon-originals-channels" component={Program_Live}/>
                        <Route exact path="/ramadan-specials" component={Program_Live}/>
                        <Route exact path="/digi-leaders-conference-live" component={Program_Live}/>
                        <Route exact path="/pakistan-digital-awards" component={Program_Live}/>
                        
                        
                        
                        {/* <Route exact path="*" component={NoInternet}/> */}
                        <Route exact path="/404" component={NoInternet}/>
                        <Route exact path="/tv-guide" component={TvGuideNew}/>
                        <Route exact path="/tv-guide-new" component={TvGuideNew}/>


                        {/* live channel without livastream end */}

                        {/* embed PLayer */}
                        <Route exact path="/embedplayer/:slug" component={Embed}/>
                        {/* embed PLayer end*/}

                        <Route exact path="/:slug" component={Live}/>
                        <Route component={NoInternet}/>



                    </Switch>  
        );              
    }
}

export default App;
