import React from 'react';
import Shimmer from "react-shimmer-effect";
class SliderShimmer extends React.Component {    

    render() {
        return (
            <div>
               <section className="NewHomeSlider">
                    <div className="NewHomeSlider_inner desktopSlide shimmerSliderHomeContainer"> 
                    <Shimmer>                                                
                        
                            <div className="shimmerSliderHome shimmer_bg"></div>
                            <div className="NewHomeSlider_txt shimmer_bg">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                    <div className="NewHomeSlider_btns uk-margin-small-bottom">
                                    <span></span><span></span>
                                    </div>
                                    <div className="sliderlogo"></div>
                                        <h3> </h3>
                                    <div className="gf_txt">
                                        <div className="gf_watch_btn"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                
                    </Shimmer>
                    </div>
                    </section>
            </div>
        );
    }
}

export default SliderShimmer;