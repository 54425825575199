import React from 'react';
import {Link} from 'react-router-dom';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
class WC_EpisodeMobile extends React.Component{
    handleEpisodeList = () => {
        let episodes = "";
        episodes = this.props.data.map((episode,index) => (
            
            <div key={index} className="uk-width-1-5@xl uk-width-1-4@m uk-width-1-3@s uk-width-1-2">
              <div className="prog_sldr_box">
                <div className="prog_sldr_img"><Link to={'/watch/'+episode.episode_slug}><ImageWebp webp={episode.episode_thumbnail} src={episode.episode_thumbnail_jpg} alt={episode.episode_name}/>
                  <div className="duration_time"><span>{episode.episode_duration}</span></div>
                  <div className="hvr_box"><span uk-icon="play"></span></div>
                  </Link> </div>
                <div className="prog_sldr_text">
                  <h4 className="homesectionone"><b><Link className="anchor" to={'/watch/'+episode.episode_slug} title={episode.episode_name}>{episode.episode_name}</Link></b></h4>
                  {/* <p><span>{episode.channel_name} </span> <span>{episode.episode_views} views</span> </p> */}
                  <p><span className="episode_channel">{(episode.channel_name === undefined )? '' : episode.channel_name+" ." }</span> <span>{episode.episode_views} views . </span><span>{episode.episode_date}</span> </p>
                  
                </div>
              </div>
            </div>
                
        ));        
        return episodes;
    };  
    render(){
        return (
            <div>
                <section className="pad_left pad_right pad_t prog_box_sec">
                <div className="headng_box">
                  <h2 className="h2forhome"><strong>{this.props.name}</strong></h2>
                  </div>
                  <div className="uk-height-match uk-grid-small" uk-grid="">
                    {this.handleEpisodeList()}
                  </div> 
                </section>
            </div>
        );
    }
}

export default WC_EpisodeMobile;