import React from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import ImageWebp from 'react-image-webp';


class MoviesCard extends React.Component{

    componentDidMount(){
        new window.Glider(document.querySelector('#glider_slider_pro'+this.props.sliderKey), {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 110,
            itemWidth: 110,
            duration: 0.25,
            draggable: true,
            arrows: {
            prev: '#glider_slider_pro'+this.props.sliderKey+'_prev',
            next: '#glider_slider_pro'+this.props.sliderKey+'_next'
            },
            responsive: [
            {
            breakpoint: 360,
            settings: {
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            exactWidth: 120,
            itemWidth: 120,
            duration: 0.25,
            }
            },{
            breakpoint: 375,
            settings: {
            exactWidth: 120,
            itemWidth: 120,
            }
            },{
            breakpoint: 411,
            settings: {
            exactWidth: 120,
            itemWidth: 120,
            }
            },{
            breakpoint: 768,
            settings: {
            exactWidth: 120,
            itemWidth: 120,
            }
            },{
            breakpoint: 1024,
            settings: {
            exactWidth: 220,
            itemWidth: 220,
            }
            },{
            breakpoint: 1201,
            settings: {
            exactWidth: 220,
            itemWidth: 220,
            draggable: false,
            }
            },{
            breakpoint: 1281,
            settings: {
            exactWidth: 220,
            itemWidth: 220,
            draggable: false,
            }
            },{
            breakpoint: 1401,
            settings: {
            exactWidth: 220,
            itemWidth: 220,
            draggable: false,
            }
            },{
            breakpoint: 1601,
            settings: {
            exactWidth: 220,
            itemWidth: 220,
            draggable: false,
            }
            }
            ]
          })
          
    }
     /**
        {
            "channel_name": "Lollywood",
            "episode_description": "Babboo Khan is a heartwarming story of love, sacrifice, and dreams. Watch Babboo Khan movie only on Mjunoon.tv.",
            "program_name": "Babboo Khan Movie",
            "episode_name": "Babboo Khan Movie",
            "episode_slug": "babboo-khan-movie",
            "episode_thumbnail": "https://images.mjunoon.tv:8181/mjunoon_images_new/lHANslMC2i3.webp",
            "episode_thumbnail_jpg": "https://images.mjunoon.tv:8181/mjunoon_images/lHANslMC2i3.jpg",
            "episode_topic": "Babboo Khan,Movie,Lollywood",
            "episode_duration": "02:26:13",
            "episode_views": "280",
            "episode_date": "3 days ago",
            "episode_video_url": "https://vod.mjunoon.tv:8383/playlist/2124/lHANslMC2i3/180p.mp4"
        },
 
    */
   
   
    handleProgramList = () => {
        let programs = "";
        let classRef = this; 
        // console.log(this.props.name + " " + "props data: ", this.props.data);
        programs = this.props.data.map((program,index) => (
            <div key={index} id={"glider_slider_pro"+classRef.props.sliderKey+'_'+index}>
                <Link to={"/movies/"+program.episode_slug}>
                    <div className="shows_slider_img">
                        <img src={"https://www.mjunoon.tv/" + program.episode_poster} alt={program.episode_name} />
                        <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
                    </div>                    
                </Link>               
            </div>
        ));
        return programs;
    };
    render(){
        return (
            <div>
                <section className="pad_left pad_t shows_slider_sec moviesSection">
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong>{(this.props.type) ? this.props.name+' '+this.props.type : this.props.name }</strong></h2>
                       <Link className="view_all" to={"/movies/"}>{(window.location.pathname === '/') ? 'View All' : ''}</Link>  </div>
                        <div className="slider_new_container drama_sldr_container">
                       <div id={"glider_slider_pro"+this.props.sliderKey} className="drama_sldr_new">                        
                            {this.handleProgramList()}
                        </div>
                        <button role="button" aria-label="Previous" id={"glider_slider_pro"+this.props.sliderKey+"_prev"} className="glider-prev"><img src="/images/left_arrow.png" alt="left arrow"/></button>
                        <button role="button" aria-label="Next" id={"glider_slider_pro"+this.props.sliderKey+"_next"} className="glider-next"><img src="/images/right_arrow.png" alt="right arrow" /></button>
                        
                        </div>
                </section>
                <hr/>
            </div>
        );
    }
}

export default MoviesCard;