import React from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import InfiniteScroll from "react-infinite-scroll-component";
import EpisodeViewAll from "../section/EpisodeViewAll";
import ChannelViewAll from "../section/ChannelViewAll";
import ProgramViewAll from "../section/ProgramViewAll";
import $ from 'jquery';
import EpisodeViewAllMobile from "../section/EpisodeViewAllMobile";
import {isMobile} from "react-device-detect";
import Loader from "../section/loader";
var query_string = "";
class ComedyList extends React.Component {
  constructor() {
    super();

    this.callApi = this.callApi.bind(this);
    this.handleDataList = this.handleDataList.bind(this);

    this.state = {
      listDatas: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      progarms:{
        isLoaded:false,
        programData:[]
      },
      channels:{
        isLoaded:false,
        channelData:[]
      },
      program_status:true,
      channel_status:true,
      date:true,
      pageNumber: 1,
      hasMore: true,
      isFilter : false,
      heading : '',
      currentURLState: '',
    };
  }

  componentDidMount() {
    {/**
        this page is same as list.js
        on list js we were getting query string from url
        but in this we have hard coded that query string to reuse the api and to create seperate clean url for seo


    */}
    const { location } = this.props;
    const currentURL = location.pathname;
    // this.setState({currentURLState : currentURL});

    // console.log(currentURLState);
    // function to remove / and - signs and capitalize words  
    function cleanAndCapitalizeURL(url) {
        // Remove leading and trailing slashes and hyphens using regular expression
        const cleanedURL = url.replace(/^[-/]+|[-/]+$/g, '');
        // Split the cleaned URL into words, capitalize the first letter of each word, and join them back
        const words = cleanedURL.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const capitalizedURL = words.join(' ');
        return capitalizedURL;
      }
      const cleanedAndCapitalizedURL = cleanAndCapitalizeURL(currentURL);
    //   console.log(cleanedAndCapitalizedURL);



    query_string = {topic: cleanedAndCapitalizedURL, type: 'episode'};


    // console.log("query string: ", query_string);

    this.setState({heading:query_string.topic})
    if(query_string.topic == 'Headline Updates'){
      this.setState({isFilter:true});
      this.setState({program_status:true});
      this.setState({channel_status:true});
      this.setState({date:true});
      this.getChannels();    
    }
    if(query_string.type == 'episode'){
      this.setState({program_status:true});
      this.setState({channel_status:true});
      this.setState({date:true});
      this.getChannels();    
    }
    // if(query_string.type == 'program'){
    //   this.setState({program_status:false});
    //   this.setState({date:false});
    //   this.getChannels();
    // }
    // if(query_string.type == 'channel'){
    //   this.setState({program_status:false});
    //   this.setState({channel_status:false});
    //   this.setState({date:false});
    // }
    window.initializSearchDate();
    LocalStorageService._serviceToken(this.callApi);
  }



  pagination = () => {
      
    this.setState({ pageNumber: this.state.pageNumber + 1 },this.callApi);
    // LocalStorageService._serviceToken(this.callApi);
  };

  callApi = () => {
    let classRef = this;
    let user_id = localStorage.getItem("user_id");
    let type = query_string.type;
    let topic = query_string.topic;

    axios({
      method: "POST",
      url: Constant.url + "topic-base-data",
      data: {
        user_id: user_id,
        type: type,
        topic: topic,
        page: this.state.pageNumber,        
        channel_id:$("#channels").val(),
        program_id: $("#programs").val(),
        date:$("#SearchDate").val()
      },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200) {
            // console.log("data list: ", response.data);
          classRef.setState({
            listDatas: {
              isLoaded: true,
              list: [
                ...classRef.state.listDatas.list,
                ...response.data.data.body,
              ],
            },
            // pageNumber: classRef.state.pageNumber + 1,
            hasMore: response.data.data.hasMore,
          });
           
          window.tagManagerEvent(
            response.data.data.body[0].heading + " - Content Listing",
            response.data.data.body[0].type + " - Content Listing",
            response.data.data.body[0].heading,
            {
              placement_channel_status: "",
              program_status: "",
              placement_genre: "",
              user_gender: localStorage.getItem("gender")
                ? localStorage.getItem("gender")
                : "Unknown",
              user_age: localStorage.getItem("dob")
                ? Constant.getDateDiff(
                    localStorage.getItem("dob"),
                    Constant.currentDate
                  )
                : "",
              user_interest:
                window.getUserTopics() !== "" ? window.getUserTopics() : "",
              user_type: localStorage.getItem("mobile") ? "real" : "temp",
              user_behavior: Constant.currentEpocTime,
              custom_ga_client_id:
                window.ga_client_id !== "" ? window.ga_client_id : "",
              email: localStorage.getItem("email")
                ? localStorage.getItem("email")
                : "",
              name: localStorage.getItem("name")
                ? localStorage.getItem("name")
                : "",
              number: localStorage.getItem("mobile")
                ? localStorage.getItem("mobile")
                : "",
              search_keyword: "",
              user_custom_id: localStorage.getItem("user_id")
                ? localStorage.getItem("user_id")
                : "",
              user_device_id: "",
            }
          );
        }else{
            classRef.setState({
                hasMore: response.data.data.hasMore,
              });
        }
      })
      .then(function (response) {
        
      });
  };
  getPrograms = () => {
    let classRef = this;
    classRef.setState({
      progarms:{
        isLoaded:true,
        programData:[]
      }
    });
    let user_id = localStorage.getItem("user_id");
    let type = 'program';
    let topic = query_string.topic;

    axios({
      method: "POST",
      url: Constant.url + "program-by-channel",
      data: {
        channel_id: $("#channels").val()
      },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200) {
          classRef.setState({
            progarms:{
              isLoaded:true,
              programData:response["data"]["data"]
            }
          });
        }
      })
      .then(function (response) {
        
      });
  };
  getChannels = () => {
    let classRef = this;
    let user_id = localStorage.getItem("user_id");
    let type = 'channels';
    let topic = query_string.topic;

    axios({
      method: "POST",
      url: Constant.url + "topic-base-data",
      data: {
        user_id: user_id,
        type: type,
        topic: topic,
        page: this.state.pageNumber,
      },
      headers: {
        Authorization: "bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (result) {
        let response = {};
        response["data"] = JSON.parse(
          LocalStorageService.decryptAes(result.data.eData)
        );
        if (response.data.code === 200) {
          classRef.setState({
            channels:{
              isLoaded:true,
              channelData:response["data"]["data"]["body"][0]["channel"]
            }
          });
        }
      })
      .then(function (response) {
        
      });
  };

  handleDataList = () => {
    const { location } = this.props;
    const currentURL = location.pathname;
    
    
    let listDatas = "";
    let index = 0;
    let divIndex = 0;
    let fav_channel = queryString.parse(this.props.location.search).fav
      ? queryString.parse(this.props.location.search).fav
      : "";
    if (this.state.pageNumber === 2) {
      divIndex = 1;
    } else {
      divIndex = 0;
    }
    listDatas = this.state.listDatas.list.map((listData) => {
      index++;
      if (listData.type === "channel" && fav_channel === "") {
        return (
          <ChannelViewAll
            pagekey={divIndex}            
            data={listData.channel}
          />
        );
      } else if (listData.type === "episode") {
        if(isMobile){
        return (          
          <EpisodeViewAllMobile
            pagekey={divIndex}            
            data={listData.episodes}
          />          
        );
        }else{
            console.log(listData.type)
          return (          
            <EpisodeViewAll
              singlePageURL={currentURL.replace(/^\/+|\/+$/g, '')}
              pagekey={divIndex}            
              data={listData.episodes}
            />          
          );
        }
      } else if (listData.type === "programs") {
        return (
          <ProgramViewAll
            pagekey={divIndex}            
            data={listData.programs}
          />
        );
      }
    });
    return listDatas;
  };
  handleChannels(){
    let result= "";
        let index = 0;
        result = this.state.channels.channelData.map((response,index) => (
            <option value={response.channel_id}>{response.channel_name}</option>
        ));
        return result;
  }
  handlePrograms(){
    let programs= "";
        let index = 0;
        programs = this.state.progarms.programData.map((response,index) => (
            <option value={response.id}>{response.name}</option>
        ));
        return programs;
  }

  handleContentDetails(){
    /**
     *  Function to check url and give static content in return
     * */ 
    const { location } = this.props;
    const currentURL = location.pathname;
    console.log(currentURL);
    if(currentURL === "/headline-updates/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Stay informed with today’s headline news on Mjunoon TV. Explore a wide selection of English and Urdu news channels that provide real-time news coverage of international, national, regional, and local news, analysis, and current affairs.</p>;
    }
    else if(currentURL === "/talk-shows/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Stay engaged with the latest Pakistani talk shows today on Mjunoon TV. Dive into a diverse collection of captivating talk shows, offering insightful discussions, thought-provoking conversations, and in-depth analysis on various international, national, regional, and local topics.</p>;
    }
    else if(currentURL === "/sports-reviews/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Stay tuned for the latest sports review, analysis, live broadcasts, documentaries, talk shows, discussions, and interviews covering a wide range of international, national, and local sports only on Mjunoon TV. </p>;
    }
    else if(currentURL === "/nashpati-nights/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Stay informed with today’s headline news on Mjunoon TV. Explore a wide selection of English and Urdu news channels that provide real-time news coverage of international, national, regional, and local news, analysis, and current affairs.</p>;
    }
    else if(currentURL === "/dramas/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Stay immersed in the world of Pakistani drama on Mjunoon TV. Explore a diverse collection of Pakistani drama that deliver compelling stories, intriguing narratives, and top-notch performances by renowned actors that resonate with national and international audiences.</p>;
    }
    else if(currentURL === "/mjunoon.tv-originals/"){
      return <p style={{margin: "20px 0", lineHeight: "1.6",}}>Welcome to Mjunoon TV Originals – where impactful, thought-provoking skits address serious issues like harassment, drug abuse, women's awareness, costly healthcare, and much more. Get ready for impactful, original content that sparks important conversations. </p>;
    }
    else{}
    

  }

  render() {
    return (
      <div>

          {this.state.listDatas.isLoaded? <>
            <Header />
            <div className="content_sec" id="talk-shows-view-all" >
                {/* {(this.state.isFilter)? */}
                <div className="FilterSec pad_t pad_left pad_right">
                <div style={{ marginTop: "20px"}}>
                    <div className="headng_box">
                        <h2 className="h2forhome"><strong>{this.state.heading}</strong></h2>
                        {this.handleContentDetails()}
                    </div>
                </div>
                {/* <div className="headng_box">
                <h2 className="h2forhome"><strong>{this.state.heading}</strong></h2>
                </div> */}
                    {/* Modal Button */}
                    {/* <button className="FilterButton" uk-toggle="target: #FilterModal" type="button">
                    <img src="/images/search.svg" uk-svg="" />
                    </button> */}
                    
                </div>
                {/* :""} */}
            
                <InfiniteScroll
                    useWindow={false}
                    style={{ overflow: "none" }}
                    dataLength={this.state.listDatas.list.length} //This is important field to render the next data
                    next={this.pagination}
                    hasMore={this.state.hasMore}
                    loader={
                    <div className="loader_box">
                        <div className="loader">
                        <div className="loader_inner"></div>
                        </div>
                    </div>
                    }
                    endMessage={(this.state.pageNumber == 1 && this.state.listDatas.list.length==0)?<section class="pad_left pad_right pad_tb prog_box_sec uk-text-center"><p className="uk-margin-large-top">No Data Found</p></section>:<p style={{textAlign: 'center'}}><b>Yay! You have seen it all</b></p>}
                >
                    
                    {this.state.listDatas.isLoaded === true
                    ? this.handleDataList()
                    : ""}
                </InfiniteScroll>
            </div>
            <div id="FilterModal" className="FilterModal uk-flex-top" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close" type="button">
                        <span uk-icon="close"></span>
                    </button>
                    <form action="javascript:;">
                        {(this.state.date)?<div className="FilterFld">
                            <label>Select Date</label>
                            <input
                            className="uk-input SearchDate"
                            id="SearchDate"
                            type="text"
                            placeholder="Select Date"
                            />
                        </div>:""}
                        <div className="FilterFld">
                            <label>Channel</label>
                            <select className="uk-select" id="channels" onChange={this.getPrograms}>
                            <option value="">Please Select Channel</option>
                            {(this.state.channels.isLoaded)?this.handleChannels():""}
                            </select>
                        </div>
                        {(this.state.program_status)?<div className="FilterFld">
                            <label>Program</label>
                            <select className="uk-select" id="programs">
                            <option value="">Please Select Program</option>
                            {(this.state.progarms.isLoaded)?this.handlePrograms():""}
                            </select>
                        </div>:""}
                        <div className="FilterFld">
                            <button className="searchbtn" onClick={this.searchData} uk-toggle="target: html; cls: uk-modal-page">Search</button>
                            <button className="searchbtn" onClick={this.resetSearch} uk-toggle="target: html; cls: uk-modal-page">Reset</button>
                        </div>              
                    </form>
                </div>
            </div>
            <Footer />
            </> : <Loader/>  }
      </div>
    );
  }
}
export default withRouter(ComedyList);
