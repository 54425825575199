import React from 'react';
import {Link} from 'react-router-dom';
import Constant from "../../utils/Constant";
class TypesFilterShimmer extends React.Component{
    render(){
        return (
            <div>
                <section className="pad_left pad_t pad_right fav_slider uk-position-relative jd">
                  <a href="javascript:;" className="filter_btn filter_btnShimmer"></a>
                </section>
                <section className="pad_left pad_tb pad_right CrouselShimmerCont">
                        <div className="headng_box">
                            <h2 className="h2forhome"><strong></strong></h2>
                        </div>
                        <div className="uk-height-match uk-grid-small" uk-grid="">
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            <div className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
                                <div className="fav_sld_img"></div>
                            </div>
                            
                        </div>
                    </section>
            </div>
        );
    }
}

export default TypesFilterShimmer;