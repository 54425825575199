import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import Constant from "../../utils/Constant";
import LocalStorageService from "../../utils/LocalStorageService";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Meta from '../section/Meta';
import MetaCreation from "../../utils/MetaCreation";
import NoInternetConnect from "../section/NoInternetConnect";

class Contact extends React.Component{

  constructor(){
    super();
    this.state = {
        metaKeywords: '',
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
        schema: ''                                               
    }
}
componentDidMount(){
  MetaCreation.createMeta('static',window.location.pathname.replace(/^\/|\/$/g, ''),(e) => this.callMetaData(e));

}
submitForm = () =>{  
  $("#btn_custom_contact").attr("disabled", true);
  LocalStorageService._serviceToken(this.initializeToken);
}
callMetaData = (metaResponse) =>{
  
  this.setState({
      metaKeywords : metaResponse.keywords,
      metaTitle : metaResponse.title,
      metaDescription : metaResponse.description,
      metaImage : metaResponse.thumbnail,
      schema : metaResponse.schema
  })
}

validateLength = (event) => {
  const input = event.target;
  if (input.value.length > 11) {
    input.value = input.value.slice(0, 11); // Limit to 11 digits
  }
};


initializeToken = () => {
        $('#btn_custom_contact').html('Processing...');	
        var f_name = $("#f_name").val();
        var l_name = $("#l_name").val();
        var email_add = $("#email_add").val();
        var number = $("#number").val();
        var subject = $("#subject").val();
        var msg = $("#msg").val();

      const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
      const regtest  = emailregex.test(email_add)
      
      if(f_name == "" || l_name == "" || email_add == "" || number == "" || subject == "" || msg == ""){
        $("#save_error").html('All Fields Are Required!');
        $("#save_error").show();
        $('#btn_custom_contact').html('Submit');	
        $('#btn_custom_contact').attr("disabled", false);	
        return;
      }

      if(!regtest){
          $("#save_error").html('Invalid Email !');
          $("#save_error").show();
          $('#btn_custom_contact').html('Submit');	
          $('#btn_custom_contact').attr("disabled", false);	
          return
      }

        if(number.length > 11 || number.length < 11){

          number = number.slice(0, 11);

          $("#save_error").html('Invalid Number!');
          $("#save_error").show();
          $('#btn_custom_contact').html('Submit');	
          $('#btn_custom_contact').attr("disabled", false);	
          return
        }

        axios({
            method: 'POST',
            url: Constant.url+"contact-us",
            data: {f_name : f_name,l_name : l_name,email_add : email_add,number : number,subject : subject,msg : msg},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if(response.data.code === 200){
                $("#save_error").hide();
                $("#save_success").html('Thank you for contacting us! We will back to you soon. ');  
                $("#save_success").show();
                $('#btn_custom_contact').attr("disabled", false);	
                $('#btn_custom_contact').html('Submit');	
            }else{
                $("#save_error").html('Something went wrong!');
                $("#save_error").show();
                $('#btn_custom_contact').html('Submit');	
                $('#btn_custom_contact').attr("disabled", false);	
            }

        }).then(function (response) {
            
        });        
    }

    
    render(){
        return(
            <div>
               <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>
            <Header/>                                    
            <div class="content_sec"> 
                <section class="randdom_header pad_left pad_right pad_tb">
                    <h1>Contact Us</h1>
                    <p><strong>Help and customer support</strong></p>
                    <p>We're always happy to help. Your question may have already been answered in our <Link to="/faqs">FAQ's</Link>. If not, send us a message at <a href="mailto:info@mjunoon.tv">info@mjunoon.tv</a></p>
                </section>    
                <section class="randdom_body pad_left pad_right pad_tb">
                    <div uk-grid="">
                      <div class="uk-width-3-5@m">
                        <form  action="javascript:void(0)">
                          <fieldset class="uk-fieldset">
                            <legend class="uk-legend">Just fill in the form below and we'll get back to you:</legend>
                            <div class="uk-margin">
                              <input name="f_name" id="f_name" class="uk-input" type="text" placeholder="First Name*" required />
                            </div>
                            <div class="uk-margin">
                              <input name="l_name" id="l_name" class="uk-input" type="text" placeholder="Last Name*" />
                            </div>
                            <div class="uk-margin">
                              <input name="email_add"  id="email_add" class="uk-input" type="email" placeholder="Email Address*"/>
                            </div>
                            <div class="uk-margin">
                              <input name="number" onChange={this.validateLength}  id="number" class="uk-input" type="number" placeholder="Contact Number*"/>
                            </div>
                            <div class="uk-margin">
                              <select name="subject" id="subject" class="uk-select">
                                <option value="">Select Subject</option>
                                <option value="partner">Partner</option>
                                <option value="advertising-sales">Advertising Sales</option>
                                <option value="content-partnership">Content Partnership</option>
                                <option value="business-relationship">Business Relationship</option>
                                <option value="press">Press</option>
                                <option value="dmca-complaint">DMCA Complaints</option>
                                <option value="feedback">Feedback</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div class="uk-margin">
                              <textarea name="msg" id="msg" class="uk-textarea" rows="5" placeholder="Type Your Message*"></textarea>
                            </div>
                            <div class="uk-margin">
                              <button class="submit_btn" onClick={this.submitForm} id="btn_custom_contact">Submit</button>
                            </div>
                            <p id="save_error" style={{display:'none'}}  class="error_msg_pro"></p>
                            <p id="save_success" style={{display:'none'}} class="success_msg_pro"></p>
                          </fieldset>
                        </form>
                      </div>
                      <div class="uk-width-2-5@m">
                        <p><strong>Get in touch with us:</strong></p>
                        <address>
                        <a href="callto:+922134327748">+92 21 34327748 - 49</a>
                        </address>
                        <address>
                        Suite 803, 8th Floor, Ibrahim Trade<br/>
                        Tower, Block 7 &amp; 8, M.C.H.S,<br/>
                        Main Shahrah-e-Faisal,<br/>
                        Karachi, Pakistan.
                        </address>
                      </div>
                    </div>
                </section>                                      
            </div>
            <Footer/>   
            </div>
        );
     }
}
export default withRouter(Contact);