import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import $ from 'jquery';
import ReactPlayer from 'react-player';
import Meta from '../section/Meta';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import MetaCreation from "../../utils/MetaCreation";
import InfiniteScroll from 'react-infinite-scroll-component';
import Constant from "../../utils/Constant";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ImageWebp from 'react-image-webp';
// import {AdSlot} from "react-dfp";

class Psl extends React.Component{
    constructor(){
        super();
        this.callApi = this.callApi.bind(this);
        this.handleData = this.handleData.bind(this);
        this.state = {
            matches : {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            metaKeywords: '',
            metaTitle: '',
            metaDescription: '',
            metaImage: '',
            schema: ''   
        }
        
    }

    componentDidMount(){
        LocalStorageService._serviceToken((e) => this.callApi());
        MetaCreation.createMeta('static','live-updates',(e) => this.callMetaData(e));
    }

    callMetaData = (metaResponse) =>{
        
        this.setState({
            metaKeywords : metaResponse.keywords,
            metaTitle : metaResponse.title,
            metaDescription : metaResponse.description,
            metaImage : metaResponse.thumbnail,
            schema : metaResponse.schema
        });
        let classRef = this;
        setTimeout(function () {
            window.metaUpdated();
        }, 1000);
    }

    callApi = () => {
        let classRef = this;

        let user_id = localStorage.getItem('user_id');
        axios({
            method: 'POST',
            url: Constant.url+"psl-teams",
            // data: {user_id: user_id,slug: slug},
            headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            
            if(response.data.code === 200){
                classRef.setState({
                    matches:{
                        isLoaded : true,
                        list: response.data.data
                    },                                        
                })
            }else{
                classRef.setState({
                    matches:{
                        isLoaded : true,
                        list: []
                    },                                        
                })
            }  
        }).then(function () {
            
        });        
    };

    handleData = () =>{
        let result= "";
        let index = 0;
        if(this.state.matches.list.length > 0){
            result = this.state.matches.list.map((response,index) => (
                <div class="uk-width-1-2 uk-width-1-3@m">
                    <div class="prog_sldr_box">
                        <div class="prog_sldr_img">
                            <Link to={'/psl/'+response.slug}>{(response.status === 'live') ? <span class="live_btn"></span> : "" }<img src={response.main_image} alt={response.match_title}/></Link>
                        </div>
                        <div class="prog_sldr_text">
                            <h4 class="homesectionone"><b><Link class="anchor" title={response.match_title} to ={'/psl/'+response.slug} >{response.match_title}</Link></b></h4>
                            <p><span class="episode_channel">{response.match_number}</span> </p>
                        </div>
                    </div>
                </div>  
            ));
        }else{
            result = <div>NO MATCHES YET</div>;
        }
        return result;  
    }

    render(){
        return(
            <div>
                {this.state.metaTitle !== '' ? <Meta schema ={null} metaKeywords = {this.state.metaKeywords} metaTitle={this.state.metaTitle} metaDescription={this.state.metaDescription}  metaImage={this.state.metaImage}/>: ''}
                <Header/>
                <div class="content_sec live_sec">
                        <section class="tending_player_sec uk-padding-remove-top">
                            <div class="psl_sec">
                                <div class="box_psl">
                                    <div class="psl_menu uk-margin-bottom" uk-slider="finite: true">
                                        <ul class="uk-slider-items">
                                            <li class={(window.location.pathname === '/psl/live-updates') ? "active" : ''}><Link to="/psl/live-updates">Live Updates</Link></li>
                                            <li class={(window.location.pathname === '/psl/teams') ? "active" : ''}><Link to="/psl/teams">Teams</Link></li>
                                            <li class={(window.location.pathname === '/psl/matches') ? "active" : ''}><Link to="/psl/matches">Matches</Link></li>
                                            <li class={(window.location.pathname === '/psl/points') ? "active" : ''}><Link to="/psl/points">Points Table</Link></li>
                                        </ul>
                                    </div>
                                    <Link class="live_span_btn" to="/psl-live"><span class="live_span">PSL Live</span></Link>
                                    <div class="uk-height-match" uk-grid="">
                                    {(this.state.matches.isLoaded) ? this.handleData() : <div className="loader_box"><div className="loader"><div className="loader_inner"></div></div></div>}
                                    </div>
                                </div>
                            </div>
                            <div class="sidebar">
                            </div>
                        </section>
                    </div>
                <Footer/>
            </div>
        );
    }
}
export default withRouter(Psl);