import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Constant from "../../utils/Constant";
import $ from 'jquery';
import ImageWebp from 'react-image-webp';
import LocalStorageService from "../../utils/LocalStorageService";
class List_Program extends React.Component{
    
    editButton = () =>{
        var edit_btn_txt = $(".edit_btn");
        edit_btn_txt.toggleClass('open_edit');
        if(edit_btn_txt.hasClass('open_edit')){
            edit_btn_txt.text('Save');         
        } else {
           edit_btn_txt.text('Edit');
        }
      $('.dramas_box').toggleClass('for_del');
    }
    deleteProgram = (programId) =>{
      LocalStorageService._serviceToken((e) => this.deleteProgramApi(programId,e));    
    }
    deleteProgramApi = (programId) =>{
      // console.log("deleted");
      let user_id = localStorage.getItem('user_id');
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"remove-favourite",
          data: {id: programId, user_id: localStorage.getItem('user_id'),type:'program'},
          headers: {'Authorization': "bearer " + localStorage.getItem("access_token")}
        }).then(function (result) {
          let response = {};
          response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if(response.data.code === 200 && response.data.status === true){
            $("#deleteSection"+programId).hide();
          }
          
      })
    }

    handleProgramList = () => {
        let programs = "";
        programs = this.props.data.map((program,index) => (
          <div class="prog_list_cont_inner" id={"deleteSection"+program.program_id} >
            <div class="dramas_box">
                <div class="shows_slider_img">
                  <Link to={"/program/"+program.program_slug}><ImageWebp  webp={program.program_thumbnail} src={program.program_thumbnail_jpg} alt={program.program_name}/></Link>
                  <div class="del_box pointer" onClick={(e) => this.deleteProgram(program.program_id,e)}><img src="/images/del.svg" alt="delete" uk-svg=""/></div></div>
                <div class="shows_slider_txt">
                <h4>{program.program_name}</h4>
                </div>
            </div>                
          </div>  
                
        ));        
        return programs;
    };  
    render(){
        return (
            <div>
              <section  class={(this.props.noClass) ? "pad_left pad_right pad_tb prog_box_sec" : "pad_left pad_right pad_tb prog_box_sec dark_bg" }>
                <div class="headng_box">                
                <h2 class="h2forhome"><strong>Favorite Programs</strong></h2><a href="javascript:;" class="edit_btn view_all" onClick={this.editButton}>Edit</a>
                  </div>
                  <div class="prog_list_cont">
                    {this.handleProgramList()}
                  </div>
               </section>
            </div>
        );
    }
}

export default List_Program;