import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import LocalStorageService from "../../utils/LocalStorageService";

import Constant from "../../utils/Constant";
import { isMobile } from 'react-device-detect';


class MobileSearchSuggestions extends React.Component {
    // Searh Filter Show
    constructor() {
        super();
        this.keywordHandler = this.keywordHandler.bind(this);
        this.channelHandler = this.channelHandler.bind(this);
        this.episodeHandler = this.episodeHandler.bind(this);
        this.handleChannelDropdown = this.handleChannelDropdown.bind(this);
        this.handleTypeDropdown = this.handleTypeDropdown.bind(this);
        this.state = {
            channelDropDown: {
                isLoading: false,
                list: []
            },
            typesDropDown: {
                isLoading: false,
                list: []
            },
            programDropDown: {
                isLoading: false,
                list: []
            },
            suggestion: {
                isLoading: false,
                searchKeyword: [],
                searchChannels: [],
                searchEpisodes: [],
            },
        }
    }

    componentDidMount(){
        let classRef = this;

        if(isMobile){
            this.callTypeDropDown();
        }else{
            window.location.href="/";
        }
        
        window.initializFromSearchFilterDate();
        window.initializToSearchFilterDate();
        var data = { search: 'default' };
        this.searchPrediction(data)

    }

    callChannelDropDown = () => {
        let type = $('#typeDropDown').val();
        let classRef = this;
        axios({
            url: Constant.url + 'get-channels-by-type',
            method: 'POST',
            data: { type: type },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {

                classRef.setState({
                    channelDropDown: {
                        isLoading: true,
                        list: response.data.data.channels
                    }
                })
            }
        })
    }

    handleChannelDropdown = () => {
        let result = "";
        let index = 0;
        result = this.state.channelDropDown.list.map((response, index) => (
            <option value={response.channel_id}>{response.channel_name}</option>
        ));
        return result;
    }
    
    callTypeDropDown = () => {
        let classRef = this;
        axios({
            url: Constant.url + 'channel-types',
            method: 'GET',
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                
                
                classRef.setState({
                    typesDropDown: {
                        isLoading: true,
                        list: response.data.data.types
                    }
                })
            }
        })
    }

    handleTypeDropdown = () => {
        let result = "";
        let index = 0;
        result = this.state.typesDropDown.list.map((response, index) => (
            <option value={response.id}>{response.type}</option>
        ));
        return result;
    }

    callProgramDropDown = () => {
        let channel_id = $('#channelDropDown').val();
        let classRef = this;
        axios({
            url: Constant.url + 'program-by-channel',
            method: 'POST',
            data: { channel_id: channel_id },
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                
                
                classRef.setState({
                    programDropDown: {
                        isLoading: true,
                        list: response.data.data
                    }
                })
            }
        })
    }

    handleProgramDropdown = () => {
        let result = "";
        let index = 0;
        result = this.state.programDropDown.list.map((response, index) => (
            <option value={response.id}>{response.name}</option>
        ));
        return result;
    }
    
    
    searchPrediction = (keyword) => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "search-prediction",
            data: keyword,
            headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") }
        }).then(function (result) {
            let response = {};
            response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
            if (response.data.code === 200) {
                classRef.setState({
                    suggestion: {
                        isLoaded: true,
                        searchKeyword: (response.data.data.suggestions) ? response.data.data.suggestions : [],
                        searchChannels: (response.data.data.channels) ? response.data.data.channels : [],
                        searchEpisodes: (response.data.data.episodes) ? response.data.data.episodes : [],
                    }
                })
            }
        }).then(function (response) {

        });
    };

    keywordHandler = () => {
        let keywords = "";
        keywords = this.state.suggestion.searchKeyword.map((keyword, index) => (
            <li>
                <a href={"/search?search=" + keyword.search}>{keyword.search}</a>
            </li>
        ));
        return keywords;
    }

    channelHandler = () => {
        let searchChannels = "";
        searchChannels = this.state.suggestion.searchChannels.map((channels, index) => (
            <li>
                <Link to={'/' + channels.channel_slug}>
                    <div className="searchChannelList">
                        <img src={channels.channel_logo_jpg} />
                        <div className="searchChannelText">
                            <p>{channels.channel_name}</p>
                            <span>News Channel</span>
                        </div>
                    </div>
                </Link>
            </li>
        ));
        return searchChannels;
    }

    episodeHandler = () => {
        let episodes = "";
        episodes = this.state.suggestion.searchEpisodes.map((episode, index) => (
            <li>
                <Link to={'/watch/' + episode.episode_slug}>
                    <div className="searchVideoList">
                        <img src={episode.episode_thumbnail} />
                        <div className="searchVideoText">
                            <p>{episode.episode_name}</p>
                            <span>{episode.channel_name + ' ' + episode.episode_date}</span>
                        </div>
                    </div>
                </Link>
            </li>
        ));
        return episodes;
    }

    mobileSearchFilterShow = () => {
        $('.MobileFilterSection').toggleClass('MobileFilterShow');
        $('body').toggleClass('overflowHidden')
    }

    handleKeyPress = (event) => {
        if ($("#search_filter").val() == "") {
            $('.searchSec').removeClass('noBtmRadius');
        } else {
            $('.searchSec').addClass('noBtmRadius');
            $('.searchSec').removeClass('ShowFilterBox');
            // $('.SearchFilterbtn').show();
            $('.search_btn').show();
            $('.SearchFilterClosebtn').hide();
            $("#filterrelated>ul>li>a.active").removeClass("active");
        }
        this.setState({
            suggestion: {
                isLoaded: false,
                searchKeyword: [],
                searchChannels: [],
                searchEpisodes: [],
            }
        })
        var data = { search: $("#search_filter").val() };
        this.searchPrediction(data)
    }

    advanceSearch = () => {            
        window.location.href = "/search?search="+$("#search_filter").val()+"&searchtype=advance&from="+$('#FromSearchDateFilter').val()+"&to="+$('#ToSearchDateFilter').val()+"&type="+$('#typeDropDown').val()+"&channel="+$('#channelDropDown').val()+"&program="+$('#programDropDown').val();
    }

    goBack = () =>{
        window.history.back();
    }

    render() {
        return (
            <div>
                <div className="SuggestionsSearchHead">
                    <div className="uk-container">
                        <button className="MobileheaderBack" onClick={this.goBack}><img src="/images/player_back.svg" uk-svg="" alt="Back" /></button>
                        <input className="mobileSearchFld" autocomplete="off" type="search" placeholder="Search" id="search_filter" placeholder="search" onChange={this.handleKeyPress} />
                        <a className="MobileSearchFilterbtn" onClick={this.mobileSearchFilterShow} href={void (0)}><img src="/images/playlist_play.svg" uk-svg="" alt="search Filter" /></a>
                    </div>
                </div>
                {/* Mobile FIlter Section */}
                <div className="MobileFilterSection">
                    <div className="uk-container">
                        <form action="javascript:void(0);">
                            <div className="MobileFilterSectionInner">
                                <h1>Video Search Filter</h1>
                                <div className="MobileFldBox">
                                    <label>From</label>
                                    <input
                                        className="uk-input SearchDate"
                                        id="FromSearchDateFilter"
                                        type="text"
                                        placeholder="From"
                                    />
                                </div>
                                <div className="MobileFldBox">
                                    <label>To</label>
                                    <input
                                        className="uk-input SearchDate"
                                        id="ToSearchDateFilter"
                                        type="text"
                                        placeholder="To"
                                    />
                                </div>
                                <div className="MobileFldBox">
                                    <label>Type</label>
                                    <select id="typeDropDown" onChange={this.callChannelDropDown} className="uk-select">
                                        <option value="">Select Type</option>
                                        {(this.state.typesDropDown.isLoading) ? this.handleTypeDropdown() : ''}
                                    </select>
                                </div>
                                <div className="MobileFldBox">
                                    <label>Channel</label>
                                    <select id="channelDropDown" onChange={this.callProgramDropDown} className="uk-select">
                                        <option value="">Select Channel</option>
                                        {(this.state.channelDropDown.isLoading) ? this.handleChannelDropdown() : ''}
                                    </select>
                                </div>
                                <div className="MobileFldBox">
                                    <label>Programs</label>
                                    <select id="programDropDown" className="uk-select">
                                        <option value="">Select Program</option>
                                        {(this.state.programDropDown.isLoading) ? this.handleProgramDropdown() : ''}
                                    </select>
                                </div>

                            </div>
                            <div className="MobileSearchFilterBar">
                                <button onClick={this.advanceSearch}>Apply</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* Mobile FIlter Section */}
                <div className="SuggestionsSearchBody">
                    <div className="uk-container">
                        {/* Predictions Search Start */}
                        <ul className="MobileSearchPredictions">
                            {(this.state.suggestion.searchKeyword.length > 0) ? this.keywordHandler() : ''}
                            {(this.state.suggestion.searchChannels.length > 0) ? <li><h4>Channels</h4></li> : ''}
                            {(this.state.suggestion.searchChannels.length > 0) ? this.channelHandler() : ''}
                            {(this.state.suggestion.searchEpisodes.length > 0) ? <li><h4>Videos</h4></li> : ''}
                            {(this.state.suggestion.searchEpisodes.length > 0) ? this.episodeHandler() : ''}
                            <li>

                            </li>
                        </ul>
                        {/* Predictions Search End */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MobileSearchSuggestions);