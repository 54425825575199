import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import ImageWebp from 'react-image-webp';
import Constant from "../../utils/Constant";
import axios from 'axios';
import LocalStorageService from "../../utils/LocalStorageService";
import Header from "../common/Header";
class LiveChannels extends React.Component {



  componentDidMount() {
    window.imgNotFound();
  }

  addToFavourite = (heart_id, channelId) => {
    
    if (!localStorage.getItem('user_id')) {
      new Header().openLoginModal();
      return;
    }
    if ($("#" + heart_id).hasClass('active')) {

      $("#heart_8632" + channelId).removeClass('active')
      $("#" + heart_id).removeClass('active');
      let classRef = this;
      axios({
        method: 'POST',
        url: Constant.url + "remove-favourite",
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
        data: { id: channelId, user_id: localStorage.getItem('user_id'),type: "channel" },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
        
        if (response.data.code === 200 && response.data.status === true) {


        }
      })
    } else {
      $("#" + heart_id).addClass('active');
      $("#heart_8632" + channelId).addClass('active')
      let classRef = this;
      let params = JSON.stringify({channel_id: channelId, user_id: localStorage.getItem('user_id')});
      axios({
        method: 'POST',
        url: Constant.url + "add-user-favourite-channels-2",
        headers: { 'Authorization': "bearer " + localStorage.getItem("access_token") },
        // data: { dataparams: LocalStorageService.encryptAes(params)},
        data: { channel_id: channelId, user_id: localStorage.getItem("user_id") },
      }).then(function (result) {
        let response = {};
        response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
        
        if (response.data.code === 200 && response.data.status === true) {

        }
      })
    }
  }




        




  handleChannelList = () => {
    let channels = "";
    channels = this.props.data.map((channel, index) => (
      <li key={index} className="">
        <Link className="channel_sldr_box" to={channel.channel_status === 'live' ? `/${channel.channel_slug}` : `/${channel.channel_slug}`}>
            <div className="channel_sldr_img">
              <ImageWebp webp={channel.channel_logo} src={channel.channel_logo_jpg} alt={channel.channel_name}/>
              <div className="chnl_hvr_box"><span uk-icon="play"></span></div>
              <span className="live_span">{channel.channel_status === 'live' ? channel.channel_status : ''}</span>
            </div>
          </Link>
        </li>
    ));
    return channels;
  };



  // handleChannelList = () => {
  //   let channels = "";
  //   channels = this.props.data.map((channel, index) => (
  //     <li key={index} className="uk-width-1-6@l uk-width-1-5@m uk-width-1-3@s uk-width-1-2">
  //       <div className="live_style live_style_full RelatedFilterBox">
  //         <Link className="fav_sld_img" to={(channel.channel_status === 'live' ? '/' + channel.channel_slug : "/channel/" + channel.channel_slug)}>
  //           <ImageWebp className="img_log" webp={channel.channel_logo} src={channel.channel_logo} alt={channel.channel_name} />
  //           <ImageWebp webp={channel.channel_live_image} src={channel.channel_live_image_jpg} alt={channel.channel_name} />
  //           <h4>{channel.channel_name}</h4>
  //           <span className="views_txt">{channel.channel_views}</span>
  //         </Link>
  //         {/* {(channel.is_fav) ?
  //           <span className="relatedHeart active" id={"heart_8632" + channel.channel_id} uk-icon="icon: heart" onClick={(e) => this.addToFavourite("heart_8632" + channel.channel_id, channel.channel_id, e)}></span>
  //           : <span className="relatedHeart" uk-icon="icon: heart" id={"heart_8632" + channel.channel_id} onClick={(e) => this.addToFavourite("heart_8632" + channel.channel_id, channel.channel_id, e)}></span>
  //         } */}
          
  //       </div></li>
  //   ));
  //   return channels;
  // };

  render() {
    return (
      <div>
        <section id={(this.props.typeid) ? "type" + this.props.typeid : ''} className={(this.props.ad_class === 'a') ? "pad_left pad_tb pad_right channel_box_sec" : "pad_left pad_t pad_right channel_box_sec"}>
          <div className="headng_box">
            <h2 className="h2forhome" style={{paddingLeft:'0'}}><strong>{this.props.name}</strong></h2>
          </div>
          <ul className="uk-height-match uk-grid-small" uk-grid="">
            {this.handleChannelList()}
          </ul>
        </section>
      </div>
    );
  }
}

export default LiveChannels;